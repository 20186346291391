<template>
  <AbstractPassengerModal v-bind="$attrs" v-on="$listeners" :strategy="strategy" :entity="entity">
    <template #header="{ onClose }">
      <AbstractModalHeader title="Dodaj pasażera">
        <template #close>
          <CloseIconButton @click="onClose"></CloseIconButton>
        </template>
      </AbstractModalHeader>
    </template>
  </AbstractPassengerModal>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import AbstractPassengerModal from './abstract-passenger-modal';
import CreatePassengerStrategy from '../create-passenger-strategy';

export default {
  name: 'CreatePassengerModal',
  components: {
    AbstractPassengerModal,
    AbstractModalHeader,
    CloseIconButton,
  },
  computed: {
    ...mapGetters(['isCompany', 'user']),
    strategy() {
      return new CreatePassengerStrategy(this.$store);
    },
    entity() {
      return {
        user: {
          firstname: null,
          surname: null,
          username: null,
          email: null,
          company: this.isCompany ? this.user.company : null,
          home: null,
          employeeGroup: null,
          phones: [{ '@id': 0, phone: null, main: false }],
          car: null,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
