<template>
  <v-col cols="12" class="mb-0">
    <div class="px-6 mb-4" v-if="title">
      <h2 class="grey--text">{{ title }}</h2>
    </div>

    <v-card class="pb-6 pt-0 px-0">
      <v-container fluid class="pb-0 mb-0">
        <v-row>
          <slot></slot>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'OneFormSection',
  props: { title: String, icon: String },
};
</script>

<style scoped>
h2 {
  font-weight: 400;
}
</style>
