<template>
  <v-toolbar flat color="transparent">
    <v-btn icon v-if="back !== undefined" :to="back" exact data-cy="header-back-button">
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <v-toolbar-title v-if="title !== undefined">{{ title }}</v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  name: 'OnePageHeader',
  props: { title: String, back: Object },
};
</script>
