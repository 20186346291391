// eslint-disable-next-line import/prefer-default-export
export class CreateEmployeeGroupStrategy {
  constructor(store) {
    this.store = store;
  }

  save(form) {
    return this.store.dispatch('employeeGroup/createEmployeeGroup', form);
  }
}
