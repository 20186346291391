import dayjs from 'dayjs';
import Vue from 'vue';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import TaxiCourseService from '@/service/TaxiCourseService';
import { FillingPassengerAlgorithm, RemovalPassengerAlgorithm } from '@/domain/PassengerFillingAlgorithm';
import { isEqual } from 'lodash';
import * as types from './types/course-creation-mutation-types';

const defaultCourse = () => ({
  contractor: null,
  subcontractor: null,
  rides: [
    {
      '@type': COURSE_TYPE_DICT.TAXI_COURSE_RIDE,
      company: null,
      driverHasToAcceptRelative: dayjs().add(15, 'minute').format('YYYY-MM-DDTHH:mm'),
      status: TAXI_COURSE_RIDE_STATUS.PLANNING,
      fromStartingBase: {
        waypointStart: { place: null },
        waypointEnd: {},
      },
      segments: [
        {
          locomotives: [],
          employees: [],
          waypointStart: { time: `${dayjs().format('YYYY-MM-DD')}T` },
          waypointEnd: { time: `${dayjs().format('YYYY-MM-DD')}T` },
          position: 0,
        },
      ],
      position: 0,
      number: null,
    },
  ],
});

export default {
  namespaced: true,
  state: {
    course: defaultCourse(),
  },
  getters: {
    course: (state) => state.course,
    segments: (state) => state.course.rides[0].segments,
    courseCompany: (state) => state.course.rides[0].company,
  },
  mutations: {
    [types.UPDATE_WAYPOINTS_POSITION]: (state) => {
      const { segments } = state.course.rides[0];

      state.course.rides[0].segments = segments.map((waypoint, waypointIdx) => ({
        ...waypoint,
        position: waypointIdx,
      }));
    },
    [types.RESET_WAYPOINT_DISTANCE]: (state, index) => {
      const { segments } = state.course.rides[0];
      segments.splice(index, 1, {
        ...segments[index],
        distance: null,
        time: null,
      });
    },
    [types.ADD_WAYPOINT]: (state, indexToAdd) => {
      const { segments } = state.course.rides[0];
      segments.splice(indexToAdd, 0, {
        employees: [],
        waypointStart: { time: `${dayjs().format('YYYY-MM-DD')}T` },
        waypointEnd: { time: `${dayjs().format('YYYY-MM-DD')}T` },
        locomotives: segments[segments.length - 1].locomotives,
        position: indexToAdd,
      });
    },
    [types.SET_LOCOMOTIVE]: (state, { index, locomotives }) => {
      const { segments } = state.course.rides[0];
      state.course.rides[0].segments = segments.map((segment, segmentIdx) =>
        segmentIdx >= index
          ? {
              ...segment,
              locomotives,
            }
          : segment
      );
    },
    [types.DELETE_WAYPOINT]: (state, indexToDelete) => {
      state.course.rides[0].segments.splice(indexToDelete, 1);
    },
    [types.SET_PLACE]: (state, { index, place }) => {
      const { segments } = state.course.rides[0];
      segments.splice(index, 1, {
        ...segments[index],
        waypointStart: {
          ...segments[index].waypointStart,
          place,
        },
      });
    },
    [types.SET_WAYPOINT_TIME]: (state, position = null) => {
      const { segments } = state.course.rides[0];
      const route = [];
      const isDistanceCalculated = (node) => node?.distance;

      for (let i = 0; i < segments.length; i += 1) {
        if (isDistanceCalculated(segments[i])) {
          route.push([segments[i], segments[i + 1]]);
        }
      }

      const data = TaxiCourseService.calculateRouteTime(route, position);
      // eslint-disable-next-line no-unused-vars
      data.forEach((val, key, _) => {
        Vue.set(segments, key, val);
      });
    },
    [types.RESET_COURSE]: (state) => {
      state.course = defaultCourse();
    },
    [types.SET_COURSE]: (state, course) => {
      state.course = course;
    },
    [types.UPDATE_WAYPOINT_ID]: (state, waypointIds) => {
      Vue.set(
        state.course.rides[0],
        'segments',
        state.course.rides[0].segments.map((segment, idx) => ({
          ...segment,
          waypointStart: {
            ...segment.waypointStart,
            '@id': waypointIds[idx],
          },
        }))
      );
    },
    [types.SET_COMPANY]: (state, company) => {
      state.course.rides[0].company = company;
    },
    [types.RESET_RIDE_ACCEPTANCE_TIME]: (state) => {
      state.course.rides[0].driverHasToAcceptRelative = dayjs().add(15, 'minute').format('YYYY-MM-DDTHH:mm');
    },
    [types.UPDATE_EMPLOYEES_IN_COURSE]: (state, newSegments) => {
      state.course.rides[0].segments = state.course.rides[0].segments.map((segment, idx) => ({
        ...segment,
        employees:
          isEqual(segment.employees, newSegments[idx].employees) === false
            ? newSegments[idx].employees
            : segment.employees,
      }));
    },
  },
  actions: {
    async calculateWaypointDistance({ getters, commit, dispatch }, { index, companyId }) {
      commit('taxiCourse/SET_LOADING', true, { root: true });

      const route = await dispatch('getSiblingWaypointsWithCalculatedDistance', index);

      if (route.length >= 2) {
        const data = await TaxiCourseService.calculateRoute({ segments: route, companyId });

        data.forEach((item) => {
          Vue.set(getters.segments, item.position, item);
        });

        commit(types.SET_WAYPOINT_TIME, index);
      }

      commit('taxiCourse/SET_LOADING', false, { root: true });
    },
    async calculateKilometerWaypointDistance({ state, commit, dispatch }, { index, companyId }) {
      const { segments } = state.course.rides[0];

      const route = await dispatch('getSiblingWaypointsWithCalculatedDistance', index);

      if (route.length >= 2) {
        const data = await TaxiCourseService.calculateKilometerRoute({ segments: route, companyId });

        data.forEach((item) => {
          Vue.set(segments, item.position, item);
        });

        commit(types.SET_WAYPOINT_TIME, index);
      }
    },
    async calculateKilometerWaypointDistanceWithoutTime({ state, commit, dispatch }, { index, companyId }) {
      const { segments } = state.course.rides[0];

      const route = await dispatch('getSiblingWaypointsWithCalculatedDistance', index);

      if (route.length >= 2) {
        const data = await TaxiCourseService.calculateKilometerRoute({ segments: route, companyId });

        data.forEach((item) => {
          Vue.set(segments, item.position, item);
        });

        commit(types.SET_WAYPOINT_TIME, index);
      }
    },
    getSiblingWaypointsWithCalculatedDistance({ state }, index) {
      const { segments } = state.course.rides[0];
      const route = [segments[index]];

      if (segments[index].waypointStart.place === null) {
        return [];
      }
      if (segments[index - 1]?.waypointStart?.place) {
        route.unshift(segments[index - 1]);
      }

      if (segments[index + 1]?.waypointStart?.place) {
        route.push(segments[index + 1]);
      }

      return route;
    },
    addWaypoint({ commit, dispatch }, index) {
      commit(types.ADD_WAYPOINT, index);

      dispatch('removeDistanceOnPreviousWaypoint', index);

      commit(types.UPDATE_WAYPOINTS_POSITION);
    },
    removeDistanceOnPreviousWaypoint({ commit }, index) {
      const PREVIOUS_INDEX = index - 1;
      const FIRST_WAYPOINT_INDEX = 0;

      if (index > FIRST_WAYPOINT_INDEX) {
        commit(types.RESET_WAYPOINT_DISTANCE, PREVIOUS_INDEX);
      }
    },
    async deleteWaypoint({ commit, rootGetters, state, dispatch }, index) {
      const { segments } = state.course.rides[0];
      const MINIMAL_WAYPOINTS_LENGTH = 1;

      if (segments.length === MINIMAL_WAYPOINTS_LENGTH) {
        return;
      }

      const newSegments = RemovalPassengerAlgorithm.execute({
        waypoints: JSON.parse(JSON.stringify(segments)),
        passengers: rootGetters.passengersInCourse,
        currentUser: rootGetters.user,
        removedWaypoint: segments[index],
      });

      commit(types.UPDATE_EMPLOYEES_IN_COURSE, newSegments);

      commit(types.DELETE_WAYPOINT, index);
      await dispatch('removeDistanceOnPreviousWaypoint', index);
      commit(types.UPDATE_WAYPOINTS_POSITION);
      if (state.course.rides[0].segments[index]) {
        await dispatch('calculateWaypointDistance', { index, companyId: 'test' });
      }
    },
    setPlace({ commit, dispatch, getters, rootGetters }, { index, place }) {
      commit(types.SET_PLACE, { index, place });

      const segments = FillingPassengerAlgorithm.execute({
        waypoints: JSON.parse(JSON.stringify(getters.segments)),
        passengers: rootGetters.passengersInCourse,
        currentUser: rootGetters.user,
      });

      commit(types.UPDATE_EMPLOYEES_IN_COURSE, segments);

      if (place === null) {
        commit(types.RESET_WAYPOINT_DISTANCE, index);

        dispatch('removeDistanceOnPreviousWaypoint', index);
      }
    },
    updateWaypointsId({ commit }, waypointIds) {
      commit(types.UPDATE_WAYPOINT_ID, waypointIds);
    },
    setCompany({ commit }, company) {
      commit(types.SET_COMPANY, company);
    },
    resetRideAcceptanceTime({ commit }) {
      commit(types.RESET_RIDE_ACCEPTANCE_TIME);
    },
  },
};
