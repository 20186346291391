export default {
  data() {
    return {
      registrationInstance: null,
      updateAvailable: false,
      refreshing: false,
    };
  },
  created() {
    document.addEventListener('swUpdated', this.showUpdateBanner, { once: true });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;

      this.refreshing = true;
      window.location.reload();
    });
  },
  computed: {
    isServiceWorkerWaiting() {
      return this.registrationInstance || this.registrationInstance.waiting;
    },
  },
  methods: {
    showUpdateBanner(event) {
      this.registrationInstance = event.detail;
      this.updateAvailable = true;
    },
    refreshApp() {
      this.hideUpdateBanner();

      if (this.isServiceWorkerWaiting) {
        this.registrationInstance.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    hideUpdateBanner() {
      this.updateAvailable = false;
    },
  },
};
