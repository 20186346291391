const splitCourse = ({ course, ride, idx }) => {
  const courseId = `${course.id}-course-${idx}`;
  return {
    ...course,
    id: courseId,
    rides: [{ ...ride, courseId }],
  };
};

// eslint-disable-next-line import/prefer-default-export
export class RailwayCompanyAbstractCourseMapper {
  static toModel(course) {
    if (course.rides.length > 1) {
      return course.rides.map((ride, idx) => splitCourse({ course, ride, idx }));
    }

    return course;
  }

  static toDTO(course) {
    return course;
  }
}
