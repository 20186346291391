<template>
  <v-snackbar
    v-if="employeeRequest && messageUnread"
    v-model="employeeRequest"
    :timeout="-1"
    outlined
    color="transparent"
    class="employee-request-snackbar"
    left
  >
    <employee-request-list-item
      :employee-request="employeeRequest"
      closable
      @close="close"
      @requestClicked="requestClicked"
    />
  </v-snackbar>
</template>
<script>
import EmployeeRequestListItem from '@/component/EmployeeRequest/employee-request-list-item';

export default {
  name: 'EmployeeRequestSnackbar',
  components: { EmployeeRequestListItem },
  computed: {
    employeeRequest() {
      return this.$store.getters['employeeRequest/lastUnread'];
    },
    messageUnread() {
      const lastMessage = this.employeeRequest.messages.at(-1);

      return (
        lastMessage.createdBy.id !== this.$store.getters.user.id &&
        !lastMessage.viewedBy.filter((user) => user.id === this.$store.getters.user.id).length
      );
    },
  },
  methods: {
    close() {
      this.$store.dispatch('employeeRequest/clearLastUnread');
    },
    requestClicked(employeeRequest) {
      this.close();
      this.$router.push({
        name: 'employee_request_index',
        params: { selectedEmployeeRequestId: employeeRequest.id },
      });
    },
  },
};
</script>
<style lang="scss">
.employee-request-snackbar {
  .v-snack__content {
    padding: 0;
  }
  .v-snack__wrapper.v-sheet {
    background: transparent;
  }
}
</style>
