<template>
  <v-btn
    :block="block"
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :icon="icon"
    class="text-subtitle-1 font-weight-medium"
    small
    :height="height"
    text
    @click="click"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: 'TextButton',
  props: {
    icon: Boolean,
    disabled: Boolean,
    block: Boolean,
    height: Number,
    color: { default: 'primary' },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
