import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  fetchDrivers() {
    return Vue.http.get('api/drivers');
  },
  createPayload({ entity }) {
    const { startingBase, employedSince, user } = entity;
    return {
      user: {
        firstname: user.firstname,
        surname: user.surname,
        username: user.username,
        email: user.email,
        phones: entity.user.phones.map((val) => ({ phone: val.phone, main: val.main })).filter((val) => val.phone),
        company: user.company ? user.company['@id'] : null,
        home: user.home ? user.home['@id'] : null,
        id: user['@id'],
      },
      startingBase: startingBase !== null ? startingBase['@id'] : null,
      employedSince,
    };
  },
  createDriver(payload) {
    return Vue.http
      .post('api/drivers', this.createPayload(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(
          defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd dodawania')
        );

        throw data;
      });
  },
  updateDriver(payload) {
    return Vue.http
      .put(`api/drivers/${payload.entity.id}`, this.createPayload(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd edycji'));

        throw data;
      });
  },
  deleteDriver(payload) {
    return Vue.http
      .delete(`api/drivers/${payload.id}`)
      .then(() => {
        Vue.notify(`${payload.name} został usunięty`);
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd usuwania'));

        throw data;
      });
  },
};
