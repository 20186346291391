<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import AddEmployeeGroupHeader from './add-employee-group-header';
import EditEmployeeGroupHeader from './edit-employee-group-header';

export default {
  name: 'EmployeeGroupHeaderFactory',
  inheritAttrs: false,
  props: {
    isEditModal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    currentComponent() {
      return this.isEditModal ? EditEmployeeGroupHeader : AddEmployeeGroupHeader;
    },
  },
};
</script>

<style lang="scss" scoped></style>
