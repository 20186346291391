import { ROLES } from '@/core/dict/role-dict';

const UserList = () => import('@/controller/User/user-list');
const UserCreate = () => import('@/controller/User/user-create');
const UserShow = () => import('@/controller/User/user-show');
const UserEdit = () => import('@/controller/User/user-edit');

const name = 'user';

export default [
  {
    path: '/users',
    name: `${name}_index`,
    component: UserList,
    meta: {
      title: 'Użytkownicy',
      create: `${name}_create`,
      roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER, ROLES.ROLE_COMPANY],
    },
  },
  {
    path: '/users/create',
    name: `${name}_create`,
    component: UserCreate,
    meta: {
      title: 'Użytkownicy',
    },
  },
  {
    path: '/users/:id',
    name: `${name}_show`,
    component: UserShow,
    meta: {
      title: 'Użytkownik',
    },
  },
  {
    path: '/users/:id/edit',
    name: `${name}_edit`,
    component: UserEdit,
    meta: {
      title: 'Użytkownik',
    },
  },
];
