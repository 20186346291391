<template>
  <v-col :class="sizeableClasses">
    <v-menu allow-overflow absolute :close-on-content-click="false" backgound-color="white" content-class="white">
      <template #activator="data">
        <v-text-field
          v-on="data.on"
          :value="datetime"
          :autofocus="autofocus"
          :filled="filled"
          :clearable="clearable"
          :color="color"
          :dark="dark"
          :disabled="disabled"
          :error="error"
          :flat="flat"
          :height="height"
          :prepend-icon="icon"
          :label="label"
          :loading="loading"
          :outline="outline"
          :placeholder="placeholder"
          :solo="solo"
          :success="success"
          :type="type"
          :min="min"
          :max="max"
          readonly
          :hide-details="!rules && !hint"
          :hint="hint"
          :persistent-hint="!!hint"
          :rules="rules"
          @click:clear="clear"
        />
      </template>

      <v-row class="fluid">
        <v-col cols="12" md="6">
          <v-date-picker
            data-cy="datetime-date-input"
            v-model="date"
            @input="setDate"
            locale="pl"
            first-day-of-week="1"
            class="elevation-0"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-time-picker
            data-cy="datetime-time-input"
            v-model="time"
            @input="setTime"
            locale="pl"
            first-day-of-week="1"
            format="24hr"
            class="elevation-0"
          />
        </v-col>
      </v-row>
    </v-menu>
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';
import dayjs from 'dayjs';

export default {
  name: 'OneFieldDateTime',
  mixins: [sizeable],
  props: {
    autofocus: Boolean,
    filled: Boolean,
    clearable: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    error: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    height: null,
    icon: String,
    label: String,
    min: Number,
    max: Number,
    value: String,
    loading: Boolean,
    outline: Boolean,
    placeholder: String,
    readonly: Boolean,
    solo: Boolean,
    success: Boolean,
    type: String,
    hint: String,
    rules: Array,
  },

  created() {
    if (this.value !== null) {
      this.date = dayjs(this.value).format(this.format.date);
      this.time = dayjs(this.value).format(this.format.time);
    } else {
      this.date = null;
      this.time = null;
    }
    this.$emit('input', this.datetime);
  },

  methods: {
    setDate(date) {
      this.date = date === null || date === undefined ? null : date;
      this.$emit('input', this.datetime);
    },

    setTime(time) {
      this.time = time === null || time === undefined ? null : time;
      this.$emit('input', this.datetime);
    },

    clear() {
      this.setDate();
      this.setTime();
      this.$emit('input', null);
    },
  },

  data() {
    return {
      date: null,
      time: null,

      format: {
        time: 'HH:mm',
        date: 'YYYY-MM-DD',
      },
    };
  },

  computed: {
    datetime: {
      get() {
        if (!!this.date && !!this.time) {
          return `${this.date} ${this.time}`;
        }
        if (this.date) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.time = dayjs(0).format(this.format.time);
          return `${this.date} ${this.time}`;
        }
        if (this.time) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.date = dayjs().format(this.format.date);
          return `${this.date} ${this.time}`;
        }
        return null;
      },
    },
  },

  watch: {
    value() {
      if (this.value !== null) {
        this.date = dayjs(this.value).format(this.format.date);
        this.time = dayjs(this.value).format(this.format.time);
      } else {
        this.date = null;
        this.time = null;
      }
      this.$emit('input', this.datetime);
    },
  },
};
</script>

<style>
.v-picker {
  border-radius: 0 !important;
}

.v-picker__title {
  height: 100px !important;
}

.v-time-picker-title {
  height: 100px !important;
  padding: 0;
}
</style>
