export const INVOICE_TYPE = {
  PROFORMA: 1,
  ISSUED: 2,
  SENT_FOR_PAYMENT: 3,
  PAID: 4,
  CANCELED: 10,
};

export const INVOICE_TYPE_DICT = {
  [INVOICE_TYPE.PROFORMA]: 'Proforma',
  [INVOICE_TYPE.ISSUED]: 'Wystawiona',
  [INVOICE_TYPE.SENT_FOR_PAYMENT]: 'Przekazana do płatności',
  [INVOICE_TYPE.PAID]: 'Opłacona',
  [INVOICE_TYPE.CANCELED]: 'Anulowana',
};
