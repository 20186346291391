<template>
  <v-row class="my-0 pa-0">
    <slot></slot>
  </v-row>
</template>

<script>
export default {
  name: 'OneProfile',
};
</script>
