import Vue from 'vue';
import router from '@/core/config/router';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';

function transformSegmentPayload(segment, index, ride) {
  // TODO: investigate dayjs behavior with isValid
  const isTimeValid = (date) => !!date.split('T')[1];

  return {
    ...segment,
    position: index,
    locomotives: segment.locomotives?.map((locomotive) => locomotive['@id']) ?? [],
    fallbackTime: segment.fallbackTime
      ? +`${segment.fallbackTime}:00`.split(':').reduce((acc, time) => 60 * acc + +time)
      : null,
    waypointStart: {
      place: segment.waypointStart.place?.['@id'],
      time: isTimeValid(ride.segments[index].waypointStart.time) ? ride.segments[index].waypointStart.time : '',
    },
    waypointEnd: {
      place: ride.segments[index + 1]
        ? ride.segments[index + 1].waypointStart.place?.['@id']
        : ride.segments[0].waypointStart.place?.['@id'],
      time: ride.segments[index + 1]
        ? ride.segments[index + 1].waypointStart.time
        : ride.segments[index].waypointEnd.time,
    },
    employees: segment.employees ? segment.employees.map((employee) => employee['@id']) : [],
  };
}

export default {
  fetchFavouritePlaces(passengerId) {
    return Vue.http.get(`api/passengers/${passengerId}`).then(({ favouritePlaces }) => favouritePlaces);
  },

  updatePassenger({ passengerId, payload }) {
    return Vue.http
      .put(`api/passengers/${passengerId}`, payload)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  fetchRecentlyUsedPlaces() {
    return Vue.http.get(`api/abstract_courses?itemsPerPage=3&rides.status[neq]=cancelled`).then(({ data }) => data);
  },

  createKilometerCourse(kilometerCourse, passenger) {
    const isSegmentTimeValid = (course) =>
      course.rides[0].segments.find((segment) => {
        const isTimeValid = (date) => !!date?.split('T')[1];
        return isTimeValid(segment.waypointStart.time) || isTimeValid(segment.waypointEnd.time);
      }) !== undefined;

    if (!isSegmentTimeValid(kilometerCourse)) {
      Vue.notify({
        group: 'global',
        type: 'error',
        title: 'Błąd',
        text: 'Należy uzupełnić czas na wszystkich przystankach',
      });

      return Promise.reject();
    }

    return Vue.http
      .post(`api/kilometer_courses`, this.preparePayload(kilometerCourse, passenger))
      .then(() => {
        Vue.notify(defaultApiResultManager.then());

        router.push({ name: TAXI_COURSE_LIST_ROUTE });
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  preparePayload(kilometerCourse, passenger) {
    const { rides } = kilometerCourse;
    return {
      driver: passenger['@id'],
      rides: rides.map((ride, rideIdx) => ({
        company: passenger.company['@id'],
        type: 'kilometerCourseRide',
        status: 'created',
        position: rideIdx,
        segments: ride.segments
          .map((segment, index) => transformSegmentPayload(segment, index, ride))
          .filter((_, index) => index < ride.segments.length - 1),
      })),
    };
  },
};
