<template>
  <v-list-item v-if="to" :to="to">
    <v-list-item-avatar v-if="icon !== undefined">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title data-testid="tile-list-title">{{ title }}</v-list-item-title>
      <v-list-item-subtitle data-testid="tile-list-subtitle">{{ subtitle }}</v-list-item-subtitle>
    </v-list-item-content>
    <router-link tag="span" to="#" v-if="buttonCta">
      <v-btn small :color="buttonCta.color" @click="buttonCta.callback(buttonCta.params)" data-testid="tile-cta-button">
        <v-icon>{{ buttonCta.icon }}</v-icon>
        {{ buttonCta.title }}
      </v-btn>
    </router-link>
    <slot></slot>
  </v-list-item>

  <v-list-item v-else>
    <v-list-item-avatar v-if="icon !== undefined">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title data-testid="tile-list-title">{{ title }}</v-list-item-title>
      <v-list-item-subtitle data-testid="tile-list-subtitle">{{ subtitle }}</v-list-item-subtitle>
    </v-list-item-content>
    <router-link tag="span" to="#" v-if="buttonCta">
      <v-btn small :color="buttonCta.color" @click="buttonCta.callback(buttonCta.params)" data-testid="tile-cta-button">
        <v-icon>{{ buttonCta.icon }}</v-icon>
        {{ buttonCta.title }}
      </v-btn>
    </router-link>
    <slot></slot>
  </v-list-item>
</template>

<script>
export default {
  name: 'OneListTile',
  props: { to: null, title: null, subtitle: null, icon: null, buttonCta: null },
};
</script>
