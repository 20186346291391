<template>
  <section>
    <v-radio-group :value="billingModel" @change="$emit('update:billingModel', $event)" :rules="[rules.required]">
      <template #label>
        <BaseTypography variant="subtitle">Model rozliczeniowy</BaseTypography>
      </template>
      <v-radio :value="BILLING_MODEL.ARRIVAL_AND_RETURN_TO_THE_STARTING_BASE">
        <template #label>
          <p class="text-body-2 mb-0 grey--text text--darken-4">Dojazd i powrót do bazy startowej</p>
        </template>
      </v-radio>
      <v-radio :value="BILLING_MODEL.BACK_TO_THE_STARTING_POINT">
        <template #label>
          <p class="text-body-2 mb-0 grey--text text--darken-4">Powrót do miejsca początkowego (tzw. A-B-A)</p>
        </template>
      </v-radio>
    </v-radio-group>
  </section>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import { BILLING_MODEL } from '@/core/dict/billing-model-dict';
import formValidationRules from '@/core/utils/form-validation-rules';

export default {
  name: 'BillingModelSection',
  components: {
    BaseTypography,
  },
  props: {
    billingModel: {
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: formValidationRules.required,
      },
    };
  },
  created() {
    this.BILLING_MODEL = BILLING_MODEL;
  },
};
</script>
