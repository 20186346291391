<template>
  <AbstractModalHeader title="Szczegóły przejazdu">
    <template #prepend-item>
      <CloseIconButton @click="closeDrawer"></CloseIconButton>
    </template>
  </AbstractModalHeader>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';

export default {
  name: 'KilometerCourseHeader',
  components: {
    AbstractModalHeader,
    CloseIconButton,
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer');
    },
  },
};
</script>
