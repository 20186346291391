import dayjs from 'dayjs';

export default {
  methods: {
    calculateDriverWorkingTime(course) {
      const diff = dayjs(course.driverEndAt || new Date()).diff(dayjs(course.driverStartAt), 'minute');
      const hours = Math.floor(diff / 60);
      const minutes = diff - hours * 60;

      return { hours, minutes };
    },
    getFormattedDriverWorkingTime(course) {
      const { hours, minutes } = this.calculateDriverWorkingTime(course);
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes} h`;
    },
  },
};
