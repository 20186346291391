<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" :title="headerTitle">
          <template #prepend-item>
            <BackIconButton @click="navigateToSettings"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="flex-grow-1 pa-4 d-flex flex-column">
          <v-form ref="form" class="flex-grow-1 flex-md-grow-0 mb-4">
            <v-text-field
              class="mb-4"
              v-model="phoneEntityCopy.phone"
              label="Numer telefonu"
              maxlength="9"
              outlined
              hide-details="auto"
              :rules="[rules.required, rules.phoneNumber]"
            ></v-text-field>
            <SwitchField v-model="phoneEntityCopy.main">Domyślny numer telefonu</SwitchField>
          </v-form>
          <v-btn class="flex-grow-0 align-self-md-end" color="primary" @click="submitForm">
            {{ submitButtonText }}
          </v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import SwitchField from '@/component/Field/Switch/switch-field';
import formValidationRules from '@/core/utils/form-validation-rules';
import * as ROUTE_NAMES from '@/core/router/route-names';

export default {
  name: 'UserPhoneSettingsPage',
  components: {
    AbstractModalHeader,
    BackIconButton,
    SwitchField,
  },
  props: {
    headerTitle: {
      type: String,
      required: true,
    },
    strategy: {
      type: Object,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    phoneEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phoneEntityCopy: JSON.parse(JSON.stringify(this.phoneEntity)),
      rules: formValidationRules,
    };
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTE_NAMES.SETTINGS_ROUTE });
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.strategy.execute(this.phoneEntityCopy).then(() => {
          this.navigateToSettings();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
