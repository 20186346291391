<template>
  <DialogFactory :value="value" v-on="$listeners">
    <slot name="header"></slot>
    <v-card-text>
      <v-form style="display: flex; flex-direction: column; row-gap: 12px" ref="form">
        <TextField v-model="entity.name" label="Nazwa marki" required validate-on-blur></TextField>
        <div class="d-flex align-center" v-for="model in entity.models" :key="model.id">
          <TextField v-model="model.name" label="Model" required validate-on-blur></TextField>
          <RemoveIconButton @click="removeModel(model)"></RemoveIconButton>
        </div>
        <base-text-button class="align-self-start" prependIcon="mdi-plus" @click="addModel">
          Dodaj kolejny model
        </base-text-button>
      </v-form>
    </v-card-text>
    <DialogSaveButton @click="saveBrand"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import BaseTextButton from '@/component/Base/base-text-button';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import RemoveIconButton from '@/component/Button/remove-icon-button';
import TextField from '@/component/Field/Generic/text-field';
import formValidationRules from '@/core/utils/form-validation-rules';
import { CarBrand } from '@/shared/models/car-brand.model';

export default {
  name: 'BrandModal',
  components: {
    DialogFactory,
    BaseTextButton,
    DialogSaveButton,
    RemoveIconButton,
    TextField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    strategy: {
      type: Object,
      required: true,
    },
    entity: {
      type: CarBrand,
      required: true,
    },
  },
  data() {
    return {
      entityCopy: this.entity,
      rules: formValidationRules,
    };
  },
  methods: {
    saveBrand() {
      if (this.$refs.form.validate()) {
        this.strategy.execute(this.entity).then(() => {
          this.emitCloseEvent();
        });
      }
    },
    emitCloseEvent() {
      this.$emit('close');
    },
    addModel() {
      this.entity.addModel();
    },
    removeModel(model) {
      this.entity.removeModel(model);
    },
  },
  watch: {
    value() {
      this.entityCopy = this.entity;

      if (this.value === false) {
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
