<template>
  <div class="d-inline">
    <v-list-item class="pb-6 mt-2">
      <kilometer-course-status-field
        :status="filters.rideStatus"
        @input="$emit('input', { ...filters, rideStatus: $event })"
      />
    </v-list-item>
    <v-list-item class="pb-6">
      <passenger-field
        :icon="null"
        :value="filters.kilometerCourseDriver"
        :companyId="companyId"
        label="Kierowca"
        @input="$emit('input', { ...filters, kilometerCourseDriver: $event })"
      />
    </v-list-item>
  </div>
</template>
<script>
import KilometerCourseStatusField from '@/component/Field/KilometerCourse/kilometer-course-status-field';
import PassengerField from '@/component/Field/Passenger/passenger-field';

export default {
  name: 'KilometerCourseFilters',
  components: { KilometerCourseStatusField, PassengerField },
  model: {
    prop: 'filters',
  },
  props: {
    filters: Object,
    companyId: {
      type: Number,
    },
  },
};
</script>
