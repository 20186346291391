<template>
  <one-list :items="items">
    <template #items="props">
      <one-list-tile
        :title="props.item.title"
        :subtitle="props.item.subtitle"
        :to="props.item.to"
        :icon="props.item.icon"
        data-cy="list-tile"
      />
    </template>
  </one-list>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';
import OneList from '@/component/List/list-index';
import OneListTile from '@/component/List/tile-list';

export default {
  name: 'OneProfileSummaryList',
  components: { OneListTile, OneList },
  mixins: [sizeable],
  props: { items: Array },
};
</script>
