<template>
  <AbstractPlaceModal
    :value="isModalOpen"
    :placeEntity="placeEntity"
    :strategy="strategy"
    :key="state.type"
    v-on="$listeners"
  >
    <template #header="{ onClose }">
      <DialogTitleHeader :text="headerText" @click="onClose"></DialogTitleHeader>
    </template>
  </AbstractPlaceModal>
</template>

<script>
import { MODAL_STATE_TYPE } from '@/core/dict/modal-states-dict';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AbstractPlaceModal from './abstract-place-modal';

export default {
  name: 'HomeplaceModal',
  components: {
    AbstractPlaceModal,
    DialogTitleHeader,
  },
  props: {
    state: {
      type: Object,
      required: true,
    },
    strategy: {},
  },
  computed: {
    isModalOpen() {
      return (
        this.state.type === MODAL_STATE_TYPE.CREATE_ENTITY_MODAL ||
        this.state.type === MODAL_STATE_TYPE.EDIT_ENTITY_MODAL
      );
    },
    isCreationModal() {
      return this.state.type === MODAL_STATE_TYPE.CREATE_ENTITY_MODAL;
    },
    isEditionModal() {
      return this.state.type === MODAL_STATE_TYPE.EDIT_ENTITY_MODAL;
    },
    headerText() {
      return this.isCreationModal ? 'Dodaj miejsce zamieszkania' : 'Zmień miejsce zamieszkania';
    },
    placeEntity() {
      return this.isEditionModal
        ? this.state.selectedEntity
        : {
            longitude: null,
            latitude: null,
          };
    },
  },
};
</script>

<style lang="scss" scoped></style>
