<template>
  <AbstractContractorInput
    :items="contractorsOfRailwayCompany"
    :loading="loggedCompanyLoading"
    v-bind="$attrs"
    v-on="$listeners"
    @input="emitContractor"
  ></AbstractContractorInput>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractContractorInput from './abstract-contractor-input';

export default {
  name: 'NonEditableContractorInput',
  inheritAttrs: false,
  components: {
    AbstractContractorInput,
  },
  props: {
    taxiCourse: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['loggedCompany', 'loggedCompanyLoading']),
    // TODO: the same logic as in editable-contractor-input
    contractorsOfRailwayCompany() {
      return this.loggedCompany?.contractors?.map(({ contractor }) => contractor) ?? [];
    },
  },
  created() {
    this.$store.dispatch('fetchCompanyById', this.taxiCourse.rides[0].company.id);
  },
  methods: {
    emitContractor(val) {
      this.taxiCourse.contractor = val;
      this.taxiCourse.subcontractor = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
