<template>
  <v-badge dot :value="isVisible" v-bind="$attrs" color="#FF505F"><slot /></v-badge>
</template>

<script>
export default {
  name: 'BasePulseBadge',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-badge__badge {
  animation: pulse 1500ms infinite;
}

@keyframes pulse {
  0% {
    box-shadow: rgb(255, 80, 95) 0 0 0 0;
  }

  75% {
    box-shadow: rgb(255, 80, 95, 0.4) 0 0 0 4px;
  }
}
</style>
