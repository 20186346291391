import { ROLES } from '@/core/dict/role-dict';
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';
import { FIN_SETTLEMENT_TITLE, FIN_SETTLEMENT_PAGE } from '@/core/router/Page/financial-settlement';
import { DRIVER_REPORT_PAGE, CAR_FLEET_PAGE, SUBCONTRACTOR_REPORT_PAGE } from '@/core/router/Page/fleet';

const SINGLE_INTERSECTION = 1;
const MULTIPLE_INTERSECTIONS = 2;

export default [
  {
    icon: 'directions',
    title: 'Kursy',
    link: TAXI_COURSE_LIST_ROUTE,
    roles: [ROLES.ROLE_DEFAULT],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'train',
    title: 'Zgłoszenia maszynistów',
    link: 'employee_request_index',
    roles: [ROLES.ROLE_EMPLOYEE_REQUEST],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'hotel',
    title: 'Noclegi',
    link: 'accommodation_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_HOTEL],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'people',
    title: 'Pasażerowie',
    link: 'passenger_index',
    roles: [
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_USER,
      ROLES.ROLE_COMPANY,
      ROLES.ROLE_USER_COMPANY,
      ROLES.ROLE_TRANSPORT_COMPANY,
    ],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'people',
    title: 'Ewidencja zmian pasażerów',
    link: 'passenger_change_index',
    roles: [ROLES.ROLE_PASSENGER_CHANGE],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'domain',
    title: 'Firmy',
    link: 'company_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'directions_car',
    title: 'Kierowcy',
    link: 'driver_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER, ROLES.ROLE_TRANSPORT_COMPANY],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'directions_car',
    title: 'Urlopy',
    link: 'driver_absence_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER, ROLES.ROLE_TRANSPORT_COMPANY],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'mdi-car-cog',
    title: 'Flota',
    link: 'fleet',
    roles: [ROLES.ROLE_FLEET],
    expectedIntersection: SINGLE_INTERSECTION,
    items: [
      {
        title: 'Samochody',
        link: CAR_FLEET_PAGE,
      },
      {
        title: 'Rozliczenia kierowców',
        link: DRIVER_REPORT_PAGE,
      },
      {
        title: 'Rozliczenia podwykonawców',
        link: SUBCONTRACTOR_REPORT_PAGE,
      },
    ],
  },
  {
    icon: 'pin_drop',
    title: 'Miejsca',
    link: 'place_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER, ROLES.ROLE_TRANSPORT_COMPANY, ROLES.ROLE_COMPANY],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'hotel',
    title: 'Hotele',
    link: 'hotel_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_HOTEL],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'pin_drop',
    title: 'Bazy startowe',
    link: 'starting_base_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_USER, ROLES.ROLE_TRANSPORT_COMPANY],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'terrain',
    title: 'Zdefiniowane kilometry',
    link: 'defined_kilometer_index',
    roles: [ROLES.ROLE_DEFINED_KILOMETERS],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'person',
    title: 'Użytkownicy',
    link: 'user_index',
    roles: [ROLES.ROLE_ADMIN, ROLES.ROLE_COMPANY, ROLES.ROLE_TRANSPORT_COMPANY],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'attach_money',
    title: 'Finanse',
    link: 'finances',
    roles: [ROLES.ROLE_COMPANY_STATISTICS, ROLES.ROLE_COURSE_FINANCIAL_REPORT],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'receipt',
    title: 'Fakturowanie',
    link: 'invoices_index',
    roles: [ROLES.ROLE_INVOICE],
    expectedIntersection: SINGLE_INTERSECTION,
  },
  {
    icon: 'mdi-currency-usd',
    title: FIN_SETTLEMENT_TITLE,
    link: FIN_SETTLEMENT_PAGE,
    roles: [ROLES.ROLE_PASSENGER, ROLES.ROLE_KILOMETER_COURSE],
    expectedIntersection: MULTIPLE_INTERSECTIONS,
  },
];
