<template>
  <AbstractPageToolbar>
    <template #header>
      <v-toolbar-title v-if="!mobileSearchInputVisible">Kursy</v-toolbar-title>
    </template>
    <template #extension>
      <ToolbarSearchField
        class="page-toolbar__search-input page-toolbar__search-input--desktop"
        :value="searchTerm"
        placeholder="Szukaj w kursach..."
        @input="fetchFilteredCourses"
        data-testid="course-filter-input"
      ></ToolbarSearchField>
      <v-text-field
        v-if="mobileSearchInputVisible"
        class="page-toolbar__search-input page-toolbar__search-input--mobile"
        prepend-inner-icon="mdi-magnify"
        ref="mobileSearchInput"
        append-icon="mdi-close"
        :value="searchTerm"
        dense
        hide-details
        placeholder="Szukaj w kursach..."
        single-line
        @input="fetchFilteredCourses"
        @click:append="resetSearchResult"
      ></v-text-field>
      <v-spacer v-if="!mobileSearchInputVisible"></v-spacer>
      <v-icon
        v-if="!mobileSearchInputVisible"
        class="page-toolbar__search-activator"
        aria-label="wyszukiwarka"
        @click="showMobileSearchInput"
      >
        mdi-magnify
      </v-icon>
      <v-btn
        v-if="!mobileSearchInputVisible"
        class="page-toolbar__settings"
        icon
        color="white"
        aria-label="ustawienia"
        @click="openSettingsDialog"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>

      <v-toolbar-items>
        <v-btn
          v-if="!isUserWithoutAccessToTaxiCourseForm"
          class="page-toolbar__button--hidden"
          text
          @click="openTaxiCourseForm"
        >
          <v-icon left>mdi-plus</v-icon>
          Utwórz
        </v-btn>
      </v-toolbar-items>

      <RailwayCompanyModal :value="railwayCompanyDialog" @close="closeRailwayCompanyModal"></RailwayCompanyModal>
    </template>
  </AbstractPageToolbar>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { EVENTS, eventBus } from '@/core/dict/events-dict';
import { KILOMETER_COURSE_CREATE_ROUTE } from '@/core/router/route-names';
import AbstractPageToolbar from './abstract-page-toolbar';
import RailwayCompanyModal from './railway-company-modal';
import ToolbarSearchField from './toolbar-search-field/toolbar-search-field';

const FILTER_DEBOUNCE_TIME = 500;
const MIN_SEARCH_TERM_LENGTH = 2;
const DESKTOP_BREAKPOINT = 900;

export default {
  name: 'CoursePageToolbar',
  components: {
    AbstractPageToolbar,
    RailwayCompanyModal,
    ToolbarSearchField,
  },
  data() {
    return {
      mobileSearchInputVisible: false,
      railwayCompanyDialog: false,
    };
  },
  mounted() {
    if (this.searchTerm && window.innerWidth < DESKTOP_BREAKPOINT) {
      this.showMobileSearchInput();
    }
    eventBus.$on(EVENTS.OPEN_COURSE_FORM, this.isPassenger ? this.openCourseForm : this.openTaxiCourseForm);
  },
  destroyed() {
    eventBus.$off(EVENTS.OPEN_COURSE_FORM);
  },
  computed: {
    ...mapGetters('taxiCourse', ['searchTerm']),
    ...mapGetters(['isPassenger', 'isDriver', 'isCompany', 'userHasPermissionForKilometerCourse']),
    courseRoute() {
      return this.isPassenger ? KILOMETER_COURSE_CREATE_ROUTE : this.$route.meta.create;
    },
    isUserWithoutAccessToTaxiCourseForm() {
      return this.isDriver || this.isPassenger;
    },
  },
  methods: {
    fetchFilteredCourses: debounce(function (searchTerm) {
      this.filterCoursesCallback(searchTerm);
    }, FILTER_DEBOUNCE_TIME),
    filterCoursesCallback(value) {
      this.$store.commit('taxiCourse/SET_SEARCH_TERM', value);

      if (this.isValidSearchTerm(value)) {
        if (this.isPassenger) {
          this.$store.dispatch('taxiCourse/fetchAllCourses');
        } else {
          this.$store.dispatch('taxiCourse/fetchCoursesByStatus');
        }
      }
    },
    isValidSearchTerm(value) {
      return value === null || value.length >= MIN_SEARCH_TERM_LENGTH || value === '';
    },
    resetSearchResult() {
      const EMPTY_STRING = '';
      this.hideMobileSearchInput();
      if (this.searchTerm !== EMPTY_STRING) {
        this.clearSearchInput();
        this.fetchFilteredCourses(EMPTY_STRING);
      }
    },
    hideMobileSearchInput() {
      this.mobileSearchInputVisible = false;
    },
    async showMobileSearchInput() {
      this.mobileSearchInputVisible = true;
      await this.$nextTick();
      this.$refs.mobileSearchInput.focus();
    },
    clearSearchInput() {
      this.$store.commit('taxiCourse/SET_SEARCH_TERM', '');
    },
    openSettingsDialog() {
      this.$store.dispatch('courseSettings/openSettingsDialog');
    },
    openCourseForm() {
      this.$router.push({ name: this.courseRoute });
    },
    openTaxiCourseForm() {
      if (this.isCompany) {
        this.openCourseForm();
      } else {
        this.openRailwayCompanyModal();
      }
    },
    closeRailwayCompanyModal() {
      this.railwayCompanyDialog = false;
    },
    openRailwayCompanyModal() {
      this.railwayCompanyDialog = true;
    },
  },
};
</script>
<style scoped lang="scss">
@use '@/core/style/variables' as *;

.page-toolbar {
  &__button--hidden {
    display: none;

    @media (min-width: $course-list-desktop-breakpoint) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__settings {
    @media (min-width: $course-list-desktop-breakpoint) {
      display: none;
    }
  }

  &__search-activator {
    display: block;

    @media (min-width: $course-list-desktop-breakpoint) {
      display: none !important;
    }
  }

  &__search-input {
    margin-left: 24px !important;

    &--mobile {
      display: block;

      @media (min-width: $course-list-desktop-breakpoint) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: $course-list-desktop-breakpoint) {
        display: block;
      }
    }
  }
}
</style>
