<template>
  <div ref="ride-message-section">
    <div
      v-if="!ride.messages.length"
      style="color: rgba(0, 0, 0, 0.38); height: 100%"
      class="d-flex justify-center align-center ml-4 mr-4"
    >
      Brak komunikacji
    </div>
    <div v-else class="ml-4 mr-4 overflow-auto mt-4">
      <template v-for="message in ride.messages">
        <div :key="message.id" class="mb-4">
          <div class="text-body-2 pl-2 pr-2">
            <b>{{ message.createdBy ? message.createdBy.name : '-' }}</b>
          </div>
          <div class="text-body-2 pl-2 pr-2" style="overflow-wrap: anywhere; color: rgba(0, 0, 0, 0.54)">
            {{ message.createdAt | datetimeHourFirst }} wysłano
            <b>{{ message['@type'] }}</b>
            do {{ message.recipientUser ? message.recipientUser.name : '-' }} ({{ message.recipient }})
          </div>
          <v-row class="ma-1 text-left white rounded-lg rounded-bl-0">
            <div class="col-10 pa-2 text-break message-html-box" v-html="message.message" />
            <div class="col-2 pa-2 d-flex align-end justify-end">
              <div class="d-flex align-center text-body-2" style="color: rgba(0, 0, 0, 0.54)">
                <template v-if="message.sent">
                  <v-icon class="pr-1" color="success" small>mdi-check-all</v-icon>
                  <div>Wysłano</div>
                </template>
                <template v-else>
                  <v-icon class="pr-1 warning--text" small>info_outline</v-icon>
                  <div>Niedostarczono</div>
                </template>
              </div>
            </div>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import scrollToBottom from '@/core/mixin/scrollToBottom';

export default {
  name: 'RideMessageSection',
  props: {
    ride: Object,
    offset: {
      default: 393,
    },
  },
  mixins: [scrollToBottom('ride-message-section')],
  mounted() {
    this.scrollToBottom();
  },
};
</script>
<style>
.message-html-box p:last-of-type {
  margin-bottom: 0;
}
</style>
