<template>
  <AutocompleteField :value="value" :items="statuses" :label="label" item-text="1" item-value="0" @input="emitChange" />
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { TAXI_COURSE_RIDE_DICT } from '@/core/dict/taxi-course-dict';

export default {
  name: 'TaxiCourseRideStatusField',
  components: { AutocompleteField },
  data() {
    return {
      statuses: Object.entries(TAXI_COURSE_RIDE_DICT),
      selectedStatus: null,
    };
  },
  props: {
    value: {
      required: false,
      default: 0,
    },
    label: {
      type: String,
      default: 'Status',
    },
  },
  methods: {
    emitChange(company) {
      this.$emit('input', company ? company[0] : null);
    },
  },
};
</script>
