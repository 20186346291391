<template>
  <v-row class="fluid">
    <template v-if="$store.getters.isCompany">
      <FinancesForCompany />
    </template>
    <template v-if="$store.getters.isTransportCompany">
      <FinancesForTransportCompany />
    </template>
  </v-row>
</template>

<script>
import FinancesForCompany from '@/controller/Finances/finances-for-company';
import FinancesForTransportCompany from '@/controller/Finances/finances-for-transport-company';

export default {
  components: {
    FinancesForCompany,
    FinancesForTransportCompany,
  },
};
</script>
