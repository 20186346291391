<template>
  <section>
    <BaseTypography class="mb-5" variant="subtitle">Warunki współpracy</BaseTypography>
    <v-text-field
      class="mb-5"
      :value="entity.waitHourRate"
      label="Cena za 1h oczekiwania"
      type="number"
      min="0"
      outlined
      hide-details="auto"
      :rules="[rules.isWaitHourRateValid, rules.isPositiveNumber]"
      @input="$emit('update:entity', { ...entity, waitHourRate: toNumber($event) })"
    ></v-text-field>
    <v-text-field
      :value="entity.kilometerRate"
      label="Cena za 1km"
      type="number"
      min="0"
      outlined
      hide-details="auto"
      :rules="[rules.isKilometerRateValid, rules.isPositiveNumber]"
      @input="$emit('update:entity', { ...entity, kilometerRate: toNumber($event) })"
    ></v-text-field>
  </section>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import formValidationRules from '@/core/utils/form-validation-rules';
import { toNumber } from '@/core/utils/to-number';

export default {
  name: 'AddStakeForm',
  components: {
    BaseTypography,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        isWaitHourRateValid: () =>
          this.isKilometerRateDefined ? this.isWaitHourRateDefined || 'Pole jest wymagane' : true,
        isKilometerRateValid: () =>
          this.isWaitHourRateDefined ? this.isKilometerRateDefined || 'Pole jest wymagane' : true,
        isPositiveNumber: formValidationRules.isPositiveNumber(),
      },
    };
  },
  computed: {
    isKilometerRateDefined() {
      return this.entity.kilometerRate !== null && this.entity.kilometerRate !== '';
    },
    isWaitHourRateDefined() {
      return this.entity.waitHourRate !== null && this.entity.waitHourRate !== '';
    },
  },
  methods: {
    toNumber,
  },
};
</script>

<style lang="scss" scoped></style>
