import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  create(entity, message, userId) {
    this.waiting = true;
    const comment = {
      user: `api/users/${userId}`,
      content: message,
    };
    comment[this.toLowerFirstLetter(entity['@type'])] = entity['@id'];

    return Vue.http
      .post(`api/${this.prepareEntityCommentTarget(entity)}`, comment)
      .then((res) => {
        Vue.notify(defaultApiResultManager.then('Komentarz został dodany'));
        return res;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, 'Coś poszło nie tak przy zapisywaniu komentrza'));

        throw data;
      });
  },

  remove(commentId) {
    return Vue.http
      .delete(`api/ride_comments/${commentId}`)
      .then(() => {
        Vue.notify(defaultApiResultManager.then('Komentarz został usunięty'));
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, 'Coś poszło nie tak przy usuwaniu komentrza'));

        throw data;
      });
  },

  prepareEntityCommentTarget(entity) {
    return `${entity['@type']
      .split(/(?=[A-Z])/)
      .map((part) => this.toLowerFirstLetter(part))
      .join('_')}_comments`;
  },

  toLowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  },
};
