import { SET_LOADING, SET_STARTING_BASE } from '@/core/store/modules/types/starting-base-mutation-types';
import StartingBaseService from '@/service/StartingBaseService';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    startingBase: { address: null },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    startingBase: (state) => state.startingBase,
  },
  mutations: {
    [SET_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    [SET_STARTING_BASE]: (state, startingBase) => {
      state.startingBase = startingBase;
    },
  },
  actions: {
    fetchStartingBase({ commit }, id) {
      return StartingBaseService.fetchStartingBase(id).then(({ data }) => {
        commit(SET_STARTING_BASE, data);
      });
    },

    save({ commit }, startingBase) {
      commit(SET_LOADING, true);
      StartingBaseService.insertStartingBase(startingBase).finally(() => {
        commit(SET_LOADING, false);
      });
    },

    update({ commit }, startingBase) {
      commit(SET_LOADING, true);
      StartingBaseService.updateStartingBase(startingBase).finally(() => {
        commit(SET_LOADING, false);
      });
    },

    resetStartingBase({ commit }) {
      commit(SET_STARTING_BASE, { address: null });
    },
  },
};
