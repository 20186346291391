<template>
  <v-dialog content-class="premium-modal" :value="dialog" @click:outside="closeDialog" width="768">
    <v-card class="premium-modal__container">
      <v-card-title class="premium-modal__header">
        <premium-feature-icon class="flex-shrink-0" />
        <h2 class="premium-modal__heading">Odblokuj funkcję pobierania raportów</h2>
        <v-btn icon color="rgba(0, 0, 0, 0.54)" dark @click="closeDialog" data-testid="modal-close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="premium-modal__body text-center">
        <img
          class="premium-modal__image"
          src="/img/report.svg"
          width="269"
          height="200"
          alt="obrazek ikonki arkuszu z excela"
        />
        <h3 class="premium-modal__subtitle-1">
          Generuj zbiorcze zestawienia zleconych przejazdów. Kontroluj koszty travelingu i automatyzuj rozliczenia z
          przewoźnikami.
        </h3>
        <section>
          <h4 class="premium-modal__subtitle-2">Co otrzymujesz?</h4>

          <ul class="premium-modal__list">
            <li class="premium-modal__list-item" v-for="description in modalDescription" :key="description.key">
              <v-icon small color="green">mdi-check</v-icon>
              <BaseTypography variant="body-1" noMargin>{{ description.value }}</BaseTypography>
            </li>
          </ul>
        </section>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="premium-modal__footer">
        <hgroup class="premium-modal__price-section">
          <h3 class="mb-1">
            <v-chip class="font-weight-regular" style="height: 20px" small color="rgba(123, 198, 126, 0.5)">
              Comiesięczna faktura
            </v-chip>
          </h3>
          <p class="premium-modal__footer-title">0,005 zł/km</p>
          <p class="premium-modal__text">Możesz anulować tę funkcję w każdej chwili</p>
        </hgroup>
        <div>
          <p class="premium-modal__text premium-modal__text--black mb-4">
            Skontaktuj się z bezpośrednio z przedstawicielem:
          </p>
          <address class="premium-modal__footer-container">
            <div>
              <span class="premium-modal__name">Jakub Zaborowski</span>
              <p class="premium-modal__link">
                <a href="tel:+48666829898">666 829 898</a>
              </p>
              <p class="premium-modal__link">
                <a href="mailto:sprzedaz@lillitrans.eu">sprzedaz@lillitrans.eu</a>
              </p>
            </div>
            <div>
              <span class="premium-modal__name">Piotr Stefański</span>
              <p class="premium-modal__link">
                <a href="tel:+48725198915">725 198 915</a>
              </p>
              <p class="premium-modal__link">
                <a href="mailto:sprzedaz@lillitrans.eu">sprzedaz@lillitrans.eu</a>
              </p>
            </div>
          </address>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PremiumFeatureIcon from '@/component/PremiumFeature/premium-feature-icon';
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'PremiumFeatureModal',
  components: {
    PremiumFeatureIcon,
    BaseTypography,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modalDescription: [
        { key: 1, value: 'Oszczędzaj czas z automatycznie przeliczanymi odległościami Google Maps' },
        { key: 2, value: 'Precyzyjnie i sprawnie rozliczaj się z przewoźnikami' },
        {
          key: 3,
          value:
            'Kontroluj rozliczenia ze wskaźnikiem odchylenia między wartościami Google Maps, a deklarowanymi przez przewoźników',
        },
        {
          key: 4,
          value: 'Weryfikuj opłacalność kontraktów kolejowych analizując koszty travelingu dla każdej lokomotywy',
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', !this.dialog);
    },
  },
};
</script>

<style scoped lang="scss">
$desktop-breakpoint: 600px;
.premium-modal {
  border: 2px solid #ff9800;

  &__container {
    background: linear-gradient(180deg, #ff980014 0%, #00000000 100%), #ffffff;
  }

  &__header {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 16px;
  }

  .v-dialog > .v-card > &__header {
    padding: 16px;

    @media (min-width: $desktop-breakpoint) {
      padding: 24px;
    }
  }

  .v-dialog > .v-card > &__body {
    padding: 0 16px 32px;

    @media (min-width: $desktop-breakpoint) {
      padding: 0 24px 32px;
    }
  }

  .v-dialog > .v-card > &__footer {
    padding: 16px;

    @media (min-width: $desktop-breakpoint) {
      padding: 16px 24px;
    }
  }

  &__heading {
    font: 500 20px/28px 'Roboto';
    color: #000000de;
    letter-spacing: 0.15px;
    flex-grow: 1;
    word-break: break-word;
  }

  &__image {
    margin-bottom: 32px;
  }

  &__subtitle-1 {
    font: 400 24px/29px 'Roboto';
    color: #000000de;
    letter-spacing: 0.15px;
    margin-bottom: 32px;
  }

  &__subtitle-2 {
    font: 600 16px/24px 'Roboto';
    color: #000000de;
    letter-spacing: 0.15px;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    padding-left: 0 !important;
  }

  &__list-item {
    display: inline-flex;
    align-items: baseline;
    column-gap: 8px;
    text-align: left;

    @media (min-width: $desktop-breakpoint) {
      flex: 0 0 50%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;

    @media (min-width: $desktop-breakpoint) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__text {
    font: 400 14px/24px 'Roboto';
    color: #0000008a;
    margin-bottom: 0;

    &--black {
      color: #000000de;

      @media (min-width: $desktop-breakpoint) {
        text-align: right;
      }
    }
  }

  &__link {
    font: normal 400 14px/18px 'Roboto';
  }

  &__name {
    color: #000000de;
    font: 500 14px/24px 'Roboto';
    text-align: right;
  }

  &__footer {
    &-title {
      font: 500 20px/32px 'Roboto';
      letter-spacing: 0.15px;
      color: #000000de;
      margin-bottom: 4px;
    }

    &-container {
      display: flex;
      column-gap: 16px;

      @media (min-width: $desktop-breakpoint) {
        justify-content: flex-end;
        text-align: right;
      }

      .premium-modal__link {
        margin-bottom: 0;
      }
    }
  }
}
</style>
