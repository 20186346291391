<template>
  <v-navigation-drawer app :value="sidebar" @input="setSidebar" :mini-variant="isMenuClosed" permanent>
    <template #prepend>
      <v-btn class="mx-2 my-3" :aria-label="drawerToggle.label" icon @click.stop="isMenuClosed = !isMenuClosed">
        <v-icon>{{ drawerToggle.icon }}</v-icon>
      </v-btn>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'DesktopPageDrawer',
  data() {
    return {
      isMenuClosed: false,
    };
  },
  computed: {
    ...mapGetters(['sidebar']),
    drawerToggle() {
      return this.isMenuClosed
        ? {
            icon: 'mdi-menu-open',
            label: 'Otwórz menu',
          }
        : {
            icon: 'mdi-menu-close',
            label: 'Zwiń menu',
          };
    },
  },
  methods: {
    ...mapMutations(['setSidebar']),
  },
};
</script>
