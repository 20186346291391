<template>
  <div class="d-inline">
    <v-list-item>
      <date-field
        class="col-12 pa-0"
        :value="filters.dateStartAfter"
        outlined
        label="Data rozpoczęcia kursu (od)"
        @input="$emit('input', { ...filters, dateStartAfter: $event })"
      />
    </v-list-item>
    <v-list-item>
      <date-field
        class="col-12 pa-0"
        :value="filters.dateStartBefore"
        outlined
        label="Data rozpoczęcia kursu (do)"
        @input="$emit('input', { ...filters, dateStartBefore: $event })"
      />
    </v-list-item>
    <v-list-item>
      <locomotive-field
        :value="filters.locomotive"
        @input="$emit('input', { ...filters, locomotive: $event })"
        :company-id="companyId"
      />
    </v-list-item>
    <v-list-item>
      <CreatedByField
        :value="filters.createdBy"
        @input="$emit('input', { ...filters, createdBy: $event })"
      ></CreatedByField>
    </v-list-item>
    <v-list-item>
      <v-checkbox
        class="mt-0 checkbox--black"
        label="Pokaż anulowane kursy"
        hide-details
        :input-value="filters.includeCancelledCourses"
        @change="$emit('input', { ...filters, includeCancelledCourses: $event })"
      />
    </v-list-item>
  </div>
</template>
<script>
import DateField from '@/component/Field/Date/date-field';
import LocomotiveField from '@/component/Field/Locomotive/locomotive-field';
import CreatedByField from '@/component/Field/User/created-by-field';
import { mapGetters } from 'vuex';

export default {
  name: 'AbstractCourseFilters',
  components: {
    LocomotiveField,
    DateField,
    CreatedByField,
  },
  props: {
    filters: Object,
  },
  computed: {
    ...mapGetters(['isCompanyUser', 'user']),
    companyId() {
      return this.isCompanyUser ? this.user.company.id : null;
    },
  },
};
</script>

<style scoped>
.v-list-item ~ .v-list-item {
  margin-top: 24px;
}

::v-deep .checkbox--black .v-label {
  color: rgba(0, 0, 0, 0.87);
}
</style>
