import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FCM_API_KEY,
  authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FCM_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FCM_APP_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
if (isSupported()) {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      getToken(messaging, { vapidKey: process.env.VUE_APP_FCM_VAPID_KEY })
        .then((deviceToken) => {
          if (deviceToken) {
            // TODO: after login send device token to serwer by PUT request
            localStorage.setItem(process.env.VUE_APP_FCM_DEVICE_TOKEN_NAME, deviceToken);
            console.log('fcm deviceToken', deviceToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

onMessage(messaging, (payload) => {
  console.log('Received foreground message', payload);
});
