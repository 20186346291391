import { API_COURSE_RIDE_DICT } from '@/core/dict/course-dict';
import BaseEditStartingBaseTemplate from './BaseEditStartingBaseTemplate';

export default class StartingBaseEdit extends BaseEditStartingBaseTemplate {
  // eslint-disable-next-line class-methods-use-this
  get entityType() {
    return API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE;
  }

  // eslint-disable-next-line class-methods-use-this
  get startField() {
    return 'fromStartingBase';
  }

  // eslint-disable-next-line class-methods-use-this
  get endField() {
    return 'toStartingBase';
  }

  async payload({ course, ...args }) {
    return {
      rides: [
        await super.payload(args),
        ...course.rides
          .filter((ride) => ride.id !== args.ride.id)
          .map((ride) => ({
            id: ride['@id'],
            type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
          })),
      ],
    };
  }
}
