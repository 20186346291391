<template>
  <v-col cols="12">
    <v-divider v-if="comments.length !== 0" />
    <v-data-iterator :items="comments" :hide-default-footer="comments.length === 0">
      <template #item="props">
        <v-card flat :class="`pa-0 ${props.item.class}`">
          <v-list class="pa-0 ma-0">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <b>{{ props.item.user.name || props.item.user.username }}</b>
                  <i>{{ props.item.createdAt | datetime }}</i>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action v-if="$store.getters.isAdmin">
                <v-btn icon small text color="error" @click="deleteComment(props.item)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-text>{{ props.item.content }}</v-card-text>
        </v-card>
        <v-divider />
      </template>

      <template #no-data>
        <v-col cols="12" v-if="comments.length === 0">
          <div class="pa-6 text-center grey--text">Brak komentarzy</div>
        </v-col>
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import compareDesc from 'date-fns/compare_desc';

export default {
  props: { entity: null },
  computed: {
    comments: {
      get() {
        const comments = this.entity.comments || [];
        // eslint-disable-next-line array-callback-return
        comments.map((comment) => {
          // eslint-disable-next-line no-nested-ternary,no-param-reassign
          comment.class = comment.user.roles.includes('ROLE_ADMIN')
            ? 'admin-comment'
            : comment.user.roles.includes('ROLE_COMPANY')
            ? 'company-comment'
            : '';
        });
        return comments.sort((a, b) => compareDesc(a.createdAt, b.createdAt));
      },
      set(val) {
        this.entity.comments = val;
      },
    },
  },

  methods: {
    deleteComment(comment) {
      const index = this.comments.indexOf(comment);
      this.$http
        .delete(comment['@id'].substring(1))
        .then(() => {})
        .catch();
      this.comments.splice(index, 1);
    },
  },
};
</script>
