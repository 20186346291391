<template>
  <div>
    <DialogFactory :value="value" v-on="$listeners">
      <AbstractModalHeader title="Dodaj samochód">
        <template #close>
          <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
        </template>
      </AbstractModalHeader>
      <v-card-text>
        <v-form class="d-flex flex-column" style="row-gap: 24px" ref="form">
          <slot name="form-extension" v-bind:entity="entityCopy"></slot>
          <CarBrandFieldFactory
            v-model="entityCopy.brand"
            :readonly="readonly"
            @input="entityCopy.model = null"
          ></CarBrandFieldFactory>
          <v-autocomplete
            v-model="entityCopy.model"
            :items="models"
            item-text="name"
            item-value="name"
            :loading="isBrandsLoading"
            :disabled="!entityCopy.brand"
            outlined
            hide-details="auto"
            validate-on-blur
            :readonly="readonly"
            :rules="[rules.required]"
          >
            <template #label>
              <span>Model</span>
              <span class="red--text">*</span>
            </template>
          </v-autocomplete>
          <TextField
            v-model="entityCopy.registrationNumber"
            label="Numer rejestracyjny"
            required
            :readonly="readonly"
            hint="Nie używaj spacji w numerze rejestracyjnym"
            :rules="[rules.registrationNumber]"
          ></TextField>
          <TextField
            v-model="entityCopy.vin"
            label="Numer VIN"
            required
            :readonly="readonly"
            hint="Numer VIN to 17 znaków, które znajdziesz w dowodzie rejestracyjnym w punkcie E"
            :rules="[rules.vinNumber]"
          ></TextField>
          <TextField
            v-model="entityCopy.engineCapacity"
            label="Pojemność silnika"
            required
            :readonly="readonly"
            hint="Pojemność silnika znajdziesz w dowodzie rejestracyjnym w punkcie P.1."
            suffix="cm3"
            :rules="[rules.engineCapacity]"
          ></TextField>
          <TextField
            v-model="entityCopy.initialMileage"
            label="Stan licznika"
            suffix="km"
            :readonly="readonly"
            :rules="[rules.initialMileage]"
          ></TextField>
        </v-form>
      </v-card-text>
      <DialogSaveButton @click="saveCar"></DialogSaveButton>
    </DialogFactory>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import formValidationRules from '@/core/utils/form-validation-rules';
import TextField from '@/component/Field/Generic/text-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import CarBrandFieldFactory from './car-brand-field/car-brand-field-factory';

export default {
  name: 'CarModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    CarBrandFieldFactory,
    TextField,
    DialogSaveButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    strategy: {
      required: true,
    },
    carEntity: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entityCopy: JSON.parse(JSON.stringify(this.carEntity)),
      rules: formValidationRules,
    };
  },
  computed: {
    ...mapGetters('car', ['brands', 'isBrandsLoading']),
    models() {
      return this.brands.find((b) => b.name === this.entityCopy.brand)?.models;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    saveCar() {
      if (this.$refs.form.validate()) {
        this.strategy.execute(this.entityCopy).then((carModel) => {
          this.$emit('carCreated', carModel);
          this.emitCloseEvent();
        });
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
  },
  watch: {
    value() {
      this.entityCopy = JSON.parse(JSON.stringify(this.carEntity));

      if (this.value === false) {
        this.resetForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
