export const FIN_SETTLEMENT_PAGE = 'financial-settlement';
export const FIN_SETTLEMENT_TITLE = 'Rozliczenia';

const FinancialSettlement = () => import('@/controller/FinancialSettlement');

export default [
  {
    path: '/financial-settlement',
    name: FIN_SETTLEMENT_PAGE,
    component: FinancialSettlement,
    meta: {
      title: FIN_SETTLEMENT_TITLE,
    },
  },
];
