<template>
  <alert-info v-if="segment.time && segment.distance && !isFallbackDistanceSource">
    <span class="font-weight-medium">{{ alertText }}</span>
    {{ segment.distance }} km, {{ segment.time | secondsToHumanString }}
  </alert-info>
  <div v-else-if="isFallbackDistanceSource">
    <alert-warning>
      <span class="font-weight-medium">Brak informacji o czasie i odległości odcinka od Google Maps</span>
      <br />
      Uzupełnij poniższe pola.
    </alert-warning>
    <v-row>
      <v-col class="col-6 pb-0">
        <time-field label="Czas przejazdu" v-model="segment.time" @blur="$listeners.blur" />
      </v-col>
      <v-col class="col-6 pb-0">
        <text-field
          label="Odległość przejazdu"
          icon="mdi-map"
          type="number"
          v-model.number="segment.distance"
          suffix="km"
          @blur="$listeners.blur"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AlertInfo from '@/component/Alert/alert-info';
import AlertWarning from '@/component/Alert/alert-warning';
import TimeField from '@/component/Field/Generic/fallback-distance-time-field';
import TextField from '@/component/Field/Generic/text-field';
import { TIME_AND_DISTANCE_SOURCE_DICT } from '@/core/dict/Course/time-and-distance-source-dict';

export default {
  name: 'TaxiCourseSegmentKilometerAlert',
  components: { TextField, TimeField, AlertWarning, AlertInfo },
  props: {
    segment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isFallbackDistanceSource() {
      return this.segment.timeAndDistanceSource === TIME_AND_DISTANCE_SOURCE_DICT.FALLBACK;
    },
    alertText() {
      switch (this.segment.timeAndDistanceSource) {
        case TIME_AND_DISTANCE_SOURCE_DICT.DEFINED:
          return 'Czas i odległość odcinka wg zdefiniowanych kilometrów:';
        case TIME_AND_DISTANCE_SOURCE_DICT.ESTIMATED:
          return 'Czas i odległość odcinka wg Google Maps:';
        default:
          return '';
      }
    },
  },
};
</script>
