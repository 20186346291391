// eslint-disable-next-line import/prefer-default-export
export const MODAL_STATE_TYPE = {
  CREATE_ENTITY_MODAL: 'CREATE_ENTITY_MODAL',
  EDIT_ENTITY_MODAL: 'EDIT_ENTITY_MODAL',
  CLOSED: 'CLOSED',
};

export const MODAL_STATE = {
  CREATE_ENTITY_MODAL: { type: MODAL_STATE_TYPE.CREATE_ENTITY_MODAL },
  EDIT_ENTITY_MODAL: { type: MODAL_STATE_TYPE.EDIT_ENTITY_MODAL, selectedEntity: null },
  CLOSED: { type: MODAL_STATE_TYPE.CLOSED },
};
