<template>
  <!-- TODO: temporary solution until v-app will be refactored to common component -->
  <!-- TODO: 101 is greater than loading-screen index -->
  <!-- TODO: 1001 is grater than taxi-course modal, but it's temporary solution -->
  <v-snackbar style="z-index: 1001; padding: 0" top v-bind="$attrs" v-on="$listeners">
    <div class="notification__wrapper">
      <v-icon left>{{ icon }}</v-icon>
      <span class="notification__text">{{ text }}</span>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AbstractNotification',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__text {
    color: var(--white, #fff);
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 0.25px;
  }
}
</style>
