<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import HomePlace from './home-place';
import BlankHomeplace from './blank-homeplace';

export default {
  name: 'HomeBtnFactory',
  inheritAttrs: false,
  computed: {
    currentComponent() {
      return this.$attrs.place ? HomePlace : BlankHomeplace;
    },
  },
};
</script>
