<template>
  <AbstractModalHeader title="Nowa grupa pracownicza">
    <template #close>
      <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
    </template>
  </AbstractModalHeader>
</template>

<script>
import CloseIconButton from '@/component/Button/close-icon-button';
import closeEvent from '@/core/mixin/closeEvent';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';

export default {
  name: 'AddEmployeeGroupHeader',
  mixins: [closeEvent],
  components: {
    AbstractModalHeader,
    CloseIconButton,
  },
};
</script>

<style lang="scss" scoped></style>
