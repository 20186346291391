<template>
  <v-dialog :width="width" transition="dialog-bottom-transition" persistent v-bind="$attrs" v-on="$listeners">
    <v-card :height="height" tile class="d-flex flex-column">
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  inheritAttrs: false,
  props: {
    width: {
      default: '480',
    },
    height: {
      default: 'auto',
    },
  },
};
</script>
