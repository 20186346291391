<template>
  <abstract-locomotive-dialog v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import AbstractLocomotiveDialog from './abstract-locomotive-dialog';

export default {
  name: 'CompanyLocomotiveDialog',
  components: {
    AbstractLocomotiveDialog,
  },
};
</script>
