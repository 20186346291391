<template>
  <v-data-iterator
    :items="sorted"
    :hide-default-footer="hiddenFooter || items === false || items.length === 0"
    :search="search"
    items-per-page-text="Wyświetl:"
    :items-per-page="itemsPerPage ? itemsPerPage : undefined"
    :items-per-page-options="[25, 50, 100, { text: 'Wszystko', value: -1 }]"
  >
    <template #item="props">
      <one-list-tile
        :title="title !== undefined ? title(props) : undefined"
        :subtitle="subtitle !== undefined ? subtitle(props) : undefined"
        :icon="icon || undefined"
        :to="to !== undefined ? to(props) : undefined"
        :buttonCta="buttonCta !== undefined ? buttonCta(props) : undefined"
        data-testid="list-tile"
      >
        <template v-for="(_, scopedSlotName) in $scopedSlots">
          <slot :name="scopedSlotName" v-bind="props" />
        </template>
      </one-list-tile>
      <v-divider :hide-default-footer="items === false || items.length === 0" />
    </template>

    <template #pageText="props">
      <b>{{ props.pageStart }} - {{ props.pageStop }}</b>
      / {{ props.itemsLength }}
    </template>

    <template #no-data>
      <v-col cols="12" class="pa-6 text-center grey--text" style="font-size: 0.9rem">Pusto</v-col>
    </template>

    <template #no-results>
      <v-col cols="12" class="pa-6 text-center grey--text" style="font-size: 0.9rem" data-testid="no-result-label">
        Brak wyników dla:
        <b>{{ search }}</b>
      </v-col>
    </template>
  </v-data-iterator>
</template>

<script>
import OneListTile from '@/component/List/tile-list';

export default {
  name: 'OneDataIterator',
  components: { OneListTile },
  props: {
    items: null,
    search: null,
    title: null,
    subtitle: null,
    icon: null,
    to: null,
    buttonCta: null,
    hiddenFooter: null,
    itemsPerPage: null,
  },
  computed: {
    sorted: {
      get() {
        const items = this.items || [];
        if (items.length > 0) {
          items.sort((a, b) => b.id - a.id);
        }
        return items;
      },
    },
  },
};
</script>
