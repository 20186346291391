<template>
  <AbstractCourseCard class="kilometer-course-card" v-bind="$attrs" v-on="$listeners">
    <template #course-title>
      <RideDate v-bind="$attrs"></RideDate>
    </template>
    <template #course-badge>
      <KilometerCourseBadge></KilometerCourseBadge>
    </template>
    <template #course-report>
      <KilometerCourseReport v-bind="$attrs"></KilometerCourseReport>
    </template>
  </AbstractCourseCard>
</template>

<script>
import AbstractCourseCard from './abstract-course-card';
import KilometerCourseBadge from './CourseBadge/kilometer-course-badge';
import RideDate from './CourseTitle/ride-date';
import KilometerCourseReport from './CourseReport/kilometer-course-report';

export default {
  name: 'KilometerCourseCard',
  inheritAttrs: false,
  components: {
    AbstractCourseCard,
    RideDate,
    KilometerCourseBadge,
    KilometerCourseReport,
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.kilometer-course-card.theme--light.v-card {
  @include course-card(var(--kilometer-course-card-background-color), var(--kilometer-course-card-border-color));
}
</style>
