import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { CarModel } from './car-model.model';

// eslint-disable-next-line import/prefer-default-export
export class CarBrand {
  id;

  name;

  models;

  constructor({ id = uuidv4(), name = '', models = [new CarModel()] } = {}) {
    this.id = id;
    this.name = name;
    this.models = cloneDeep(models);
  }

  addModel() {
    this.models.push(new CarModel());
  }

  removeModel(carModel) {
    this.models = this.models.filter((m) => m.id !== carModel.id);
  }
}
