<template>
  <div class="tooltip" :class="{ 'tooltip--active': tooltipVisible }" data-testid="selected-place-marker">
    <img src="/img/home-marker.svg" alt="marker with current position of the home" />
    <p class="tooltip__text">Przeciągnij mapę, by dostosować lokalizację adresu miejsca</p>
  </div>
</template>

<script>
export default {
  props: {
    tooltipVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;

  &__text {
    background-color: #007dff;
    visibility: hidden;
    width: 344px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px 32px;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #007dff transparent transparent transparent;
    }
  }

  &--active .tooltip__text {
    visibility: visible;
    opacity: 1;
  }
}
</style>
