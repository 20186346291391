<template>
  <v-card-actions class="px-6 pt-4 pb-6 add-button__container">
    <v-btn block color="primary" :type="type" v-on="$listeners">{{ text }}</v-btn>
    <slot name="extension"></slot>
  </v-card-actions>
</template>

<script>
export default {
  name: 'DialogSaveButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    text: {
      type: String,
      default: 'Zapisz',
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button__container {
  position: sticky;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
}
</style>
