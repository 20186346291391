<template>
  <autocomplete-field
    icon="train"
    :label="label"
    :items="locomotives"
    item-text="sideNumber"
    :value="value"
    @input="emitChange"
    :loading="listLoading"
    v-bind="$attrs"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { mapGetters } from 'vuex';

export default {
  name: 'AbstractLocomotiveField',
  inheritAttrs: false,
  components: { AutocompleteField },
  props: {
    label: {
      type: String,
      default: 'Numer lokomotywy',
    },
    value: {
      required: false,
    },
    companyId: {},
  },
  computed: {
    ...mapGetters({
      allLocomotives: 'locomotive/locomotiveList',
      listLoading: 'locomotive/locomotiveListLoading',
    }),
    locomotives() {
      return typeof this.companyId === 'number' ? this.locomotivesByCompany : this.allLocomotives;
    },
    locomotivesByCompany() {
      return this.allLocomotives.filter((locomotive) => locomotive.company === `/api/companies/${this.companyId}`);
    },
  },
  created() {
    if (!this.locomotives.length) {
      this.$store.dispatch('locomotive/fetchLocomotiveList');
    }
  },
  methods: {
    emitChange(passenger) {
      this.$emit('input', passenger);
    },
  },
};
</script>
