import Vue from 'vue';
import { UNAUTHORIZED_STATUS } from '@/core/dict/response-status-dict';
import { NOTIFICATION_TYPES } from '@/core/dict/notification-dict';

function notificationMessage(code) {
  return {
    type: NOTIFICATION_TYPES.ERROR,
    text: code === UNAUTHORIZED_STATUS ? 'Błędny login lub hasło.' : 'Coś poszło nie tak',
  };
}

export default {
  login(credentials) {
    return Vue.http
      .post('login', credentials)
      .then(({ data }) => data)
      .catch(({ data }) => {
        Vue.notify(notificationMessage(data.code));
        throw new Error(data.message);
      });
  },
  refreshToken() {
    const refreshToken = localStorage.getItem(process.env.VUE_APP_JWT_REFRESH_NAME);
    Vue.http.headers.common.Authorization = null;

    return Vue.http
      .post('api/token/refresh', {
        refresh_token: refreshToken,
      })
      .then(({ data }) => {
        localStorage.setItem(process.env.VUE_APP_JWT_STORAGE_NAME, data.token);
        Vue.http.headers.common.Authorization = `Bearer ${data.token}`;

        return data.token;
      });
  },
};
