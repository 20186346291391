<template>
  <EmployeeGroupModal :value="isModalOpen" :entity="entity" :strategy="strategy" v-on="$listeners">
    <template #header="{ entity }">
      <EmployeeGroupHeaderFactory
        :isEditModal="isEditModal"
        :entity="entity"
        v-on="$listeners"
      ></EmployeeGroupHeaderFactory>
    </template>
    <template v-slot:stake-form="{ entity, updateEntity }">
      <StakesTable v-if="isEditModal" :selectedEmployeeGroup="entity"></StakesTable>
      <AddStakeForm v-else :entity="entity" v-on:update:entity="updateEntity"></AddStakeForm>
    </template>
  </EmployeeGroupModal>
</template>

<script>
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import AddStakeForm from '@/controller/Page/page-toolbar/principal-modal/add-stake-form';
import EmployeeGroupModal from './employee-group-modal';
import { CreateEmployeeGroupStrategy } from '../Strategy/create-employee-group-strategy';
import { EditEmployeeGroupStrategy } from '../Strategy/edit-employee-group-strategy';
import EmployeeGroupHeaderFactory from './Header/employee-group-header-factory';
import StakesTable from '../Stakes/stakes-table';

export default {
  name: 'EmployeeGroupModalFactory',
  inheritAttrs: false,
  components: {
    EmployeeGroupModal,
    EmployeeGroupHeaderFactory,
    AddStakeForm,
    StakesTable,
  },
  props: {
    state: {
      required: true,
    },
  },
  computed: {
    isModalOpen() {
      return (
        this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type ||
        this.state.type === MODAL_STATE.CREATE_ENTITY_MODAL.type
      );
    },
    isEditModal() {
      return this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type;
    },
    entity() {
      return this.isEditModal
        ? this.state.selectedEntity
        : {
            name: '',
            kilometerRate: null,
            waitHourRate: null,
          };
    },
    strategy() {
      return this.isEditModal
        ? new EditEmployeeGroupStrategy(this.$store)
        : new CreateEmployeeGroupStrategy(this.$store);
    },
  },
};
</script>

<style lang="scss" scoped></style>
