<template>
  <one-page-section class="my-0">
    <v-col cols="12" class="py-0">
      <v-card class="pa-0 ma-0 px-6" flat color="transparent">
        <div
          :class="{ 'no-stretch': true, 'justify-center': center !== undefined }"
          style="display: flex; align-items: center"
        >
          <slot name="default"></slot>
        </div>
      </v-card>
    </v-col>
  </one-page-section>
</template>

<script>
import OnePageSection from '@/component/Page/section-page';

export default {
  name: 'OneFormAction',
  components: { OnePageSection },
  props: { center: Boolean },
};
</script>

<style scoped>
.no-stretch {
  display: flex !important;
  align-items: flex-start;
}
</style>
