<template>
  <div>
    <AutocompleteField
      label="Grupa pracownicza"
      :items="employeeGroups"
      :loading="isEmployeeGroupsLoading"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #no-data>
        <NoDataText>Brak zdefiniowanych grup pracowniczych</NoDataText>
      </template>
      <template #append-item>
        <AddItemButton @click="openCreateEmployeeGroupModal()">Dodaj grupę pracowniczą</AddItemButton>
      </template>
      <template #item="{ item }">
        <div class="d-flex align-center flex-grow-1">
          <p class="mb-0 flex-grow-1">{{ item.name }}</p>
          <EditIconButton @click.stop="openEditEmployeeGroupModal(item)"></EditIconButton>
        </div>
      </template>
    </AutocompleteField>
    <EmployeeGroupModalFactory :state="state" @close="closeEmployeeGroupModal"></EmployeeGroupModalFactory>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import NoDataText from '@/component/Field/Generic/no-data-text';
import EditIconButton from '@/component/Button/edit-icon-button';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import EmployeeGroupModalFactory from './EmployeeGroupModal/employee-group-modal-factory';

export default {
  name: 'EmployeeGroupInput',
  components: {
    AutocompleteField,
    NoDataText,
    AddItemButton,
    EditIconButton,
    EmployeeGroupModalFactory,
  },
  data() {
    return {
      state: MODAL_STATE.CLOSED,
    };
  },
  created() {
    this.$store.dispatch('employeeGroup/fetchEmployeeGroups');
  },
  computed: {
    ...mapGetters('employeeGroup', ['employeeGroups', 'isEmployeeGroupsLoading']),
  },
  methods: {
    openCreateEmployeeGroupModal() {
      this.state = MODAL_STATE.CREATE_ENTITY_MODAL;
    },
    openEditEmployeeGroupModal(val) {
      this.state = {
        ...MODAL_STATE.EDIT_ENTITY_MODAL,
        selectedEntity: val,
      };
    },
    closeEmployeeGroupModal() {
      this.state = MODAL_STATE.CLOSED;
    },
  },
  watch: {
    employeeGroups() {
      if (this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type) {
        const updatedReferenceToEntity = this.employeeGroups.find((group) => group.id === this.state.selectedEntity.id);
        this.openEditEmployeeGroupModal(updatedReferenceToEntity);
      }
    },
  },
};
</script>
