<template>
  <SegmentsTimeline v-bind="{ ...$attrs, ...strategies, ride: ride, isDriverRelation }" v-on="$listeners" />
</template>

<script>
import { TAXI_COURSE_CREATE_INFO } from '@/core/router/route-names';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import SegmentsTimeline from '../segments-timeline';
import KilometerCourseEditStartingPlaceTemplate from './EditStartingBaseTemplate/KilometerCourseEditStartingBaseTemplate';
import NullEdit from './EditStartingBaseTemplate/NullEdit';
import StartingBaseEdit from './EditStartingBaseTemplate/StartingBaseEdit';
import StartingPlaceEdit from './EditStartingBaseTemplate/StartingPlaceEdit';
import SegmentsEdit from './EditRelationTemplate/SegmentsEdit';
import DriverSegmentsEdit from './EditRelationTemplate/DriverSegmentsEdit';

export default {
  name: 'SegmentsTimelineFactory',
  inheritAttrs: false,
  components: {
    SegmentsTimeline,
  },
  props: {
    // TODO: change this the most stupid name
    isDriverRelation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ride() {
      // TODO: think if the logic of picking should be in the factory or in the client
      return this.isDriverRelation
        ? // TODO: change to adapter
          {
            ...this.$attrs.ride,
            segments: this.$attrs.ride.driverSegments,
            fromStartingBase: this.$attrs.ride.fromStartingPlace,
            toStartingBase: this.$attrs.ride.toStartingPlace,
          }
        : this.$attrs.ride;
    },
    isCourseInfoRoute() {
      return this.$route.name === TAXI_COURSE_CREATE_INFO;
    },
    isKilometerCourseRide() {
      return this.ride?.['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE_RIDE;
    },
    isTaxiCourse() {
      return this.ride?.['@type'] === COURSE_TYPE_DICT.TAXI_COURSE;
    },
    isTaxiRide() {
      return this.ride?.['@type'] === COURSE_TYPE_DICT.TAXI_COURSE_RIDE;
    },
    isCourseWithSingleRide() {
      return this.$attrs.course && this.$attrs.course.rides.length === 1;
    },
    isRideAcceptedByDriver() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER;
    },
    strategies() {
      if (this.isKilometerCourseRide) {
        return {
          startingBaseStrategy: new KilometerCourseEditStartingPlaceTemplate(),
          startingPlaceStrategy: new NullEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new NullEdit(),
        };
      }

      if (this.isTaxiCourse && this.isCourseWithSingleRide && this.isCourseInfoRoute) {
        return {
          startingBaseStrategy: new StartingBaseEdit(),
          startingPlaceStrategy: new StartingPlaceEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new DriverSegmentsEdit(),
        };
      }

      if (this.isTaxiCourse && this.isCourseWithSingleRide) {
        return {
          startingBaseStrategy: new NullEdit(),
          startingPlaceStrategy: new StartingPlaceEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new DriverSegmentsEdit(),
        };
      }

      if (this.isTaxiCourse && !this.isCourseWithSingleRide) {
        return {
          startingBaseStrategy: new NullEdit(),
          startingPlaceStrategy: new StartingPlaceEdit(),
          segmentsEditStrategy: new NullEdit(),
          driverSegmentsEditStrategy: new DriverSegmentsEdit(),
        };
      }

      if (this.isTaxiRide && this.isCourseWithSingleRide) {
        return {
          startingBaseStrategy: new StartingBaseEdit(),
          startingPlaceStrategy: new StartingPlaceEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new DriverSegmentsEdit(),
        };
      }

      if (this.isTaxiRide && !this.isCourseWithSingleRide && !this.isRideAcceptedByDriver) {
        return {
          startingBaseStrategy: new StartingBaseEdit(),
          startingPlaceStrategy: new NullEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new NullEdit(),
        };
      }

      if (this.isTaxiRide && !this.isCourseWithSingleRide) {
        return {
          startingBaseStrategy: new StartingBaseEdit(),
          startingPlaceStrategy: new NullEdit(),
          segmentsEditStrategy: new SegmentsEdit(),
          driverSegmentsEditStrategy: new DriverSegmentsEdit(),
        };
      }

      return {
        startingBaseStrategy: new NullEdit(),
        startingPlaceStrategy: new NullEdit(),
        segmentsEditStrategy: new NullEdit(),
        driverSegmentsEditStrategy: new NullEdit(),
      };
    },
  },
};
</script>
