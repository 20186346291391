<template>
  <v-list-item
    v-if="!closable || messageUnread"
    :key="employeeRequest.id"
    @click="requestClicked(employeeRequest)"
    :class="`ma-1 rounded ${messageUnread ? 'unread-message' : ''} ${isSelected ? 'active-message' : ''}`"
  >
    <v-list-item-content>
      <v-list-item-title
        :class="`d-flex justify-space-between font-weight-bold ${isSelected ? 'white--text' : 'black--text'}`"
      >
        <div>{{ employeeRequest.createdBy.name }} #{{ employeeRequest.id }}</div>
        <v-icon small @click.prevent="close" v-if="closable">mdi-close</v-icon>
        <div v-else-if="messageUnread" class="unread-message-dot"></div>
      </v-list-item-title>
      <v-list-item-subtitle :class="isSelected ? 'white--text' : 'black--text'">
        <div class="d-flex justify-space-between pb-1">
          <div>{{ lastMessage.createdBy.name }}</div>
          <div>{{ lastMessage.createdAt | time }}</div>
        </div>
        <div>{{ lastMessage.message }}</div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'EmployeeRequestListItem',
  props: {
    employeeRequest: { type: Object },
    isSelected: { type: Boolean },
    closable: { type: Boolean },
  },
  computed: {
    lastMessage() {
      return this.employeeRequest.messages.at(-1);
    },
    messageUnread() {
      const lastMessage = this.employeeRequest.messages.at(-1);

      return (
        lastMessage.createdBy.id !== this.$store.getters.user.id &&
        !lastMessage.viewedBy.filter((user) => user.id === this.$store.getters.user.id).length
      );
    },
  },
  methods: {
    requestClicked(employeeRequest) {
      this.$emit('requestClicked', employeeRequest);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style>
.unread-message {
  background-color: rgb(232 242 255);
}
.unread-message-dot {
  width: 8px;
  height: 8px;
  background: #ff9800;
  border-radius: 100px;
}
.active-message {
  background-color: #007dff;
}
</style>
