export const SET_MERCURE_WATCH_INITIALIZED = 'SET_MERCURE_WATCH_INITIALIZED';
export const SET_EMPLOYEE_REQUESTS_INITIALIZED = 'SET_EMPLOYEE_REQUESTS_INITIALIZED';
export const SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED = 'SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED';
export const SET_EMPLOYEE_REQUESTS = 'SET_EMPLOYEE_REQUESTS';
export const SET_EMPLOYEE_REQUESTS_LOADING = 'SET_EMPLOYEE_REQUESTS_LOADING';
export const SET_SELECTED_EMPLOYEE_REQUEST_ID = 'SET_SELECTED_EMPLOYEE_REQUEST';
export const SET_FINISHED_EMPLOYEE_REQUESTS = 'SET_FINISHED_EMPLOYEE_REQUESTS';
export const SET_FINISHED_EMPLOYEE_REQUESTS_LOADING = 'SET_FINISHED_EMPLOYEE_REQUESTS_LOADING';
export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST';
export const ADD_FINISHED_EMPLOYEE_REQUEST = 'ADD_FINISHED_EMPLOYEE_REQUEST';
export const SET_UNSENT_MESSAGES = 'SET_UNSENT_MESSAGES';
export const PUSH_UNSENT_MESSAGE = 'PUSH_UNSENT_MESSAGE';
export const POP_UNSENT_MESSAGE = 'POP_UNSENT_MESSAGE';
export const SET_LAST_UNREAD = 'SET_LAST_UNREAD';
