<template>
  <RideContextMenu v-if="buttons.length" :buttons="buttons" v-bind="$attrs"></RideContextMenu>
</template>

<script>
import dayjs from 'dayjs';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { KILOMETER_COURSE_STATUS } from '@/core/dict/kilometer-course-dict';
import { OpenTransferRideDialogCommand } from '@/command/OpenTransferRideDialogCommand';
import { ExtractRideCommand } from '@/command/ExtractRideCommand';
import { RevertCancelledTaxiRideCommand } from '@/command/RevertCancelledTaxiRideCommand';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';
import { mapGetters } from 'vuex';
import RideContextMenu from './ride-context-menu';

export default {
  name: 'RideContextMenuFactory',
  inheritAttrs: false,
  components: {
    RideContextMenu,
  },
  props: {
    ride: {
      type: Object,
    },
    course: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['isManager', 'isCompany', 'isTransportCompany', 'isLoggedCompanyIsSuperiorContractor']),
    isRideCanBeExtracted() {
      return this.$store.getters['transferRide/isRideCanBeExtracted'](this.ride.courseId);
    },
    isRideStatusAllowsExtraction() {
      return (
        [
          TAXI_COURSE_RIDE_STATUS.NEW,
          TAXI_COURSE_RIDE_STATUS.PLANNING,
          TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE,
          TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_TRANSPORT_COMPANY,
          TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY,
          TAXI_COURSE_RIDE_STATUS.DECLINED_BY_TRANSPORT_COMPANY,
          TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE,
          TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_DRIVER,
          TAXI_COURSE_RIDE_STATUS.DECLINED_BY_DRIVER,
        ].includes(this.ride.status) ||
        (this.ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER && this.course.driverEndAt === null)
      );
    },
    isRideCancelled() {
      return [
        TAXI_COURSE_RIDE_STATUS.CANCELLED,
        TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION,
        KILOMETER_COURSE_STATUS.CANCELLED,
      ].includes(this.ride.status);
    },
    isKilometerCourseRide() {
      return this.ride['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE_RIDE;
    },
    isTaxiCourseRide() {
      return this.ride['@type'] === COURSE_TYPE_DICT.TAXI_COURSE_RIDE;
    },
    isRideCreatedByCurrentUser() {
      return this.$store.getters.user.id === this.ride.createdBy.id;
    },
    isTaxiCourseCanBeCancelled() {
      return (
        !this.isRideCancelled &&
        this.isTaxiCourseRide &&
        (this.$store.getters.isManager ||
          this.isLoggedCompanyIsSuperiorContractor({ ride: this.ride }) ||
          this.$store.getters.isCompany)
      );
    },
    isTaxiCourseCanBeCancelledDuringRealization() {
      const isRideStarted = () => dayjs().isAfter(this.ride.fromStartingBase.waypointStart.time);
      return !this.isRideCancelled && this.isTaxiCourseRide && this.ride.fromStartingBase !== null && isRideStarted();
    },
    userHasRightsToRevertCancelledTaxiRide() {
      return this.isManager || this.isCompany || this.isLoggedCompanyIsSuperiorContractor({ ride: this.ride });
    },
    isTaxiRideCancelled() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.CANCELLED;
    },
    isKilometerCourseCanBeCancelled() {
      return (
        !this.isRideCancelled &&
        this.isKilometerCourseRide &&
        (this.$store.getters.isCompany || this.isRideCreatedByCurrentUser)
      );
    },
    isUserWithRoleForRideTransfer() {
      return this.$store.getters.isManager || this.$store.getters.isTransportCompany;
    },
    buttons() {
      const arr = [];

      if (this.isTaxiCourseRide && this.isUserWithRoleForRideTransfer) {
        if (this.isRideStatusAllowsExtraction) {
          arr.push({
            title: 'Przenieś przejazd',
            command: new OpenTransferRideDialogCommand({ ride: this.ride, course: this.course }),
          });
        }
        if (this.isRideCanBeExtracted && this.isRideStatusAllowsExtraction) {
          arr.push({
            title: 'Wyciągnij przejazd',
            command: new ExtractRideCommand({ ride: this.ride, course: this.course }),
          });
        }
      }

      if (this.isTaxiCourseCanBeCancelled || this.isKilometerCourseCanBeCancelled) {
        arr.push({ title: 'Anuluj przejazd', command: this.cancelRideCommand() });
      }

      if (this.isTaxiCourseCanBeCancelledDuringRealization && this.isTransportCompany) {
        arr.push({ title: 'Anuluj w trakcje realizacji', command: this.cancelRideDuringRealizationCommand() });
      }

      if (this.isTaxiRideCancelled && this.userHasRightsToRevertCancelledTaxiRide) {
        arr.push({
          title: 'Przywróć anulowany przejazd',
          command: new RevertCancelledTaxiRideCommand({ course: this.course, ride: this.ride }),
        });
      }

      return arr;
    },
  },
  methods: {
    getCancelledRideStatus() {
      return this.isKilometerCourseRide ? KILOMETER_COURSE_STATUS.CANCELLED : this.getCancelledTaxiRideStatus();
    },
    getCancelledTaxiRideStatus() {
      if (this.isCompany && this.isTaxiCourseCanBeCancelledDuringRealization) {
        return TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION;
      }

      return TAXI_COURSE_RIDE_STATUS.CANCELLED;
    },
    cancelRideCommand() {
      return new UpdateRideStatusCommand({ ride: this.ride, status: this.getCancelledRideStatus() });
    },
    cancelRideDuringRealizationCommand() {
      return new UpdateRideStatusCommand({
        ride: this.ride,
        status: TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION,
      });
    },
  },
};
</script>
