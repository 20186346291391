export const KILOMETER_COURSE_STATUS = {
  CREATED: 'created',
  TO_BE_SETTLED: 'to_be_settled',
  INCONSISTENCY: 'inconsistency',
  SETTLED: 'settled',
  FINISHED: 'kcr_finished',
  CANCELLED: 'cancelled',
};

export const KILOMETER_COURSE_DICT = {
  [KILOMETER_COURSE_STATUS.CREATED]: 'Utworzono',
  [KILOMETER_COURSE_STATUS.TO_BE_SETTLED]: 'Do rozliczenia',
  [KILOMETER_COURSE_STATUS.INCONSISTENCY]: 'Niezgodność',
  [KILOMETER_COURSE_STATUS.SETTLED]: 'Rozliczono',
  [KILOMETER_COURSE_STATUS.FINISHED]: 'Zakończone',
  [KILOMETER_COURSE_STATUS.CANCELLED]: 'Anulowano',
};

export const KILOMETER_COURSE_COLOR_DICT = {
  [KILOMETER_COURSE_STATUS.CREATED]: '#005DB2',
  [KILOMETER_COURSE_STATUS.TO_BE_SETTLED]: '#24B200',
  [KILOMETER_COURSE_STATUS.INCONSISTENCY]: '#B20000',
  [KILOMETER_COURSE_STATUS.SETTLED]: '#24B300',
  [KILOMETER_COURSE_STATUS.FINISHED]: '#00A7B2',
  [KILOMETER_COURSE_STATUS.CANCELLED]: '#9d9d9d',
};
