<template>
  <!-- reset of height is needed because on Android devices height of nav-drawer is greater, than the screen of phone -->
  <v-navigation-drawer
    app
    :value="sidebar"
    @input="setSidebar"
    fixed
    floating
    width="300"
    style="height: 100% !important"
  >
    <template #prepend>
      <CloseIconButton class="mx-2 my-3" @click="setSidebar(false)"></CloseIconButton>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CloseIconButton from '@/component/Button/close-icon-button';

export default {
  name: 'MobilePageDrawer',
  components: {
    CloseIconButton,
  },
  computed: {
    ...mapGetters(['sidebar']),
  },
  methods: {
    ...mapMutations(['setSidebar']),
  },
};
</script>
