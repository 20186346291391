<template>
  <v-app app :class="{ 'grey lighten-3': !$vuetify.theme.dark }">
    <template v-if="$store.getters.user">
      <UpdateBanner v-if="updateAvailable" @click="refreshApp" />
      <ToolbarFactory :class="{ 'toolbar--banner-spacing': updateAvailable }" />
      <DrawerFactory />
      <DefinedKilometersDrawer />
      <employee-request-snackbar />
      <v-main :class="{ 'main--banner-spacing': updateAvailable }">
        <online-indicator />
        <router-view></router-view>
      </v-main>
    </template>
    <template v-if="!$store.getters.user">
      <v-container class="fill-height align justify-center">
        <v-progress-circular indeterminate />
      </v-container>
    </template>
  </v-app>
</template>

<script>
import ToolbarFactory from '@/controller/Page/page-toolbar/page-toolbar-factory';
import DrawerFactory from '@/controller/Page/page-drawer/page-drawer-factory';
import DefinedKilometersDrawer from '@/controller/Page/page-defined-kilometer-drawer';
import OnlineIndicator from '@/controller/Page/online-indicator';
import EmployeeRequestSnackbar from '@/component/EmployeeRequest/employee-request-snackbar';
import UpdateBanner from '@/component/Banner/app-banner';
import updateMixin from '@/core/mixin/update';

export default {
  components: {
    EmployeeRequestSnackbar,
    OnlineIndicator,
    ToolbarFactory,
    DrawerFactory,
    DefinedKilometersDrawer,
    UpdateBanner,
  },
  mixins: [updateMixin],
};
</script>

<style lang="scss">
@use '@/core/style/variables' as *;
.grey.lighten-3 {
  background-color: #eee !important;
  border-color: #eee !important;
}

$update-banner-mobile-s-height: 122px;
$update-banner-mobile-height: 106px;
$update-banner-tablet-height: 76px;
$update-banner-desktop-height: 64px;

$mobile-breakpoint: 415px;
$desktop-breakpoint: 630px;

.main--banner-spacing,
.toolbar--banner-spacing {
  margin-top: $update-banner-mobile-s-height !important;

  @media (min-width: $mobile-breakpoint) {
    margin-top: $update-banner-mobile-height !important;
  }

  @media (min-width: $update-banner-breakpoint) {
    margin-top: $update-banner-tablet-height !important;
  }

  @media (min-width: $desktop-breakpoint) {
    margin-top: $update-banner-desktop-height !important;
  }
}
</style>
