export default {
  methods: {
    getIconType(placeType) {
      switch (placeType) {
        case 'Hotel':
          return 'mdi-bed';
        case 'Home':
          return 'mdi-home';
        case 'Workplace':
          return 'mdi-train';
        case 'Place':
        default:
          return 'pin_drop';
      }
    },
  },
};
