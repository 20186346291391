// eslint-disable-next-line import/prefer-default-export
export class CarMapper {
  static toDTO({ carModel, userModel }) {
    return {
      brand: carModel.brand,
      model: carModel.model,
      engineCapacity: +carModel.engineCapacity,
      initialMileage: carModel.initialMileage,
      registrationNumber: carModel.registrationNumber,
      vin: carModel.vin,
      company: userModel.company['@id'],
      user: userModel['@id'],
      main: true,
    };
  }

  static toModel(carDTO) {
    return {
      id: carDTO['@id'].slice(1),
      brand: carDTO.brand,
      model: carDTO.model,
      initialMileage: carDTO.initialMileage,
      registrationNumber: carDTO.registrationNumber,
      vin: carDTO.vin,
      company: carDTO.company['@id'],
      user: carDTO.user,
      engineCapacity: carDTO.engineCapacity,
      createdBy: carDTO.createdBy,
      createdAt: carDTO.createdAt,
      driverName: carDTO.driverName,
    };
  }
}
