<template>
  <v-alert class="pa-0 mb-0" color="transparent" dense>
    <v-icon small left>mdi-message-reply-text</v-icon>
    <slot />
    <v-icon v-if="editable" class="ml-2" color="primary" small @click="$emit('click')">mdi-pencil</v-icon>
  </v-alert>
</template>

<script>
export default {
  name: 'BaseComment',
  props: {
    editable: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
