<template>
  <DialogFactory :value="value" v-on="$listeners">
    <slot name="header" v-bind:onClose="emitCloseEvent"></slot>
    <div v-if="isLoading" class="d-flex align-center justify-center" style="height: 329px">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-card-text v-else class="flex-grow-1">
      <v-tabs v-model="selectedTab" grow>
        <v-tab>
          <pulse-badge :isVisible="!forms.detailsForm">Szczegóły</pulse-badge>
        </v-tab>
        <v-tab>
          <pulse-badge :isVisible="!forms.contactDetailsForm">Dane kontaktowe</pulse-badge>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <BaseTypography class="pt-4" variant="subtitle">Dane pasażera</BaseTypography>
          <v-form
            class="d-flex flex-column pt-4"
            v-model="forms.detailsForm"
            lazy-validation
            ref="details-form"
            style="row-gap: 16px"
          >
            <v-text-field
              v-model="entityCopy.user.firstname"
              label="Imię"
              outlined
              hide-details="auto"
              :rules="[rules.required]"
              @input="generateUsername"
            ></v-text-field>
            <v-text-field
              v-model="entityCopy.user.surname"
              label="Nazwisko"
              outlined
              hide-details="auto"
              :rules="[rules.required]"
              @input="generateUsername"
            ></v-text-field>
            <EmployerField
              v-if="!isCompany"
              v-model="entityCopy.user.company"
              :rules="[rules.required]"
              data-testid="driver-modal-transport-company-field"
            ></EmployerField>
            <EmployeeGroupInput v-if="isCompany" v-model="entityCopy.user.employeeGroup"></EmployeeGroupInput>
          </v-form>
        </v-tab-item>
        <v-tab-item eager>
          <v-form
            class="d-flex flex-column pt-4"
            v-model="forms.contactDetailsForm"
            lazy-validation
            ref="contact-details-form"
            style="row-gap: 16px"
          >
            <section>
              <BaseTypography class="pt-4" variant="subtitle">Adres e-mail</BaseTypography>
              <email-field v-model="entityCopy.user.email" :entity="entityCopy" label="Adres email"></email-field>
            </section>
            <PhoneSection :phones.sync="entityCopy.user.phones" :entity="entityCopy"></PhoneSection>
            <HomeplaceSection
              :entity="entityCopy"
              :homePlace="entityCopy.user.home"
              :strategy="homeplaceStrategy"
              :removeStrategy="removeHomeplaceStrategy"
              @setHomeplaceFromMap="onSetHomeplaceFromMap"
            ></HomeplaceSection>
            <CarSection :entity.sync="entityCopy"></CarSection>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <DialogSaveButton @click="savePassenger"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import PulseBadge from '@/component/Base/pulse-badge';
import DialogFactory from '@/component/Dialog/dialog-factory';
import BaseTypography from '@/component/Base/base-typography';
import EmailField from '@/component/Field/Email/email-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import PhoneSection from '@/component/Field/Phone/phone-section';
import HomeplaceSection from '@/component/Field/Place/homeplace-section';
import EmployeeGroupInput from '@/component/NewCourse/EmployeeGroupInput/employee-group-input';
import { mapGetters } from 'vuex';
import formValidationRules from '@/core/utils/form-validation-rules';
import CarSection from '../Car/car-section';
import EmployerField from '../employer-field';
import { CreatePassengerHomeplaceStrategy } from '../create-passenger-homeplace-strategy';
import { RemovePassengerHomeplaceStrategy } from '../remove-passenger-homeplace-strategy';

export default {
  name: 'PassengerModal',
  components: {
    DialogFactory,
    EmailField,
    PhoneSection,
    DialogSaveButton,
    HomeplaceSection,
    EmployeeGroupInput,
    PulseBadge,
    BaseTypography,
    CarSection,
    EmployerField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    strategy: {
      required: true,
    },
    entity: {
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entityCopy: JSON.parse(JSON.stringify(this.entity)),
      selectedTab: 0,
      rules: formValidationRules,
      forms: {
        detailsForm: false,
        contactDetailsForm: false,
      },
      homeplaceStrategy: null,
      removeHomeplaceStrategy: null,
    };
  },
  created() {
    if (this.isCompany) {
      this.entityCopy.user.company = this.user.company;
    }
  },
  computed: {
    ...mapGetters(['isCompany', 'user']),
    isFullNameDefined() {
      return !!(this.entityCopy.user.firstname && this.entityCopy.user.surname);
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.resetForm();
    },
    resetForm() {
      this.$refs['details-form'].reset();
      this.$refs['contact-details-form'].reset();
    },
    validateForm() {
      const isContactDetailsFormValid = this.$refs['contact-details-form'].validate();
      const isDetailsFormValid = this.$refs['details-form'].validate();

      return isContactDetailsFormValid && isDetailsFormValid;
    },
    savePassenger() {
      if (this.validateForm()) {
        this.strategy.save({ entity: this.entityCopy }).then(() => {
          this.emitCloseEvent();
        });
      }
    },
    // TODO: updates name in new homeplace
    generateUsername() {
      if (this.isFullNameDefined && this.entityCopy.user.home && this.entityCopy.user.home.id === undefined) {
        this.entityCopy.user.home.name = [this.entityCopy.user.firstname, this.entityCopy.user.surname].join('');
      }
    },
    onSetHomeplaceFromMap(val) {
      this.entityCopy.user.home = val;
    },
  },
  watch: {
    entity() {
      this.entityCopy = JSON.parse(JSON.stringify(this.entity));
      this.homeplaceStrategy = new CreatePassengerHomeplaceStrategy(this.$store, this.entityCopy);
      this.removeHomeplaceStrategy = new RemovePassengerHomeplaceStrategy({
        store: this.$store,
        entity: this.entityCopy,
        componentRef: this,
      });
    },
    value() {
      this.entityCopy = JSON.parse(JSON.stringify(this.entity));
      this.homeplaceStrategy = new CreatePassengerHomeplaceStrategy(this.$store, this.entityCopy);
      this.removeHomeplaceStrategy = new RemovePassengerHomeplaceStrategy({
        store: this.$store,
        entity: this.entityCopy,
        componentRef: this,
      });
      this.selectedTab = 0;
      if (this.value === false) {
        this.resetForm();
      }
    },
  },
};
</script>
