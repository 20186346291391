import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import router from '@/core/config/router';

export default {
  fetchStartingBases: () => Vue.http.get(`api/starting_bases`),

  fetchStartingBase: (id) => Vue.http.get(`api/starting_bases/${id}`),

  softDeleteStartingPlace: (id) => Vue.http.put(`api/starting_bases/${id}`, { isActive: false }),

  insertStartingBase(startingBase) {
    return Vue.http
      .post('api/starting_bases', startingBase)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        router.push({ name: 'starting_base_show', params: { id: data.id } });
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  updateStartingBase(startingBase) {
    return Vue.http
      .put(startingBase['@id'].substring(1), startingBase)
      .then(() => {
        Vue.notify(defaultApiResultManager.then());
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },
};
