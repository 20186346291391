<template>
  <one-page :loading="!entity">
    <one-profile v-if="entity">
      <one-profile-summary
        :title="entity.name"
        :back="{ name: 'company_index' }"
        :edit="{ name: 'company_edit', params: { id: $route.params.id } }"
        class="col-sm-12"
      >
        <one-profile-summary-list :items="summary" />
      </one-profile-summary>
    </one-profile>
  </one-page>
</template>

<script>
import OneProfile from '@/component/Profile/profile-index';
import OneProfileSummary from '@/component/Profile/profile-summary';
import OneProfileSummaryList from '@/component/Profile/profile-summary-list';
import OnePage from '@/component/Page/page-index';

export default {
  components: {
    OnePage,
    OneProfileSummaryList,
    OneProfileSummary,
    OneProfile,
  },
  data() {
    return {
      api: false,
    };
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$http
        .get(`api/companies/${this.$route.params.id}`)
        .then(({ data }) => {
          this.entity = data;
        })
        .catch();
    },
  },

  computed: {
    entity: {
      get() {
        return this.api;
      },
      set(val) {
        this.api = val;
      },
    },

    summary() {
      return [
        {
          title: this.entity.name,
          subtitle: 'Nazwa',
          icon: 'pin_drop',
        },
        {
          title: this.entity.headquartersStreet,
          subtitle: 'Ulica i numer firmy',
          icon: 'domain',
        },
        {
          title: this.entity.headquartersZipCode,
          subtitle: 'Kod pocztowy firmy',
          icon: 'domain',
        },
        {
          title: this.entity.headquartersCity,
          subtitle: 'Miasto firmy',
          icon: 'domain',
        },
        {
          title: this.entity.taxNumber,
          subtitle: 'NIP',
          icon: 'domain',
        },
        {
          title: this.entity.bankAccountNumber,
          subtitle: 'Numer konta bankowego',
          icon: 'domain',
        },
      ];
    },
  },
};
</script>
