import { store } from '@/core/store';

// eslint-disable-next-line import/prefer-default-export
export class RevertCancelledTaxiRideCommand {
  #store = store;

  #payload;

  constructor(payload) {
    this.#payload = payload;
  }

  execute() {
    this.#store.dispatch('taxiCourse/revertCancelledRide', this.#payload);
  }
}
