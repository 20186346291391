import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const EVENTS = {
  SHOW_COURSE_DRAWER: 'SHOW_COURSE_DRAWER',
  OPEN_COURSE_FORM: 'OPEN_COURSE_FORM',
  OPEN_CREATE_MODAL: 'OPEN_CREATE_MODAL',
};

export const eventBus = new Vue({ name: 'event-bus' });
