<template>
  <div>
    <FullscreenDialog :value="isVisible">
      <TaxiCourseDrawer
        :course="course"
        :selectedRide.sync="selectedRideIdx"
        @openSettlementColumn="openSettlementDrawer"
        v-on="$listeners"
      ></TaxiCourseDrawer>
    </FullscreenDialog>
    <FullscreenDialog content-class="taxi-ride-modal" v-if="isRideDrawerVisible" :value="isRideDrawerVisible">
      <TaxiRideDrawer :course="course" :ride="selectedRide" @close="closeRideDrawer"></TaxiRideDrawer>
    </FullscreenDialog>
    <FullscreenDialog :value="isSettlementDrawerVisible">
      <SettlementDrawerFactory
        :ride="selectedRide"
        :course="course"
        @close="closeSettlementDrawer"
      ></SettlementDrawerFactory>
    </FullscreenDialog>
  </div>
</template>

<script>
import FullscreenDialog from '@/component/Dialog/fullscreen-dialog';
import TaxiCourseDrawer from './course-drawer/taxi-course-drawer';
import TaxiRideDrawer from './ride-drawer/taxi-ride-drawer';
import SettlementDrawerFactory from './settlement-drawer/settlement-drawer-factory';

export default {
  name: 'MobileCourseDrawer',
  inheritAttrs: false,
  components: {
    FullscreenDialog,
    TaxiCourseDrawer,
    TaxiRideDrawer,
    SettlementDrawerFactory,
  },
  props: {
    course: Object,
  },
  data() {
    return {
      isVisible: false,
      isRideDrawerVisible: false,
      isSettlementDrawerVisible: false,
      selectedRideIdx: null,
    };
  },
  computed: {
    selectedRide() {
      return this.course.rides[this.selectedRideIdx];
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer');
    },
    resetCourseForm() {
      this.$store.commit('courseCreation/RESET_COURSE');
    },
    closeRideDrawer() {
      this.isRideDrawerVisible = false;
      this.selectedRideIdx = null;
    },
    openSettlementDrawer() {
      this.isSettlementDrawerVisible = true;
    },
    closeSettlementDrawer() {
      this.isSettlementDrawerVisible = false;
    },
  },
  watch: {
    course: {
      handler(val) {
        this.isVisible = !!val;
      },
      immediate: true,
    },
    isVisible(val) {
      if (!val) {
        this.closeDrawer();
        this.resetCourseForm();
      }
    },
    selectedRideIdx(val) {
      this.isRideDrawerVisible = val !== null;
    },
  },
};
</script>

<style lang="scss">
.taxi-ride-modal {
  overflow-y: hidden;

  .v-card {
    height: inherit;
  }
}
</style>
