// eslint-disable-next-line import/prefer-default-export
export class AddEmployeeGroupStakeStrategy {
  #store;

  #selectedEmployeeGroup;

  constructor(store, selectedEmployeeGroup) {
    this.#store = store;
    this.#selectedEmployeeGroup = selectedEmployeeGroup;
  }

  save(form) {
    return this.#store.dispatch('employeeGroup/addEmployeeGroupStake', {
      id: this.#selectedEmployeeGroup.id,
      stakes: [...this.#selectedEmployeeGroup.stakes.map((el) => el['@id']), form],
    });
  }
}
