export default class BaseRelationEditTemplate {
  // eslint-disable-next-line getter-return, class-methods-use-this, no-empty-function
  get relationField() {}

  payload(waypoints) {
    return {
      [this.relationField]: waypoints
        .map((segment, index) => ({
          position: segment.position,
          locomotives: segment.locomotives.map((locomotive) => locomotive['@id']),
          employees: segment.employees.map((employee) => employee['@id']),
          distance: segment.distance,
          time: segment.time,
          timeAndDistanceSource: segment.timeAndDistanceSource,
          waypointStart: {
            id: segment.waypointStart['@id'],
            time: segment.waypointStart.time,
            place: segment.waypointStart.place['@id'],
          },
          waypointEnd: {
            id:
              waypoints[index + 1] && !waypoints[index + 1].waypointStart['@id']
                ? null
                : waypoints[index + 1]?.waypointStart['@id'],
            time: waypoints[index + 1]?.waypointStart?.time,
            place: waypoints[index + 1]?.waypointStart?.place['@id'],
          },
        }))
        .slice(0, -1),
    };
  }
}
