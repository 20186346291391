import { CarBrand } from '../models/car-brand.model';
import { CarModel } from '../models/car-model.model';

const isExistingEntity = (m) => m.id.startsWith('/api/models/');

// eslint-disable-next-line import/prefer-default-export
export class CarBrandMapper {
  static toModel(carBrandDTO) {
    return new CarBrand({
      id: carBrandDTO['@id'].slice(1),
      name: carBrandDTO.name,
      models: carBrandDTO.models.map(
        (m) =>
          new CarModel({
            id: m['@id'],
            name: m.name,
          })
      ),
    });
  }

  static toDTO(carModel) {
    return {
      name: carModel.name,
      models: carModel.models.map((m) => (isExistingEntity(m) ? { id: m.id, name: m.name } : { name: m.name })),
    };
  }
}
