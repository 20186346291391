<template>
  <DescriptionSection>
    <DescriptionItem title="Firma Transportowa">
      <inline-edit-dialog
        :update="updateContractor"
        :readonly="isLoggedUserIsSubcontractor || isCompany"
        :value="transportCompanyName"
        ariaEditLabel="Edytuj firmę transportową"
        chipTestId="transport-company"
      >
        <ContractorInputFactory
          :value="transportCompany"
          v-bind.sync="inlineEditModel"
          isCourseEdition
          :taxiCourse="course"
        ></ContractorInputFactory>
      </inline-edit-dialog>
    </DescriptionItem>
    <DescriptionItem title="Kierowca i nr telefonu">
      <inline-edit-dialog
        :update="updateDriver"
        :readonly="isDriverFieldReadonly"
        :value="driver"
        ariaEditLabel="Edytuj kierowcę"
        chipTestId="driver"
        @open="inlineEditModel = { driver: course.driver, driverPhoneNumber: course.driverPhoneNumber }"
      >
        <!-- TODO: should it have an editable input option? -->
        <driver-field
          v-model="inlineEditModel.driver"
          :company-id="companyId"
          :disabled="!course.contractor && !course.subcontractor"
          class="pb-5"
          data-cy="driver-input"
          @input="onDriverChange"
        />
        <text-field
          v-model="inlineEditModel.driverPhoneNumber"
          :disabled="!course.contractor && !course.subcontractor"
          data-cy="driver-phone-input"
          icon="phone"
          label="Telefon do kierowcy"
        />
      </inline-edit-dialog>
    </DescriptionItem>
    <DescriptionItem title="Czas pracy kierowcy">
      <div class="d-inline-flex align-center">
        <span v-if="!course.driverStartAt" :style="{ 'min-width': isDriverFieldReadonly ? 'auto' : '24px' }">-</span>
        <template v-else>
          <v-icon color="success">mdi-circle-medium</v-icon>
          {{ driverWorkingTime }} ({{ course.driverStartAt | datetimeHourFirst }} -
          {{ course.driverEndAt | datetimeHourFirst }})
        </template>
      </div>
    </DescriptionItem>
  </DescriptionSection>
</template>
<script>
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import ContractorInputFactory from '@/controller/Page/page-toolbar/contractor-input/contractor-input-factory';
import InlineEditDialog from '@/component/NewCourse/Show/Details/inline-edit-dialog';
import TextField from '@/component/Field/Generic/text-field';
import DriverField from '@/component/Field/Driver/driver-field';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { API_COURSE_RIDE_DICT } from '@/core/dict/course-dict';
import driverWorkingTimeMixin from '@/core/mixin/driverTimeTracking';
import { mapGetters } from 'vuex';

const DEFAULT_PLACEHOLDER = '-';

export default {
  name: 'TaxiCourseGeneralInfo',
  components: {
    DriverField,
    TextField,
    InlineEditDialog,
    ContractorInputFactory,
    DescriptionSection,
    DescriptionItem,
  },
  mixins: [driverWorkingTimeMixin],
  data() {
    return {
      inlineEditModel: {
        contractor: this.course.contractor,
        subcontractor: this.course.subcontractor,
      },
    };
  },
  props: {
    course: Object,
  },
  computed: {
    ...mapGetters(['isTransportCompany', 'user', 'isManager', 'isCompany']),
    isDriverFieldReadonly() {
      if (this.isCompany) {
        return true;
      }

      if (this.isSubcontractorDefined && this.isLoggedUserIsContractor) {
        return false;
      }

      if (this.isManager) {
        return false;
      }

      if (!this.isSubcontractorDefined && this.isLoggedUserIsContractor) {
        return false;
      }

      if (this.isLoggedUserIsSubcontractor) {
        return false;
      }

      return true;
    },
    isSubcontractorDefined() {
      return this.course.subcontractor !== null;
    },
    isLoggedUserIsContractor() {
      return this.isTransportCompany && this.course.contractor?.id === this.user.company.id;
    },
    isLoggedUserIsSubcontractor() {
      return this.isTransportCompany && this.course.subcontractor?.id === this.user.company.id;
    },
    driverWorkingTime() {
      return this.getFormattedDriverWorkingTime(this.course);
    },
    companyId() {
      return this.course.subcontractor?.id || this.course.contractor?.id;
    },
    driver() {
      if (this.course.driver) {
        return `${this.course.driver?.name} ${
          this.course.driverPhoneNumber ? `| ${this.course.driverPhoneNumber}` : ''
        }`;
      }

      return DEFAULT_PLACEHOLDER;
    },
    transportCompanyName() {
      return this.course.subcontractor?.name || this.course.contractor?.name || DEFAULT_PLACEHOLDER;
    },
    transportCompany() {
      return this.course.subcontractor || this.course.contractor;
    },
  },
  methods: {
    onDriverChange() {
      this.inlineEditModel.driverPhoneNumber = this.inlineEditModel.driver ? this.inlineEditModel.driver.phone : null;
    },
    // TODO: extract repetead code to separate function
    updateContractor() {
      return this.updateValues({
        contractor: this.inlineEditModel.contractor,
        subcontractor: this.inlineEditModel.subcontractor,
        driver: null,
        driverPhoneNumber: null,
        rides: this.course.rides.map((ride) => ({
          id: ride['@id'],
          type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
          status:
            this.inlineEditModel.contractor &&
            ![TAXI_COURSE_RIDE_STATUS.FINISHED, TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR].includes(ride.status)
              ? TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE
              : ride.status,
        })),
      });
    },
    updateDriver() {
      return this.updateValues({
        driver: this.inlineEditModel.driver?.['@id'] || this.inlineEditModel.driver,
        driverPhoneNumber: this.inlineEditModel.driverPhoneNumber,
        rides: this.course.rides.map((ride) => ({
          id: ride['@id'],
          type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
          status:
            this.inlineEditModel.driver &&
            this.transportCompany.courseEmployeeSmsNotification &&
            ![TAXI_COURSE_RIDE_STATUS.FINISHED, TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR].includes(ride.status)
              ? TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE
              : ride.status,
        })),
      });
    },
    updateValues(payload) {
      return this.$store.dispatch('taxiCourse/updateValues', {
        '@id': this.course['@id'],
        payload: {
          ...payload,
        },
      });
    },
  },
};
</script>
