<template>
  <div>
    <v-list-item>
      <div class="col-4">Kierowca</div>
      <div class="col-8">
        {{ driver }}
      </div>
    </v-list-item>
    <v-list-item>
      <div class="col-4">Numer lokomotyw</div>
      <div class="col-8">
        {{ ride.locomotivesSideNumbers | placeholder }}
      </div>
    </v-list-item>
    <v-list-item>
      <div class="col-4">Status</div>
      <!-- TODO: make chip width as in figma -->
      <div class="col-8">
        <ride-status-chip-field :value="ride.status" :statuses="statuses" readonly kilometer-course />
      </div>
    </v-list-item>
    <v-list-item>
      <div class="col-4">Dodane</div>
      <div class="col-8">{{ course.createdAt | datetime }} przez {{ course.createdBy.name }}</div>
    </v-list-item>
  </div>
</template>
<script>
import RideStatusChipField from '@/component/Field/TaxiCourse/ride-status-chip-field';
import { KILOMETER_COURSE_DICT } from '@/core/dict/kilometer-course-dict';

export default {
  name: 'KilometerCourseGeneralInfo',
  components: { RideStatusChipField },
  props: {
    course: Object,
    // TODO: passing of ride has downsides, when multiple ride will be declared in the course
    ride: Object,
  },
  computed: {
    driver() {
      return `${this.course.driver?.name} ${this.course.driverPhoneNumber ? `| ${this.course.driverPhoneNumber}` : ''}`;
    },
    statuses() {
      return Object.entries(KILOMETER_COURSE_DICT).map(([value, text]) => ({ value, text }));
    },
  },
};
</script>
