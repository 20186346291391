<template>
  <div class="white fill-height flex-grow-1 d-flex flex-column">
    <TaxiRideHeader :ride="ride" :course="course" :rideNumber="ride.number" v-on="$listeners"></TaxiRideHeader>
    <v-tabs v-model="selectedTab" fixed-tabs class="flex-grow-0">
      <v-tab>Relacja</v-tab>
      <v-tab>
        <pulse-badge :isVisible="rideHasComments" offset-y="5px" offset-x="-5px">Komentarze</pulse-badge>
      </v-tab>
      <v-tab>Komunikacja</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="selectedTab" class="flex-grow-1">
      <v-tab-item class="tab__item--full-height">
        <TaxiCourseRideGeneralInfo class="mx-6 my-4" :ride="ride" :course="course"></TaxiCourseRideGeneralInfo>
        <v-divider></v-divider>
        <div class="overflow-y-auto">
          <BaseTypography variant="subtitle-2" class="mx-6 mt-4 py-4">Relacja przejazdu</BaseTypography>
          <segments-timeline
            :ride="ride"
            :course="course"
            @acceptRide="updateRideStatus"
            @declineRide="updateRideStatus"
            editable
            extended-view
          />
          <taxi-course-segments-google-map
            v-if="isGoogleMapDisplayed"
            :waypoints="waypoints"
            :zoom="4.7"
            height="168px"
            style="width: 100%"
            data-testid="inline-google-map"
          />
          <base-text-button block v-else prependIcon="mdi-map" @click="showInlineGoogleMap">
            Pokaż na mapie
          </base-text-button>
        </div>
      </v-tab-item>
      <v-tab-item class="tab__item--full-height">
        <comment-section class="flex-grow-1 overflow-y-auto" :entity="{ ...ride, '@type': 'Ride' }" />
      </v-tab-item>
      <v-tab-item class="tab__item--full-height">
        <ride-message-section class="flex-grow-1 overflow-y-auto" :ride="ride" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PulseBadge from '@/component/Base/pulse-badge';
import BaseTypography from '@/component/Base/base-typography';
import SegmentsTimeline from '@/component/NewCourse/Segments/segments-timeline-factory';
import TaxiCourseSegmentsGoogleMap from '@/component/GMap/taxi-course-segments-map';
import BaseTextButton from '@/component/Base/base-text-button';
import RideMessageSection from '@/component/NewCourse/Show/Details/ride-message-section';
import CommentSection from '@/component/NewCourse/Show/Details/comment-section';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';
import TaxiCourseRideGeneralInfo from '@/component/NewCourse/Show/Details/taxi-course-ride-general-info';
import TaxiRideHeader from '../header/taxi-ride-header';

const RELATION_TAB = 0;

export default {
  name: 'TaxiRideDrawer',
  components: {
    TaxiRideHeader,
    PulseBadge,
    SegmentsTimeline,
    TaxiCourseSegmentsGoogleMap,
    BaseTextButton,
    RideMessageSection,
    CommentSection,
    TaxiCourseRideGeneralInfo,
    BaseTypography,
  },
  props: {
    ride: Object,
    course: Object,
  },
  data() {
    return {
      selectedTab: RELATION_TAB,
      isGoogleMapDisplayed: false,
    };
  },
  methods: {
    showInlineGoogleMap() {
      this.isGoogleMapDisplayed = true;
    },
    updateRideStatus(status) {
      new UpdateRideStatusCommand({ status, ride: this.ride }).execute();
    },
  },
  computed: {
    rideHasComments() {
      return this.ride.comments.length > 0;
    },
    waypoints() {
      const waypoints = this.ride.segments.map((segment) => segment.waypointStart.place);

      const lastSegment = this.ride.segments.at(-1);
      waypoints.push(lastSegment.waypointEnd.place);

      return waypoints;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab__item--full-height {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

::v-deep .v-window__container {
  height: 100%;
}

::v-deep #map {
  box-shadow: none;
}
</style>
