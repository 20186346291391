<template>
  <div>
    <add-waypoint-mobile-button
      class="mb-4"
      @click="$emit('addWaypoint', index)"
      data-testid="add-waypoint-before-btn"
    />
    <waypoint-form
      :segment="segment"
      :companyId="companyId"
      :showDetails="showDetails"
      :readonly="readonly"
      @calculateRoute="$emit('calculateRoute', index, $event)"
      @calculateTime="$emit('calculateTime', index)"
      @locomotiveSelected="$emit('locomotiveSelected', $event, index)"
      @placeInputClick="$emit('placeInputClick')"
    >
      <template #header>
        <div class="d-flex justify-space-between align-center mb-7">
          <span class="segment-form__header">Przystanek {{ generateWaypointName(index) }}</span>
          <v-btn icon small color="error" :disabled="isSingleWaypointCourse" @click="$emit('deleteWaypoint', index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </waypoint-form>
    <add-waypoint-mobile-button
      v-if="isLastWaypoint"
      class="mt-4"
      @click="$emit('addWaypointAfter', index)"
      data-testid="add-waypoint-after-btn"
    />
  </div>
</template>

<script>
import AddWaypointMobileButton from '@/component/NewCourse/Form/Ride/Segment/add-waypoint-mobile-button';
import WaypointForm from '@/component/NewCourse/Form/Ride/Segment/waypoint-form';
import waypointNameGenerator from '@/core/mixin/waypointNameGenerator';

export default {
  components: {
    WaypointForm,
    AddWaypointMobileButton,
  },
  mixins: [waypointNameGenerator],
  props: {
    segment: {
      type: Object,
    },
    index: {
      type: Number,
    },
    showDetails: {
      type: Boolean,
    },
    companyId: {},
    isLastWaypoint: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isSingleWaypointCourse: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<!-- TODO: add styles -->
<style lang="scss" scoped>
.segment-form__header {
  font: 700 16px/19px 'Roboto';
  letter-spacing: 0.15px;
}
</style>
