const DefinedKilometerList = () => import('@/controller/DefinedKilometer/defined-kilometer-list');
const DefinedKilometerCreate = () => import('@/controller/DefinedKilometer/defined-kilometer-create');
const DefinedKilometerEdit = () => import('@/controller/DefinedKilometer/defined-kilometer-edit');
const DefinedKilometerShow = () => import('@/controller/DefinedKilometer/defined-kilometer-show');

const name = 'defined_kilometer';

export default [
  {
    path: '/defined-kilometers',
    name: `${name}_index`,
    component: DefinedKilometerList,
    meta: {
      title: 'Zdefiniowane kilometry',
      create: `${name}_create`,
      roles: ['ROLE_ADMIN', 'ROLE_TRANSPORT_COMPANY'],
    },
  },
  {
    path: '/defined-kilometers/create',
    name: `${name}_create`,
    component: DefinedKilometerCreate,
    meta: {
      title: 'Zdefiniowane kilometry',
    },
  },
  {
    path: '/defined-kilometers/:id/edit',
    name: `${name}_edit`,
    component: DefinedKilometerEdit,
    meta: {
      title: 'Zdefiniowane kilometry',
    },
  },
  {
    path: '/defined-kilometers/:id',
    name: `${name}_show`,
    component: DefinedKilometerShow,
    meta: {
      title: 'Zdefiniowane kilometry',
    },
  },
];
