import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/core/router';
import { UNPROTECTED_ROUTES, LOGIN_ROUTE } from '@/core/router/route-names';
import auth from '@/core/mixin/auth';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, _from, next) => {
  if (UNPROTECTED_ROUTES.includes(to.name)) {
    next();
  } else if (!auth.methods.isAuthenticated()) {
    next({ name: LOGIN_ROUTE, query: { redirectTo: to.path } });
  } else {
    next();
  }
});

export default router;
