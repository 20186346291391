<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" title="Zmiana danych osobowych">
          <template #prepend-item>
            <BackIconButton @click="navigateToSettings"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="flex-grow-1 pa-4 d-flex flex-column">
          <v-form class="flex-grow-1 flex-md-grow-0 mb-4" ref="form">
            <v-text-field
              v-model="userModel.firstname"
              class="mb-4"
              label="Imię"
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Pole jest wymagane']"
            ></v-text-field>
            <v-text-field
              v-model="userModel.surname"
              label="Nazwisko"
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Pole jest wymagane']"
            ></v-text-field>
          </v-form>
          <v-btn @click="updatePersonalData" class="flex-grow-0 align-self-md-end" color="primary">
            Zmień dane osobowe
          </v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import { mapGetters } from 'vuex';
import * as ROUTES from '@/core/router/route-names';

export default {
  name: 'UserNameSettings',
  components: {
    AbstractModalHeader,
    BackIconButton,
  },
  data() {
    return {
      userModel: null,
    };
  },
  created() {
    this.userModel = JSON.parse(JSON.stringify(this.user));
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTES.SETTINGS_ROUTE });
    },
    updatePersonalData() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateUser', this.userModel).then(() => {
          this.navigateToSettings();
        });
      }
    },
  },
};
</script>
