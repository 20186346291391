<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" title="Lokalizacja">
          <template #prepend-item>
            <BackIconButton @click="navigateToSettings"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="pa-4">
          <BaseTypography variant="body-1">
            Aby zmienić ustawienia lokalizacji postępuj zgodnie z poniższymi instrukcjami.
          </BaseTypography>
          <SettingsElement
            :title="browserName"
            subtitle="Udostępnianie lokalizacji"
            :href="geolocationSettingsArticle"
            variant="primary"
          >
            <template #append-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </template>
          </SettingsElement>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import BrowserDetectionService from '@/service/BrowserDetectionService';
import * as ROUTES from '@/core/router/route-names';
import SettingsElement from './SettingsOverview/settings-element';

export default {
  name: 'GeolocationPermissionSettings',
  components: {
    AbstractModalHeader,
    BackIconButton,
    BaseTypography,
    SettingsElement,
  },
  computed: {
    browserName() {
      return BrowserDetectionService.getBrowserName();
    },
    geolocationSettingsArticle() {
      return BrowserDetectionService.getGeolocationLink();
    },
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTES.SETTINGS_ROUTE });
    },
  },
};
</script>

<style lang="scss" scoped></style>
