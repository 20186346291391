import TransferRideService from '@/service/TransferRideService';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { COURSE_STATUS, TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import TaxiCourseAdapter from '@/core/shared/taxi-course-adapter';
import TaxiCourseService from '@/service/TaxiCourseService';
import DriverSegmentsEdit from '@/component/NewCourse/Segments/EditRelationTemplate/DriverSegmentsEdit';
import Vue from 'vue';
import dayjs from 'dayjs';
import * as types from './types/transfer-ride-mutation-types';

export default {
  namespaced: true,
  state: {
    dialogOpen: false,
    transferSubject: null,
  },
  getters: {
    isDialogOpen: (state) => state.dialogOpen,
    transferSubject: (state) => state.transferSubject,
    transferSubjectNumber: (state) => state.transferSubject?.ride?.number,
    transferSubjectCourseId: (state) => state.transferSubject?.ride?.courseId,
    subjectRideCountAfterTransfer: (_, getters, _3, rootGetters) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const status of Object.values(COURSE_STATUS)) {
        const subjectCourse = rootGetters['taxiCourse/courses'][status].find(
          (course) => course.id === getters.transferSubjectCourseId
        );
        if (subjectCourse !== undefined) {
          return subjectCourse.rides.length - 1;
        }
      }
      return 0;
    },
    isRideCanBeExtracted: (_, _2, _3, rootGetters) => (courseId) => {
      const MIN_RIDES_COUNT_TO_ALLOW_EXTRACTION = 2;
      // eslint-disable-next-line no-restricted-syntax
      for (const status of Object.values(COURSE_STATUS)) {
        const courseToExtractFrom = rootGetters['taxiCourse/courses'][status].find((course) => course.id === courseId);
        if (courseToExtractFrom !== undefined) {
          return courseToExtractFrom.rides.length >= MIN_RIDES_COUNT_TO_ALLOW_EXTRACTION;
        }
      }

      return false;
    },
    coursesTransferTo: (_, getters, _3, rootGetters) =>
      rootGetters['taxiCourse/courses'][COURSE_STATUS.IN_PROGRESS].filter(
        (course) => course.id !== getters.transferSubjectCourseId && course['@type'] === COURSE_TYPE_DICT.TAXI_COURSE
      ),
  },
  mutations: {
    [types.OPEN_DIALOG]: (state) => {
      state.dialogOpen = true;
    },
    [types.CLOSE_DIALOG]: (state) => {
      state.dialogOpen = false;
    },
    [types.SET_TRANSFER_SUBJECT]: (state, val) => {
      state.transferSubject = val;
    },
  },
  actions: {
    openDialog({ commit }, transferSubject) {
      commit(types.OPEN_DIALOG);
      commit(types.SET_TRANSFER_SUBJECT, transferSubject);
    },
    closeDialog({ commit }) {
      commit(types.CLOSE_DIALOG);
    },
    async synchronizeDriverRelation({ dispatch }, { course, ride }) {
      if (ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER && course.rides.length >= 2) {
        const driverSegments = [];
        const dependantWaypoints = {};
        const ridesWithoutExtractedRide = course.rides.filter((originalRide) => originalRide.id !== ride.id);
        const rideWaypoints = ridesWithoutExtractedRide
          .map((newRide) => new TaxiCourseAdapter(newRide).transformSegmentsToWaypoints())
          .flat();

        driverSegments.push(...rideWaypoints);
        driverSegments.sort((a, b) => new Date(a.waypointStart.time) - new Date(b.waypointStart.time));

        driverSegments.forEach((waypoint, index) => {
          // eslint-disable-next-line no-param-reassign
          waypoint = {
            ...waypoint,
            distance: null,
            time: null,
            estimatedDistance: null,
            definedDistance: null,
            fallbackDistance: null,
            estimatedTime: null,
            definedTime: null,
            fallbackTime: null,
          };

          if (
            driverSegments[index + 1] &&
            dayjs(waypoint.waypointStart.time).isSame(driverSegments[index + 1].waypointStart.time, 'minute') &&
            waypoint.waypointStart.place.id === driverSegments[index + 1].waypointStart.place.id
          ) {
            waypoint.locomotives.push(...driverSegments[index + 1].locomotives);
            waypoint.employees.push(...driverSegments[index + 1].employees);
            dependantWaypoints[waypoint.id] = [
              ...(dependantWaypoints[waypoint.id] ?? []),
              driverSegments[index + 1].id,
            ];
            driverSegments.splice(index + 1, 1);
          }
        });

        driverSegments.forEach((waypoint, index) => {
          // eslint-disable-next-line no-param-reassign
          waypoint.position = index;
          // eslint-disable-next-line no-param-reassign
          delete waypoint['@id'];
        });

        const getSiblingWaypointsWithCalculatedDistance = (index) => {
          const route = [driverSegments[index]];

          if (driverSegments[index].waypointStart.place === null) {
            return [];
          }

          if (driverSegments[index + 1]?.waypointStart?.place) {
            route.push(driverSegments[index + 1]);
          }

          return route;
        };

        const calculateKilometerWaypointDistanceWithoutTime = async ({ index }) => {
          const route = getSiblingWaypointsWithCalculatedDistance(index);

          if (route.length >= 2) {
            const data = await TaxiCourseService.calculateKilometerRoute({ segments: route });

            data.forEach((item) => {
              Vue.set(driverSegments, item.position, {
                ...item,
                distance: item.distance ?? 0,
                time: item.time ?? 0,
              });
            });
          }
        };

        const processSequentially = async () => {
          for (let index = 0; index < driverSegments.length; index += 1) {
            // eslint-disable-next-line no-await-in-loop
            await calculateKilometerWaypointDistanceWithoutTime({ index });
          }
        };

        await processSequentially();

        const result = new DriverSegmentsEdit().payload(driverSegments);
        const fromStartingPlace = await TaxiCourseService.fromStartingPlacePayload({
          ...course,
          rides: [{ fromStartingBase: course.fromStartingPlace, segments: driverSegments }],
        });
        const toStartingPlace = await TaxiCourseService.toStartingPlacePayload({
          ...course,
          rides: [{ fromStartingBase: course.fromStartingPlace, segments: driverSegments }],
        });

        if (fromStartingPlace) {
          delete fromStartingPlace.id;
          delete fromStartingPlace.type;
          delete toStartingPlace.id;
          delete toStartingPlace.type;
        }

        await dispatch(
          'taxiCourse/updateValues',
          {
            '@id': `/api/taxi_courses/${course.id}`,
            payload: {
              ...result,
              fromStartingPlace,
              toStartingPlace,
            },
          },
          { root: true }
        );
      }
    },
    transferRide({ getters, commit, dispatch }, targetCourse) {
      commit('setLoading', true, { root: true });
      return TransferRideService.transferRide({
        target: targetCourse,
        transferSubject: getters.transferSubject.ride,
        count: getters.subjectRideCountAfterTransfer,
      })
        .then(async () => {
          await dispatch('synchronizeDriverRelation', getters.transferSubject);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    extractRide({ commit, dispatch }, { ride, course }) {
      commit('setLoading', true, { root: true });
      return TransferRideService.extractRide(ride)
        .then(async () => {
          await dispatch('synchronizeDriverRelation', { ride, course });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
