<template>
  <!-- TODO: remove div after migration to vue 3 -->
  <div>
    <AbstractModalHeader title="Edytuj grupę pracowniczą">
      <template #append-item>
        <RemoveIconButton @click="openConfirmDialog"></RemoveIconButton>
      </template>
      <template #close>
        <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
      </template>
    </AbstractModalHeader>
    <ConfirmDialog
      :showDialog="confirmDialog"
      text="Czy na pewno chcesz usunąć grupę pracowniczą?"
      @confirm="onConfirm"
    ></ConfirmDialog>
  </div>
</template>

<script>
import RemoveIconButton from '@/component/Button/remove-icon-button';
import CloseIconButton from '@/component/Button/close-icon-button';
import closeEvent from '@/core/mixin/closeEvent';
import ConfirmDialog from '@/component/Dialog/confirm-dialog';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';

export default {
  name: 'EditEmployeeGroupHeader',
  mixins: [closeEvent],
  components: {
    AbstractModalHeader,
    CloseIconButton,
    RemoveIconButton,
    ConfirmDialog,
  },
  props: {
    entity: {
      required: true,
    },
  },
  data() {
    return {
      confirmDialog: false,
    };
  },
  methods: {
    openConfirmDialog() {
      this.confirmDialog = true;
    },
    deleteEmployeeGroup() {
      this.$store.dispatch('employeeGroup/deleteEmployeeGroup', this.entity.id).then(() => {
        this.emitCloseEvent();
      });
    },
    onConfirm(val) {
      if (val) {
        this.deleteEmployeeGroup();
      }
      this.confirmDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
