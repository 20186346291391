<template>
  <AbstractRailwayCompanyInput
    :items="railwayCompanies"
    :loading="railwayCompaniesLoading"
    v-bind="$attrs"
    v-on="$listeners"
  ></AbstractRailwayCompanyInput>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractRailwayCompanyInput from './abstract-railway-company-input';

export default {
  name: 'NonEditableRailwayCompanyInput',
  inheritAttrs: false,
  components: {
    AbstractRailwayCompanyInput,
  },
  created() {
    this.$store.dispatch('fetchRailwayCompanies');
  },
  computed: {
    ...mapGetters(['railwayCompanies', 'railwayCompaniesLoading']),
  },
};
</script>
