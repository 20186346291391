<template>
  <h4
    class="font-weight-medium d-flex align-center"
    :class="{ 'course-list__header--requires-attention': requiresAttention }"
    style="height: 72px"
  >
    <slot></slot>
    <v-chip
      class="ml-2 pa-2 custom-chip custom-chip--disabled"
      :color="requiresAttention ? '#E31B0C' : '#F5F5F5'"
      :text-color="requiresAttention ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)'"
      small
    >
      {{ counter || 0 }}
    </v-chip>
    <slot name="append"></slot>
  </h4>
</template>
<script>
export default {
  name: 'CourseListHeader',
  props: {
    courseGroup: {},
    counter: Number,
    requiresAttention: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.custom-chip.custom-chip--disabled:hover:before {
  opacity: 0;
}

.course-list__header--requires-attention {
  color: #e31b0c;
}
</style>
