<template>
  <v-overlay z-index="1000" :value="isVisible">
    <v-progress-circular color="primary" indeterminate size="44"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
