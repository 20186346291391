<template>
  <AbstractPlace
    icon="mdi-home-edit"
    iconColor="primary"
    btnColor="#3397FF42"
    :place="blankPlace"
    :type="null"
    v-bind="$attrs"
    ariaLabel="Dodaj adres domowy"
    @click="emitOpenHomeAddressDialog"
  ></AbstractPlace>
</template>

<script>
import AbstractPlace from './abstract-place';

export default {
  name: 'BlankHomeplace',
  inheritAttrs: false,
  components: {
    AbstractPlace,
  },
  computed: {
    blankPlace() {
      return {
        name: 'Dom',
        address: 'Dodaj adres',
      };
    },
  },
  methods: {
    emitOpenHomeAddressDialog() {
      this.$emit('openHomeAddressDialog');
    },
  },
};
</script>
