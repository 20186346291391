import CompanyShow from '@/controller/Company/company-show';

const CompanyIndex = () => import('@/controller/Company/company-index');
const CompanyCreate = () => import('@/controller/Company/company-create');
const CompanyEdit = () => import('@/controller/Company/company-edit');

const name = 'company';

export default [
  {
    path: '/companies',
    name: `${name}_index`,
    component: CompanyIndex,
    meta: {
      title: 'Firmy',
      create: `${name}_create`,
    },
  },
  {
    path: '/companies/create',
    name: `${name}_create`,
    component: CompanyCreate,
    meta: {
      title: 'Firmy',
    },
  },
  {
    path: '/companies/:id',
    name: `${name}_show`,
    component: CompanyShow,
    meta: {
      title: 'Firma',
    },
  },
  {
    path: '/companies/:id/edit',
    name: `${name}_edit`,
    component: CompanyEdit,
    meta: {
      title: 'Firma',
    },
  },
];
