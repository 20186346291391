<template>
  <v-timeline-item fill-dot color="rgba(51, 151, 255, 0.26)">
    <template #icon>
      <v-btn icon @click="emitClick">
        <v-icon small color="primary">mdi-plus</v-icon>
      </v-btn>
    </template>
    <add-waypoint-button @click="emitClick" />
  </v-timeline-item>
</template>

<script>
import AddWaypointButton from '@/component/NewCourse/Form/Ride/Segment/add-waypoint-button';

export default {
  name: 'AddWaypointDesktopButton',
  components: { AddWaypointButton },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
