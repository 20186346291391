<template>
  <v-list subheader class="py-0" two-line>
    <template v-for="(item, index) in items">
      <slot name="items" :item="item"></slot>
      <v-divider v-if="index !== items.length - 1" :key="index" />
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'OneList',
  props: { items: Array },
};
</script>
