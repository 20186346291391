<template>
  <div>
    <AbstractPassengerField v-bind="$attrs" v-on="$listeners">
      <template #append-item>
        <AddItemButton @click="openPassengerModal()">Dodaj pasażera</AddItemButton>
      </template>
      <template #item="{ attrs, item }">
        <div class="d-flex flex-row align-center flex-grow-1" style="column-gap: 16px">
          <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
          <p class="flex-grow-1 mb-0">{{ item.name }}</p>
          <v-btn
            v-if="userHasRightsToEditPassenger"
            aria-label="edytuj"
            color="primary"
            icon
            @click.stop="openPassengerModal(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </AbstractPassengerField>

    <PassengerModalFactory :state="state" @close="closePassengerModal"></PassengerModalFactory>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import AbstractPassengerField from './passenger-field';
import PassengerModalFactory from './passenger-modal/passenger-modal-factory';

export default {
  name: 'EditablePassengerField',
  inheritAttrs: false,
  components: {
    AbstractPassengerField,
    AddItemButton,
    PassengerModalFactory,
  },
  data() {
    return {
      state: MODAL_STATE.CLOSED,
    };
  },
  computed: {
    ...mapGetters(['isTransportCompany', 'isSuperiorTransportCompany']),
    userHasRightsToEditPassenger() {
      return this.isTransportCompany ? this.isSuperiorTransportCompany : true;
    },
  },
  methods: {
    openPassengerModal(item = null) {
      this.state = item ? { ...MODAL_STATE.EDIT_ENTITY_MODAL, selectedEntity: item } : MODAL_STATE.CREATE_ENTITY_MODAL;
    },
    closePassengerModal() {
      this.state = MODAL_STATE.CLOSED;
    },
  },
};
</script>
