<template>
  <base-text-button @click="emitClick">dodaj przystanek</base-text-button>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';

export default {
  name: 'AddWaypointButton',
  components: { BaseTextButton },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
