<template>
  <v-divider class="settlement__divider"></v-divider>
</template>

<script>
export default {
  name: 'SettlementTableDivider',
};
</script>

<style lang="scss" scoped>
.settlement__divider {
  border-top: 2px dashed #e0e0e0;
  margin: 16px 0;
}
</style>
