<template>
  <AbstractPlace
    icon="mdi-home"
    iconColor="warning"
    btnColor="#FF98004D"
    type="homeplace"
    v-bind="$attrs"
    @click.native="emitSelectHomeplace"
  ></AbstractPlace>
</template>

<script>
import AbstractPlace from './abstract-place';

export default {
  name: 'FavouritePlace',
  inheritAttrs: false,
  components: {
    AbstractPlace,
  },
  methods: {
    emitSelectHomeplace() {
      this.$emit('selectHomeplace');
    },
  },
};
</script>
