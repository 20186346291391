<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" title="Nie pamiętasz hasła?">
          <template #prepend-item>
            <BackIconButton @click="navigateToPasswordChange"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="flex-grow-1 pa-4 d-flex flex-column">
          <v-form
            ref="form"
            class="d-flex flex-column flex-grow-1 flex-md-grow-0 mb-4"
            style="row-gap: 16px"
            @submit.prevent
          >
            <BaseTypography variant="body-1" noMargin>
              Na poniższy adres e-mail lub numer telefonu zostanie wysłany link aktywacyjny.
            </BaseTypography>
            <TextField v-model="userIdentifier" label="Adres e-mail lub numer telefonu" required></TextField>
          </v-form>
          <v-btn class="flex-grow-0 align-self-md-end" color="primary" @click="sendResetLink">
            Wyślij link resetujący
          </v-btn>
        </div>
        <ResetLinkConfirmModal
          :value="confirmModal"
          :userIdentifier="userIdentifier"
          @close="navigateOnClose"
        ></ResetLinkConfirmModal>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import TextField from '@/component/Field/Generic/text-field';
import ResetLinkConfirmModal from './UserPasswordReset/reset-link-confirm-modal';

export default {
  name: 'UserPasswordResetRequest',
  components: {
    AbstractModalHeader,
    BackIconButton,
    BaseTypography,
    TextField,
    ResetLinkConfirmModal,
  },
  props: {
    navigateTo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userIdentifier: null,
      confirmModal: false,
    };
  },
  methods: {
    navigateToPasswordChange() {
      this.$router.back();
    },
    navigateOnClose() {
      this.$router.push(this.navigateTo);
    },
    sendResetLink() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('requestResetPasswordLink', this.userIdentifier).then(() => {
          this.confirmModal = true;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
