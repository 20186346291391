import Vue from 'vue';
import core from '@/core';
import VueResource from 'vue-resource';
import vuetify from '@/core/config/vuetify';
import PassengerChangeAssignDialog from '@/component/Dialog/passenger-change-assign-dialog';
import DIALOGS from '@/core/dict/custom-dialog-dict';
import { LOGOUT_ROUTE } from '@/core/router/route-names';
import './registerServiceWorker';
import { UNAUTHORIZED_STATUS } from '@/core/dict/response-status-dict';
import Notification from '@/plugins/Notification';
import AuthService from '@/service/AuthService';
import App from './App';

Vue.dialog.registerComponent(DIALOGS.PASSENGER_CHANGE_ASSIGN, PassengerChangeAssignDialog);

Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_API_URL;

Vue.use(Notification);

// eslint-disable-next-line no-new
new Vue({
  vuetify,
  mixins: [core],
  data: {
    rightSidebar: {
      active: false,
      blankDefinedKilometers: [],
    },
    dark: false,
  },
  created() {
    Vue.http.interceptors.push((request, next) => {
      // eslint-disable-next-line consistent-return
      next((response) => {
        if (response.status === UNAUTHORIZED_STATUS && this.$route.name !== 'logout' && this.$route.name !== 'login') {
          if (response.url.endsWith('api/token/refresh')) {
            this.$router.replace({ name: LOGOUT_ROUTE });

            return false;
          }

          const refreshToken = localStorage.getItem(process.env.VUE_APP_JWT_REFRESH_NAME);
          if (!refreshToken) {
            return false;
          }

          return AuthService.refreshToken().then((newToken) => {
            request.headers.set('Authorization', `Bearer ${newToken}`);
            return Vue.http(request).then((data) => data);
          });
        }
      });
    });

    this.initUser();
  },
  render: (h) => h(App),
}).$mount('#app');
