<template>
  <div class="white fill-height d-flex flex-column">
    <TaxiCourseHeader @openSettlementColumn="$emit('openSettlementColumn')" @close="$emit('close')"></TaxiCourseHeader>
    <TaxiCourseGeneralInfo class="mx-6 my-4" :course="course"></TaxiCourseGeneralInfo>
    <v-divider></v-divider>
    <section class="mx-6 my-4">
      <BaseTypography variant="subtitle-2" class="py-4">Przejazdy w kursie</BaseTypography>
      <SettingsElement
        v-for="(ride, idx) in course.rides"
        :key="ride.id"
        :title="`Przejazd ${ride.number}`"
        :subtitle="ride.company.name"
        :input-value="selectedRide === idx"
        @click="$emit('update:selectedRide', idx)"
      ></SettingsElement>
    </section>
    <v-divider></v-divider>
    <section v-if="isManager || isTransportCompany" class="overflow-y-auto">
      <BaseTypography variant="subtitle-2" class="mx-6 mt-4 py-4">Relacja kierowcy</BaseTypography>
      <segments-timeline
        class="overflow-y-auto"
        v-if="isCourseFinished"
        :ride="course"
        :course="course"
        isDriverRelation
        extended-view
        editable
        data-testid="driver-relation"
      />
      <v-col v-else cols="12">
        <div class="pa-6 text-center grey--text">Relacja będzie dostępna po zakończeniu kursu</div>
      </v-col>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseTypography from '@/component/Base/base-typography';
import SegmentsTimeline from '@/component/NewCourse/Segments/segments-timeline-factory';
import SettingsElement from '@/controller/Settings/SettingsOverview/settings-element';
import { COURSE_STATUS } from '@/core/dict/taxi-course-dict';
import TaxiCourseHeader from '../header/taxi-course-header';
import TaxiCourseGeneralInfo from '../Details/taxi-course-general-info';

export default {
  name: 'TaxiCourseDrawer',
  components: {
    TaxiCourseHeader,
    TaxiCourseGeneralInfo,
    SettingsElement,
    SegmentsTimeline,
    BaseTypography,
  },
  props: {
    course: Object,
    selectedRide: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isManager', 'isTransportCompany', 'isCompany']),
    isCourseFinished() {
      return this.course.status === COURSE_STATUS.FINISHED;
    },
  },
};
</script>

<style lang="scss" scoped></style>
