<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" title="Zmiana hasła">
          <template #prepend-item>
            <BackIconButton @click="navigateToSettings"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="flex-grow-1 pa-4 d-flex flex-column">
          <v-form ref="form" class="d-flex flex-column flex-grow-1 flex-md-grow-0 mb-4" style="row-gap: 16px">
            <BaseTypography variant="body-1" noMargin>
              Pamiętaj, aby nowe hasło różniło się od obecnego hasła.
            </BaseTypography>
            <PasswordField class="flex-grow-0" v-model="oldPassword" label="Obecne hasło" required></PasswordField>
            <BaseLink
              :to="{ name: ROUTE_NAMES.USER_PASSWORD_RESET_REQUEST_ROUTE }"
              text="Nie pamiętasz hasła?"
            ></BaseLink>
            <PasswordField v-model="newPassword" label="Nowe hasło" required></PasswordField>
          </v-form>
          <v-btn class="flex-grow-0 align-self-md-end" color="primary" @click="updatePassword">Zmień hasło</v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import BaseLink from '@/component/Base/base-link';
import PasswordField from '@/component/Field/Generic/password-field';
import * as ROUTE_NAMES from '@/core/router/route-names';

export default {
  name: 'UserPasswordChangeSettings',
  components: {
    AbstractModalHeader,
    BackIconButton,
    BaseTypography,
    BaseLink,
    PasswordField,
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
    };
  },
  created() {
    this.ROUTE_NAMES = ROUTE_NAMES;
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTE_NAMES.SETTINGS_ROUTE });
    },
    updatePassword() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('updatePassword', { oldPassword: this.oldPassword, password: this.newPassword })
          .then(() => {
            this.navigateToSettings();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
