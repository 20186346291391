<template>
  <v-badge :content="unreadCounter" :value="unreadCounter && show" color="#f44336" overlap>
    <slot />
  </v-badge>
</template>
<script>
import { ROLES } from '@/core/dict/role-dict';

export default {
  name: 'EmployeeRequestUnreadCounter',
  props: {
    show: {
      default: true,
    },
  },
  computed: {
    unreadCounter() {
      return this.$store.getters['employeeRequest/unreadEmployeeRequestCounter'];
    },
  },
  async mounted() {
    if (!this.$store.getters.hasRole(ROLES.ROLE_EMPLOYEE_REQUEST)) {
      return;
    }

    await this.$store.dispatch('employeeRequest/fetchEmployeeRequests');
    await this.$store.dispatch('employeeRequest/watchEmployeeRequests');
  },
};
</script>
