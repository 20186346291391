import Vue from 'vue';
import router from '@/core/config/router/index';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';
import { COURSES_PER_PAGE } from '@/core/dict/taxi-course-dict';
import { API_COURSE_RIDE_DICT } from '@/core/dict/course-dict';
import SegmentService from '@/service/SegmentService';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { uniqueIds } from '@/core/shared/uniqueFilter';
import { TIME_AND_DISTANCE_SOURCE_DICT } from '@/core/dict/Course/time-and-distance-source-dict';

function prepareFilterParams(filters) {
  const params = new URLSearchParams();
  const createSearchTermWithWildCards = (search) => `%${search}%`;

  Object.entries(filters).forEach((filter) => {
    if (filter[1]) {
      switch (filter[0]) {
        case 'dateStartAfter':
          params.append('firstPlaceDateStart[after]', filter[1]);
          break;
        case 'dateStartBefore':
          params.append('firstPlaceDateStart[before]', `${filter[1]}T23:59:59`);
          break;
        case 'locomotive':
          params.append('rides.segments.locomotives.id', filter[1].id);
          break;
        case 'rideStatus':
          params.append('rides.status[eq]', filter[1]);
          break;
        case 'search':
          params.append('search', createSearchTermWithWildCards(filter[1]));
          break;
        case 'includeCancelledCourses':
          break;
        case 'createdBy':
          params.append('createdBy.id', filter[1].id);
          break;
        default:
          params.append(filter[0], filter[1].id || filter[1]);
          break;
      }
    }
  });

  if (filters.includeCancelledCourses === false) {
    params.append('rides.status[neq]', 'cancelled');
  }

  return params;
}

function transformSegmentPayload(segment, index, ride) {
  // TODO: investigate dayjs behavior with isValid
  const isTimeValid = (date) => !!date.split('T')[1];

  return {
    ...segment,
    position: index,
    locomotives: segment.locomotives?.map((locomotive) => locomotive['@id']) ?? [],
    fallbackTime: segment.fallbackTime
      ? +`${segment.fallbackTime}:00`.split(':').reduce((acc, time) => 60 * acc + +time)
      : null,
    waypointStart: {
      place: segment.waypointStart.place?.['@id'],
      time: isTimeValid(ride.segments[index].waypointStart.time) ? ride.segments[index].waypointStart.time : '',
    },
    waypointEnd: {
      place: ride.segments[index + 1]
        ? ride.segments[index + 1].waypointStart.place?.['@id']
        : ride.segments[0].waypointStart.place?.['@id'],
      time: ride.segments[index + 1]
        ? ride.segments[index + 1].waypointStart.time
        : ride.segments[index].waypointEnd.time,
    },
    employees: segment.employees ? segment.employees.map((employee) => employee['@id']) : [],
  };
}

export default {
  fetchCoursesByStatus: (status, filters) =>
    Vue.http.get(
      `api/abstract_courses?status=${status}&itemsPerPage=${COURSES_PER_PAGE}&${prepareFilterParams(
        filters
      ).toString()}`
    ),

  fetchAllCourses: (filters) =>
    Vue.http.get(`api/abstract_courses?itemsPerPage=${COURSES_PER_PAGE}&${prepareFilterParams(filters).toString()}`),

  fetchCoursesByStatusOnPage: ({ status, filters, page }) =>
    Vue.http.get(
      `api/abstract_courses?status=${status}&itemsPerPage=${COURSES_PER_PAGE}&page=${page}&${prepareFilterParams(
        filters
      ).toString()}`
    ),

  fetchAllCoursesOnPage: ({ filters, page }) =>
    Vue.http.get(
      `api/abstract_courses?itemsPerPage=${COURSES_PER_PAGE}&page=${page}&${prepareFilterParams(filters).toString()}`
    ),

  getRideNumberDateQuery(firstPlaceDate) {
    const currentMonth = dayjs().month();
    const firstPlaceDateMonth = dayjs(firstPlaceDate).month();
    const FIRST_DAY_OF_MONTH = 1;

    const isPrevMonthCourse = () => firstPlaceDateMonth < currentMonth;

    const dateObjAfter = dayjs(firstPlaceDate).set('date', FIRST_DAY_OF_MONTH).format('YYYY-MM-DD');

    let query = `firstPlaceDateStart[after]=${dateObjAfter}`;

    if (isPrevMonthCourse()) {
      const daysInMonth = dayjs(firstPlaceDate).daysInMonth();
      const dateObjBefore = dayjs(firstPlaceDate).set('date', daysInMonth).format('YYYY-MM-DD');
      query = `firstPlaceDateStart[before]=${dateObjBefore}`;
    }

    return query;
  },

  generateRideNumber({ course, companyId }) {
    const NEW_MONTH_RIDE_NUMBER = 1;
    const RIDE_NUMBER_INCREMENTOR = 1;

    if (course === undefined) {
      return NEW_MONTH_RIDE_NUMBER;
    }

    const { number: courseRideNumber } = course.rides
      .filter((ride) => ride.company.id === companyId)
      .sort((a, b) => +a.number - +b.number)
      .at(-1);

    let rideNumber;

    if (isNaN(courseRideNumber)) {
      rideNumber = null;
    } else {
      rideNumber = Number(courseRideNumber) + RIDE_NUMBER_INCREMENTOR;
    }

    return rideNumber;
  },

  fetchRideNumber({ companyId, firstPlaceDate }) {
    return Vue.http
      .get(
        `api/abstract_courses?discriminator=taxiCourse&customerCompany=${companyId}&${this.getRideNumberDateQuery(
          firstPlaceDate
        )}&itemsPerPage=1`
      )
      .then(({ data }) => this.generateRideNumber({ course: data['hydra:member'][0], companyId }));
  },

  settleFinishedCourses: (filters) =>
    Vue.http
      .get(`api/abstract_course/settle?pagination=false&${prepareFilterParams(filters).toString()}`)
      .then(({ data, status }) => {
        if (data['hydra:totalItems'] === 0) {
          Vue.notify(
            defaultApiResultManager.catch(data, status, 'Nie ma żadnych kursów, które mogą być zaakceptowane')
          );
        } else {
          Vue.notify(defaultApiResultManager.then(`Zaakceptowano ${data['hydra:totalItems']} kursów`));
        }
      })
      .catch(() => {
        Vue.notify(defaultApiResultManager.catch());
      }),

  postRideReport: (payload) =>
    Vue.http
      .post(`api/taxi_course_ride_ratings`, payload)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(() => {
        Vue.notify(defaultApiResultManager.catch());
      }),

  downloadReport: (filters) => {
    const reportFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    return Vue.http
      .get(`api/abstract_courses?pagination=false&${prepareFilterParams(filters).toString()}`, {
        headers: {
          accept: reportFormat,
        },
        responseType: 'arraybuffer',
      })
      .then((result) => {
        download(result.data, `report-${dayjs().format('DD-MM-YYYY-HH:MM:ss')}.xlsx`, reportFormat);
      });
  },

  fetchCourseByToken: (driverAccessToken) =>
    Vue.http.get(`api/course-info/${driverAccessToken}`).then(({ data }) => data),

  replyToRide(driverAccessToken, rideId, reply) {
    return Vue.http
      .get(`api/course-info/reply/${driverAccessToken}/${rideId}/${reply}`)
      .then(({ data }) => data)
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  segmentAchieved(driverAccessToken, waypointId) {
    return Vue.http
      .get(`api/course-info/segment-achieved/${driverAccessToken}/${waypointId}`)
      .then(({ data }) => data)
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  startRide(driverAccessToken) {
    return Vue.http
      .get(`api/course-info/start/${driverAccessToken}`)
      .then(({ data }) => data)
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  endRide(driverAccessToken, date) {
    return Vue.http
      .get(`api/course-info/end/${driverAccessToken}/${date}`)
      .then(({ data }) => data)
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  sendCourseReport({ driverAccessToken, ride }) {
    return Vue.http
      .put(`api/course-info/course-report/${driverAccessToken}`, ride.payload)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  async createTaxiCourse(taxiCourse) {
    // TODO: refactor this shit!!!!
    const isRideCompanySelected = (course) => course.rides[0].company !== null;
    const isSegmentTimeValid = (course) =>
      course.rides[0].segments.find((segment) => {
        const isTimeValid = (date) => !!date?.split('T')[1];
        return isTimeValid(segment.waypointStart.time) || isTimeValid(segment.waypointEnd.time);
      }) !== undefined;

    const isStartingBaseSelected = () => !!taxiCourse.rides[0].fromStartingBase.waypointStart.place;
    const isSingleWaypointCourse = () => taxiCourse.rides[0].segments.length === 1;

    if (!isRideCompanySelected(taxiCourse)) {
      Vue.notify({
        group: 'global',
        type: 'error',
        title: 'Błąd',
        text: 'Należy wybrać obsługawaną firmę',
      });

      return Promise.reject();
    }

    if (isRideCompanySelected(taxiCourse) && !isSegmentTimeValid(taxiCourse)) {
      Vue.notify({
        group: 'global',
        type: 'error',
        title: 'Błąd',
        text: 'Należy uzupełnić czas na wszystkich przystankach',
      });

      return Promise.reject();
    }

    if (isSingleWaypointCourse() && !isStartingBaseSelected()) {
      Vue.notify({
        group: 'global',
        type: 'error',
        title: 'Błąd',
        text: 'Dla kursu z jednym przystankiem należy wybrać bazę statową',
      });

      return Promise.reject();
    }

    if (taxiCourse.rides[0].number === null) {
      const generatedRideNumber = await this.fetchRideNumber({
        companyId: taxiCourse.rides[0].company.id,
        firstPlaceDate: taxiCourse.rides[0].segments[0].waypointStart.time,
      });
      // eslint-disable-next-line no-param-reassign
      taxiCourse.rides[0].number = generatedRideNumber;
    }

    return this.preparePayload(taxiCourse).then((payload) =>
      Vue.http
        .post(`api/taxi_courses`, payload)
        .then(() => {
          Vue.notify(defaultApiResultManager.then());

          router.push({ name: TAXI_COURSE_LIST_ROUTE });
        })
        .catch(({ data, status }) => {
          Vue.notify(defaultApiResultManager.catch(data, status));

          throw data;
        })
    );
  },

  updateValue(payload) {
    return Vue.http
      .put(payload['@id'].substring(1), { [payload.model]: payload.value })
      .then(() => {
        Vue.notify(defaultApiResultManager.then());
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  updateValues(payload) {
    return Vue.http
      .put(payload['@id'].substring(1), payload.payload)
      .then((response) => {
        Vue.notify(defaultApiResultManager.then());
        return response;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  processStartingBasePayload(data) {
    return !data.time
      ? { distance: 0, time: 0, timeAndDistanceSource: TIME_AND_DISTANCE_SOURCE_DICT.FALLBACK }
      : {
          distance: data.distance,
          time: data.time,
          timeAndDistanceSource: data.timeAndDistanceSource,
        };
  },

  async fromStartingPlacePayload(course) {
    const ride = course.rides[0];

    if (!course.driver) {
      return null;
    }

    const val = {
      waypointStart: {
        place: course.driver.startingBase,
      },
      waypointEnd: null,
    };

    return {
      ...(await this.calculateRoute({
        segments: [val, ride.segments[0]],
        companyId: ride.company?.id,
      }).then((data) => ({
        ...this.processStartingBasePayload(data[0]),
        waypointEnd: { time: data[1].waypointStart.time, place: data[1].waypointStart.place?.['@id'] },
        waypointStart: {
          time: this.subtractTime(data[1], data[0]),
          place: data[0].waypointStart.place['@id'],
        },
      }))),
      locomotives: uniqueIds(course.rides[0].segments, 'locomotives'),
      employees: uniqueIds(course.rides[0].segments, 'employees'),
      position: 0,
    };
  },

  async fromStartingBasePayload(course) {
    const ride = course.rides[0];

    if (!ride.fromStartingBase?.waypointStart?.place) {
      return null;
    }

    return {
      ...(await this.calculateRoute({
        segments: [ride.fromStartingBase, ride.segments[0]],
        companyId: ride.company?.id,
      }).then((data) => ({
        ...this.processStartingBasePayload(data[0]),
        waypointEnd: { time: data[1].waypointStart.time, place: data[1].waypointStart.place?.['@id'] },
        waypointStart: {
          time: this.subtractTime(data[1], data[0]),
          place: data[0].waypointStart.place['@id'],
        },
      }))),
      locomotives: uniqueIds(course.rides[0].segments, 'locomotives'),
      employees: uniqueIds(course.rides[0].segments, 'employees'),
      position: 0,
    };
  },

  async toStartingBasePayload(course) {
    const ride = course.rides[0];

    if (!ride.fromStartingBase?.waypointStart?.place) {
      return null;
    }

    return {
      ...(await this.calculateRoute({
        segments: [ride.segments[ride.segments.length - 1], ride.fromStartingBase],
        companyId: ride.company?.id,
      }).then((data) => ({
        ...this.processStartingBasePayload(data[0]),
        waypointStart: {
          place: data[0].waypointStart.place?.['@id'],
          time: data[0].waypointStart.time,
        },
        waypointEnd: {
          time: this.addTime(data[0]),
          place: data[1].waypointStart.place['@id'],
        },
      }))),
      locomotives: uniqueIds(course.rides[0].segments, 'locomotives'),
      employees: uniqueIds(course.rides[0].segments, 'employees'),
      position: 0,
    };
  },

  async toStartingPlacePayload(course) {
    const ride = course.rides[0];

    if (!course.driver) {
      return null;
    }

    const val = {
      waypointStart: {
        place: course.driver.startingBase,
      },
      waypointEnd: null,
    };

    return {
      ...(await this.calculateRoute({
        segments: [ride.segments[ride.segments.length - 1], val],
        companyId: ride.company?.id,
      }).then((data) => ({
        ...this.processStartingBasePayload(data[0]),
        waypointStart: {
          place: data[0].waypointStart.place?.['@id'],
          time: data[0].waypointStart.time,
        },
        waypointEnd: {
          time: this.addTime(data[0]),
          place: data[1].waypointStart.place['@id'],
        },
      }))),
      locomotives: uniqueIds(course.rides[0].segments, 'locomotives'),
      employees: uniqueIds(course.rides[0].segments, 'employees'),
      position: 0,
    };
  },

  async preparePayload(taxiCourse) {
    const fromStartingBase = await this.fromStartingBasePayload(taxiCourse);
    const toStartingBase = await this.toStartingBasePayload(taxiCourse);

    const fromStartingPlace = await this.fromStartingPlacePayload(taxiCourse);
    const toStartingPlace = await this.toStartingPlacePayload(taxiCourse);

    return {
      ...taxiCourse,
      contractor: taxiCourse.contractor?.['@id'],
      subcontractor: taxiCourse.subcontractor?.['@id'],
      driver: taxiCourse.driver?.['@id'],
      fromStartingPlace,
      toStartingPlace,
      rides: await Promise.all(
        taxiCourse.rides.map(async (ride, rideIndex) => ({
          ...ride,
          type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
          driverHasToAcceptAt: dayjs(ride.driverHasToAcceptRelative).format('YYYY-MM-DD HH:mm'),
          company: ride.company['@id'] || null,
          position: rideIndex,
          number: ride.number?.toString(),
          fromStartingBase,
          toStartingBase,
          segments: ride.segments
            .map((segment, index) => transformSegmentPayload(segment, index, ride))
            .filter((segment, index) => index < ride.segments.length - 1),
        }))
      ),
    };
  },

  async calculateRoute({ segments, companyId }) {
    const segmentsTransformed = segments;

    for (let i = 0; i < segments.length - 1; i += 1) {
      // TODO: generate another approach to pass companyId
      // eslint-disable-next-line no-await-in-loop
      segmentsTransformed[i] = await this.calculateRouteForSegment({ segmentsTransformed, index: i, companyId });
    }

    return segmentsTransformed;
  },

  async calculateKilometerRoute({ segments, companyId }) {
    const segmentsTransformed = segments;

    for (let i = 0; i < segments.length - 1; i += 1) {
      // TODO: generate another approach to pass companyId
      // eslint-disable-next-line no-await-in-loop
      segmentsTransformed[i] = await this.calculateRouteForKilometerSegment({
        segmentsTransformed,
        index: i,
        companyId,
      });
    }

    return segmentsTransformed;
  },

  calculateRouteTime(segments, preferredPosition = null) {
    const isTimeSet = (segment) => segment.waypointStart.time?.split('T')[1].length;
    const validSegmentIndex = segments.findIndex(
      ([waypointA, waypointB]) =>
        this.findTheOnlyOneValidSegment(waypointA) || this.findTheOnlyOneValidSegment(waypointB)
    );
    const findPreferredIndex =
      segments.findIndex(
        ([waypointA, waypointB]) =>
          (waypointA.position === preferredPosition && isTimeSet(waypointA)) ||
          (waypointB.position === preferredPosition && isTimeSet(waypointB))
      ) === -1
        ? null
        : segments.findIndex(
            ([waypointA, waypointB]) =>
              (waypointA.position === preferredPosition && isTimeSet(waypointA)) ||
              (waypointB.position === preferredPosition && isTimeSet(waypointB))
          );

    const indexToStartFrom = findPreferredIndex || validSegmentIndex;

    if (indexToStartFrom === -1) {
      return [];
    }

    const segmentsTransformed = new Map();
    for (let i = indexToStartFrom; i >= 0; i -= 1) {
      const [startWaypoint, endWaypoint] = segments[i];

      if (startWaypoint.position === preferredPosition && findPreferredIndex !== null) {
        segmentsTransformed.set(endWaypoint.position, {
          ...endWaypoint,
          waypointStart: {
            ...endWaypoint.waypointStart,
            time: this.addTime(startWaypoint),
          },
        });
      } else if (endWaypoint.position === preferredPosition && findPreferredIndex !== null) {
        segmentsTransformed.set(startWaypoint.position, {
          ...startWaypoint,
          waypointStart: {
            ...startWaypoint.waypointStart,
            time: this.subtractTime(endWaypoint, startWaypoint),
          },
        });
      } else if (segmentsTransformed.has(endWaypoint.position)) {
        segmentsTransformed.set(startWaypoint.position, {
          ...startWaypoint,
          waypointStart: {
            ...startWaypoint.waypointStart,
            time: this.subtractTime(segmentsTransformed.get(endWaypoint.position), startWaypoint),
          },
        });
      } else if (isTimeSet(endWaypoint)) {
        segmentsTransformed.set(startWaypoint.position, {
          ...startWaypoint,
          waypointStart: {
            ...startWaypoint.waypointStart,
            time: this.subtractTime(endWaypoint, startWaypoint),
          },
        });
      } else if (isTimeSet(startWaypoint)) {
        segmentsTransformed.set(endWaypoint.position, {
          ...endWaypoint,
          waypointStart: {
            ...endWaypoint.waypointStart,
            time: this.addTime(startWaypoint),
          },
        });
      }
    }

    for (let i = indexToStartFrom; i < segments.length; i += 1) {
      const [startWaypoint, endWaypoint] = segments[i];

      if (startWaypoint.position === preferredPosition && findPreferredIndex !== null) {
        segmentsTransformed.set(endWaypoint.position, {
          ...endWaypoint,
          waypointStart: {
            ...endWaypoint.waypointStart,
            time: this.addTime(startWaypoint),
          },
        });
      } else if (endWaypoint.position === preferredPosition && findPreferredIndex !== null) {
        segmentsTransformed.set(startWaypoint.position, {
          ...startWaypoint,
          waypointStart: {
            ...startWaypoint.waypointStart,
            time: this.subtractTime(endWaypoint, startWaypoint),
          },
        });
      } else if (segmentsTransformed.has(startWaypoint.position)) {
        segmentsTransformed.set(endWaypoint.position, {
          ...endWaypoint,
          waypointStart: {
            ...endWaypoint.waypointStart,
            time: this.addTime(segmentsTransformed.get(startWaypoint.position)),
          },
        });
      } else if (isTimeSet(endWaypoint)) {
        segmentsTransformed.set(startWaypoint.position, {
          ...startWaypoint,
          waypointStart: {
            ...startWaypoint.waypointStart,
            time: this.subtractTime(endWaypoint, startWaypoint),
          },
        });
      } else if (isTimeSet(startWaypoint)) {
        segmentsTransformed.set(endWaypoint.position, {
          ...endWaypoint,
          waypointStart: {
            ...endWaypoint.waypointStart,
            time: this.addTime(startWaypoint),
          },
        });
      }
    }

    return segmentsTransformed;
  },

  calculateRouteForSegment({ segmentsTransformed, index, companyId }) {
    const requestUrlParams = new URLSearchParams();
    const nextElementExists = segmentsTransformed.length > index + 1;
    requestUrlParams.set('placeStart', segmentsTransformed[index].waypointStart.place.id);
    requestUrlParams.set('placeEnd', segmentsTransformed[nextElementExists ? index + 1 : 0].waypointStart.place.id);

    return SegmentService.sendDefinedKilometerRequest({
      requestUrlParams,
      segmentModel: segmentsTransformed[index],
      companyId,
    })
      .then((segmentCalculated) => ({
        ...segmentCalculated,
      }))
      .catch(() => SegmentService.sendEstimatedKilometerRequest(requestUrlParams, segmentsTransformed[index]));
  },

  calculateRouteForKilometerSegment({ segmentsTransformed, index }) {
    const requestUrlParams = new URLSearchParams();
    const nextElementExists = segmentsTransformed.length > index + 1;
    requestUrlParams.set('placeStart', segmentsTransformed[index].waypointStart.place.id);
    requestUrlParams.set('placeEnd', segmentsTransformed[nextElementExists ? index + 1 : 0].waypointStart.place.id);

    return SegmentService.sendEstimatedKilometerRequest(requestUrlParams, segmentsTransformed[index]);
  },

  addTime(segment) {
    return dayjs(segment.waypointStart.time).add(segment.time, 'seconds').format();
  },

  subtractTime(subtractFrom, amountToSubtract) {
    return dayjs(subtractFrom.waypointStart.time).subtract(amountToSubtract.time, 'seconds').format();
  },

  findTheOnlyOneValidSegment: (segment) =>
    !!segment.waypointStart.time &&
    segment.waypointStart.time !== 'T' &&
    segment.waypointStart.time.split('T')[0].length &&
    segment.waypointStart.time.split('T')[1].length,

  addPrincipal({ companyContractor, company }) {
    return Vue.http
      .put(`api/companies/${company.id}`, {
        principals: [companyContractor],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Dodano obsługiwaną firmę'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },

  addContractor({ contractor, company }) {
    return Vue.http
      .put(`api/companies/${company.id}`, {
        contractors: [contractor],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Dodano podwykonawcę'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },

  addContractorStake({ contractorStake, contractorId, company, contractorName }) {
    return Vue.http
      .put(`api/companies/${company.id}`, {
        contractors: [{ id: contractorId, stakes: [contractorStake] }],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then(`Dodano nowe stawki dla firmy ${contractorName}`));
        return data;
      })
      .catch(({ data, status, message }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, message));
        throw data;
      });
  },

  updateContractor({ payload, contractorId, company }) {
    return Vue.http
      .put(`api/companies/${company.id}`, {
        contractors: [{ id: contractorId, ...payload }],
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw data;
      });
  },
};
