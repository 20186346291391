export default {
  getAccountType(user) {
    let type = 'Nieodnaleziony typ konta';
    if (user.roles.includes('ROLE_COMPANY')) {
      type = 'Firma';
    } else if (user.roles.includes('ROLE_TRANSPORT_COMPANY')) {
      type = 'Firma transportowa';
    } else if (user.roles.includes('ROLE_ADMIN')) {
      type = 'Administrator';
    } else if (user.roles.includes('ROLE_DRIVER')) {
      type = 'Kierowca';
    } else if (user.roles.includes('ROLE_USER_COMPANY')) {
      type = 'Dyspozytor zewnętrzny';
    } else if (user.roles.includes('ROLE_USER')) {
      type = 'Dyspozytor';
    } else if (user.roles.includes('ROLE_PASSENGER')) {
      type = 'Pracownik';
    }

    return type;
  },

  methods: {
    placeSearchingFilter(item, queryText) {
      const fullValue = item.name + item.address;

      return fullValue.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
    },
  },
};
