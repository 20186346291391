import BaseEditStartingBaseTemplate from './BaseEditStartingBaseTemplate';

export default class StartingPlaceEdit extends BaseEditStartingBaseTemplate {
  // eslint-disable-next-line class-methods-use-this
  get entityType() {
    return 'taxiCourse';
  }

  // eslint-disable-next-line class-methods-use-this
  get startField() {
    return 'fromStartingPlace';
  }

  // eslint-disable-next-line class-methods-use-this
  get endField() {
    return 'toStartingPlace';
  }
}
