<template>
  <component :is="currentComponent" :course="course" v-on="$listeners"></component>
</template>

<script>
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import KilometerCourseCard from './kilometer-course-card';
import TaxiCourseCard from './taxi-course-card';

export default {
  name: 'CourseCardFactory',
  inheritAttrs: false,
  props: {
    course: Object,
  },
  computed: {
    currentComponent() {
      if (this.isTaxiCourse) {
        return TaxiCourseCard;
      }

      if (this.isKilometerCourse) {
        return KilometerCourseCard;
      }

      throw new Error(`${this.course['@type']} is unrecognized course type`);
    },
    isTaxiCourse() {
      return this.course['@type'] === COURSE_TYPE_DICT.TAXI_COURSE;
    },
    isKilometerCourse() {
      return this.course['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE;
    },
  },
};
</script>
