import { KILOMETER_COURSE_DICT } from '@/core/dict/kilometer-course-dict';

export const TAXI_COURSE_RIDE_STATUS = {
  NEW: 'new',
  WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE: 'waiting_for_transport_company_acceptance',
  NOT_ACCEPTED_BY_TRANSPORT_COMPANY: 'not_accepted_by_transport_company',
  ACCEPTED_BY_TRANSPORT_COMPANY: 'accepted_by_transport_company',
  WAITING_FOR_DRIVER_ACCEPTANCE: 'waiting_for_driver_acceptance',
  NOT_ACCEPTED_BY_DRIVER: 'not_accepted_by_driver',
  ACCEPTED_BY_DRIVER: 'accepted_by_driver',
  DECLINED_BY_DRIVER: 'declined_by_driver',
  DECLINED_BY_TRANSPORT_COMPANY: 'declined_by_transport_company',
  FINISHED: 'finished',
  ACCEPTED_BY_CUSTOMER: 'accepted_by_customer',
  ACCEPTED_BY_CONTRACTOR: 'accepted_by_contractor',
  CANCELLED: 'cancelled',
  CANCELLED_DURING_REALIZATION: 'cancelled_during_realization',
  PLANNING: 'planning',
  INCONSISTENCY: 'tcr_inconsistency',
};

export const TAXI_COURSE_RIDE_DICT = {
  [TAXI_COURSE_RIDE_STATUS.NEW]: 'Nowy, czeka na dyspozytora',
  [TAXI_COURSE_RIDE_STATUS.PLANNING]: 'Planowanie',
  [TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE]: 'Czeka na potwierdzenie firmy transportowej',
  [TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_TRANSPORT_COMPANY]: 'Niepotwierdzone przez firmę transportową',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY]: 'Potwierdzone przez firmę transportową',
  [TAXI_COURSE_RIDE_STATUS.DECLINED_BY_TRANSPORT_COMPANY]: 'Odrzucone przez firmę transportową',
  [TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE]: 'Czeka na potwierdzenie kierowcy',
  [TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_DRIVER]: 'Niepotwierdzone przez kierowcę',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER]: 'Potwierdzone przez kierowcę',
  [TAXI_COURSE_RIDE_STATUS.DECLINED_BY_DRIVER]: 'Odrzucone przez kierowcę',
  [TAXI_COURSE_RIDE_STATUS.FINISHED]: 'Zakończone',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR]: 'Zaakceptowano przez firmę transportową',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CUSTOMER]: 'Zaakceptowano przez firmę kolejową',
  [TAXI_COURSE_RIDE_STATUS.CANCELLED]: 'Anulowano',
  [TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION]: 'Anulowano w trakcie realizacji',
  [TAXI_COURSE_RIDE_STATUS.INCONSISTENCY]: 'Niezgodność',
};

export const TAXI_COURSE_EDITION_RIDE_DICT = Object.entries(TAXI_COURSE_RIDE_DICT).filter(([key]) =>
  [
    TAXI_COURSE_RIDE_STATUS.PLANNING,
    TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE,
    TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE,
    TAXI_COURSE_RIDE_STATUS.FINISHED,
  ].includes(key)
);

export const TAXI_COURSE_STATUS_COLOR_DICT = {
  [TAXI_COURSE_RIDE_STATUS.NEW]: '#B20000',
  [TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE]: '#005DB2',
  [TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE]: '#005DB2',
  [TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_DRIVER]: '#B20000',
  [TAXI_COURSE_RIDE_STATUS.NOT_ACCEPTED_BY_TRANSPORT_COMPANY]: '#B20000',
  [TAXI_COURSE_RIDE_STATUS.DECLINED_BY_DRIVER]: '#B20000',
  [TAXI_COURSE_RIDE_STATUS.DECLINED_BY_TRANSPORT_COMPANY]: '#B20000',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER]: '#005DB2',
  [TAXI_COURSE_RIDE_STATUS.FINISHED]: '#00A7B2',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CUSTOMER]: '#24B300',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR]: '#24B300',
  [TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY]: '#005DB2',
  [TAXI_COURSE_RIDE_STATUS.CANCELLED]: '#9d9d9d',
  [TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION]: '#00A7B2',
  [TAXI_COURSE_RIDE_STATUS.PLANNING]: '#B29600',
  [TAXI_COURSE_RIDE_STATUS.INCONSISTENCY]: '#B20000',
};

export const COURSE_STATUS = {
  REQUIRES_ATTENTION: 'requires_attention',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
};

export const COURSE_STATUS_VERBAL = {
  [COURSE_STATUS.REQUIRES_ATTENTION]: 'Wymaga uwagi',
  [COURSE_STATUS.IN_PROGRESS]: 'W trakcie realizacji',
  [COURSE_STATUS.FINISHED]: 'Zakończone',
};

export const RIDE_MESSAGE_TYPE = {
  DRIVER_SMS: 'driver-new-ride-sms',
  COMPANY_EMAIL: 'company-user-new-ride-email',
  PASSENGER_PUSH: 'passenger-new-ride-push',
};

export const COURSE_TYPE = {
  TAXI: 'taxiCourse',
  KILOMETER: 'kilometerCourse',
};

export const COURSE_TYPE_DICT = {
  [COURSE_TYPE.TAXI]: 'Taksówka',
  [COURSE_TYPE.KILOMETER]: 'Kilometrówka',
};

export const RIDE_STATUSES = {
  ...TAXI_COURSE_RIDE_DICT,
  ...KILOMETER_COURSE_DICT,
};

export const COURSES_PER_PAGE = window.innerWidth < 900 ? 3 : 15;

export const RIDE_CARD_SETTINGS = {
  RELATION: 'relation',
  INTERMEDIATE_WAYPOINTS: 'intermediate_waypoints',
  PASSENGERS: 'passengers',
  DISTANCE_AND_TIME: 'distance_and_time',
  LOCOMOTIVES: 'locomotives',
  FULL_WAYPOINT_ADDRESS: 'full_waypoint_address',
};

export const RIDE_RATING_MARK = {
  BAD: 1,
  AVERAGE: 3,
  GOOD: 5,
};

export const RIDE_RATING_EXTREME_WAITING = {
  OVER_QUARTER: 'over_quarter',
  OVER_HOUR: 'over_hour',
  DRIVER_NOT_ARRIVED: 'driver_not_arrived',
};
