import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  methods: {
    generateWaypointName(index) {
      const LETTER_A_UNICODE = 65;
      return String.fromCharCode(LETTER_A_UNICODE + index);
    },
    showAddWaypointNotification(index) {
      this.$notify(
        defaultApiResultManager.then(`Przystanek został dodany przed przystankiem ${this.generateWaypointName(index)}`)
      );
    },
    showAddWaypointAfterNotification(index) {
      this.$notify(
        defaultApiResultManager.then(`Przystanek został dodany po przystanku ${this.generateWaypointName(index)}`)
      );
    },
  },
};
