<template>
  <v-text-field
    :label="`${label}${required ? '*' : ''}`"
    :value="value"
    @input="emitChange"
    prepend-inner-icon="today"
    type="datetime-local"
    outlined
    hide-details
    :disabled="disabled"
    @click:prepend-inner="triggerPicker($event)"
    clearable
  />
</template>
<script>
export default {
  name: 'DateTimeField',
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
  },
  methods: {
    emitChange(value) {
      this.$emit('input', value);
    },
    triggerPicker(event) {
      event.target.closest('.v-input').querySelector('input').showPicker();
    },
  },
};
</script>
<style>
input[type='date']::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
