<template>
  <div
    class="course-list__container"
    :class="{ 'course-list__container--empty': !courses.length }"
    style="column-gap: 16px"
  >
    <div
      v-if="isLoading"
      class="d-flex align-center justify-center fill-height text-body-2 course-list__column"
      style="color: rgba(0, 0, 0, 0.38)"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <template v-else-if="courses.length">
      <template v-for="course in courses">
        <course-card-factory
          :key="course.id"
          :course="course"
          @courseClick="courseClick(course)"
          @rideClick="rideClick"
        />
      </template>
      <v-progress-circular
        v-if="isMoreCoursesAvailable"
        class="course-list__loader"
        color="primary"
        v-intersect="loadNextPage"
        indeterminate
      ></v-progress-circular>
    </template>
    <div
      v-else
      class="d-flex align-center justify-center fill-height text-body-2 course-list__column"
      style="color: rgba(0, 0, 0, 0.38)"
    >
      Brak kursów
    </div>
  </div>
</template>
<script>
import CourseCardFactory from '@/component/NewCourse/CourseCard/course-card-factory';
import { COURSES_PER_PAGE } from '@/core/dict/taxi-course-dict';

export default {
  name: 'CourseList',
  components: {
    CourseCardFactory,
  },
  props: {
    courses: Array,
    isLoading: Boolean,
    totalCount: Number,
  },
  data() {
    return {
      pageLoaded: 1,
      unwatchFilterListener: null,
    };
  },
  mounted() {
    this.registerFilterListener();
  },
  destroyed() {
    this.unwatchFilterListener();
  },
  computed: {
    isMoreCoursesAvailable() {
      return Math.ceil(this.totalCount / COURSES_PER_PAGE) > this.pageLoaded;
    },
    isPassenger() {
      return this.$store.getters.isPassenger;
    },
  },
  methods: {
    courseClick(course) {
      this.$emit('courseClick', course);
    },
    rideClick(ride) {
      this.$emit('rideClick', ride);
    },
    async loadNextPage(entries) {
      if (entries[0].isIntersecting && this.isMoreCoursesAvailable) {
        const nextPage = this.pageLoaded + 1;
        if (this.isPassenger) {
          await this.$store.dispatch('taxiCourse/fetchAllCoursesOnPage', nextPage);
        } else {
          await this.$store.dispatch('taxiCourse/fetchCoursesByStatusOnPage', {
            page: nextPage,
            status: this.courses[0].status,
          });
        }
        this.pageLoaded = nextPage;
      }
    },
    resetLoadedPage() {
      this.pageLoaded = 1;
    },
    registerFilterListener() {
      this.unwatchFilterListener = this.$store.watch(
        (_, getters) => getters['taxiCourse/formattedFilters'],
        () => {
          this.resetLoadedPage();
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.course-list {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    height: inherit;
    grid-auto-rows: min-content;

    &--empty {
      flex: 1;
      grid-auto-rows: initial;
      grid-template-columns: initial;
    }
  }

  &__column {
    height: inherit;
  }

  &__loader {
    height: 150px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
