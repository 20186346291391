<template>
  <div class="d-flex flex-column" style="row-gap: 10px">
    <taxi-course-ride-card
      class="flex-grow-1"
      v-for="(ride, index) in rides"
      :key="index"
      :course="course"
      :ride="ride"
      @rideClick="rideClick"
    />
  </div>
</template>

<script>
import TaxiCourseRideCard from '@/component/NewCourse/ride-card';

export default {
  name: 'RideList',
  components: { TaxiCourseRideCard },
  props: {
    rides: Array,
    course: Object,
  },
  methods: {
    rideClick(ride) {
      this.$emit('rideClick', ride);
    },
  },
};
</script>
