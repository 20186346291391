import { uniqueIds } from '@/core/shared/uniqueFilter';
import { store } from '@/core/store';
import TaxiCourseService from '@/service/TaxiCourseService';
import format from 'date-fns/format';
import subSeconds from 'date-fns/sub_seconds';
import addSeconds from 'date-fns/add_seconds';

export default class BaseEditStartingBaseTemplate {
  // eslint-disable-next-line class-methods-use-this
  get entityType() {
    throw new Error('Function need to be implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  get startField() {
    throw new Error('Function need to be implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  get endField() {
    throw new Error('Function need to be implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  get waypointsForEdit() {
    return store.getters['courseCreation/course'].rides[0].segments;
  }

  isStartingBaseRelationChanged(ride) {
    return (
      ride.fromStartingBase?.waypointEnd.place.id !== this.waypointsForEdit[0].waypointStart.place.id ||
      ride.toStartingBase?.waypointStart.place.id !== this.waypointsForEdit.at(-1).waypointStart.place.id
    );
  }

  async payload({ ride, selectedStartingBase, selectedStartingBaseChanged }) {
    if (ride.segments.length === 0 && !this.isStartingBaseRelationChanged(ride)) {
      return {
        id: ride['@id'],
        type: this.entityType,
        [this.startField]: {
          position: 0,
          distance: ride[this.startField].distance,
          time: ride[this.startField].time,
          timeAndDistanceSource: ride[this.startField].timeAndDistanceSource,
          locomotives: uniqueIds(this.waypointsForEdit, 'locomotives'),
          employees: uniqueIds(this.waypointsForEdit, 'employees'),
          waypointStart: {
            id: ride[this.startField].waypointStart['@id'],
            place: ride[this.startField].waypointStart.place['@id'],
            time: ride[this.startField].waypointStart.time,
          },
          waypointEnd: {
            id: ride[this.startField].waypointEnd['@id'],
            place: ride[this.startField].waypointEnd.place['@id'],
            time: ride[this.startField].waypointEnd.time,
          },
        },
        [this.endField]: {
          position: 0,
          distance: ride[this.endField].distance,
          time: ride[this.endField].time,
          timeAndDistanceSource: ride[this.endField].timeAndDistanceSource,
          locomotives: uniqueIds(this.waypointsForEdit, 'locomotives'),
          employees: uniqueIds(this.waypointsForEdit, 'employees'),
          waypointStart: {
            id: ride[this.endField].waypointStart['@id'],
            place: ride[this.endField].waypointStart.place['@id'],
            time: ride[this.endField].waypointStart.time,
          },
          waypointEnd: {
            id: ride[this.endField].waypointEnd['@id'],
            place: ride[this.endField].waypointEnd.place['@id'],
            time: ride[this.endField].waypointEnd.time,
          },
        },
      };
    }

    if (!selectedStartingBase) {
      return { id: ride['@id'], type: this.entityType, [this.startField]: null, [this.endField]: null };
    }

    if (selectedStartingBaseChanged || this.isStartingBaseRelationChanged(ride)) {
      return {
        id: ride['@id'],
        type: this.entityType,
        [this.startField]: {
          ...(await TaxiCourseService.calculateRoute({
            segments: [
              {
                ...ride[this.startField],
                waypointStart: { ...ride[this.startField]?.waypointStart, place: selectedStartingBase },
              },
              // TODO: first global ref
              this.waypointsForEdit[0],
            ],
            companyId: ride.company?.id,
          }).then((data) => ({
            ...TaxiCourseService.processStartingBasePayload(data[0]),
            unlockDefined: data[0].unlockDefined,
            waypointEnd: { time: data[1].waypointStart.time, place: data[1].waypointStart.place?.['@id'] },
            waypointStart: {
              time: format(subSeconds(data[1].waypointStart.time, data[0].time)),
              place: data[0].waypointStart.place['@id'],
            },
          }))),
          locomotives: uniqueIds(this.waypointsForEdit, 'locomotives'),
          employees: uniqueIds(this.waypointsForEdit, 'employees'),
          position: 0,
        },
        [this.endField]: {
          ...(await TaxiCourseService.calculateRoute({
            segments: [
              this.waypointsForEdit.at(-1),
              {
                ...ride[this.startField],
                waypointStart: { ...ride[this.startField]?.waypointStart, place: selectedStartingBase },
              },
            ],
            companyId: ride.company?.id,
          }).then((data) => ({
            ...TaxiCourseService.processStartingBasePayload(data[0]),
            unlockDefined: data[0].unlockDefined,
            waypointStart: {
              place: data[0].waypointStart.place?.['@id'],
              time: data[0].waypointStart.time,
            },
            waypointEnd: {
              time: format(addSeconds(data[0].waypointStart.time, data[0].time)),
              place: data[1].waypointStart.place['@id'],
            },
          }))),
          locomotives: uniqueIds(this.waypointsForEdit, 'locomotives'),
          employees: uniqueIds(this.waypointsForEdit, 'employees'),
          position: 0,
        },
      };
    }

    return { id: ride['@id'], type: this.entityType };
  }
}
