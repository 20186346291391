<template>
  <v-text-field
    :label="`${label}${required ? '*' : ''}`"
    :value="value"
    @input="emitChange"
    ref="timeInput"
    @blur="emitBlur"
    prepend-inner-icon="mdi-clock-outline"
    type="time"
    outlined
    hide-details="auto"
    @click:prepend-inner="triggerPicker($event)"
    :disabled="disabled"
    :clearable="clearable"
    :rules="rules"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'TimeField',
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: {
      default: () => [],
    },
  },
  data() {
    return {
      timestamp: null,
    };
  },
  methods: {
    emitChange(value) {
      this.$emit('input', value);
    },
    emitBlur(value) {
      this.$emit('blur', value);
    },
    triggerPicker(event) {
      this.$refs.timeInput.$refs.input.focus();
      event.target.closest('.v-input').querySelector('input').showPicker();
    },
  },
};
</script>
<style>
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
