<template>
  <div class="d-inline-block">
    {{ name }}
    <template v-if="phone">
      |
      <a class="text-decoration-none" :href="phoneLink" @click.stop>{{ formattedPhoneNumber }}</a>
    </template>
  </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';

export default {
  name: 'ExecutorLabel',
  props: {
    phone: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  computed: {
    parsedPhoneNumber() {
      return parsePhoneNumber(this.phone, 'PL');
    },
    formattedPhoneNumber() {
      return this.parsedPhoneNumber.formatInternational();
    },
    phoneLink() {
      return this.parsedPhoneNumber.getURI();
    },
  },
};
</script>
