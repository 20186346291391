<template>
  <v-dialog
    :value="isDialogOpen"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    @click:outside="emitClose"
    style="z-index: 205"
    data-testid="locomotive-dialog"
  >
    <v-card>
      <v-toolbar class="elevation-0" height="auto">
        <v-toolbar-title class="font-weight-medium py-6">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" lazy-validation data-testid="locomotive-form">
          <v-text-field
            v-model="model.sideNumber"
            outlined
            label="Numer lokomotywy*"
            icon="mdi-clipboard-text"
            required
            clearable
            :rules="[(v) => !!v || 'Uzupełnij numer lokomotywy']"
          ></v-text-field>
          <slot v-bind:model="model"></slot>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <base-text-button :disabled="isLoading" @click="emitClose">Anuluj</base-text-button>
        <base-text-button :loading="isLoading" @click="saveLocomotive">Zapisz</base-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import { mapGetters } from 'vuex';

export default {
  name: 'LocomotiveField',
  components: {
    BaseTextButton,
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    strategy: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    locomotiveModel: {
      type: Object,
    },
  },
  data() {
    return {
      model: JSON.parse(JSON.stringify(this.locomotiveModel)),
    };
  },
  computed: {
    ...mapGetters('locomotive', ['isLoading']),
  },
  methods: {
    saveLocomotive() {
      if (this.$refs.form.validate()) {
        this.strategy().then(() => {
          this.emitClose();
        });
      }
    },
    emitClose() {
      this.resetValidation();
      this.$emit('close');
    },
    resetValidation() {
      // TODO: error in test, when v-form is wrapped in v-dialog
      this.$refs.form?.resetValidation();
    },
  },
  watch: {
    locomotiveModel(val) {
      this.model = JSON.parse(JSON.stringify(val));
      this.resetValidation();
    },
  },
};
</script>
