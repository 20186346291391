<template>
  <v-text-field
    :label="`${label}${required ? '*' : ''}`"
    :value="value"
    @input="emitChange"
    prepend-inner-icon="today"
    type="date"
    outlined
    hide-details="auto"
    @click:prepend-inner="triggerPicker($event)"
    :clearable="clearable"
    :rules="rules"
  />
</template>
<script>
export default {
  name: 'DateField',
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: {
      default: () => [],
    },
  },
  methods: {
    emitChange(value) {
      this.$emit('input', value);
    },
    triggerPicker(event) {
      event.target.closest('.v-input').querySelector('input').showPicker();
    },
  },
};
</script>
<style>
input[type='date']::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
