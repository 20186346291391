import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import formatStake from '@/core/utils/stakeFormatter';
import dayjs from 'dayjs';

export default {
  fetchEmployeeGroups() {
    return Vue.http.get('api/employee_groups').then(({ data }) => data['hydra:member']);
  },
  createEmployeeGroup({ kilometerRate, waitHourRate, ...payload }) {
    const getFormattedStakes = () =>
      typeof kilometerRate === 'number' && typeof waitHourRate === 'number'
        ? [
            {
              kilometerRate: formatStake(kilometerRate),
              waitHourRate: formatStake(waitHourRate),
              validFrom: dayjs().format('YYYY-MM-DD'),
            },
          ]
        : [];
    return Vue.http
      .post('api/employee_groups', { ...payload, stakes: getFormattedStakes() })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Grupa pracownicza została poprawnie dodana'));
        return data;
      })
      .catch(({ data }) => {
        Vue.notify(defaultApiResultManager.catch());
        throw new Error(data['hydra:description']);
      });
  },
  editEmployeeGroup({ name, id }) {
    return Vue.http
      .put(`api/employee_groups/${id}`, {
        name,
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Grupa pracownicza została poprawnie zapisana'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data['hydra:description']);
      });
  },
  addEmployeeGroupStake({ id, stakes }) {
    return Vue.http
      .put(`api/employee_groups/${id}`, {
        stakes,
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Stawka została poprawnie zapisana'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        throw new Error(data['hydra:description']);
      });
  },
  deleteEmployeeGroup(id) {
    return Vue.http
      .delete(`api/employee_groups/${id}`)
      .then(() => {
        Vue.notify(defaultApiResultManager.then('Grupa pracownicza została usunięta'));
      })
      .catch(({ data }) => {
        Vue.notify(defaultApiResultManager.catch());
        throw new Error(data['hydra:description']);
      });
  },
};
