<template>
  <!-- TODO: extract repetetive logic of report dialog. The same as for driver -->
  <v-dialog
    v-bind="$attrs"
    width="500"
    :value="reportDialogVisible"
    scrollable
    transition="dialog-bottom-transition"
    @click:outside="emitClose"
    @keydown.esc="emitClose"
  >
    <v-card>
      <div class="pl-3 pr-3">
        <div class="d-flex justify-space-between align-center mb-5">
          <div class="d-flex align-center">
            <v-btn icon @click="emitClose"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-title>Raport kursu</v-card-title>
          </div>
          <text-button :disabled="!valid" :loading="isRequestPending" @click="emitEditedReport">WYŚLIJ</text-button>
        </div>
        <v-form v-model="valid">
          <v-text-field
            v-model.number="reportModelCopy.distance"
            label="Łączna liczba przejechanych km"
            type="number"
            inputmode="decimal"
            clearable
            outlined
            :messages="distanceSumMessage"
          ></v-text-field>
          <report-dialog-field
            label="Koszty za autostradę do rozliczenia"
            v-model.number="reportModelCopy.highwayCost"
          />
          <v-text-field
            v-model="reportModelCopy.comment"
            label="Komentarz"
            clearable
            outlined
            :error-messages="distanceDifferenceError"
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import TextButton from '@/component/Button/text-button';
import ReportDialogField from '@/component/NewCourse/Show/Details/report-dialog-field';
import { isEqual } from 'lodash';

export default {
  name: 'ReportModal',
  components: { TextButton, ReportDialogField },
  props: {
    reportModel: {
      type: Object,
      default: () => ({
        // TODO: why do I need this default specified
        distance: '',
        highwayCost: '',
        comment: '',
      }),
    },
    reportDialogVisible: {
      type: Boolean,
      required: true,
    },
    isRequestPending: {
      type: Boolean,
      default: false,
    },
    distanceSum: {
      type: Number,
    },
  },
  data() {
    return {
      reportModelCopy: JSON.parse(JSON.stringify(this.reportModel)),
      commentError: false,
      valid: false,
    };
  },
  computed: {
    distanceSumMessage() {
      return `${this.distanceSum} km wyliczone na podstawie Google Maps. Edycja tego pola wymaga dodania komentarza.`;
    },
    distanceDifferenceError() {
      return this.reportModelCopy.distance === this.distanceSum || this.reportModelCopy.comment
        ? null
        : 'Uzasadnij zmianę łącznej liczby przejechanych km';
    },
  },
  methods: {
    emitEditedReport() {
      if (this.valid) {
        this.$emit('onReportEdit', this.reportModelCopy);
      }
    },
    emitClose() {
      this.$emit('close');
    },
  },
  watch: {
    // TODO: think if it not over engineering, and I should use prop-event architecture instead
    reportModel(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.reportModelCopy = JSON.parse(JSON.stringify(newVal));
      }
    },
  },
};
</script>

<style scoped lang="scss">
// TODO: talk with Bartek to unify styles of both dialogs:
// - space between inputs
// - with or without suffix
::v-deep .v-input.v-text-field {
  margin-bottom: 16px;
}
</style>
