<template>
  <UserPhoneSettingsPage v-bind="config" :key="phoneRouteKey"></UserPhoneSettingsPage>
</template>

<script>
import { mapGetters } from 'vuex';
import UserPhoneSettingsPage from './user-phone-settings-page';
import { AddPhoneStrategy } from './AddPhoneStrategy';
import { EditPhoneStrategy } from './EditPhoneStrategy';

export default {
  name: 'UserPhoneSettingsFactory',
  components: {
    UserPhoneSettingsPage,
  },
  props: ['state'],
  computed: {
    ...mapGetters(['user']),
    selectedPhone() {
      return this.user.phones[this.state.selectedPhoneIdx];
    },
    config() {
      // TODO: when migrated to TS, switch case can include exhaustiveness check
      switch (this.state.type) {
        case 'create':
          return {
            headerTitle: 'Dodaj numer telefonu',
            submitButtonText: 'Dodaj numer telefonu',
            phoneEntity: {
              phone: null,
              main: false,
            },
            strategy: new AddPhoneStrategy(this.$store),
          };
        case 'edit':
          return {
            headerTitle: 'Zmiana numeru telefonu',
            submitButtonText: 'Zmień numer telefonu',
            phoneEntity: this.selectedPhone,
            strategy: new EditPhoneStrategy(this.$store),
          };
        default:
          throw new Error(`${this.state.type} is not supported by PhoneSettingsFactory`);
      }
    },
    phoneRouteKey() {
      return JSON.stringify(this.state);
    },
  },
};
</script>

<style lang="scss" scoped></style>
