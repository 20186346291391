<template>
  <one-field-date-time
    :label="label"
    icon="today"
    :value="date"
    :rules="rules"
    class="mt-0 pt-0"
    @input="emitDateTimeChange"
    clearable
  />
</template>

<script>
import OneFieldDateTime from '@/component/Field/DateTime/index';

export default {
  name: 'DateTimeField',
  components: { OneFieldDateTime },
  props: {
    required: {
      type: Boolean,
    },
    date: {
      default: null,
    },
    label: {
      default: 'Data',
    },
  },
  computed: {
    rules() {
      return this.required ? [(v) => !!v || 'Pole wymagane'] : [];
    },
  },
  methods: {
    emitDateTimeChange(dateTime) {
      this.$emit('dateTimeChange', dateTime);
    },
  },
};
</script>
