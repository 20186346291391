// eslint-disable-next-line import/prefer-default-export
export class AddPhoneStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  #createPayload(newPhone) {
    const userPhones = this.#store.getters.user.phones;

    if (newPhone.main) {
      return [...userPhones.map(({ id, phone }) => ({ id: `/api/user_phones/${id}`, phone, main: false })), newPhone];
    }

    return [...userPhones.map(({ id, phone, main }) => ({ id: `/api/user_phones/${id}`, phone, main })), newPhone];
  }

  execute(newPhone) {
    return this.#store.dispatch('addUserPhone', this.#createPayload(newPhone));
  }
}
