<template>
  <v-col :class="this.sizeableClasses">
    <v-text-field
      :value="this.value"
      @input="this.input"
      :autofocus="this.autofocus"
      :filled="this.filled"
      :clearable="this.clearable"
      :color="this.color"
      :dark="this.dark"
      :disabled="this.disabled"
      :error="this.error"
      :flat="this.flat"
      :full-width="this.fullWidth"
      :height="this.height"
      :prepend-icon="this.icon"
      :label="this.label"
      :loading="this.loading"
      :outline="this.outline"
      :placeholder="this.placeholder"
      :readonly="this.readonly"
      :solo="this.solo"
      :success="this.success"
      :type="this.type"
      :hide-details="!this.hint"
      :hint="this.hint"
      :persistent-hint="!!this.hint"
      :rules="this.rules"
      v-bind="$attrs"
    />
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';

export default {
  name: 'OneTextField',
  mixins: [sizeable],
  inheritAttrs: false,
  props: {
    solo: Boolean,
    autofocus: Boolean,
    filled: Boolean,
    clearable: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    error: Object,
    flat: Boolean,
    fullWidth: Boolean,
    height: null,
    icon: String,
    label: String,
    value: String,
    loading: Boolean,
    outline: Boolean,
    placeholder: String,
    readonly: Boolean,
    success: Boolean,
    type: String,
    hint: String,
    rules: Array,
  },
  methods: {
    input(val) {
      this.$emit('input', val);
    },
  },
};
</script>
