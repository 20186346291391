export default {
  TIMEOUT_IN_MS: 30000,

  getCoordinates() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: this.TIMEOUT_IN_MS });
    });
  },

  calculateDistanceBetweenTwoPoints(placeCoordinates, currentCoordinates) {
    const earthRadius = 6371; // średni promień Ziemi w kilometrach
    const latDiffInKm = (placeCoordinates.latitude - currentCoordinates.latitude) * (Math.PI / 180) * earthRadius;
    const lonDiffInKm =
      (placeCoordinates.longitude - currentCoordinates.longitude) *
      (Math.PI / 180) *
      earthRadius *
      Math.cos(((placeCoordinates.latitude + currentCoordinates.latitude) / 2) * (Math.PI / 180));
    const distance = Math.sqrt(latDiffInKm * latDiffInKm + lonDiffInKm * lonDiffInKm);
    return distance;
  },
};
