export default function courseAdapter(course) {
  const { billFromDriver, ...restOfCourse } = course;
  return {
    ...restOfCourse,
    driverDistance: billFromDriver?.distance ?? null,
    driverWaitingHours: billFromDriver?.waitingHours ?? null,
    driverHighwayCost: billFromDriver?.highwayCost ?? null,
    driverComment: billFromDriver?.comment ?? null,
    rides: course.rides.map((ride) => {
      const { billToSettled, ...restOfRide } = ride;

      return {
        ...restOfRide,
        distanceToSettled: billToSettled?.distance ?? null,
        highwayCostToSettled: billToSettled?.highwayCost ?? null,
        waitingHoursToSettled: billToSettled?.waitingHours ?? null,
        commentToSettled: billToSettled?.comment ?? null,
      };
    }),
  };
}
