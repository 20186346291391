<template>
  <v-btn class="mb-4" rounded color="white" small style="text-transform: none" @click="$emit('click')">
    <span class="primary--text edit-place__text">Edytuj lokalizację na mapie</span>
    <v-icon color="primary" right>mdi-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'EditSelectedPlaceButton',
};
</script>

<style lang="scss" scoped>
.edit-place__text {
  font: 500 14px/18px 'Roboto', sans-serif;
  letter-spacing: 0em;
  text-transform: none;
}
</style>
