import {
  SET_BASE_TRANSPORT_COURSES,
  SET_INVOICE,
  SET_INVOICES,
  SET_INVOICES_TO_CREATE,
  SET_LOADING,
  SET_TRANSPORT_COURSES,
  UPDATE_INVOICE_TO_CREATE,
} from '@/core/store/modules/types/invoice-mutatuin-types';
import InvoiceService from '@/service/InvoiceService';
import { differenceInDays } from 'date-fns';
import groupBy from 'lodash/groupBy';
import Vue from 'vue';
import router from '@/core/config/router';
import { INVOICE_TYPE } from '@/core/dict/invoice-dict';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    invoice: null,
    invoices: [],
    invoicesToCreate: [],
    baseTransportCourses: [],
    transportCourses: [],
  },
  getters: {
    isLoading: (state) => state.isLoading,
    invoices: (state) => state.invoices,
    invoicesToCreate: (state) => state.invoicesToCreate,
    invoice: (state) => state.invoice,
    baseTransportCourses: (state) => state.baseTransportCourses,
    transportCourses: (state) => state.transportCourses,
  },
  mutations: {
    [SET_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    [SET_INVOICES]: (state, invoices) => {
      state.invoices = invoices;
    },

    [SET_INVOICE]: (state, invoice) => {
      state.invoice = invoice;
    },

    [SET_INVOICES_TO_CREATE]: (state, invoicesToCreate) => {
      state.invoicesToCreate = invoicesToCreate;
    },

    [UPDATE_INVOICE_TO_CREATE]: (state, invoice) => {
      Vue.set(state.invoicesToCreate, invoice.index, invoice);
    },

    [SET_BASE_TRANSPORT_COURSES]: (state, baseTransportCourses) => {
      state.baseTransportCourses = baseTransportCourses;
    },

    [SET_TRANSPORT_COURSES]: (state, transportCourses) => {
      state.transportCourses = transportCourses;
    },
  },
  actions: {
    fetchInvoices({ commit }) {
      commit(SET_LOADING, true);

      return InvoiceService.fetchInvoices()
        .then(({ data }) => {
          commit(
            SET_INVOICES,
            data['hydra:member'].map((invoice) => ({
              ...invoice,
              daysLeft: [INVOICE_TYPE.CANCELED, INVOICE_TYPE.PAID].includes(invoice.status)
                ? null
                : differenceInDays(invoice.paymentTo, new Date()),
            }))
          );
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    },

    fetchInvoice({ commit }, id) {
      commit(SET_LOADING, true);

      return InvoiceService.fetchInvoice(id)
        .then(({ data }) => {
          commit(SET_INVOICE, data);
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    },

    async fetchAcceptedCourses({ commit }, dateRange) {
      commit(SET_LOADING, true);
      await InvoiceService.fetchAcceptedCourses(dateRange[0], dateRange[1], 'transportCompany').then((data) => {
        commit(SET_TRANSPORT_COURSES, data['hydra:member']);
      });

      return InvoiceService.fetchAcceptedCourses(dateRange[0], dateRange[1], 'baseTransportCompany')
        .then((data) => {
          commit(SET_BASE_TRANSPORT_COURSES, data['hydra:member']);
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    },

    updateInvoice({ commit }, invoice) {
      commit(SET_LOADING, true);
      InvoiceService.updateInvoice(invoice).finally(() => commit(SET_LOADING, false));
    },

    saveInvoices({ commit }, invoices) {
      commit(SET_LOADING, true);
      let createdInvoices = 0;
      invoices.forEach((invoice, index) => {
        if (!invoice.invoiceItems.length) {
          return;
        }

        if (invoice.isCreated) {
          createdInvoices += 1;
          return;
        }

        InvoiceService.saveInvoice(invoice)
          .then(() => {
            commit(UPDATE_INVOICE_TO_CREATE, { ...invoice, index, isCreated: true });
            createdInvoices += 1;
          })
          .finally(() => {
            if (createdInvoices === invoices.length) {
              router.push({ name: 'invoices_index' });
            }

            if (index === invoices.length - 1) {
              commit(SET_LOADING, false);
            }
          });
      });
    },

    downloadInvoice(_, invoice) {
      InvoiceService.downloadInvoice(invoice);
    },

    downloadReport(_, invoice) {
      InvoiceService.downloadReport(invoice);
    },

    recalculateInvoices({ commit }, payload) {
      commit(SET_INVOICES_TO_CREATE, [
        ...InvoiceService.transform(
          payload,
          groupBy(payload.selectedTransportCourses, (course) => course.baseTransportCompany['@id']),
          false
        ),
        ...InvoiceService.transform(
          payload,
          groupBy(payload.selectedBaseTransportCourses, (course) => course.passengers[0].passenger.company['@id'])
        ),
      ]);
    },

    resetCreateForm({ commit }) {
      commit(SET_TRANSPORT_COURSES, []);
      commit(SET_BASE_TRANSPORT_COURSES, []);
      commit(SET_INVOICES_TO_CREATE, []);
    },
  },
};
