<template>
  <v-dialog :value="value" persistent max-width="328">
    <v-card>
      <v-card-title class="pa-6 pb-5">
        <BaseTypography variant="h4">Sukces! 🎉</BaseTypography>
      </v-card-title>
      <v-card-text>
        <BaseTypography variant="body-1">
          Link resetujący hasło został wysłany do {{ userIdentifier }}. Kliknij w niego, aby kontynuować proces zmiany
          hasła.
        </BaseTypography>
      </v-card-text>
      <v-card-actions class="justify-end pa-2">
        <BaseTextButton @click="$emit('close')">Ok</BaseTextButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import BaseTextButton from '@/component/Base/base-text-button';

export default {
  name: 'ResetLinkConfirmModal',
  components: {
    BaseTypography,
    BaseTextButton,
  },
  props: {
    userIdentifier: {
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
