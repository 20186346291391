import { uniqBy } from 'lodash';

export function uniqueValues(array, property) {
  const flattedArray = array.map((segment) => segment[property]).flat();
  return uniqBy(flattedArray, 'id');
}

export function uniqueIds(array, property) {
  return uniqueValues(array, property).map((v) => v['@id']);
}
