<template>
  <v-row data-cy="date-time-separate-input" :class="{ 'flex-nowrap': isInlineLayout }">
    <v-col :cols="isInlineLayout ? 6 : 12" md="6" style="min-width: 240px">
      <time-field v-model="timeExtracted" :label="labels.timeLabel" @blur="emitBlur" @input="emitChange" />
    </v-col>
    <v-col :cols="isInlineLayout ? 6 : 12" md="6" style="min-width: 240px">
      <date-field :label="labels.dateLabel" v-model="dateExtracted" @blur="emitBlur" @input="emitChange" />
    </v-col>
  </v-row>
</template>
<script>
import TimeField from '@/component/Field/Generic/time-field';
import DateField from '@/component/Field/Generic/date-field';

export default {
  name: 'DateTimeSeparateField',
  components: { DateField, TimeField },
  props: {
    value: { required: false },
    // TODO: add JSDOc type
    labels: {
      type: Object,
      default: () => ({
        timeLabel: 'Godzina odjazdu',
        dateLabel: 'Data odjazdu',
      }),
    },
    isInlineLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTime: null,
    };
  },
  created() {
    this.dateTime = this.value;
  },
  methods: {
    emitChange() {
      this.$emit('input', this.dateTime);
    },
    emitBlur() {
      this.$emit('blur', this.timeExtracted);
    },
  },
  watch: {
    value(val) {
      this.dateTime = val;
    },
  },
  computed: {
    dateExtracted: {
      get() {
        return this.dateTime?.split('T')[0];
      },
      set(val) {
        if (!val) {
          this.dateTime = `T${this.dateTime.split('T')[1]}`;
          return;
        }

        if (!this.dateTime) {
          this.dateTime = 'T';
        }

        this.dateTime = `${val}T${this.dateTime.split('T')[1]}`;
      },
    },
    timeExtracted: {
      get() {
        return this.dateTime?.split('T')[1].slice(0, 5);
      },
      set(val) {
        if (!val) {
          this.dateTime = `${this.dateTime.split('T')[0]}T`;
          return;
        }

        if (!this.dateTime) {
          this.dateTime = 'T';
        }

        this.dateTime = `${this.dateTime.split('T')[0]}T${val}:00`;
      },
    },
  },
};
</script>
