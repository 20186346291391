<template>
  <AbstractCourseBadge class="taxi-course-badge" text="taxi" />
</template>

<script>
import AbstractCourseBadge from './abstract-course-badge';

export default {
  name: 'TaxiCourseBadge',
  components: {
    AbstractCourseBadge,
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.taxi-course-badge {
  @include course-badge(var(--taxi-course-badge-color), var(--taxi-course-badge-text-color));
}
</style>
