<template>
  <AbstractCourseBadge class="kilometer-course-badge" text="kilometrówka" />
</template>

<script>
import AbstractCourseBadge from './abstract-course-badge';

export default {
  name: 'KilometerCourseBadge',
  components: {
    AbstractCourseBadge,
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.kilometer-course-badge {
  @include course-badge(var(--kilometer-course-badge-color), var(--kilometer-course-badge-text-color));
}
</style>
