<template>
  <section class="d-flex flex-column" style="row-gap: 16px">
    <BaseTypography variant="subtitle">Numery telefonów</BaseTypography>
    <v-radio-group class="mt-0" v-model="mainPhone" mandatory hide-details>
      <div
        v-for="(phoneEntity, idx) in phones"
        :key="phoneEntity['@id']"
        class="d-flex align-center"
        style="column-gap: 8px"
      >
        <div class="flex-grow-1">
          <phone-field
            label="Numer telefonu"
            :value="phoneEntity.phone"
            :entity="entity"
            @input="updatePhone($event, idx)"
          ></phone-field>
          <radio-field :value="phoneEntity['@id']" class="ma-2" label="Domyślny numer telefonu"></radio-field>
        </div>
        <v-btn v-if="isMultiplePhonesDefined" icon color="red" @click="removePhone(idx)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-radio-group>
    <base-text-button class="align-self-start" prependIcon="mdi-plus" @click="addPhone">
      Dodaj kolejny numer telefonu
    </base-text-button>
  </section>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import RadioField from '@/component/Field/Radio/radio-field';
import PhoneField from '@/component/Field/Phone/phone-field';
import BaseTypography from '@/component/Base/base-typography';
import formValidationRules from '@/core/utils/form-validation-rules';

export default {
  name: 'PhoneSection',
  components: {
    BaseTextButton,
    RadioField,
    PhoneField,
    BaseTypography,
  },
  props: {
    phones: {
      type: Array,
      required: true,
    },
    entity: {
      required: true,
    },
  },
  data() {
    return {
      rules: formValidationRules,
    };
  },
  computed: {
    isMultiplePhonesDefined() {
      const MULTIPLE_PHONES_COUNT = 2;
      return this.phones.length >= MULTIPLE_PHONES_COUNT;
    },
    mainPhone: {
      get() {
        const mainPhone = this.phones.find((phone) => phone.main);
        return mainPhone && mainPhone['@id'];
      },
      set(v) {
        this.$emit(
          'update:phones',
          this.phones.map((p) => ({ ...p, main: p['@id'] === v }))
        );
      },
    },
  },
  methods: {
    updatePhone(val, idx) {
      this.$emit(
        'update:phones',
        this.phones.map((phone, phoneIdx) => (phoneIdx === idx ? { ...phone, phone: val } : phone))
      );
    },
    removePhone(idx) {
      this.$emit(
        'update:phones',
        this.phones.filter((_, phoneIdx) => phoneIdx !== idx)
      );
    },
    addPhone() {
      const phoneIdx = this.phones.length + 1;
      this.$emit('update:phones', [...this.phones, { '@id': phoneIdx, phone: null, main: false }]);
    },
  },
};
</script>
