<template>
  <!-- TODO: duplication with abstract-car-button -->
  <v-list-item class="abstract-place__container" v-on="$listeners" :disabled="disabled">
    <v-btn fab small :color="btnColor" :disabled="disabled" elevation="0">
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </v-btn>
    <div class="d-inline-flex flex-column ml-2" style="width: 80px; white-space: nowrap; flex: 1">
      <p class="abstract-place__name">{{ place.name }}</p>
      <p class="abstract-place__address">{{ place.address }}</p>
    </div>
    <PlacesContextMenuFactory :type="type" :buttons="$attrs.buttons"></PlacesContextMenuFactory>
  </v-list-item>
</template>

<script>
import PlacesContextMenuFactory from './places-context-menu-factory';

export default {
  name: 'AbstractPlace',
  components: {
    PlacesContextMenuFactory,
  },
  props: {
    place: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      required: true,
    },
    btnColor: {
      type: String,
      required: true,
    },
    type: {
      required: true,
    },
    disabled: {
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.abstract-place {
  &__container {
    display: flex;
    align-items: center;
    padding: 8px;
    column-gap: 8px;

    // override of default vuetify hover state
    // https://app.clickup.com/t/8693q3ex9 AC.1
    & .v-btn:hover::before {
      opacity: 0;
    }
  }
  &__name,
  &__address {
    margin-bottom: 0;

    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    font: 400 15px/18px 'Roboto', sans-serif;
    color: #323232;
  }
  &__address {
    font: 400 13px/15px 'Roboto', sans-serif;
    color: #5a5a5a;
  }
}
</style>
