<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader :text="contractorName" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text class="d-flex flex-column" style="row-gap: 16px">
          <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
          <div>
            <CompanyContractorStakesTable :companyContractorStakes="contractorStakes"></CompanyContractorStakesTable>
            <TextButton prependIcon="mdi-plus" block @click="openStakesModal">Dodaj nowe stawki</TextButton>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <NewStakesModal :value="stakesModal" :strategy="strategy" @close="closeStakesModal"></NewStakesModal>
  </div>
</template>

<script>
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import TextButton from '@/component/Base/base-text-button';
import BaseTypography from '@/component/Base/base-typography';
import CompanyContractorStakesTable from '@/controller/Page/page-toolbar/new-stakes-modal.vue/company-contractor-stakes-table';
import NewStakesModal from '../new-stakes-modal.vue/new-stakes-modal';
import { AddContractorStakeStrategy } from '../new-stakes-modal.vue/add-contractor-stake-strategy';

export default {
  name: 'EditContactorOfTransportCompany',
  components: {
    DialogTitleHeader,
    NewStakesModal,
    TextButton,
    CompanyContractorStakesTable,
    BaseTypography,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    companyContractor: {
      required: true,
    },
    isContractorOfRailwayCompany: {
      default: false,
    },
  },
  data() {
    return {
      stakesModal: false,
    };
  },
  computed: {
    contractorName() {
      return this.companyContractor?.contractor?.name;
    },
    contractorStakes() {
      return this.companyContractor?.stakes;
    },
    contractorId() {
      return this.companyContractor ? this.companyContractor['@id'] : '';
    },
    strategy() {
      return new AddContractorStakeStrategy({
        store: this.$store,
        contractorId: this.contractorId,
        contractorName: this.contractorName,
        isContractorOfRailwayCompany: this.isContractorOfRailwayCompany,
      });
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    openStakesModal() {
      this.stakesModal = true;
    },
    closeStakesModal() {
      this.stakesModal = false;
    },
  },
};
</script>
