import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';

const courseExecutor = (course) => course.subcontractor || course.contractor;

export function getStatusForTransferredRide(course) {
  if (course.driver && courseExecutor(course).courseEmployeeSmsNotification) {
    return TAXI_COURSE_RIDE_STATUS.WAITING_FOR_DRIVER_ACCEPTANCE;
  }

  if (course.contractor || course.subcontractor) {
    return TAXI_COURSE_RIDE_STATUS.WAITING_FOR_TRANSPORT_COMPANY_ACCEPTANCE;
  }

  return TAXI_COURSE_RIDE_STATUS.PLANNING;
}

export function getStatusForCancelledRide(course) {
  if (course.driverDistance) {
    return TAXI_COURSE_RIDE_STATUS.FINISHED;
  }

  return getStatusForTransferredRide(course);
}
