import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import DIALOGS from '@/core/dict/custom-dialog-dict';

function playNotification() {
  // eslint-disable-next-line global-require
  const audio = new Audio(`${require('@/assets/audio/notification.mp3')}`);
  audio.play().catch(() => defaultApiResultManager.catch('Nie udało się odtworzyć dźwięku powiadomienia.'));
}

export default {
  methods: {
    passengerChange(passengerChange) {
      playNotification();
      const options = {
        html: true,
        view: DIALOGS.PASSENGER_CHANGE_ASSIGN,
        courseId: passengerChange.courseId,
      };

      const modalData = {
        title: `Nastąpiła zmiana dla pasażera <b>${passengerChange.passenger.name}</b>`,
        body: `<p>Osoba zgłaszająca <b>${passengerChange.reportingUser.name}</b></p>
               <p>Powód zgłoszenia: <b>${passengerChange.reason.reason}</b></p>`,
      };

      if (passengerChange.comment) {
        modalData.body += `<div><p>Komentarz: ${passengerChange.comment}</p></div>`;
      }

      this.$dialog.confirm(modalData, options).then((result) => {
        this.$http
          .put(`api/passenger_changes/${passengerChange.id}`, {
            realizedBy: `api/users/${this.$store.state.user.id}`,
          })
          .then(() => this.$notify(defaultApiResultManager.then()))
          .catch(({ data, status }) => this.$notify(defaultApiResultManager.catch(data, status)));

        if (result.data) {
          this.$router.push({
            name: 'course_show',
            params: { id: passengerChange.courseId },
          });
        }
      });
    },
  },
};
