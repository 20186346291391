<template>
  <div ref="map" :style="{ height }" />
</template>

<script>
import { INITIAL_MAP_CENTER, INITIAL_MAP_ZOOM } from '@/core/dict/google-map-dict';

/* eslint-disable no-undef */
export default {
  name: 'TaxiCourseSegmentsMap',
  props: {
    waypoints: Array,
    height: {
      type: String,
      default: '500px',
    },
    zoom: {
      type: Number,
      default: INITIAL_MAP_ZOOM,
    },
  },
  data() {
    return {
      directionsService: null,
      directionsRenderer: null,
    };
  },
  async created() {
    await this.initGoogleMap();
    this.drawRoute();
  },
  methods: {
    async initGoogleMap() {
      // CREATING MAP
      const { Map } = await google.maps.importLibrary('maps');
      this.map = new Map(this.$refs.map, {
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        zoom: this.zoom,
        center: INITIAL_MAP_CENTER,
      });

      // SETTING DIRECTIONS FOR SHOWING ROUTE
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
    },
    drawRoute() {
      const waypointsCount = this.waypoints.length;
      if (waypointsCount <= 1 || typeof this.directionsRenderer === 'undefined') {
        return;
      }

      const waypoints = [];
      if (waypointsCount > 2) {
        this.waypoints.forEach((waypoint, i) => {
          if (i === 0 || i === waypointsCount - 1) {
            return;
          }

          if (!waypoint.latitude || !waypoint.longitude) {
            this.directionsRenderer.setMap(null);
            return;
          }

          waypoints.push({
            location: new google.maps.LatLng(waypoint.latitude, waypoint.longitude),
            stopover: true,
          });
        });
      }

      const request = {
        origin: new google.maps.LatLng(this.waypoints[0].latitude, this.waypoints[0].longitude),
        destination: new google.maps.LatLng(
          this.waypoints[waypointsCount - 1].latitude,
          this.waypoints[waypointsCount - 1].longitude
        ),
        travelMode: 'DRIVING',
        waypoints,
      };
      this.directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.directionsRenderer.setDirections(result);
        }
      });
    },
  },
  watch: {
    waypoints: {
      handler: 'drawRoute',
    },
  },
};
</script>
