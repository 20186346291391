<template>
  <div ref="map" :style="{ height }" />
</template>

<script>
import { INITIAL_MAP_ZOOM, INITIAL_MAP_CENTER, MAP_ZOOM_TYPES } from '@/core/dict/google-map-dict';

export default {
  /* eslint-disable no-undef */
  name: 'GoogleMap',
  props: {
    coords: {
      type: Object,
      default: () => ({
        latitude: null,
        longitude: null,
      }),
    },
    height: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
      entity: {
        coordinates: {
          latitude: null,
          longitude: null,
        },
        address: null,
      },
      markers: [],
      geocoder: null,
      Marker: null,
    };
  },
  async created() {
    await this.loadMarkerPackage();
    await this.initGoogleMap();
  },
  computed: {
    isCoordsDefined() {
      return this.coords.latitude && this.coords.longitude;
    },
    markerPosition() {
      return {
        lat: this.coords.latitude,
        lng: this.coords.longitude,
      };
    },
  },
  methods: {
    async initGoogleMap() {
      this.markers = [];
      // CREATING GEOCODER
      const { Geocoder } = await google.maps.importLibrary('geocoding');
      this.geocoder = new Geocoder();

      // CREATING MAP
      const { Map } = await google.maps.importLibrary('maps');
      this.map = new Map(this.$refs.map, {
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        zoom: INITIAL_MAP_ZOOM,
        center: this.isCoordsDefined ? this.markerPosition : INITIAL_MAP_CENTER,
      });

      // FOR CREATING PLACE IN MAP (ex. starting base picking)
      google.maps.event.addListener(this.map, 'click', (event) => this.createMarkerObject(event.latLng));
    },

    async loadMarkerPackage() {
      const { Marker } = await google.maps.importLibrary('marker');
      this.Marker = Marker;
    },

    async createMarkerObject(location) {
      this.entity.coordinates.latitude = location.lat();
      this.entity.coordinates.longitude = location.lng();
      this.entity.address = await this.findAddressByPosition(location);

      this.emitToParentNewValues();
    },

    removePreviousMarker() {
      if (this.markers.length > 0) {
        this.markers[0].setMap(null);
        this.markers = [];
      }
    },

    resetMap() {
      this.removePreviousMarker();
      this.map.setZoom(INITIAL_MAP_ZOOM);
      this.map.setCenter(INITIAL_MAP_CENTER);
    },

    findAddressByPosition(position) {
      return new Promise((resolve) => {
        this.geocoder.geocode(
          {
            latLng: position,
          },
          (responses) => {
            if (responses && responses.length > 0) {
              resolve(responses[0].formatted_address);
            } else {
              this.$notify({
                group: 'global',
                type: 'error',
                text: 'Nie znaleziono adresu dla zaznaczonego miejsca',
              });
              resolve(null);
            }
          }
        );
      });
    },

    emitToParentNewValues() {
      this.$emit('newData', this.entity);
    },

    setMarker() {
      if (this.isCoordsDefined) {
        this.removePreviousMarker();

        this.markers.push(
          new this.Marker({
            position: this.markerPosition,
            map: this.map,
            // eslint-disable-next-line global-require
            icon: `${require('@/assets/car-icon.png')}`,
          })
        );

        this.map.panTo(this.markers[0].position);
        this.map.setZoom(MAP_ZOOM_TYPES.STREET);
      } else {
        this.resetMap();
      }
    },
  },
  watch: {
    coords: {
      handler: 'setMarker',
    },
  },
};
</script>
