const PassengerChangeList = () => import('@/controller/PassengerChange/passenger-change-list');

const name = 'passenger_change';

export default [
  {
    path: '/passengers-change',
    name: `${name}_index`,
    component: PassengerChangeList,
    meta: {
      title: 'Ewidencja zmian pasażerów',
    },
  },
];
