<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader :text="contractorName" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text class="d-flex flex-column" style="row-gap: 16px">
          <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
          <v-switch v-model="isContractorSuperior" class="mt-0 pt-0" hide-details>
            <template #label>
              <p class="text-body-2 mb-0 grey--text text--darken-4">
                <b>{{ contractorName }}</b>
                jest nadrzędną firmą transportową dla firmy kolejowej
                <b>{{ railwayCompanyName }}</b>
              </p>
            </template>
          </v-switch>
          <alert-info class="mb-0">
            <p class="mb-0">
              Nadrzędna firma transportowa ma dostęp do wszystkich kursów firmy kolejowej oraz może dysponować inne
              firmy transportowe, z którymi współpracuje firma kolejowa.
            </p>
          </alert-info>
          <BillingModelSection :billingModel.sync="billingModel"></BillingModelSection>
          <div>
            <CompanyContractorStakesTable :companyContractorStakes="contractorStakes"></CompanyContractorStakesTable>
            <TextButton prependIcon="mdi-plus" block @click="openStakesModal">Dodaj nowe stawki</TextButton>
          </div>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn block color="primary" @click="save">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NewStakesModal :value="stakesModal" :strategy="strategy" @close="closeStakesModal"></NewStakesModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AlertInfo from '@/component/Alert/alert-info';
import TextButton from '@/component/Base/base-text-button';
import CompanyContractorStakesTable from '@/controller/Page/page-toolbar/new-stakes-modal.vue/company-contractor-stakes-table';
import BaseTypography from '@/component/Base/base-typography';
import NewStakesModal from '../new-stakes-modal.vue/new-stakes-modal';
import BillingModelSection from '../principal-modal/billing-model-section';
import { AddContractorStakeStrategy } from '../new-stakes-modal.vue/add-contractor-stake-strategy';

export default {
  name: 'EditContractorOfPrincipalModal',
  components: {
    DialogTitleHeader,
    AlertInfo,
    TextButton,
    NewStakesModal,
    CompanyContractorStakesTable,
    BaseTypography,
    BillingModelSection,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    companyContractor: {
      required: true,
    },
  },
  data() {
    return {
      stakesModal: false,
      isContractorSuperior: false,
      billingModel: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
    contractorName() {
      return this.companyContractor?.contractor?.name;
    },
    contractorStakes() {
      return this.companyContractor?.stakes;
    },
    railwayCompanyName() {
      return this.user.company.name;
    },
    contractorId() {
      return this.companyContractor ? this.companyContractor['@id'] : '';
    },
    strategy() {
      return new AddContractorStakeStrategy({
        store: this.$store,
        contractorId: this.contractorId,
        contractorName: this.contractorName,
      });
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    openStakesModal() {
      this.stakesModal = true;
    },
    closeStakesModal() {
      this.stakesModal = false;
    },
    save() {
      this.$store
        .dispatch('taxiCourse/updateContractor', {
          payload: {
            superior: this.isContractorSuperior,
            billingModel: this.billingModel,
          },
          company: this.user.company,
          contractorId: this.contractorId,
        })
        .then(() => {
          this.emitCloseEvent();
        });
    },
  },
  watch: {
    companyContractor(newVal) {
      this.isContractorSuperior = newVal.superior;
      this.billingModel = newVal.billingModel;
    },
  },
};
</script>
