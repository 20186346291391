import { EVENTS } from '@/core/dict/events-dict';

const PlaceList = () => import('@/controller/Place/place-list');

export default [
  {
    path: '/places',
    name: `place_index`,
    component: PlaceList,
    meta: {
      title: 'Miejsca',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
    },
  },
];
