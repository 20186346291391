<template>
  <v-col :class="sizeableClasses">
    <slot></slot>
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';

export default {
  name: 'OneProfileMore',
  mixins: [sizeable],
};
</script>
