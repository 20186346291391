import CommentService from '@/service/CommentService';

export default {
  namespaced: true,
  actions: {
    remove({ commit }, commentId) {
      commit('setLoading', true, { root: true });
      return CommentService.remove(commentId).finally(() => {
        commit('setLoading', false, { root: true });
      });
    },
  },
};
