// Refer to https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus/state

export const PERMISSION_DICT = {
  GRANTED: 'granted',
  DENIED: 'denied',
  PROMPT: 'prompt',
};

// Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#permission-aware_apis
export const USED_WEB_APIS = {
  GEOLOCATION: 'geolocation',
  NOTIFICATIONS: 'notifications',
};
