import CarService from '@/service/CarService';
import * as types from './types/car-mutation-types';

export default {
  namespaced: true,
  state: {
    cars: [],
    brands: [],
    isBrandsLoading: false,
  },
  getters: {
    brands: (state) => state.brands,
    isBrandsLoading: (state) => state.isBrandsLoading,
    cars: (state) => state.cars,
  },
  mutations: {
    [types.SET_BRAND_LOADING]: (state, val) => {
      state.isBrandsLoading = val;
    },
    [types.SET_BRANDS]: (state, val) => {
      state.brands = val;
    },
    [types.ADD_BRAND]: (state, val) => {
      const haystackIdx = state.brands.findIndex((b) => b.id === val.id);
      if (haystackIdx !== -1) {
        state.brands.splice(haystackIdx, 1, val);
      } else {
        state.brands.push(val);
      }
    },
    [types.SET_CARS]: (state, val) => {
      state.cars = val;
    },
    [types.ADD_CAR]: (state, val) => {
      state.cars = [...state.cars, val];
    },
    [types.REMOVE_CAR]: (state, carId) => {
      state.cars = state.cars.filter((c) => c.id !== carId);
    },
  },
  actions: {
    createCar({ commit }, car) {
      commit('setLoading', true, { root: true });
      return CarService.createCar(car)
        .then((data) => {
          commit(types.ADD_CAR, data);
          return data;
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    deleteCar({ commit }, car) {
      commit('setLoading', true, { root: true });
      return CarService.deleteCar(car)
        .then(() => {
          commit(types.REMOVE_CAR, car.id);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchCarBrands({ commit }) {
      commit(types.SET_BRAND_LOADING, true);
      return CarService.fetchCarBrands()
        .then((data) => {
          commit(types.SET_BRANDS, data);
        })
        .finally(() => {
          commit(types.SET_BRAND_LOADING, false);
        });
    },
    createBrand({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CarService.createBrand(payload)
        .then((data) => {
          commit(types.ADD_BRAND, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    updateBrand({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CarService.updateBrand(payload)
        .then((data) => {
          commit(types.ADD_BRAND, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    fetchCars({ commit }, filters) {
      commit('setLoading', true, { root: true });
      return CarService.fetchCars(filters)
        .then((data) => {
          commit(types.SET_CARS, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
