<template>
  <v-autocomplete
    :items="coursesTransferTo"
    :itemText="courseRideNumber"
    :filter="filterFn"
    label="Wybierz kurs"
    outlined
    hide-details
    clearable
    return-object
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item | courseRideNumber }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ item | courseExecutor }}
          <span v-if="isDriverSpecified(item)">/ {{ item | courseDriver }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TransferRideField',
  innerAttrs: false,
  created() {
    if (
      this.$store.getters['taxiCourse/isAllCoursesLoading'] ||
      this.$store.getters['taxiCourse/allCourses'].length === 0
    ) {
      this.$store.dispatch('taxiCourse/fetchAllCourses');
    }
  },
  computed: {
    ...mapGetters('transferRide', ['coursesTransferTo']),
  },
  methods: {
    courseRideNumber(course) {
      return course.rides.map((ride) => ride.number).join('+');
    },
    isDriverSpecified(course) {
      return !!course.driver;
    },
    filterFn(item, queryText, itemText) {
      let contractorQuery = false;
      let driverQuery = false;

      if (item.contractor) {
        contractorQuery = item.contractor.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }

      if (item.subcontractor) {
        contractorQuery = item.subcontractor.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }

      if (item.driver) {
        driverQuery = item.driver.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      }

      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || contractorQuery || driverQuery;
    },
  },
};
</script>
