<template>
  <DialogFactory :value="value" v-on="$listeners">
    <slot name="header" :entity="entityCopy"></slot>
    <v-card-text class="pb-8 flex-grow-1">
      <v-form ref="form">
        <v-text-field class="mb-6" v-model="entityCopy.name" outlined hide-details="auto" :rules="[rules.required]">
          <template #label>
            <span>Nazwa grupy pracowniczej</span>
            <span class="red--text">*</span>
          </template>
        </v-text-field>
        <slot name="stake-form" :entity="entityCopy" :updateEntity="updateEntity"></slot>
      </v-form>
    </v-card-text>
    <DialogSaveButton @click="saveEmployeeGroup"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import formValidationRules from '@/core/utils/form-validation-rules';
import DialogFactory from '@/component/Dialog/dialog-factory';

export default {
  name: 'EmployeeGroupModal',
  components: {
    DialogFactory,
    DialogSaveButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entity: {
      required: true,
    },
    strategy: {
      required: true,
    },
  },
  data() {
    return {
      entityCopy: JSON.parse(JSON.stringify(this.entity)),
      rules: formValidationRules,
    };
  },
  methods: {
    updateEntity(v) {
      this.entityCopy = v;
    },
    emitCloseEvent() {
      this.$emit('close');
    },
    resetForm() {
      this.$refs.form.reset();
    },
    saveEmployeeGroup() {
      if (this.$refs.form.validate()) {
        this.strategy.save(this.entityCopy).then(() => {
          this.emitCloseEvent();
        });
      }
    },
  },
  watch: {
    value() {
      if (this.value === false) {
        this.resetForm();
      }
    },
    entity() {
      if (this.entity) {
        this.entityCopy = JSON.parse(JSON.stringify(this.entity));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
