<template>
  <ConfirmDialog
    :description="dialogDescription"
    text="Zaakceptować kursy do rozliczenia?"
    confirmText="Tak, zaakceptuj"
    cancelText="Nie"
    v-on="$listeners"
    v-bind="$attrs"
  ></ConfirmDialog>
</template>

<script>
import ConfirmDialog from '@/component/Dialog/confirm-dialog';

export default {
  name: 'SettleCoursesConfirmDialog',
  inheritAttrs: false,
  components: {
    ConfirmDialog,
  },
  computed: {
    dialogDescription() {
      return 'Wszystkie kursy w odpowiednich statusach (taxi - "Zaakceptowano przez firmę transportową", kilometrówka - "Zakończone") zostaną zaakceptowane do rozliczenia';
    },
  },
};
</script>
