<template>
  <AbstractModalHeader class="px-2" title="Rozliczenie" :color="isMobile.value ? '#fff' : '#F5F5F5'">
    <template #prepend-item>
      <CloseIconButton @click="$emit('close')"></CloseIconButton>
    </template>
  </AbstractModalHeader>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';

export default {
  name: 'SettlementHeader',
  components: {
    AbstractModalHeader,
    CloseIconButton,
  },
  inject: ['isMobile'],
};
</script>

<style lang="scss" scoped></style>
