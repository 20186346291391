<template>
  <v-text-field
    :prepend-inner-icon="icon"
    :rules="required ? [(v) => !!v || 'Pole wymagane', ...rules] : rules"
    return-object
    outlined
    hide-details="auto"
    persistent-hint
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label>
      <span>{{ label }}</span>
      <span v-if="required" class="red--text">*</span>
    </template>
    <template #append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'TextField',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: () => [],
    },
  },
};
</script>
