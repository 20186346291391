export default {
  getPreviousSearchValue: () => localStorage.getItem(`searchValue-${window.location.pathname}`) || '',

  setSearchValue: (val) => localStorage.setItem(`searchValue-${window.location.pathname}`, val),

  setFilterValue: (filterKey, val) =>
    localStorage.setItem(`${window.location.pathname}-${filterKey}`, JSON.stringify(val)),

  getPreviousFilterValue: (filterKey) => JSON.parse(localStorage.getItem(`${window.location.pathname}-${filterKey}`)),
};
