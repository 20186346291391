<template>
  <v-btn icon aria-label="Zamknij" v-bind="$attrs" v-on="$listeners"><v-icon>mdi-close</v-icon></v-btn>
</template>

<script>
export default {
  name: 'CloseIconButton',
  inheritAttrs: false,
};
</script>
