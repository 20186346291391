<template>
  <div :class="loading ? 'fill-height' : ''">
    <slot name="header"></slot>

    <v-container v-if="loading" fluid class="py-2 mx-0 px-4 fill-height">
      <one-page-section>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate color="grey" />
        </v-col>
      </one-page-section>
    </v-container>

    <v-slide-y-reverse-transition>
      <v-container fluid class="py-2 mx-0 px-4" v-show="!loading">
        <slot></slot>
      </v-container>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import OnePageSection from '@/component/Page/section-page';

export default {
  name: 'OnePage',
  components: { OnePageSection },
  props: { loading: Boolean },
};
</script>
