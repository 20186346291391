<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader text="Dodaj podwykonawcę" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text>
          <v-form class="d-flex flex-column" ref="form" style="row-gap: 16px">
            <v-autocomplete
              v-model="contractorEntity.contractor"
              label="Firma transportowa"
              :loading="companiesListLoading"
              :items="companiesToAddAsContractors"
              item-text="name"
              item-value="@id"
              hide-details="auto"
              prepend-inner-icon="mdi-domain"
              outlined
              :rules="[rules.required]"
              data-testid="add-transport-company-contractor-input"
            >
              <template #append-item>
                <AddItemButton @click="openCreateCompanyModal">Dodaj firmę transportową</AddItemButton>
              </template>
            </v-autocomplete>
            <!-- <section> -->
            <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
            <v-radio-group class="mt-0" v-model="contractorEntity.contractorOf" mandatory hide-details="auto">
              <v-radio class="mb-4" value="transport_company">
                <template #label>
                  <p class="text-body-2 mb-0 grey--text text--darken-4" v-html="transportCompanyContractorLabel"></p>
                </template>
              </v-radio>
              <v-radio value="railway_company">
                <template #label>
                  <p class="text-body-2 mb-0 grey--text text--darken-4" v-html="principalCompanyContractorLabel"></p>
                </template>
              </v-radio>
              <!-- TODO: extract value to dict -->
            </v-radio-group>
            <v-radio-group
              v-if="isContractorOfRailwayCompany"
              class="mt-0 ml-10"
              v-model="contractorEntity.billingModel"
              :rules="[rules.required]"
            >
              <v-radio class="mb-4" :value="billingModelDict.ARRIVAL_AND_RETURN_TO_THE_STARTING_BASE">
                <template #label>
                  <p class="text-body-2 mb-0 grey--text text--darken-4">Dojazd i powrót do bazy startowej</p>
                </template>
              </v-radio>
              <v-radio :value="billingModelDict.BACK_TO_THE_STARTING_POINT">
                <template #label>
                  <p class="text-body-2 mb-0 grey--text text--darken-4">Powrót do miejsca początkowego (tzw. A-B-A)</p>
                </template>
              </v-radio>
            </v-radio-group>
            <v-text-field
              v-model.number="contractorEntity.waitHourRate"
              label="Cena za 1h oczekiwania"
              outlined
              type="number"
              hide-details="auto"
              :rules="[rules.isWaitHourRateValid, rules.isPositiveNumber]"
            ></v-text-field>
            <v-text-field
              v-model.number="contractorEntity.kilometerRate"
              label="Cena za 1km"
              outlined
              type="number"
              hide-details="auto"
              :rules="[rules.isKilometerRateValid, rules.isPositiveNumber]"
            ></v-text-field>
            <!-- </section> -->
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn block color="primary" @click="save">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateCompanyModal
      :value="createCompanyModal"
      :strategy="createContractorStrategy"
      @close="closeCreateCompanyModal"
    ></CreateCompanyModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import CreateCompanyModal from '@/controller/Page/page-toolbar/create-company-modal/create-company-modal';
import { BILLING_MODEL } from '@/core/dict/billing-model-dict';
import formatStake from '@/core/utils/stakeFormatter';
import formValidationRules from '@/core/utils/form-validation-rules';
import BaseTypography from '@/component/Base/base-typography';
import CreateTransportCompanyStrategy from '../create-company-modal/create-transport-company.strategy';

export default {
  name: 'AddSuperiorTransportCompanyContractorModal',
  components: {
    DialogTitleHeader,
    AddItemButton,
    CreateCompanyModal,
    BaseTypography,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    taxiCourse: {
      required: true,
    },
  },
  data() {
    return {
      contractorEntity: {
        contractor: null,
        billingModel: null,
        kilometerRate: null,
        waitHourRate: null,
        contractorOf: null,
      },
      createContractorStrategy: new CreateTransportCompanyStrategy(this.$store),
      rules: {
        required: formValidationRules.required,
        // TODO: is repeated in another modal
        isKilometerRateValid: () =>
          this.isWaitHourRateDefined ? this.isKilometerRateDefined || 'Pole jest wymagane' : true,
        isWaitHourRateValid: () =>
          this.isKilometerRateDefined ? this.isWaitHourRateDefined || 'Pole jest wymagane' : true,
        isPositiveNumber: formValidationRules.isPositiveNumber(),
      },
      createCompanyModal: false,
      billingModelDict: BILLING_MODEL,
    };
  },
  created() {
    this.$store.dispatch('fetchCompanies', true);
  },
  computed: {
    ...mapGetters(['user', 'companiesList', 'companiesListLoading', 'loggedCompany']),
    selectedRailwayCompanyName() {
      return this.selectedRailwayCompany?.name;
    },
    selectedRailwayCompany() {
      return this.loggedCompany?.principals?.find(
        ({ principal }) => principal.id === this.taxiCourse.rides[0].company.id
      )?.principal;
    },
    isContractorOfRailwayCompany() {
      return this.contractorEntity.contractorOf === 'railway_company';
    },
    company() {
      return this.isContractorOfRailwayCompany ? this.selectedRailwayCompany : this.user.company;
    },
    // TODO: rename
    companiesToAddAsContractors() {
      const companyContractorsIds = this.loggedCompany?.contractors?.map(({ contractor }) => contractor.id) ?? [];
      companyContractorsIds.push(this.loggedCompany?.id);

      const railwayCompanyContractors = this.selectedRailwayCompany?.contractors
        ?.filter((contractor) => typeof contractor !== 'string')
        ?.map(({ contractor }) => contractor.id);

      companyContractorsIds.push(...railwayCompanyContractors);

      return this.companiesList.filter((company) => !companyContractorsIds.includes(company.id));
    },
    selectedContractorName() {
      return this.companiesToAddAsContractors.find((company) => company['@id'] === this.contractorEntity.contractor)
        ?.name;
    },
    isContractorSelected() {
      return !!this.contractorEntity.contractor;
    },
    transportCompanyContractorLabel() {
      return this.isContractorSelected
        ? `<b>${this.selectedContractorName}</b> jest podwykonawcą firmy transportowej <b>${this.loggedTransportCompanyName}</b>`
        : `Podwykonawca firmy transportowej <b>${this.loggedTransportCompanyName}</b>`;
    },
    principalCompanyContractorLabel() {
      return this.isContractorSelected
        ? `<b>${this.selectedContractorName}</b> jest bezpośrednim wykonawcą dla firmy kolejowej <b>${this.selectedRailwayCompanyName}</b>`
        : `Bezpośredni wykonawca dla firmy kolejowej <b>${this.selectedRailwayCompanyName}</b>`;
    },
    loggedTransportCompanyName() {
      return this.user.company.name;
    },
    // TODO: validation of stakes is duplicated
    isKilometerRateDefined() {
      return this.contractorEntity.kilometerRate !== null && this.contractorEntity.kilometerRate !== '';
    },
    isWaitHourRateDefined() {
      return this.contractorEntity.waitHourRate !== null && this.contractorEntity.waitHourRate !== '';
    },
    isStakesDefined() {
      return this.isKilometerRateDefined && this.isWaitHourRateDefined;
    },
    contractorPayload() {
      const { kilometerRate, waitHourRate, ...contractorPayload } = this.contractorEntity;

      if (this.isStakesDefined) {
        return {
          ...contractorPayload,
          stakes: [
            {
              kilometerRate: formatStake(kilometerRate),
              waitHourRate: formatStake(waitHourRate),
              validFrom: dayjs().format('YYYY-MM-DD'),
            },
          ],
        };
      }

      return contractorPayload;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    openCreateCompanyModal() {
      this.createCompanyModal = true;
    },
    closeCreateCompanyModal() {
      this.createCompanyModal = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/addContractor', {
            contractor: this.contractorPayload,
            // TODO: change
            company: this.company,
          })
          .then(() => {
            this.emitCloseEvent();
          });
      }
    },
  },
  watch: {
    contractorEntity: {
      handler(newVal) {
        if (newVal.contractorOf === 'transport_company') {
          this.contractorEntity.billingModel = BILLING_MODEL.NOT_AVAILABLE;
        } else {
          this.contractorEntity.billingModel =
            this.contractorEntity.billingModel === BILLING_MODEL.NOT_AVAILABLE ? null : newVal.billingModel;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO: styles are repeated in a lot of places
.switch__label {
  color: #212b36;

  /* Body2 */
  // font: ;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
}
</style>
