import { RailwayCompanyAbstractCourseMapper } from './railway-company-abstract-course.mapper';
import { DefaultAbstractCourseMapper } from './default-abstract-course.mapper';

// eslint-disable-next-line import/prefer-default-export
export class AbstractCourseMapper {
  mapper;

  constructor(isCompany) {
    this.mapper = isCompany ? RailwayCompanyAbstractCourseMapper : DefaultAbstractCourseMapper;
  }

  toModel(course) {
    return this.mapper.toModel(course);
  }

  toDTO(course) {
    return this.mapper.toDTO(course);
  }
}
