<template>
  <v-autocomplete
    class="reset-focused"
    :prepend-icon="denseOutlined ? null : icon"
    :item-text="itemText"
    :items="items"
    :value="value"
    :clearable="clearable"
    return-object
    @input="emitChange"
    :multiple="multiple"
    :chips="multiple"
    :deletable-chips="multiple"
    :small-chips="multiple"
    :outlined="denseOutlined"
    hide-details="auto"
    :prepend-inner-icon="denseOutlined ? icon : null"
    v-bind="$attrs"
  >
    <template #label>
      <span>{{ label }}</span>
      <span v-if="required" class="red--text">*</span>
    </template>
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'AutocompleteField',
  inheritAttrs: false,
  props: {
    items: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    itemText: {
      default: 'name',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    denseOutlined: {
      type: Boolean,
      default: true,
    },
    value: {
      required: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: 'Pole wymagane',
    },
  },
  methods: {
    emitChange(company) {
      this.$emit('input', company);
    },
  },
};
</script>

<style scoped>
::v-deep.reset-focused.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: initial;
}
</style>
