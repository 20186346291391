<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <h5 class="text-h5 text-break">{{ text }}</h5>
      </v-card-title>
      <v-card-text v-if="description">{{ description }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel">{{ cancelText }}</v-btn>
        <v-btn color="error" data-cy="confirm-button" text @click="confirm">{{ confirmText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
    },
    description: {
      type: String,
    },
    confirmText: {
      type: String,
      default: 'Tak',
    },
    cancelText: {
      type: String,
      default: 'Nie',
    },
  },
  data() {
    return {
      dialog: this.showDialog,
    };
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    },
  },
  methods: {
    cancel() {
      this.$emit('confirm', false);
    },

    confirm() {
      this.$emit('confirm', true);
    },
  },
};
</script>
