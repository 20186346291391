<template>
  <v-btn icon :color="color" aria-label="Edytuj" v-bind="$attrs" v-on="$listeners"><v-icon>mdi-pencil</v-icon></v-btn>
</template>

<script>
export default {
  name: 'EditIconButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
