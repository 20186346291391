<template>
  <v-text-field v-bind="$attrs" v-on="$listeners" outlined type="number" min="0" hide-details="auto">
    <template #label>
      <span v-text="label"></span>
      <span v-if="required" class="red--text">*</span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'ReportDialogField',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
