<template>
  <v-btn :color="color" text v-bind="$attrs" v-on="$listeners">
    <v-icon v-if="prependIcon" left>{{ prependIcon }}</v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'BaseTextButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    prependIcon: {
      type: String,
      default: null,
    },
  },
};
</script>
