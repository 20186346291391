// eslint-disable-next-line import/prefer-default-export
export class DefaultAbstractCourseMapper {
  static toModel(course) {
    return course;
  }

  static toDTO(course) {
    return course;
  }
}
