<template>
  <v-card class="d-flex flex-column pa-4 mb-3" @click="courseClick" data-testid="course-card">
    <div class="course-card" :class="{ 'course-card--dense': isPassenger & isKilometerCourse }">
      <div class="font-weight-medium text-title justify-space-between d-flex pb-1">
        <div data-testid="course-title">
          <slot name="course-title"></slot>
        </div>
        <slot name="course-badge"></slot>
      </div>
      <slot name="course-subtitle"></slot>
    </div>
    <slot name="course-report"></slot>
    <ride-list class="flex-grow-1" :course="course" :rides="course.rides" @rideClick="rideClick" />
  </v-card>
</template>
<script>
import RideList from '@/component/NewCourse/List/ride-list';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { mapGetters } from 'vuex';

export default {
  name: 'AbstractCourseCard',
  components: {
    RideList,
  },
  props: {
    course: Object,
  },
  methods: {
    // TODO: make kilometerCourse work when clicked on course
    courseClick() {
      if (this.course['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE) {
        this.rideClick(this.course.rides[0]);
        return;
      }

      this.$emit('courseClick');
    },
    rideClick(ride) {
      this.$emit('rideClick', ride);
    },
  },
  computed: {
    ...mapGetters(['isPassenger', 'user', 'isTransportCompany', 'isDriver', 'isCompany']),
    isKilometerCourse() {
      return this.course['@type'] === COURSE_TYPE_DICT.KILOMETER_COURSE;
    },
  },
};
</script>
<style lang="scss">
$two-column-width: 636px;
.course-card {
  flex-basis: 64px;
}

.course-card--dense {
  flex-basis: 32px;

  @media (min-width: $two-column-width) {
    flex-basis: 64px;
  }
}

.alert__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .v-btn.v-btn--text.v-size--default {
    padding: 0;
  }

  // ? change to container query
  @media (min-width: 370px) {
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 386px) {
    .v-btn.v-btn--text.v-size--default {
      padding: 16px;
    }
  }
}

.v-alert__content .col {
  padding-left: 20px;
  padding-top: 20px;

  @media (min-width: 370px) {
    padding: 12px;
  }
}
</style>
