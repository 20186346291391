<template>
  <autocomplete-field
    item-text="name"
    :label="label"
    :items="apiCompanies"
    :icon="icon"
    :value="value"
    @input="emitChange"
    :multiple="multiple"
    :dense-outlined="denseOutlined"
    :loading="companiesLoading"
    :disabled="disabled"
    v-bind="$attrs"
  />
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';

export default {
  name: 'CompanyField',
  inheritAttrs: false,
  components: { AutocompleteField },
  props: {
    label: {
      type: String,
      default: 'Firma',
    },
    icon: {
      type: String,
      default: 'domain',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    denseOutlined: {
      type: Boolean,
      default: false,
    },
    isTransportCompany: {
      type: Boolean,
    },
    isNotTransportCompany: {
      type: Boolean,
    },
    value: {
      required: false,
    },
  },
  computed: {
    apiCompanies() {
      return this.$store.getters.companiesList?.filter((company) => {
        if (this.isTransportCompany) {
          return company.isTransportCompany;
        }

        if (this.isNotTransportCompany) {
          return !company.isTransportCompany;
        }

        return true;
      });
    },
    companiesLoading() {
      return this.$store.getters.companiesListLoading;
    },
  },
  created() {
    if (!this.apiCompanies.length) {
      this.$store.dispatch('fetchCompanies');
    }
  },
  methods: {
    emitChange(company) {
      this.$emit('input', company);
    },
  },
};
</script>
