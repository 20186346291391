// eslint-disable-next-line import/prefer-default-export
export class RemovePassengerHomeplaceStrategy {
  #store;

  #entity;

  #componentRef;

  constructor({ store, entity, componentRef }) {
    this.#store = store;
    this.#entity = entity;
    this.#componentRef = componentRef;
  }

  // eslint-disable-next-line class-methods-use-this
  execute() {
    this.#store.dispatch('passenger/removeHomeplace', this.#entity).then((val) => {
      this.#componentRef.onSetHomeplaceFromMap(val);
    });
  }
}
