// eslint-disable-next-line import/prefer-default-export
export class CreatePassengerHomeplaceStrategy {
  #store;

  #entity;

  constructor(store, entity) {
    this.#store = store;
    this.#entity = entity;
  }

  save(val) {
    return this.#store.dispatch('passenger/createHomeplace', { place: val, entity: this.#entity });
  }
}
