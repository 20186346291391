<template>
  <AbstractNotification
    icon="mdi-alert-circle-outline"
    color="#F44336"
    v-bind="$attrs"
    v-on="$listeners"
  ></AbstractNotification>
</template>

<script>
import AbstractNotification from './abstract-notification';

export default {
  name: 'ErrorNotification',
  inheritAttrs: false,
  components: {
    AbstractNotification,
  },
};
</script>
