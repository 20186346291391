<template>
  <div style="display: flex; justify-content: center">
    <apexchart v-if="series" type="donut" width="480" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ContributionOfTransportCompaniesToCosts',
  props: { dataForCharts: Array },
  mounted() {
    this.prepareData();
  },

  data() {
    return {
      series: null,
      chartOptions: {
        chart: {
          type: 'donut',
        },
        title: {
          text: 'Procentowy udział firm transportowych w kosztach',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: (val) => `${this.$options.filters.pretty_float(val)} zł`,
          },
        },
      },
    };
  },

  methods: {
    prepareData() {
      const dataForChart = {};
      this.dataForCharts.forEach(
        ({ priceByKilometers, priceByMotorway, priceByWaiting, transportCompanyName, transportCompanyId }) => {
          const totalPrice = Math.round((priceByKilometers + priceByMotorway + priceByWaiting) * 100) / 100;

          if (transportCompanyId in dataForChart) {
            dataForChart[transportCompanyId].totalPrice += totalPrice;
            return;
          }

          dataForChart[transportCompanyId] = {
            name: transportCompanyName,
            totalPrice,
          };
        }
      );

      this.series = Object.values(dataForChart).map((data) => data.totalPrice);
      this.chartOptions.labels = Object.values(dataForChart).map((data) => data.name);
    },
  },
};
</script>
