<template>
  <PlaceModal
    :title="modalTitle"
    :isEditionModal="isPlaceSelectedForEdition"
    :strategy="strategy"
    :placeEntity="placeEntity"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #form-extension="{ placeEntity, rules }">
      <v-select
        v-if="!isPlaceSelectedForEdition"
        v-model="placeEntity.type"
        :items="typeOfPlaces"
        :rules="[rules.required]"
        outlined
        hide-details="auto"
      >
        <template #label>
          <span>Typ miejsca</span>
          <span class="red--text">*</span>
        </template>
      </v-select>
    </template>
  </PlaceModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { PLACE_TYPES_DICT } from '@/core/dict/place-type-dict';
import PlaceModal from './place-modal';
import CreatePlaceStrategy from './create-place-strategy';
import EditPlaceStrategy from './edit-place-strategy';
import CreatePassengerPlaceStrategy from './create-passenger-place-strategy';
import EditPassengerPlaceStrategy from './edit-passenger-place-strategy';

export default {
  name: 'PlaceModalFactory',
  components: {
    PlaceModal,
  },
  props: {
    selectedPlace: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isCompany', 'isPassenger', 'user']),
    isPlaceSelectedForEdition() {
      return !!this.selectedPlace;
    },
    modalTitle() {
      return this.isPlaceSelectedForEdition ? 'Edytuj miejsce' : 'Dodaj miejsce';
    },
    strategy() {
      if (this.isPassenger) {
        return this.isPlaceSelectedForEdition
          ? new EditPassengerPlaceStrategy(this.$store)
          : new CreatePassengerPlaceStrategy(this.$store);
      }

      return this.isPlaceSelectedForEdition ? new EditPlaceStrategy(this.$store) : new CreatePlaceStrategy(this.$store);
    },
    placeEntity() {
      return this.isPlaceSelectedForEdition
        ? this.selectedPlace
        : {
            name: null,
            address: null,
            longitude: null,
            latitude: null,
            type: null,
            company: this.isCompany ? this.user.company : null,
            user: null,
          };
    },
    typeOfPlaces() {
      const convertArrayToObject = ([value, text]) => ({ value, text });
      return Object.entries(PLACE_TYPES_DICT).map(convertArrayToObject);
    },
  },
};
</script>
