<template>
  <v-simple-table :class="['settlement-table', color && `settlement-table--${color}`]">
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'SettlementTable',
  props: {
    color: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
$max-drawer-width: 1300px;

.settlement-table {
  table {
    @media (min-width: $max-drawer-width) {
      background-color: #f5f5f5;
    }
  }

  &--white table {
    background-color: #fff;
  }

  &__link {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 18px;
    color: #007dff;
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }

  th,
  td {
    width: 78px;
    height: 36px !important;
    word-wrap: break-word;
    white-space: normal;
    vertical-align: baseline;
  }

  th {
    padding: 0 !important;
    padding-bottom: 10px !important;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 18px;
  }

  td {
    padding: 10px 0 !important;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;

    word-break: break-word;
  }

  th:not(:first-of-type),
  td:not(:first-of-type) {
    text-align: right !important;
  }

  tfoot td {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
