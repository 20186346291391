import { cloneDeep } from 'lodash';

export default class TaxiCourseAdapter {
  constructor(payload) {
    this.payload = payload;
  }

  // segment consist of two points A and B together they create a segment
  // waypoint is all points from which segment consist of
  // 2 segments result in structure [A, B], [B, C]
  // for purpose of UI rendering we need all points without duplicates
  // A, B, C
  transformSegmentsToWaypoints() {
    if (this.payload.segments.length === 0) {
      return [];
    }

    const waypoints = this.payload.segments.map((segment) => this.createWaypoint(segment));

    const lastSegment = this.payload.segments.at(-1);
    waypoints.push(this.createLastWaypoint(lastSegment));

    return cloneDeep(waypoints);
  }

  // eslint-disable-next-line class-methods-use-this
  createWaypoint(segment) {
    return {
      ...segment,
      waypointStart: {
        ...segment.waypointStart,
        time: this.formatDate(segment.waypointStart),
      },
      waypointEnd: {
        ...segment.waypointEnd,
        time: this.formatDate(segment.waypointEnd),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  formatDate(segment) {
    return segment.time?.split('+')[0];
  }

  createLastWaypoint(segment) {
    return {
      ...segment,
      id: `${segment.id}-last`,
      distance: null,
      time: null,
      estimatedDistance: null,
      estimatedTime: null,
      definedDistance: null,
      definedTime: null,
      fallbackDistance: null,
      fallbackTime: null,
      waypointStart: {
        ...segment.waypointEnd,
        time: this.formatDate(segment.waypointEnd),
      },
      position: this.payload.segments.length,
      employees: [],
    };
  }
}
