<template>
  <!-- todo: refactor off v-app-bar restricted by docs -->
  <v-app-bar app color="#3B873E" height="64">
    <hgroup>
      <p class="banner__title">Dostępna jest aktualizacja aplikacji 🚀</p>
      <p class="banner__text">Podczas aktualizacji strona zostanie wczytana ponownie</p>
    </hgroup>
    <v-spacer></v-spacer>
    <v-btn color="success" @click="$emit('click')">
      <v-icon left>mdi-refresh</v-icon>
      aktualizuj aplikację
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBanner',
};
</script>

<style scoped lang="scss">
@use '@/core/style/variables' as *;

::v-deep.v-sheet.v-app-bar.v-toolbar,
::v-deep .v-toolbar__content {
  height: unset !important;
}

::v-deep .v-toolbar__content {
  flex-direction: column;
  align-items: start;
  padding: 14px 24px;
  row-gap: 5px;

  @media (min-width: $update-banner-breakpoint) {
    flex-direction: row;
  }
}
::v-deep .v-banner__wrapper {
  padding: 16px 24px !important;
}

@mixin text-styles($font-weight) {
  font: $font-weight 14px/16px 'Roboto';
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.banner {
  &__title {
    @include text-styles(500);
    color: #ffffff;
  }

  &__text {
    @include text-styles(400);
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
