<template>
  <component :is="currentComponent" v-bind="$attrs"></component>
</template>

<script>
import PlacesContextMenu from './places-context-menu';

export default {
  name: 'CurrentComponent',
  inheritAttrs: false,
  props: {
    type: {
      required: true,
    },
  },
  computed: {
    currentComponent() {
      let component;

      switch (this.type) {
        case 'homeplace':
        case 'favouritePlace':
          component = PlacesContextMenu;
          break;
        default:
          component = null;
          break;
      }

      return component;
    },
  },
};
</script>
