import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

// eslint-disable-next-line import/prefer-default-export
export class CopyMessageCommand {
  constructor(message) {
    this.message = message;
  }

  execute() {
    navigator.clipboard.writeText(this.message.content).then(() => {
      Vue.notify(defaultApiResultManager.then('Komentarz został skopiowany do schowka'));
    });
  }
}
