<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader text="Dodaj podwykonawcę" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text>
          <v-form class="d-flex flex-column" ref="form" style="row-gap: 16px">
            <v-autocomplete
              v-model="contractorEntity.contractor"
              label="Firma transportowa"
              :loading="companiesListLoading"
              :items="companiesToAddAsContractors"
              item-text="name"
              item-value="@id"
              hide-details="auto"
              prepend-inner-icon="mdi-domain"
              outlined
              :rules="[rules.required]"
              data-testid="add-transport-company-contractor-input"
            >
              <template #append-item>
                <AddItemButton @click="openCreateCompanyModal">Dodaj firmę transportową</AddItemButton>
              </template>
            </v-autocomplete>
            <AddStakeForm :entity.sync="contractorEntity"></AddStakeForm>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn block color="primary" @click="save">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateCompanyModal
      :value="createCompanyModal"
      :strategy="createContractorStrategy"
      @close="closeCreateCompanyModal"
    ></CreateCompanyModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import CreateCompanyModal from '@/controller/Page/page-toolbar/create-company-modal/create-company-modal';
import formatStake from '@/core/utils/stakeFormatter';
import formValidationRules from '@/core/utils/form-validation-rules';
import AddStakeForm from '../principal-modal/add-stake-form';
import CreateTransportCompanyStrategy from '../create-company-modal/create-transport-company.strategy';

export default {
  name: 'AddBaseTransportCompanyContractorModal',
  components: {
    DialogTitleHeader,
    AddItemButton,
    CreateCompanyModal,
    AddStakeForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    taxiCourse: {
      required: true,
    },
  },
  data() {
    return {
      contractorEntity: {
        contractor: null,
        kilometerRate: null,
        waitHourRate: null,
      },
      createContractorStrategy: new CreateTransportCompanyStrategy(this.$store),
      rules: {
        required: formValidationRules.required,
      },
      createCompanyModal: false,
    };
  },
  created() {
    this.$store.dispatch('fetchCompanies', true);
  },
  computed: {
    ...mapGetters(['user', 'companiesList', 'companiesListLoading', 'loggedCompany']),
    selectedRailwayCompany() {
      return this.$store.getters['courseCreation/courseCompany'];
    },
    // TODO: rename
    companiesToAddAsContractors() {
      const companyContractorsIds = this.loggedCompany?.contractors?.map(({ contractor }) => contractor.id) ?? [];
      companyContractorsIds.push(this.loggedCompany?.id);

      return this.companiesList.filter((company) => !companyContractorsIds.includes(company.id));
    },
    // TODO: validation of stakes is duplicated
    isKilometerRateDefined() {
      return this.contractorEntity.kilometerRate !== null && this.contractorEntity.kilometerRate !== '';
    },
    isWaitHourRateDefined() {
      return this.contractorEntity.waitHourRate !== null && this.contractorEntity.waitHourRate !== '';
    },
    isStakesDefined() {
      return this.isKilometerRateDefined && this.isWaitHourRateDefined;
    },
    contractorPayload() {
      const { kilometerRate, waitHourRate, ...contractorPayload } = this.contractorEntity;

      if (this.isStakesDefined) {
        return {
          ...contractorPayload,
          stakes: [
            {
              kilometerRate: formatStake(kilometerRate),
              waitHourRate: formatStake(waitHourRate),
              validFrom: dayjs().format('YYYY-MM-DD'),
            },
          ],
        };
      }

      return contractorPayload;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    openCreateCompanyModal() {
      this.createCompanyModal = true;
    },
    closeCreateCompanyModal() {
      this.createCompanyModal = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/addContractor', {
            contractor: this.contractorPayload,
            // TODO: change
            company: this.user.company,
          })
          .then(() => {
            this.emitCloseEvent();
          });
      }
    },
  },
};
</script>
