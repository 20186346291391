import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { API_COURSE_RIDE_DICT } from '@/core/dict/course-dict';
import { EVENTS, eventBus } from '@/core/dict/events-dict';
import { getStatusForTransferredRide } from '@/core/shared/status-picker';
import { cloneDeep } from 'lodash';

export default {
  transferRide({ target, transferSubject, count }) {
    const targetRides = cloneDeep(target.rides);

    targetRides.push({ ...transferSubject, status: getStatusForTransferredRide(target) });
    const payload = targetRides
      .sort((a, b) => new Date(a.segments[0].waypointStart.time) - new Date(b.segments[0].waypointStart.time))
      .map((ride, index) => ({
        id: ride['@id'],
        type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
        position: index,
        status: ride.status,
      }));

    return Vue.http
      .put(target['@id'].substring(1), {
        rides: payload,
      })
      .then(() => {
        Vue.notify(
          defaultApiResultManager.then(
            `Przejazd o numerze ${transferSubject.number} został połączony z przejazdami: ${target.rides
              .map((ride) => ride.number)
              .join(',')}, w kursie macierzystym pozostało ${count} przejazdów`
          )
        );
      });
  },

  extractRide(ride) {
    return Vue.http
      .post(`api/taxi_courses`, {
        rides: [
          {
            id: ride['@id'],
            type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
            status: TAXI_COURSE_RIDE_STATUS.PLANNING,
          },
        ],
      })
      .then(({ data }) => {
        Vue.notify(
          defaultApiResultManager.then(`Przejazd o numerze ${ride.number} został przeniesiony do nowego kursu`)
        );
        eventBus.$emit(EVENTS.SHOW_COURSE_DRAWER, data);
      });
  },
};
