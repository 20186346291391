<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader text="Dodaj obsługiwaną firmę" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text class="pt-0 pb-6 px-6">
          <v-form ref="form">
            <autocomplete-field
              class="mb-6"
              label="Firma kolejowa"
              :items="principals"
              :loading="railwayCompaniesLoading"
              v-model="companyContractor.principal"
              icon="mdi-train"
              :rules="[rules.required]"
            >
              <template #append-item>
                <AddItemButton @click="openCreateCompanyModal">Dodaj firmę kolejową</AddItemButton>
              </template>
            </autocomplete-field>
            <AddStakeForm :entity.sync="companyContractor"></AddStakeForm>
            <BillingModelSection :billingModel.sync="companyContractor.billingModel"></BillingModelSection>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn color="primary" block @click="addPrincipal">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateCompanyModal
      :value="createCompanyModal"
      :strategy="createRailwayCompany"
      data-testid="create-railway-company-modal"
      @close="closeCreateCompanyModal"
    ></CreateCompanyModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import { mapGetters } from 'vuex';
import formatStake from '@/core/utils/stakeFormatter';
import formValidationRules from '@/core/utils/form-validation-rules';
import CreateCompanyModal from '../create-company-modal/create-company-modal';
import CreateRailwayCompanyStrategy from '../create-company-modal/create-railway-company.strategy';
import AddStakeForm from './add-stake-form';
import BillingModelSection from './billing-model-section';

export default {
  name: 'AddPrincipalModal',
  components: {
    DialogTitleHeader,
    AutocompleteField,
    AddItemButton,
    CreateCompanyModal,
    AddStakeForm,
    BillingModelSection,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      createCompanyModal: false,
      companyContractor: {
        principal: null,
        waitHourRate: null,
        kilometerRate: null,
        billingModel: null,
      },

      rules: {
        required: formValidationRules.required,
      },
      createRailwayCompany: new CreateRailwayCompanyStrategy(this.$store),
    };
  },
  created() {
    this.$store.dispatch('fetchRailwayCompanies');
  },
  computed: {
    ...mapGetters(['railwayCompanies', 'railwayCompaniesLoading', 'loggedCompany']),
    principals() {
      const principalsOfLoggedTransportCompany =
        this.loggedCompany?.principals?.map((principal) => principal.principal.id) ?? [];

      return this.railwayCompanies.filter(
        (railwayCompany) => !principalsOfLoggedTransportCompany.includes(railwayCompany.id)
      );
    },
    isKilometerRateDefined() {
      return this.companyContractor.kilometerRate !== null && this.companyContractor.kilometerRate !== '';
    },
    isWaitHourRateDefined() {
      return this.companyContractor.waitHourRate !== null && this.companyContractor.waitHourRate !== '';
    },
    isStakesDefined() {
      return this.isKilometerRateDefined && this.isWaitHourRateDefined;
    },
    companyContractorPayload() {
      const payload = {
        principal: this.companyContractor.principal['@id'],
        billingModel: this.companyContractor.billingModel,
      };

      if (this.isStakesDefined) {
        payload.stakes = [
          {
            kilometerRate: formatStake(this.companyContractor.kilometerRate),
            waitHourRate: formatStake(this.companyContractor.waitHourRate),
            validFrom: dayjs().format('YYYY-MM-DD'),
          },
        ];
      }

      return payload;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    openCreateCompanyModal() {
      this.createCompanyModal = true;
    },
    closeCreateCompanyModal() {
      this.createCompanyModal = false;
    },
    addPrincipal() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/addPrincipal', {
            companyContractor: this.companyContractorPayload,
            company: this.loggedCompany,
          })
          .then(() => this.emitCloseEvent());
      }
    },
  },
};
</script>
