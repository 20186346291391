<template>
  <v-col :class="sizeableClasses">
    <v-text-field
      :value="value"
      @input="input"
      :autofocus="autofocus"
      :filled="filled"
      :clearable="clearable"
      :color="color"
      :dark="dark"
      :disabled="disabled"
      :error="error"
      :flat="flat"
      :full-width="fullWidth"
      :height="height"
      :prepend-icon="icon"
      :label="label"
      :loading="loading"
      :outline="outline"
      :placeholder="placeholder"
      :readonly="readonly"
      :solo="solo"
      :success="success"
      :type="secure ? 'password' : 'text'"
      hide-details
      :append-icon="secure ? 'visibility' : 'visibility_off'"
      @click:append="secure = !secure"
    />
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';

export default {
  name: 'OneFieldPassword',
  mixins: [sizeable],
  props: {
    autofocus: Boolean,
    filled: Boolean,
    clearable: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    error: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    height: null,
    icon: String,
    label: String,
    value: String,
    loading: Boolean,
    outline: Boolean,
    placeholder: String,
    readonly: Boolean,
    solo: Boolean,
    success: Boolean,
    type: String,
  },
  data() {
    return {
      secure: true,
    };
  },
  methods: {
    input(val) {
      this.$emit('input', val);
    },
  },
};
</script>
