<template>
  <AbstractLocomotiveField
    v-bind="$attrs"
    v-on="$listeners"
    clearable
    data-testid="non-editable-locomotive-input"
  ></AbstractLocomotiveField>
</template>

<script>
import AbstractLocomotiveField from '@/component/Field/Locomotive/abstract-locomotive-field';

export default {
  name: 'LocomotiveField',
  components: { AbstractLocomotiveField },
};
</script>
