<template>
  <v-switch class="mt-0 pt-0" hide-details="auto" :input-value="value" v-bind="$attrs" v-on="$listeners">
    <template #label>
      <p class="text-body-2 mb-0 grey--text text--darken-4">
        <slot></slot>
      </p>
    </template>
  </v-switch>
</template>

<script>
export default {
  name: 'SwitchField',
  model: {
    event: 'change',
    prop: 'value',
  },
  props: ['value'],
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped></style>
