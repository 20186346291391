<template>
  <AutocompleteField
    label="Pracodawca"
    :items="items"
    :loading="itemsLoading"
    v-bind="$attrs"
    v-on="$listeners"
  ></AutocompleteField>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { mapGetters } from 'vuex';

export default {
  name: 'EmployerField',
  inheritAttrs: false,
  components: {
    AutocompleteField,
  },
  created() {
    if (this.isTransportCompany) {
      this.$store.dispatch('fetchCompanyById', this.user.company.id);
    } else {
      this.$store.dispatch('fetchRailwayCompanies');
    }
  },
  computed: {
    ...mapGetters([
      'isTransportCompany',
      'user',
      'railwayCompaniesLoading',
      'loggedCompanyLoading',
      'loggedCompany',
      'railwayCompanies',
    ]),
    itemsLoading() {
      return this.isTransportCompany ? this.loggedCompanyLoading : this.railwayCompaniesLoading;
    },
    items() {
      return this.isTransportCompany ? this.principalsOfLoggedCompany : this.railwayCompanies;
    },
    principalsOfLoggedCompany() {
      return this.loggedCompany?.principals
        ?.map(({ principal }) => principal)
        ?.filter((company) => !company.isTransportCompany);
    },
  },
};
</script>
