<template>
  <component :is="currentComponent" :taxiCourse="taxiCourse" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import EditableContractorInput from './editable-contractor-input';
import NonEditableContractorInput from './non-editable-contractor-input';
import CourseEditionContractorInput from './course-edition-contractor-input';

export default {
  name: 'ContractorInputFactory',
  inheritAttrs: false,
  props: {
    taxiCourse: {
      required: true,
    },
    isCourseEdition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isTransportCompany', 'isCompany']),
    currentComponent() {
      if (this.isCourseEdition) {
        return CourseEditionContractorInput;
      }

      if (this.isCompany || this.isTransportCompany) {
        return EditableContractorInput;
      }

      return NonEditableContractorInput;
    },
  },
};
</script>
