<template>
  <AbstractContractorInput
    :items="contractors"
    :loading="loggedCompanyLoading"
    v-bind="$attrs"
    v-on="$listeners"
    @input="emitContractor"
  ></AbstractContractorInput>
</template>

<script>
import { intersectionBy } from 'lodash';
import { mapGetters } from 'vuex';
import AbstractContractorInput from './abstract-contractor-input';

export default {
  name: 'CourseEditionContractorInput',
  inheritAttrs: false,
  components: {
    AbstractContractorInput,
  },
  props: {
    taxiCourse: {
      required: true,
    },
  },
  async created() {
    if (this.isManager) {
      const companiesRequests = [];
      this.companiesLoading = true;
      this.taxiCourse.rides.forEach((ride) => {
        companiesRequests.push(this.$store.dispatch('fetchRailwayCompanyById', ride.company.id));
      });

      await Promise.all(companiesRequests).finally(() => {
        this.companiesLoading = false;
      });
    }

    if (this.isCompany) {
      this.$store.dispatch('fetchRailwayCompanyById', this.taxiCourse.rides[0].company.id);
    }

    if (this.isTransportCompany) {
      this.$store.dispatch('fetchCompanyById', this.user.company.id);
    }
  },
  data() {
    return {
      companiesLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'isManager',
      'isCompany',
      'isTransportCompany',
      'user',
      'railwayCompanies',
      'loggedCompany',
      'loggedCompanyLoading',
    ]),
    selectedRailwayCompany() {
      return this.loggedCompany?.principals?.find(
        ({ principal }) => principal.id === this.taxiCourse.rides[0].company.id
      )?.principal;
    },
    idsOfCompaniesInCourse() {
      return this.taxiCourse.rides.map(({ company }) => company.id);
    },
    isLoggedTransportCompanyIsSuperior() {
      const superiorCompanies = [];

      this.loggedCompany?.principals?.forEach((companyContractor) => {
        if (this.idsOfCompaniesInCourse.includes(companyContractor.principal.id)) {
          if (companyContractor.superior) {
            superiorCompanies.push(companyContractor.principal.id);
          }
        }
      });

      return this.idsOfCompaniesInCourse.length === superiorCompanies.length;
    },
    contractorsForManager() {
      if (this.companiesLoading) {
        return [];
      }

      const contractorsOfAllCompaniesInCourse = this.railwayCompanies
        .filter((company) => this.idsOfCompaniesInCourse.includes(company.id))
        .map((companyContractor) => companyContractor.contractors)
        .map((companyContractor) => companyContractor.map(({ contractor }) => contractor));

      return intersectionBy(...contractorsOfAllCompaniesInCourse, 'id');
    },
    contractorsForCompany() {
      if (this.companiesLoading) {
        return [];
      }

      return this.railwayCompanies
        .filter((company) => this.idsOfCompaniesInCourse.includes(company.id))
        .map((companyContractor) => companyContractor.contractors)
        .map((companyContractor) => companyContractor.map(({ contractor }) => contractor))
        .at(0);
    },
    contractorsForBaseTransportCompany() {
      if (this.loggedCompany) {
        return [
          this.loggedCompany,
          ...this.loggedCompany.contractors.map((companyContractor) => companyContractor.contractor),
        ];
      }
      return [];
    },
    contractorsForSuperiorTransportCompany() {
      if (this.loggedCompany) {
        return [
          this.loggedCompany,
          ...this.loggedCompany.contractors.map((companyContractor) => companyContractor.contractor),
          ...intersectionBy(
            ...this.loggedCompany.principals
              .filter(({ principal }) => this.idsOfCompaniesInCourse.includes(principal.id))
              .map((companyContractor) => companyContractor.principal.contractors)
              .map((contractors) => contractors.filter((companyContractor) => typeof companyContractor !== 'string'))
              .map((companyContractor) => companyContractor.map(({ contractor }) => contractor)),
            'id'
          ),
        ];
      }

      return [];
    },
    contractors() {
      if (this.isManager) {
        return this.contractorsForManager;
      }

      if (this.isCompany) {
        return this.contractorsForCompany;
      }

      if (this.isTransportCompany && !this.isLoggedTransportCompanyIsSuperior) {
        return this.contractorsForBaseTransportCompany;
      }

      if (this.isTransportCompany && this.isLoggedTransportCompanyIsSuperior) {
        return this.contractorsForSuperiorTransportCompany;
      }
      return [];
    },
  },
  methods: {
    emitContractor(val) {
      if (val === null) {
        this.$emit('update:contractor', null);
        this.$emit('update:subcontractor', null);
      } else if (this.isLoggedTransportCompanyIsSuperior && this.isContractorOfRailwayCompany(val.id)) {
        this.$emit('update:contractor', val['@id']);
        this.$emit('update:subcontractor', null);
      } else if (this.isTransportCompany) {
        this.$emit('update:contractor', this.loggedCompany['@id']);
        this.$emit('update:subcontractor', this.loggedCompany['@id'] !== val['@id'] ? val['@id'] : null);
      } else {
        this.$emit('update:contractor', val['@id']);
        this.$emit('update:subcontractor', null);
      }
    },
    isContractorOfRailwayCompany(contractorId) {
      return !!this.selectedRailwayCompany?.contractors
        ?.filter((contractor) => typeof contractor !== 'string')
        .find((companyContractor) => companyContractor.contractor.id === contractorId);
    },
  },
};
</script>
