<template>
  <one-page :loading="loading">
    <v-container class="ma-0 pa-0 fill-height" fluid>
      <v-row>
        <v-col md="6" cols="12">
          <chart-total-costs-grouped-by-transport-company v-if="dataForCharts" :dataForCharts="dataForCharts" />
        </v-col>
        <v-col md="6" cols="12">
          <contribution-of-transport-companies-to-costs
            v-if="dataForCharts"
            :dataForCharts="dataForCharts"
          ></contribution-of-transport-companies-to-costs>
        </v-col>
        <v-col md="12" cols="12" class="pt-12">
          <h4>Tabela z kosztami cząstkowymi</h4>
          <one-data-table v-if="headersForSummaryTable" :headers="headersForSummaryTable" :items="dataForSummaryTable">
            <template #item="props">
              <tr :class="props.item.class">
                <td>
                  <span>{{ props.item.name }}</span>
                </td>
                <td v-for="(month, i) in months" :key="i">
                  <div v-if="props.item.priceByKilometers[i]">
                    Koszt za wykonane kilometry:
                    <b>{{ props.item.priceByKilometers[i] | pretty_float }} zł</b>
                  </div>
                  <div v-if="props.item.priceByWaiting[i]">
                    Koszt za oczekiwania:
                    <b>{{ props.item.priceByWaiting[i] | pretty_float }} zł</b>
                  </div>
                  <div v-if="props.item.priceByMotorway[i]">
                    Koszt za autostrady:
                    <b>{{ props.item.priceByMotorway[i] | pretty_float }} zł</b>
                  </div>
                </td>
              </tr>
            </template>
          </one-data-table>
        </v-col>
        <v-col v-if="transportCosts" class="pt-12" cols="12">
          <h2>Tablica kosztów dla poszczególnych firm transportowych</h2>
          <v-subheader class="pa-0" style="height: unset">
            Tablica zawiera firmy realizujące już w historii zlecenia i jest odświeżana co 1h
          </v-subheader>
          <one-field-search label="Szukaj" v-model="search" class="px-0 col-sm-12 col-md-4 col-xl-3" />
          <one-data-table :headers="transportCostsTableHeaders" :items="transportCosts" :search="search">
            <template #item="props">
              <tr :class="props.item.class">
                <td>
                  <span>{{ props.item.transportCompany.name }}</span>
                </td>
                <td>
                  <number-input
                    @change="priceWasChanged(props.item)"
                    v-model="props.item.priceByKilometer"
                    placeholder="Cena za km (zł)"
                    :min="0"
                    :step="0.01"
                    class="w100 in-list-input"
                  ></number-input>
                </td>
                <td>
                  <number-input
                    @change="priceWasChanged(props.item)"
                    v-model="props.item.priceByWaiting"
                    placeholder="Cena za 1 godzinę oczekiwania (zł)"
                    :min="0"
                    rounded
                    class="w100 in-list-input"
                  ></number-input>
                </td>
              </tr>
            </template>
          </one-data-table>
        </v-col>
      </v-row>
    </v-container>
  </one-page>
</template>

<script>
import OneFieldSearch from '@/component/Field/Search/search-field';
import OneDataTable from '@/component/Data/data-table';
import ContributionOfTransportCompaniesToCosts from '@/component/Charts/chart-contribution-of-transport-companies-to-costs';
import OnePage from '@/component/Page/page-index';
import ChartTotalCostsGroupedByTransportCompany from '@/component/Charts/chart-total-costs-grouped-by-transport-company';

export default {
  components: {
    ChartTotalCostsGroupedByTransportCompany,
    OnePage,
    ContributionOfTransportCompaniesToCosts,
    OneDataTable,
    OneFieldSearch,
  },
  created() {
    this.fetch();
    this.fetchDataToCharts();
  },

  data() {
    return {
      loading: true,
      transportCosts: [],
      search: '',
      transportCostsTableHeaders: [
        {
          text: 'Firma transportowa',
          value: 'transportCompany.name',
          sortable: true,
        },
        {
          text: 'Cena za kilometr (zł)',
          value: 'priceByKilometer',
          sortable: true,
        },
        {
          text: 'Cena za 1 godzinę oczekiwania (zł)',
          value: 'priceByWaiting',
          sortable: true,
        },
      ],
      headersForSummaryTable: null,
      dataForCharts: null,
    };
  },

  methods: {
    fetch() {
      this.$http.get('api/company_transport_costs').then(({ data }) => {
        this.transportCosts = data['hydra:member'].map((transportCost) => {
          // obejscie buga we wtyczce vue-number-input
          // eslint-disable-next-line no-param-reassign
          transportCost.priceByKilometer =
            transportCost.priceByKilometer === null ? NaN : transportCost.priceByKilometer;
          // eslint-disable-next-line no-param-reassign
          transportCost.priceByWaiting = transportCost.priceByWaiting === null ? NaN : transportCost.priceByWaiting;

          return transportCost;
        });

        this.archivedTransportCosts = this.transportCosts.map((obj) => ({
          ...obj,
        }));
        this.loading = false;
      });
    },

    fetchDataToCharts() {
      this.$http.get('statistics/all-costs-from-accepted-and-finished-courses').then(({ status, data }) => {
        if (status === 200) {
          this.dataForCharts = data;
          this.prepareDataForSummaryTable();
        }
      });
    },

    priceWasChanged(transportCost) {
      const oldTransportCost = this.archivedTransportCosts.find(
        (archivedTransportCost) => archivedTransportCost.id === transportCost.id
      );

      if (this.isEntityChanged(transportCost, oldTransportCost)) {
        // eslint-disable-next-line no-param-reassign
        transportCost.priceByKilometer = Math.round(transportCost.priceByKilometer * 100) / 100;
        oldTransportCost.priceByKilometer = transportCost.priceByKilometer;
        oldTransportCost.priceByWaiting = transportCost.priceByWaiting;

        this.updatePrice(transportCost['@id'].substring(1), {
          priceByKilometer: transportCost.priceByKilometer,
          priceByWaiting: transportCost.priceByWaiting,
        });
      }
    },

    updatePrice(url, data) {
      this.$http
        .put(url, data)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              group: 'global',
              type: 'success',
              text: 'Zmiany zostały zapisane',
            });
          }
        })
        .catch((response) => {
          const backendInfo = response.status < 500 ? `: ${response.data['hydra:description']}` : '';
          this.$notify({
            group: 'global',
            type: 'error',
            title: 'Błąd edycji',
            text: `Wystąpił błąd edycji${backendInfo}`,
          });
        });
    },

    isEntityChanged(transportCost, oldTransportCost) {
      return (
        (transportCost.priceByKilometer !== oldTransportCost.priceByKilometer &&
          !isNaN(transportCost.priceByKilometer)) ||
        (transportCost.priceByWaiting !== oldTransportCost.priceByWaiting && !isNaN(transportCost.priceByWaiting))
      );
    },

    prepareDataForSummaryTable() {
      this.months = this.dataForCharts
        .map((oneMonthSummary) => this.upperFirstLetter(this.getMonthNameFromIdx(oneMonthSummary.month)))
        .filter((x, i, a) => a.indexOf(x) === i);

      this.monthsIdx = this.dataForCharts
        .map((oneMonthSummary) => oneMonthSummary.month)
        .filter((x, i, a) => a.indexOf(x) === i);

      const dataForTable = {};
      this.dataForCharts.forEach(
        ({ priceByKilometers, priceByMotorway, priceByWaiting, transportCompanyName, transportCompanyId, month }) => {
          if (transportCompanyId in dataForTable) {
            dataForTable[transportCompanyId].tmpPriceByKilometers.set(month, priceByKilometers);
            dataForTable[transportCompanyId].tmpPriceByWaiting.set(month, priceByWaiting);
            dataForTable[transportCompanyId].tmpPriceByMotorway.set(month, priceByMotorway);
            return;
          }
          dataForTable[transportCompanyId] = {
            name: transportCompanyName,
            tmpPriceByKilometers: this.getMapWithKeyAndVal(month, priceByKilometers),
            tmpPriceByWaiting: this.getMapWithKeyAndVal(month, priceByWaiting),
            tmpPriceByMotorway: this.getMapWithKeyAndVal(month, priceByMotorway),
          };
        }
      );

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in dataForTable) {
        this.monthsIdx.forEach((month) => {
          this.setNewMapIfDontExist(dataForTable[key], 'priceByKilometers');
          this.setNewMapIfDontExist(dataForTable[key], 'priceByWaiting');
          this.setNewMapIfDontExist(dataForTable[key], 'priceByMotorway');

          dataForTable[key].priceByKilometers.set(
            month,
            dataForTable[key].tmpPriceByKilometers.has(month) ? dataForTable[key].tmpPriceByKilometers.get(month) : null
          );
          dataForTable[key].priceByWaiting.set(
            month,
            dataForTable[key].tmpPriceByWaiting.has(month) ? dataForTable[key].tmpPriceByWaiting.get(month) : null
          );
          dataForTable[key].priceByMotorway.set(
            month,
            dataForTable[key].tmpPriceByMotorway.has(month) ? dataForTable[key].tmpPriceByMotorway.get(month) : null
          );
        });

        dataForTable[key].priceByKilometers = Array.from(dataForTable[key].priceByKilometers.values());
        dataForTable[key].priceByWaiting = Array.from(dataForTable[key].priceByWaiting.values());
        dataForTable[key].priceByMotorway = Array.from(dataForTable[key].priceByMotorway.values());
      }

      this.dataForSummaryTable = Object.values(dataForTable);

      const headers = [];
      this.months.forEach((month) => headers.push({ text: month, sortable: false }));
      headers.unshift({ text: 'Firma transportowa', sortable: false });

      this.headersForSummaryTable = headers;
    },

    setNewMapIfDontExist(object, key) {
      if (!(key in object)) {
        // eslint-disable-next-line no-param-reassign
        object[key] = new Map();
      }
    },

    getMapWithKeyAndVal(key, val) {
      const obj = new Map();
      obj.set(key, val);

      return obj;
    },

    getMonthNameFromIdx(idx) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(idx - 1);

      return date.toLocaleString('pl-PL', { month: 'long' });
    },

    upperFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
};
</script>
