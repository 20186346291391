<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import EditableLocomotiveField from './editable-locomotive-field';
import LocomotiveField from './locomotive-field';

export default {
  name: 'LocomotiveFieldFactory',
  components: {
    EditableLocomotiveField,
    LocomotiveField,
  },
  computed: {
    userWithPermissionToEditLocomotive() {
      return this.$store.getters.isManager || this.$store.getters.isCompany || this.$store.getters.isTransportCompany;
    },
    currentComponent() {
      return this.userWithPermissionToEditLocomotive ? EditableLocomotiveField : LocomotiveField;
    },
  },
};
</script>
