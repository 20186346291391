<template>
  <v-col :class="sizeableClasses">
    <v-card>
      <slot></slot>
    </v-card>
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';

export default {
  name: 'OnePageCard',
  mixins: [sizeable],
};
</script>
