<template>
  <apexchart v-if="series" type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
  name: 'ChartTotalCostsGroupedByTransportCompany',
  props: { dataForCharts: Array },
  data() {
    return {
      series: null,
      chartOptions: {
        title: {
          text: 'Podsumowanie kosztów miesięcznych z podziałem na firmy transportowe',
          align: 'center',
        },
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {},
        yaxis: {
          title: {
            text: 'Wygenerowane koszty (zł)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${this.$options.filters.pretty_float(val)} zł`,
          },
        },
      },
    };
  },

  mounted() {
    this.prepareData();
  },

  methods: {
    getMonthNameFromIdx(idx) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(idx - 1);

      return date.toLocaleString('pl-PL', { month: 'long' });
    },

    upperFirstLetter(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },

    prepareData() {
      this.months = this.dataForCharts
        .map((oneMonthSummary) => this.upperFirstLetter(this.getMonthNameFromIdx(oneMonthSummary.month)))
        .filter((x, i, a) => a.indexOf(x) === i);

      this.monthsIdx = this.dataForCharts
        .map((oneMonthSummary) => oneMonthSummary.month)
        .filter((x, i, a) => a.indexOf(x) === i);

      const dataForChart = {};
      this.dataForCharts.forEach(
        ({ priceByKilometers, priceByMotorway, priceByWaiting, transportCompanyName, transportCompanyId, month }) => {
          const totalPrice = Math.round((priceByKilometers + priceByMotorway + priceByWaiting) * 100) / 100;
          if (transportCompanyId in dataForChart) {
            dataForChart[transportCompanyId].tmpData.set(month, totalPrice);
            return;
          }

          const tmpObj = new Map();
          tmpObj.set(month, totalPrice);
          dataForChart[transportCompanyId] = {
            name: transportCompanyName,
            tmpData: tmpObj,
          };
        }
      );

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in dataForChart) {
        this.monthsIdx.forEach((month) => {
          if (!('data' in dataForChart[key])) {
            dataForChart[key].data = new Map();
          }
          dataForChart[key].data.set(
            month,
            dataForChart[key].tmpData.has(month) ? dataForChart[key].tmpData.get(month) : null
          );
        });
        dataForChart[key].data = Array.from(dataForChart[key].data.values());
      }

      this.series = Object.values(dataForChart);
      this.chartOptions.xaxis.categories = this.months;
    },
  },
};
</script>
