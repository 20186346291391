<template>
  <DialogFactory :value="value" v-on="$listeners">
    <DialogTitleHeader text="Nowy kurs" @click="emitCloseEvent"></DialogTitleHeader>
    <v-card-text class="pt-4 pb-0 px-6 flex-grow-1">
      <BaseTypography variant="body-1">Wybierz dla jakiej firmy kolejowej tworzysz nowy kurs.</BaseTypography>
      <v-form ref="form" lazy-validation>
        <RailwayCompanyInputFactory v-model="selectedRailwayCompany"></RailwayCompanyInputFactory>
      </v-form>
    </v-card-text>
    <DialogSaveButton text="Potwierdź" @click="acceptSelectedRailwayCompany"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import { TAXI_COURSE_CREATE_ROUTE } from '@/core/router/route-names';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import BaseTypography from '@/component/Base/base-typography';
import RailwayCompanyInputFactory from './railway-company-input/railway-company-input-factory';

export default {
  name: 'RailwayCompanyModal',
  components: {
    DialogFactory,
    DialogTitleHeader,
    DialogSaveButton,
    BaseTypography,
    RailwayCompanyInputFactory,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRailwayCompany: null,
    };
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    acceptSelectedRailwayCompany() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('courseCreation/setCompany', this.selectedRailwayCompany);
        this.$router.push({ name: TAXI_COURSE_CREATE_ROUTE });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
