import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';
import PlaceService from '@/service/PlaceService';

const getOwnerId = ({ data, rootGetters }) => {
  if (data.type === ABSTRACT_PLACE_TYPES.HOME) {
    return rootGetters.isPassenger ? rootGetters.userId : data.place.user['@id'];
  }

  return null;
};

export default {
  namespaced: true,
  actions: {
    softDeletePlace({ commit }, place) {
      commit('setLoading', true, { root: true });
      return PlaceService.softDeletePlace(place.id)
        .then(() => {
          commit('removeAbstractPlace', place, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    save({ commit, rootGetters }, data) {
      commit('setLoading', true, { root: true });
      return PlaceService.insertPlace({
        ...data,
        owner: getOwnerId({ data, rootGetters }),
      })
        .then((place) => {
          commit('addAbstractPlace', place, { root: true });
          return place;
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    async addPlaceToUser(_, { data, place }) {
      if (data.type === ABSTRACT_PLACE_TYPES.HOME && data.place.user) {
        await PlaceService.addHomeplaceToUser({ user: data.place.user, home: place['@id'] });
      }
      return place;
    },

    async saveNewHomeplace({ dispatch }, data) {
      const isNewHomeplace = data.entity.user.home && data.entity.user.home.id === undefined;

      if (isNewHomeplace) {
        // eslint-disable-next-line no-param-reassign
        const home = await dispatch('save', {
          place: { ...data.entity.user.home, company: data.entity.user.company },
          type: ABSTRACT_PLACE_TYPES.HOME,
        });

        return {
          ...data,
          entity: {
            ...data.entity,
            user: {
              ...data.entity.user,
              home,
            },
          },
        };
      }

      return Promise.resolve(data);
    },

    savePassengerPlace({ commit, dispatch, rootGetters }, data) {
      commit('setLoading', true, { root: true });
      return PlaceService.insertPlace({
        ...data,
        owner: getOwnerId({ data, rootGetters }),
      })
        .then(async (place) => {
          await dispatch('addAbstractPlace', place, { root: true });
          return place;
        })
        .then(async (place) => {
          if (data.type === ABSTRACT_PLACE_TYPES.HOME && data.place.user.id === rootGetters.user.id) {
            await dispatch(
              'kilometerCourse/updatePassenger',
              {
                user: {
                  id: data.place.user['@id'],
                  home: place['@id'],
                },
              },
              { root: true }
            );
          }
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    updatePassengerPlace({ commit, dispatch }, data) {
      commit('setLoading', true, { root: true });
      return PlaceService.updatePlace(data)
        .then((place) => dispatch('updateAbstractPlace', place, { root: true }))
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    update({ commit }, data) {
      commit('setLoading', true, { root: true });
      return PlaceService.updatePlace(data)
        .then((place) => {
          commit('updateAbstractPlace', place, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
