import NotificationFactory from './component/notification-factory';
import { events } from './events';

export default {
  install(Vue) {
    Vue.component('AppNotification', NotificationFactory);

    const notify = (params) => {
      events.$emit('add', params);
    };

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$notify = notify;
    // eslint-disable-next-line no-param-reassign
    Vue.notify = notify;
  },
};
