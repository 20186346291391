import {
  SET_EMPLOYEE_REQUESTS,
  ADD_EMPLOYEE_REQUEST,
  SET_UNSENT_MESSAGES,
  PUSH_UNSENT_MESSAGE,
  POP_UNSENT_MESSAGE,
  SET_FINISHED_EMPLOYEE_REQUESTS,
  SET_SELECTED_EMPLOYEE_REQUEST_ID,
  ADD_FINISHED_EMPLOYEE_REQUEST,
  SET_MERCURE_WATCH_INITIALIZED,
  SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED,
  SET_EMPLOYEE_REQUESTS_INITIALIZED,
  SET_EMPLOYEE_REQUESTS_LOADING,
  SET_FINISHED_EMPLOYEE_REQUESTS_LOADING,
  SET_LAST_UNREAD,
} from '@/core/store/modules/types/employee-request-mutation-types';
import { EMPLOYEE_REQUEST_STATUS } from '@/core/dict/employee-request-dict';
import EmployeeRequestService from '@/service/EmployeeRequestService';
import Vue from 'vue';
import router from '@/core/config/router';
import format from 'date-fns/format';

export default {
  namespaced: true,
  state: {
    mercureWatchInitialized: false,
    employeeRequests: [],
    employeeRequestsLoading: false,
    employeeRequestsInitialized: false,
    selectedEmployeeRequestId: null,
    finishedEmployeeRequests: [],
    finishedEmployeeRequestsLoading: false,
    finishedEmployeeRequestsInitialized: false,
    unsentMessages: [],
    lastUnread: null,
  },
  getters: {
    mercureWatchInitialized: (state) => state.mercureWatchInitialized,

    employeeRequests: (state) =>
      state.employeeRequests.sort(
        (a, b) => new Date(b.messages.at(-1).createdAt) - new Date(a.messages.at(-1).createdAt)
      ),

    employeeRequestsLoading: (state) => state.employeeRequestsLoading,

    employeeRequestsInitialized: (state) => state.employeeRequestsInitialized,

    selectedEmployeeRequestId: (state) => state.selectedEmployeeRequestId,

    finishedEmployeeRequests: (state) =>
      state.finishedEmployeeRequests.sort(
        (a, b) => new Date(b.messages.at(-1).createdAt) - new Date(a.messages.at(-1).createdAt)
      ),

    finishedEmployeeRequestsLoading: (state) => state.finishedEmployeeRequestsLoading,

    finishedEmployeeRequestsInitialized: (state) => state.finishedEmployeeRequestsInitialized,

    unsentMessages: (state) => state.unsentMessages,

    unreadEmployeeRequestCounter: (state, getters, rootState) => {
      let counter = 0;
      state.employeeRequests.forEach((employeeRequest) => {
        if (
          employeeRequest.messages.at(-1).createdBy.id !== rootState.user.id &&
          !employeeRequest.messages.at(-1).viewedBy.filter((user) => user.id === rootState.user.id).length
        ) {
          counter += 1;
        }
      });

      return counter;
    },

    lastUnread: (state) => state.lastUnread,
    query: (_, _2, _3, rootGetters) => {
      const TWO_MONTH_IN_DAYS = 60;
      const date = new Date();
      date.setDate(date.getDate() - TWO_MONTH_IN_DAYS);
      return rootGetters.isPassenger ? 'pagination=true' : `createdAt[after]=${format(date, 'YYYY-MM-DD')}`;
    },
    isNewEmployeeRequest: (state) => (receivedMessage) => {
      const haystackMessage = state.employeeRequests.find((request) => receivedMessage.id === request.id);
      const isNewMessagesInEmployeeRequest = haystackMessage?.messages?.length !== receivedMessage.messages.length;

      return !haystackMessage || isNewMessagesInEmployeeRequest;
    },
  },
  mutations: {
    [SET_MERCURE_WATCH_INITIALIZED]: (state, mercureWatchInitialized) => {
      state.mercureWatchInitialized = mercureWatchInitialized;
    },

    [SET_EMPLOYEE_REQUESTS]: (state, employeeRequests) => {
      state.employeeRequests = employeeRequests;
    },

    [SET_EMPLOYEE_REQUESTS_LOADING]: (state, employeeRequestsLoading) => {
      state.employeeRequestsLoading = employeeRequestsLoading;
    },

    [SET_EMPLOYEE_REQUESTS_INITIALIZED]: (state, employeeRequestsInitialized) => {
      state.employeeRequestsInitialized = employeeRequestsInitialized;
    },

    [SET_SELECTED_EMPLOYEE_REQUEST_ID]: (state, selectedEmployeeRequestId) => {
      state.selectedEmployeeRequestId = selectedEmployeeRequestId;
    },

    [SET_FINISHED_EMPLOYEE_REQUESTS]: (state, finishedEmployeeRequests) => {
      state.finishedEmployeeRequests = finishedEmployeeRequests;
    },

    [SET_FINISHED_EMPLOYEE_REQUESTS_LOADING]: (state, finishedEmployeeRequestsLoading) => {
      state.finishedEmployeeRequestsLoading = finishedEmployeeRequestsLoading;
    },

    [SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED]: (state, finishedEmployeeRequestsInitialized) => {
      state.finishedEmployeeRequestsInitialized = finishedEmployeeRequestsInitialized;
    },

    [ADD_EMPLOYEE_REQUEST]: (state, employeeRequest) => {
      const oldEmployeeRequestIndex = state.employeeRequests.findIndex(
        (haystackRequest) => haystackRequest.id === employeeRequest.id
      );
      if (oldEmployeeRequestIndex >= 0) {
        Vue.set(state.employeeRequests, oldEmployeeRequestIndex, employeeRequest);
        return;
      }

      state.employeeRequests.push(employeeRequest);
    },

    [ADD_FINISHED_EMPLOYEE_REQUEST]: (state, employeeRequest) => {
      const oldEmployeeRequestIndex = state.employeeRequests.findIndex(
        (haystackRequest) => haystackRequest.id === employeeRequest.id
      );
      if (oldEmployeeRequestIndex >= 0) {
        Vue.delete(state.employeeRequests, oldEmployeeRequestIndex);
        state.selectedEmployeeRequestId = state.employeeRequests[0]?.id;
      }

      if (
        state.finishedEmployeeRequests.findIndex((haystackRequest) => haystackRequest.id === employeeRequest.id) === -1
      ) {
        state.finishedEmployeeRequests.push(employeeRequest);
      }
    },

    [SET_UNSENT_MESSAGES]: (state, unsentMessages) => {
      state.unsentMessages = unsentMessages;
    },

    [PUSH_UNSENT_MESSAGE]: (state, message) => {
      state.unsentMessages.push(message);
    },

    [POP_UNSENT_MESSAGE]: (state, message) => {
      const index = state.unsentMessages.findIndex(
        (haystackMessage) => haystackMessage.timestamp === message.timestamp
      );

      if (index >= 0) {
        Vue.delete(state.unsentMessages, index);
      }
    },

    [SET_LAST_UNREAD]: (state, employeeRequest) => {
      state.lastUnread = employeeRequest;
    },
  },
  actions: {
    fetchEmployeeRequests({ getters, commit }) {
      if (getters.employeeRequestsInitialized) {
        return false;
      }

      commit(SET_EMPLOYEE_REQUESTS_INITIALIZED, true);
      commit(SET_EMPLOYEE_REQUESTS_LOADING, true);
      return EmployeeRequestService.fetchEmployeeRequests(EMPLOYEE_REQUEST_STATUS.OPEN, getters.query)
        .then((data) => {
          commit(SET_EMPLOYEE_REQUESTS, data);
          commit(SET_EMPLOYEE_REQUESTS_LOADING, false);
        })
        .catch(() => commit(SET_EMPLOYEE_REQUESTS_INITIALIZED, false));
    },

    selectedEmployeeRequestId({ commit }, id) {
      commit(SET_SELECTED_EMPLOYEE_REQUEST_ID, id);
    },

    fetchFinishedEmployeeRequests({ getters, commit }) {
      if (getters.finishedEmployeeRequestsInitialized) {
        return false;
      }

      commit(SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED, true);
      commit(SET_FINISHED_EMPLOYEE_REQUESTS_LOADING, true);
      return EmployeeRequestService.fetchEmployeeRequests(EMPLOYEE_REQUEST_STATUS.FINISHED, getters.query)
        .then((data) => {
          commit(SET_FINISHED_EMPLOYEE_REQUESTS, data);
          commit(SET_FINISHED_EMPLOYEE_REQUESTS_LOADING, false);
        })
        .catch(() => commit(SET_EMPLOYEE_REQUESTS_INITIALIZED, false));
    },

    watchEmployeeRequests({ dispatch, getters, commit, rootGetters }) {
      if (getters.mercureWatchInitialized) {
        return;
      }

      commit(SET_MERCURE_WATCH_INITIALIZED, true);
      rootGetters['sse/eventSource'].addEventListener('employee_request', (e) => {
        const employeeRequest = JSON.parse(e.data);
        const isEmployeeRequestListRouteOpen = router.currentRoute.name === 'employee_request_index';
        const isOpenEmployeeRequest = employeeRequest.status === EMPLOYEE_REQUEST_STATUS.OPEN;

        if (!isEmployeeRequestListRouteOpen && isOpenEmployeeRequest && getters.isNewEmployeeRequest(employeeRequest)) {
          commit(SET_LAST_UNREAD, employeeRequest);
        }

        commit(isOpenEmployeeRequest ? ADD_EMPLOYEE_REQUEST : ADD_FINISHED_EMPLOYEE_REQUEST, employeeRequest);

        if (employeeRequest.id === getters.selectedEmployeeRequestId) {
          commit(POP_UNSENT_MESSAGE, employeeRequest.messages.at(-1));
          const container = document.querySelector('#messages-box');
          container.scrollTop = container.scrollHeight;
          dispatch('viewEmployeeRequest', employeeRequest);
        }
      });
    },

    async getUnsentMessagesFromLocalStorage({ dispatch, commit, getters }) {
      commit(SET_UNSENT_MESSAGES, JSON.parse(localStorage.getItem('unsentMessages')) || []);

      for (let i = 0; i < getters.unsentMessages.length; i + 1) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch('sendMessage', getters.unsentMessages[i]);
      }
    },

    sendMessage({ dispatch, commit, getters }, message) {
      if (
        getters.unsentMessages.findIndex((haystackMessage) => haystackMessage.timestamp === message.timestamp) === -1
      ) {
        commit(PUSH_UNSENT_MESSAGE, message);
        localStorage.setItem('unsentMessages', JSON.stringify(getters.unsentMessages));
      }

      if (getters.unsentMessages.findIndex((haystackMessage) => haystackMessage.timestamp === message.timestamp)) {
        return false;
      }

      return EmployeeRequestService.sendMessage(message)
        .then(({ data }) => {
          commit(POP_UNSENT_MESSAGE, message);
          localStorage.setItem('unsentMessages', JSON.stringify(getters.unsentMessages));
          if (getters.unsentMessages.length) {
            dispatch('sendMessage', getters.unsentMessages[0]);
          }

          if (message.new) {
            EmployeeRequestService.redirectToEmployeeRequest(data.id);
          }

          return data;
        })
        .catch(async () => {
          await new Promise((r) => {
            setTimeout(r, 2000);
          });
          dispatch('sendMessage', message);
        });
    },

    viewEmployeeRequest({ commit, rootGetters }, employeeRequest) {
      commit(SET_SELECTED_EMPLOYEE_REQUEST_ID, employeeRequest.id);
      if (
        !employeeRequest.messages.at(-1).viewedBy.filter((user) => user.id === rootGetters.user.id).length &&
        employeeRequest.messages.at(-1).createdBy.id !== rootGetters.user.id
      ) {
        EmployeeRequestService.viewEmployeeRequest(employeeRequest.id);
      }
    },

    finishEmployeeRequest({ commit }, id) {
      EmployeeRequestService.finishEmployeeRequest(id).then(({ data }) => {
        commit(ADD_FINISHED_EMPLOYEE_REQUEST, data);
      });
    },

    clearLastUnread({ commit }) {
      commit(SET_LAST_UNREAD, null);
    },

    reset({ commit }) {
      commit(SET_MERCURE_WATCH_INITIALIZED, false);
      commit(SET_EMPLOYEE_REQUESTS_INITIALIZED, false);
      commit(SET_FINISHED_EMPLOYEE_REQUESTS_INITIALIZED, false);
      commit(SET_EMPLOYEE_REQUESTS, []);
      commit(SET_FINISHED_EMPLOYEE_REQUESTS, []);
      commit(SET_UNSENT_MESSAGES, []);
      commit(SET_LAST_UNREAD, null);
      localStorage.removeItem('unsentMessages');
    },
  },
};
