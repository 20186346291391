<template>
  <v-chip
    :color="statusColor"
    :disabled="disabled"
    class="taxi-course-form-status-chip pa-0"
    :class="{ 'taxi-course-form-status-chip--readonly': readonly }"
    small
  >
    <v-select
      :items="statuses"
      :rules="[(v) => !!v || 'Pole wymagane']"
      :value="value"
      background-color="none"
      append-icon="null"
      class="taxi-course-form-status-select"
      data-cy="ride-status-input"
      dense
      outlined
      placeholder="Status"
      @input="emitChange"
    >
      <template #append v-if="!readonly">
        <v-icon class="taxi-course-form-status-chip-icon">mdi-chevron-down</v-icon>
      </template>
    </v-select>
  </v-chip>
</template>

<script>
import { TAXI_COURSE_STATUS_COLOR_DICT } from '@/core/dict/taxi-course-dict';
import { KILOMETER_COURSE_COLOR_DICT } from '@/core/dict/kilometer-course-dict';

export default {
  name: 'RideStatusChipField',
  data() {
    return {
      colorsDict: this.kilometerCourse ? KILOMETER_COURSE_COLOR_DICT : TAXI_COURSE_STATUS_COLOR_DICT,
      selectedStatus: null,
    };
  },
  props: {
    value: {
      required: false,
    },
    kilometerCourse: Boolean,
    disabled: Boolean,
    statuses: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    statusColor() {
      return this.value ? this.colorsDict[this.value] : 'grey';
    },
  },
  methods: {
    emitChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss">
.taxi-course-form {
  &-status {
    &-select {
      max-height: 10px;
      font-size: 13px;

      .v-label {
        color: white;
        display: none;
      }

      input::placeholder,
      .theme--light.v-icon,
      .v-select__selection {
        color: white !important;
      }

      fieldset {
        border: none;
      }
    }

    &-chip {
      width: min-content;

      &--readonly {
        pointer-events: none;

        &:hover {
          cursor: not-allowed;
        }
      }

      .v-text-field--outlined.v-input--is-focused fieldset {
        border: none;
      }

      &-icon {
        padding-top: 12px;
        font-size: 18px !important;
      }

      .v-chip__content {
        margin-bottom: 30px;
        width: 100%;
      }
    }

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 10px;
    }

    .v-label {
      font-size: 10px;
    }
  }
}
</style>
