import { ROLES } from '@/core/dict/role-dict';

const EmployeeRequestIndex = () => import('@/controller/EmployeeRequest/employee-request-index');
const EmployeeRequestCreate = () => import('@/controller/EmployeeRequest/employee-request-create');

export default [
  {
    path: '/employee-request/:selectedEmployeeRequestId?',
    name: 'employee_request_index',
    component: EmployeeRequestIndex,
    meta: {
      title: 'Zgłoszenia',
      create: 'employee_request_create',
      roles: [ROLES.ROLE_PASSENGER, ROLES.ROLE_ADMIN],
    },
  },

  {
    path: '/employee-request/create',
    name: 'employee_request_create',
    component: EmployeeRequestCreate,
    meta: {
      title: 'Utwórz zgłoszenie',
    },
  },
];
