<template>
  <v-data-table
    :items="sorted"
    :search="search"
    :headers="headers"
    :items-per-page="1"
    :footer-props="{
      'items-per-page-options': [25, 50, 100, { text: 'Wszystko', value: -1 }],
      'items-per-page-text': 'Wyświetl:',
    }"
    :hide-default-footer="items.length === 0"
    :hide-default-header="items.length === 0"
  >
    <template #item="props">
      <slot name="item" :item="props.item"></slot>
    </template>

    <template #pageText="props">
      <b>{{ props.pageStart }} - {{ props.pageStop }}</b>
      / {{ props.itemsLength }}
    </template>

    <template #no-data>
      <v-col cols="12" class="pa-6 text-center grey--text" style="font-size: 0.9rem">Pusto</v-col>
      <v-divider />
    </template>

    <template #no-results>
      <v-col cols="12" class="pa-6 text-center grey--text" style="font-size: 0.9rem" data-testid="no-result-label">
        Brak wyników dla:
        <b>{{ search }}</b>
      </v-col>
      <v-divider />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'OneDataTable',
  props: {
    items: {
      default: () => [],
      type: Array,
    },
    search: {
      default: '',
      type: String,
    },
    headers: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    sorted: {
      get() {
        const items = this.items || [];
        items.sort((a, b) => b.id - a.id);
        return items;
      },
    },
  },
};
</script>

<style>
th {
  white-space: nowrap;
}
</style>
