<template>
  <router-link class="base__link" :to="to">{{ text }}</router-link>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    to: {
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/variables' as *;

.base__link {
  font: 600 15px/18px $base-font;
  color: $primary-color;
  text-decoration: none;
}
</style>
