import { RIDE_CARD_SETTINGS, COURSE_STATUS } from '@/core/dict/taxi-course-dict';

// eslint-disable-next-line import/prefer-default-export
export class ColumnsSettingsStorage {
  static #columnsKey = 'columnsSettings';

  static #rideCardKey = 'rideCardSettings';

  static #defaultRideCardSetting = [RIDE_CARD_SETTINGS.PASSENGERS, RIDE_CARD_SETTINGS.LOCOMOTIVES];

  static #defaultPassengerRideCardSetting = [
    RIDE_CARD_SETTINGS.INTERMEDIATE_WAYPOINTS,
    RIDE_CARD_SETTINGS.PASSENGERS,
    RIDE_CARD_SETTINGS.DISTANCE_AND_TIME,
    RIDE_CARD_SETTINGS.LOCOMOTIVES,
    RIDE_CARD_SETTINGS.FULL_WAYPOINT_ADDRESS,
  ];

  static #defaultColumnsSetting = {
    [COURSE_STATUS.REQUIRES_ATTENTION]: true,
    [COURSE_STATUS.IN_PROGRESS]: true,
    [COURSE_STATUS.FINISHED]: true,
  };

  static #setValue(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  }

  static #getValue(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  static #getDefaultRideCardSettings(isPassenger) {
    return isPassenger
      ? ColumnsSettingsStorage.#defaultPassengerRideCardSetting
      : ColumnsSettingsStorage.#defaultRideCardSetting;
  }

  static getColumnsSettings() {
    return (
      ColumnsSettingsStorage.#getValue(ColumnsSettingsStorage.#columnsKey) ||
      ColumnsSettingsStorage.#defaultColumnsSetting
    );
  }

  static getRideCardSettings(isPassenger = false) {
    return (
      ColumnsSettingsStorage.#getValue(ColumnsSettingsStorage.#rideCardKey) ||
      ColumnsSettingsStorage.#getDefaultRideCardSettings(isPassenger)
    );
  }

  static setColumnsSettings(val) {
    ColumnsSettingsStorage.#setValue(ColumnsSettingsStorage.#columnsKey, val);
  }

  static setRideCardSettings(val) {
    ColumnsSettingsStorage.#setValue(ColumnsSettingsStorage.#rideCardKey, val);
  }
}
