<template>
  <v-dialog fullscreen persistent hide-overlay transition="dialog-bottom-transition" v-bind="$attrs">
    <v-card tile class="d-flex flex-column">
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FullscreenDialog',
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped></style>
