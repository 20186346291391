<template>
  <autocomplete-field
    label="Firma transportowa"
    icon="mdi-car"
    item-text="name"
    :items="items"
    :loading="loading"
    dense-outlined
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-data>
      <NoDataText>Brak zdefiniowanych firm transportowych</NoDataText>
    </template>

    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import NoDataText from '@/component/Field/Generic/no-data-text';

export default {
  name: 'AbstractContractorInput',
  components: {
    AutocompleteField,
    NoDataText,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    items: {
      required: true,
    },
  },
};
</script>
