<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader>
      <template #prepend-item>
        <v-icon>mdi-account</v-icon>
      </template>
      <template #title>
        <div class="d-flex flex-column ml-2">
          <BaseTypography noMargin variant="h5">
            {{ course.driver ? course.driver.name : 'Brak kierowcy' }}
          </BaseTypography>
          <BaseTypography regular variant="subtitle-2">Kierowca</BaseTypography>
        </div>
      </template>
      <template #close><CloseIconButton @click="$emit('close')"></CloseIconButton></template>
    </AbstractModalHeader>
    <section class="px-6 pb-4">
      <BaseTypography class="py-2" variant="subtitle">Informacje od kierowcy</BaseTypography>
      <DescriptionSection class="mb-4">
        <DescriptionItem title="Liczba km">
          <!-- TODO: extract this to separate component -->
          <v-chip
            color="rgba(0, 125, 255, 0.12)"
            small
            text-color="black"
            data-testid="driver-distance"
            @click="openDriverReportModal"
          >
            {{ course.driverDistance ? `${course.driverDistance} km` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Godziny oczekiwania">
          <!-- TODO: extract this to separate component -->
          <v-chip
            color="rgba(0, 125, 255, 0.12)"
            small
            text-color="black"
            @click="openDriverReportModal"
            data-testid="waiting-hours"
          >
            {{ course.driverWaitingHours ? `${course.driverWaitingHours} h` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Koszty za autostrady">
          <v-chip
            color="rgba(0, 125, 255, 0.12)"
            small
            text-color="black"
            @click="openDriverReportModal"
            data-testid="highway-cost"
          >
            {{ course.driverHighwayCost | currency }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Komentarz kierowcy">
          <div
            :class="course.driverComment ? 'text-right' : 'text-left'"
            style="min-width: 24px"
            data-testid="driver-comment"
          >
            {{ course.driverComment || '-' }}
          </div>
        </DescriptionItem>
      </DescriptionSection>
      <BaseTextButton block prepend-icon="mdi-content-copy" @click="copyDriverReport">
        Skopiuj do rozliczenia
      </BaseTextButton>
    </section>
    <ReportDialog
      :reportDialogVisible="reportDialogVisible"
      :reportModel="billFromDriver.report()"
      :isRequestPending="false"
      @close="closeDriverReportModal"
      @onReportEdit="updateReport"
    ></ReportDialog>
    <v-divider></v-divider>
    <section class="px-6">
      <BaseTypography class="py-2" variant="subtitle">Rozliczenie kierowcy</BaseTypography>
      <AlertInfo icon="mdi-creation">
        <div class="d-flex justify-space-between align-center">
          <span>
            Automatycznie wyliczono
            <b>{{ course.driverDistanceSum }} km</b>
          </span>
          <BaseTextButton @click="copyDistanceSum">Użyj</BaseTextButton>
        </div>
      </AlertInfo>
      <v-form class="d-flex flex-column" style="row-gap: 16px" ref="form">
        <!-- TODO: think to extract this input to NumberInput -->
        <TextField v-model.number="billForDriver.distance" label="Liczba km" required type="number"></TextField>
        <TextField v-model.number="billForDriver.waitingHours" label="Godziny oczekiwania" type="number"></TextField>
        <TextField v-model.number="billForDriver.highwayCost" label="Koszty za autostradę" type="number"></TextField>
        <TextField v-model="billForDriver.comment" label="Komentarz osoby rozliczającej"></TextField>
      </v-form>
    </section>
    <DialogSaveButton @click="saveBillForDriver"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import BaseTextButton from '@/component/Base/base-text-button';
import AlertInfo from '@/component/Alert/alert-info';
import TextField from '@/component/Field/Generic/text-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import EditIconButton from '@/component/Button/edit-icon-button';
import ReportDialog from '@/component/NewCourse/Show/Details/report-dialog';
import { DriverSettlementReport, DriverReport } from '@/component/NewCourse/Show/Details/report';

export default {
  name: 'DriverSettlementModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    DescriptionSection,
    DescriptionItem,
    BaseTypography,
    BaseTextButton,
    AlertInfo,
    TextField,
    EditIconButton,
    ReportDialog,
    DialogSaveButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      billFromDriver: new DriverReport(this.course),
      billForDriver: new DriverSettlementReport(this.course),
      reportDialogVisible: false,
    };
  },
  methods: {
    copyDriverReport() {
      this.billForDriver = new DriverSettlementReport({
        ...this.course,
        billForDriver: this.billFromDriver.reportWithoutComments(),
      });

      this.$notify({
        type: 'success',
        text: 'Wartości zostały skopiowane',
      });
    },
    copyDistanceSum() {
      this.billForDriver = new DriverSettlementReport({
        ...this.course,
        billForDriver: {
          ...this.billForDriver,
          distance: this.course.driverDistanceSum,
        },
      });
    },
    saveBillForDriver() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('taxiCourse/updateValues', this.billForDriver.payload()).then(() => {
          this.$emit('close');
        });
      }
    },
    openDriverReportModal() {
      this.reportDialogVisible = true;
      this.billFromDriver = new DriverReport(this.course);
    },
    closeDriverReportModal() {
      this.reportDialogVisible = false;
      this.billFromDriver = new DriverReport(this.course);
    },
    updateReport(editedReport) {
      this.$store.dispatch('taxiCourse/updateValues', this.billFromDriver.payload(editedReport)).then(() => {
        this.closeDriverReportModal();
      });
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.billForDriver = new DriverSettlementReport(this.course);
        this.billFromDriver = new DriverReport(this.course);
        this.$refs.form?.resetValidation();
      }
    },
    course(newValue) {
      this.billFromDriver = new DriverReport(newValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
