<template>
  <v-row data-cy="comments-tile">
    <comment-create :entity="entity" />
    <comment-list :entity="entity" />
  </v-row>
</template>

<script>
import CommentCreate from '@/component/Api/Comment/comment-create';
import CommentList from '@/component/Api/Comment/comment-list';

export default {
  name: 'ApiComments',
  components: { CommentCreate, CommentList },
  props: { entity: null },
};
</script>
