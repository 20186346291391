/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/prefer-default-export
export class PassengerAlgorithm {
  static isHome(waypoint) {
    return waypoint.waypointStart.place['@type'] === 'Home';
  }

  static isDestination(waypoint) {
    return waypoint.waypointStart.place['@type'] === 'Hotel' || waypoint.waypointStart.place['@type'] === 'Workplace';
  }

  static condition() {
    throw new Error('Method need to be implemented in concrete class');
  }

  static getWaypointSectionsToOmit(waypoints) {
    let firstDestinationIdx = null;
    let secondDestinationIDx = null;
    let homeIdx = null;
    const sectionsToOmit = [];

    const isSegmentWithHomeBetweenDestinationsFound = () => {
      if (firstDestinationIdx !== null && homeIdx !== null && secondDestinationIDx !== null) {
        return firstDestinationIdx < homeIdx && homeIdx < secondDestinationIDx;
      }

      return false;
    };
    const isHomeAfterFirstDestinationFound = (foundHomeIdx) =>
      firstDestinationIdx !== null && homeIdx === null && foundHomeIdx > firstDestinationIdx;
    const isSecondHomeAfterFirstDestinationFound = (foundHomeIdx) => homeIdx !== null && foundHomeIdx > homeIdx;
    const isTwoDestinationsWithoutHomeFound = () =>
      firstDestinationIdx !== null && secondDestinationIDx !== null && homeIdx === null;
    const resetPointers = () => {
      homeIdx = null;
      firstDestinationIdx = secondDestinationIDx;
      secondDestinationIDx = null;
    };

    waypoints.forEach((waypoint, index) => {
      if (this.isDestination(waypoint)) {
        if (firstDestinationIdx === null) {
          firstDestinationIdx = index;
        } else if (secondDestinationIDx === null) {
          secondDestinationIDx = index;
        }
      }

      if (this.isHome(waypoint)) {
        if (isHomeAfterFirstDestinationFound(index)) {
          homeIdx = index;
        } else if (isSecondHomeAfterFirstDestinationFound(index)) {
          resetPointers();
          return;
        }
      }

      if (isSegmentWithHomeBetweenDestinationsFound()) {
        sectionsToOmit.push({
          startIdx: firstDestinationIdx,
          endIdx: secondDestinationIDx,
        });
        resetPointers();
        return;
      }

      if (isTwoDestinationsWithoutHomeFound()) {
        resetPointers();
      }
    });

    return sectionsToOmit;
  }

  static getWaypointsWithoutOmittedSections(waypoints, omitSections) {
    const isOmitSectionsEmpty = omitSections.length === 0;
    if (isOmitSectionsEmpty) {
      return [waypoints];
    }

    const result = [];

    for (let i = 0; i < omitSections.length; i += 1) {
      const { startIdx } = omitSections[i];
      const prevSection = omitSections[i - 1];
      const isFirstSectionToOmit = () => i === 0;

      if (isFirstSectionToOmit()) {
        result.push(waypoints.slice(null, startIdx + 1));
      } else {
        result.push(waypoints.slice(prevSection.endIdx, startIdx + 1));
      }
    }

    const lastOmitSection = omitSections.at(-1);
    result.push(waypoints.slice(lastOmitSection.endIdx));

    const isValidSegment = (segment) => {
      const MIN_AMOUNT_OF_WAYPOINTS_TO_CREATE_SEGMENT = 2;
      return segment.length >= MIN_AMOUNT_OF_WAYPOINTS_TO_CREATE_SEGMENT;
    };
    return result.filter(isValidSegment);
  }

  static findPassengerByHome({ waypoint, passengers }) {
    return passengers.find((passenger) => passenger.user.home?.id === waypoint.waypointStart.place.id);
  }

  static execute({ waypoints, passengers, currentUser, removedWaypoint }) {
    if (this.condition({ removedWaypoint, waypoints })) {
      return waypoints;
    }

    const sectionsToOmit = this.getWaypointSectionsToOmit(waypoints);
    const waypointsWithoutOmittedSections = this.getWaypointsWithoutOmittedSections(waypoints, sectionsToOmit);

    this.algorithmForHomeLocatedAfterDestination({
      segments: waypointsWithoutOmittedSections,
      passengers,
      currentUser,
      removedWaypoint,
    });
    this.algorithmForHomeLocatedBeforeDestination({
      segments: waypointsWithoutOmittedSections,
      passengers,
      currentUser,
      removedWaypoint,
    });

    return waypoints;
  }

  static algorithmForHomeLocatedAfterDestination() {
    throw new Error('algorithmForHomeLocatedAfterDestination() method need to be implemented in concrete class');
  }

  static algorithmForHomeLocatedBeforeDestination() {
    throw new Error('algorithmForHomeLocatedBeforeDestination() method need to be implemented in concrete class');
  }
}

export class FillingPassengerAlgorithm extends PassengerAlgorithm {
  static isRelationWithEmptyWaypoint(waypoints) {
    const isWaypointWithoutPlace = (waypoint) =>
      waypoint.waypointStart.place === undefined || waypoint.waypointStart.place === null;

    return waypoints.filter(isWaypointWithoutPlace).length > 0;
  }

  /** @override */
  static condition({ waypoints }) {
    return this.isRelationWithEmptyWaypoint(waypoints);
  }

  /** @override */
  static algorithmForHomeLocatedBeforeDestination({ segments, passengers, currentUser }) {
    /**
     * @type {number | null}
     */
    let destinationIdx = null;
    /**
     * @type {Array<number>}
     */
    let homeIndexes = [];

    const resetPointers = () => {
      destinationIdx = null;
      homeIndexes = [];
    };

    const isSegmentWitHomeBeforeDestinationFound = () => {
      if (homeIndexes.length !== 0 && destinationIdx !== null) {
        let allGreat = true;
        homeIndexes.forEach((homeIdx) => {
          // TODO: extract to method with name isHomeLocatedAfterDestination
          if (homeIdx > destinationIdx) {
            allGreat = false;
          }
        });

        return allGreat;
      }

      return false;
    };

    segments.forEach((segment) => {
      segment.forEach((waypoint, index) => {
        if (this.isDestination(waypoint)) {
          destinationIdx = index;
        }

        if (this.isHome(waypoint)) {
          homeIndexes.push(index);
        }

        if (isSegmentWitHomeBeforeDestinationFound()) {
          const foundPassengers = homeIndexes
            .map((homeIdx) => ({
              passenger: this.findPassengerByHome({ passengers, waypoint: segment[homeIdx] }),
              idx: homeIdx,
            }))
            .filter(({ passenger }) => passenger !== undefined)
            .filter(({ passenger }) => passenger.user.id !== currentUser.id);

          if (foundPassengers.length !== 0) {
            for (let i = homeIndexes[0]; i < destinationIdx; i += 1) {
              foundPassengers.forEach(({ passenger, idx }) => {
                if (idx <= i && segment[i].employees.findIndex((employee) => employee.id === passenger.id) === -1) {
                  segment[i].employees.push(passenger);
                }
              });
            }
          }

          resetPointers();
        }
      });

      resetPointers();
    });
  }

  /** @override */
  static algorithmForHomeLocatedAfterDestination({ segments, passengers, currentUser }) {
    /**
     * @type {number | null}
     */
    let destinationIdx = null;
    /**
     * @type {Array<number>}
     */
    let homeIndexes = [];

    const resetPointers = () => {
      destinationIdx = null;
      homeIndexes = [];
    };

    const isSegmentAfterDestinationFound = () => {
      if (destinationIdx !== null && homeIndexes.length !== 0) {
        const indexesToRemove = [];
        homeIndexes.forEach((homeIdx) => {
          if (homeIdx < destinationIdx) {
            indexesToRemove.push(homeIdx);
          }
        });

        homeIndexes = homeIndexes.filter((idx) => !indexesToRemove.includes(idx));

        return homeIndexes.length > 0;
      }

      return false;
    };

    segments.forEach((segment) => {
      segment.forEach((waypoint, index) => {
        if (this.isDestination(waypoint)) {
          destinationIdx = index;
        }

        if (this.isHome(waypoint)) {
          homeIndexes.push(index);
        }

        if (isSegmentAfterDestinationFound()) {
          const foundPassengers = homeIndexes
            .map((homeIdx) => ({
              passenger: this.findPassengerByHome({ passengers, waypoint: segment[homeIdx] }),
              idx: homeIdx,
            }))
            .filter(({ passenger }) => passenger !== undefined)
            .filter(({ passenger }) => passenger.user.id !== currentUser.id);

          if (foundPassengers.length !== 0) {
            for (let i = destinationIdx; i < homeIndexes[homeIndexes.length - 1]; i += 1) {
              foundPassengers.forEach(({ passenger, idx }) => {
                if (i < idx && segment[i].employees.findIndex((employee) => employee.id === passenger.id) === -1) {
                  segment[i].employees.push(passenger);
                }
              });
            }
          }
        }
      });

      resetPointers();
    });
  }
}

export class RemovalPassengerAlgorithm extends PassengerAlgorithm {
  static isPlace(waypoint) {
    return waypoint.waypointStart.place['@type'] === 'Place';
  }

  static isEmptyWaypoint(waypoint) {
    return waypoint.waypointStart.place === undefined;
  }

  /** @override */
  static condition({ removedWaypoint }) {
    return this.isEmptyWaypoint(removedWaypoint) || this.isPlace(removedWaypoint);
  }

  /** @override */
  static algorithmForHomeLocatedAfterDestination({ segments, passengers, currentUser, removedWaypoint }) {
    /**
     * @type {number | null}
     */
    let destinationIdx = null;
    /**
     * @type {Array<number>}
     */
    let homeIndexes = [];

    const resetPointers = () => {
      destinationIdx = null;
      homeIndexes = [];
    };

    const isSegmentAfterDestinationFound = () => {
      if (destinationIdx !== null && homeIndexes.length !== 0) {
        const indexesToRemove = [];
        homeIndexes.forEach((homeIdx) => {
          if (homeIdx < destinationIdx) {
            indexesToRemove.push(homeIdx);
          }
        });

        homeIndexes = homeIndexes.filter((idx) => !indexesToRemove.includes(idx));

        return homeIndexes.length > 0;
      }

      return false;
    };

    const isRemovedWaypointLocatedInFoundSegment = (segment) =>
      segment[destinationIdx].position === removedWaypoint.position ||
      homeIndexes.findIndex((homeIdx) => segment[homeIdx].position === removedWaypoint.position) !== -1;

    segments.forEach((segment) => {
      segment.forEach((waypoint, index) => {
        if (this.isDestination(waypoint)) {
          destinationIdx = index;
        }

        if (this.isHome(waypoint)) {
          homeIndexes.push(index);
        }

        if (isSegmentAfterDestinationFound() && isRemovedWaypointLocatedInFoundSegment(segment)) {
          if (this.isDestination(removedWaypoint)) {
            const foundPassengers = homeIndexes
              .map((homeIdx) => ({
                passenger: this.findPassengerByHome({ passengers, waypoint: segment[homeIdx] }),
                idx: homeIdx,
              }))
              .filter(({ passenger }) => passenger !== undefined)
              .filter(({ passenger }) => passenger.user.id !== currentUser.id);

            if (foundPassengers.length !== 0) {
              for (let i = destinationIdx; i < homeIndexes[homeIndexes.length - 1]; i += 1) {
                foundPassengers.forEach(({ passenger, idx }) => {
                  const passengerIdxInWaypoint = segment[i].employees.findIndex(
                    (employee) => employee.id === passenger.id
                  );
                  if (i < idx && passengerIdxInWaypoint !== -1) {
                    // TODO: decreasing index by one can be dangerous
                    segment[i].employees.splice(passengerIdxInWaypoint, 1);
                  }
                });
              }
            }
          } else if (this.isHome(removedWaypoint)) {
            const foundPassenger = {
              passenger: this.findPassengerByHome({ passengers, waypoint: removedWaypoint }),
              // TODO: checking by ID wouldn't work in production, because during creation, there is no id, so it will always yield undefined
              idx: segment.findIndex((waypointInSegment) => waypointInSegment.position === removedWaypoint.position),
            };

            if (foundPassenger.passenger !== undefined) {
              for (let i = destinationIdx; i < homeIndexes[homeIndexes.length - 1]; i += 1) {
                const passengerIdxInWaypoint = segment[i].employees.findIndex(
                  (employee) => employee.id === foundPassenger.passenger.id
                );
                if (i < foundPassenger.idx && passengerIdxInWaypoint !== -1) {
                  segment[i].employees.splice(passengerIdxInWaypoint, 1);
                }
              }
            }
          }
        }
      });

      resetPointers();
    });
  }

  /** @override */
  static algorithmForHomeLocatedBeforeDestination({ segments, passengers, currentUser, removedWaypoint }) {
    /**
     * @type {number | null}
     */
    let destinationIdx = null;
    /**
     * @type {Array<number>}
     */
    let homeIndexes = [];

    const resetPointers = () => {
      destinationIdx = null;
      homeIndexes = [];
    };

    const isSegmentWitHomeBeforeDestinationFound = () => {
      if (homeIndexes.length !== 0 && destinationIdx !== null) {
        let allGreat = true;
        homeIndexes.forEach((homeIdx) => {
          // TODO: extract to method with name isHomeLocatedAfterDestination
          if (homeIdx > destinationIdx) {
            allGreat = false;
          }
        });

        return allGreat;
      }

      return false;
    };

    // TODO: this function repeated in two methods: removeBefore/removeAfter
    const isRemovedWaypointLocatedInFoundSegment = (segment) =>
      segment[destinationIdx].position === removedWaypoint.position ||
      homeIndexes.findIndex((homeIdx) => segment[homeIdx].position === removedWaypoint.position) !== -1;

    segments.forEach((segment) => {
      segment.forEach((waypoint, index) => {
        if (this.isDestination(waypoint)) {
          destinationIdx = index;
        }

        if (this.isHome(waypoint)) {
          homeIndexes.push(index);
        }

        if (isSegmentWitHomeBeforeDestinationFound() && isRemovedWaypointLocatedInFoundSegment(segment)) {
          if (this.isDestination(removedWaypoint)) {
            const foundPassengers = homeIndexes
              .map((homeIdx) => ({
                passenger: this.findPassengerByHome({ passengers, waypoint: segment[homeIdx] }),
                idx: homeIdx,
              }))
              .filter(({ passenger }) => passenger !== undefined)
              .filter(({ passenger }) => passenger.user.id !== currentUser.id);

            if (foundPassengers.length !== 0) {
              for (let i = homeIndexes[0]; i < destinationIdx; i += 1) {
                foundPassengers.forEach(({ passenger, idx }) => {
                  const passengerIdxInWaypoint = segment[i].employees.findIndex(
                    (employee) => employee.id === passenger.id
                  );

                  if (idx <= i && passengerIdxInWaypoint !== -1) {
                    segment[i].employees.splice(passengerIdxInWaypoint, 1);
                  }
                });
              }
            }
            // else if branch is used, because I wasn't able to pass the knowledge that at this point removedWaypoint was casted only to destination OR home
          } else if (this.isHome(removedWaypoint)) {
            const foundPassenger = {
              passenger: this.findPassengerByHome({ passengers, waypoint: removedWaypoint }),
              idx: segment.findIndex((waypointInSegment) => waypointInSegment.id === removedWaypoint.id),
            };

            if (foundPassenger.passenger !== undefined) {
              for (let i = homeIndexes[0]; i < destinationIdx; i += 1) {
                const passengerIdxInWaypoint = segment[i].employees.findIndex(
                  (employee) => employee.id === foundPassenger.passenger.id
                );

                if (foundPassenger.idx <= i && passengerIdxInWaypoint !== -1) {
                  segment[i].employees.splice(passengerIdxInWaypoint, 1);
                }
              }
            }
          }

          resetPointers();
        }
      });

      resetPointers();
    });
  }
}
