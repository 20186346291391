import KilometerCourseService from '@/service/KilometerCourseService';
import GeolocationService from '@/service/GeolocationService';
import PlaceService from '@/service/PlaceService';
import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';
import { uniqBy } from 'lodash';

import * as types from './types/kilometer-course-mutation-types';

export default {
  namespaced: true,
  state: {
    favouritePlaces: null,
    isLoading: false,
    abstractPlacesWithDistance: [],
    recentlyUsedPlaces: [],
  },
  getters: {
    favouritePlaces: (_, _2, _3, rootGetters) => rootGetters.passenger?.favouritePlaces ?? [],
    favouritePlacesIds: (_, getters) => getters.favouritePlaces.map((place) => place.id),
    recentlyUsedPlaces: (state, getters, _3, rootGetters) => {
      let places = [];
      state.recentlyUsedPlaces.forEach((course) => {
        course.rides.forEach((ride) =>
          ride.segments.forEach((segment) => {
            places.push(segment.waypointStart.place, segment.waypointEnd.place);
          })
        );
      });

      places = uniqBy(places, 'id')
        .slice(0, 5)
        .map((place) => ({
          ...place,
          selectedAsFavourite: getters.favouritePlacesIds.includes(place.id),
        }))
        .filter((place) => place.id !== getters.homePlace?.id);

      if (rootGetters.position.latitude || rootGetters.position.longitude) {
        return places
          .map((place) => ({
            ...place,
            awayBy: Math.round(GeolocationService.calculateDistanceBetweenTwoPoints(place, rootGetters.position)),
          }))
          .sort((a, b) => a.awayBy - b.awayBy);
      }

      return places;
    },
    abstractPlacesWithDistance: (state) =>
      JSON.parse(JSON.stringify(state.abstractPlacesWithDistance)).sort((a, b) => a.awayBy - b.awayBy),
    isLoading: (state) => state.isLoading,
    homePlace: (_, _2, _3, rootGetters) => rootGetters.passenger?.user.home,
    favouritePlacesExceptId: (_, _2, _3, rootGetters) => (placeId) =>
      rootGetters.passenger.favouritePlaces.filter((place) => place['@id'] !== placeId).map((place) => place['@id']),
  },
  mutations: {
    [types.SET_FAVOURITE_PLACES]: (state, places) => {
      state.favouritePlaces = places;
    },
    [types.SET_LOADING]: (state, val) => {
      state.isLoading = val;
    },
    [types.SET_ABSTRACT_PLACES_WITH_DISTANCE]: (state, val) => {
      state.abstractPlacesWithDistance = val;
    },
    [types.SET_RECENTLY_USED_PLACES]: (state, val) => {
      state.recentlyUsedPlaces = val;
    },
  },
  actions: {
    updatePassenger({ rootGetters, commit }, payload) {
      commit(types.SET_LOADING, true);
      return KilometerCourseService.updatePassenger({
        passengerId: rootGetters.passengerId,
        payload,
      })
        .then((passenger) => {
          commit('updatePassenger', passenger, { root: true });
        })
        .finally(() => {
          commit(types.SET_LOADING, false);
        });
    },

    saveFavouritePlace({ dispatch, rootGetters }, placeId) {
      return dispatch('updatePassenger', {
        favouritePlaces: [...rootGetters.passenger.favouritePlaces.map((place) => place['@id']), placeId],
      });
    },

    removeFavouritePlace({ dispatch, getters }, placeId) {
      return dispatch('updatePassenger', {
        favouritePlaces: getters.favouritePlacesExceptId(placeId),
      });
    },

    async softDeleteHomeplace({ commit, dispatch }, place) {
      commit(types.SET_LOADING, true);
      const isHomeType = () => place && place['@type'] === 'Home';

      if (isHomeType()) {
        const data = await PlaceService.softDeletePlace(place.id);
        commit('removeAbstractPlace', data, { root: true });
        await dispatch('calculateDistanceToAbstractPlaces');
      }
    },

    async createNewHomeplace({ rootGetters, dispatch, commit, getters }, payload) {
      await dispatch('softDeleteHomeplace', getters.homePlace);
      commit(types.SET_LOADING, true);

      const placePayload = {
        ...payload,
        '@type': ABSTRACT_PLACE_TYPES.HOME,
        id: null,
        '@id': null,
        name: rootGetters.user.name,
        company: rootGetters.user.company,
      };

      const createdPlace = await dispatch(
        'place/save',
        {
          place: placePayload,
          type: ABSTRACT_PLACE_TYPES.HOME,
        },
        { root: true }
      );
      dispatch('calculateDistanceToAbstractPlaces');
      return dispatch('updatePassenger', {
        user: {
          id: rootGetters.userId,
          home: createdPlace['@id'],
        },
      });
    },

    createKilometerCourse({ commit, rootGetters }) {
      commit(types.SET_LOADING, true);
      return KilometerCourseService.createKilometerCourse(
        rootGetters['courseCreation/course'],
        rootGetters.passenger
      ).finally(() => {
        commit(types.SET_LOADING, false);
      });
    },

    calculateDistanceToAbstractPlaces({ commit, rootGetters }) {
      let abstractPlaces = rootGetters.abstractPlacesList;
      if (rootGetters.position.latitude || rootGetters.position.longitude) {
        abstractPlaces = abstractPlaces
          .map((place) => ({
            ...place,
            awayBy: Math.round(GeolocationService.calculateDistanceBetweenTwoPoints(place, rootGetters.position)),
          }))
          .sort((a, b) => a.awayBy - b.awayBy);
      }

      commit(types.SET_ABSTRACT_PLACES_WITH_DISTANCE, abstractPlaces);
    },

    fetchRecentlyUsedPlaces({ commit }) {
      KilometerCourseService.fetchRecentlyUsedPlaces().then((res) => {
        commit(types.SET_RECENTLY_USED_PLACES, res['hydra:member']);
      });
    },
  },
};
