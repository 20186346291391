<template>
  <v-card-title class="pr-4 pb-3">
    <h3 class="dialog__title">{{ text }}</h3>
    <slot></slot>
    <v-spacer></v-spacer>
    <v-btn icon v-on="$listeners" aria-label="zamknij"><v-icon>mdi-close</v-icon></v-btn>
  </v-card-title>
</template>

<script>
export default {
  name: 'DialogTitleHeader',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog__title {
  font: 500 20px/32px 'Roboto', sans-serif;
}
</style>
