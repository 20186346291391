<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader>
      <template #prepend-item>
        <v-icon>mdi-account</v-icon>
      </template>
      <template #title>
        <div class="d-flex flex-column ml-2">
          <BaseTypography noMargin variant="h5">
            {{ course.subcontractor.name }}
          </BaseTypography>
          <BaseTypography regular variant="subtitle-2">Podwykonawca</BaseTypography>
        </div>
      </template>
      <template #close><CloseIconButton @click="$emit('close')"></CloseIconButton></template>
    </AbstractModalHeader>

    <section class="px-6 pb-4">
      <BaseTypography class="py-2" variant="subtitle">Informacje od kierowcy</BaseTypography>
      <DescriptionSection class="mb-4">
        <DescriptionItem title="Liczba km">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverDistance ? `${course.driverDistance} km` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Godziny oczekiwania">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverWaitingHours ? `${course.driverWaitingHours} h` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Koszty za autostrady">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverHighwayCost | currency }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Komentarz kierowcy">
          <div :class="course.driverComment ? 'text-right' : 'text-left'" style="min-width: 24px">
            {{ course.driverComment || '-' }}
          </div>
        </DescriptionItem>
      </DescriptionSection>
      <base-text-button block prepend-icon="mdi-content-copy" @click="copyDriverReport">
        Skopiuj do rozliczenia
      </base-text-button>
    </section>

    <ReportDialog
      :reportDialogVisible="reportDialogVisible"
      :reportModel="billFromDriver.report()"
      :isRequestPending="false"
      @close="closeDriverReportModal"
      @onReportEdit="updateReport"
    ></ReportDialog>

    <v-divider></v-divider>

    <section class="px-6">
      <BaseTypography class="py-2" variant="subtitle">Rozliczenie podwykonawcy</BaseTypography>
      <AlertInfo icon="mdi-creation">
        <div class="d-flex justify-space-between align-center">
          <span>
            Automatycznie wyliczono
            <b>{{ course.distanceSum }} km</b>
          </span>
          <BaseTextButton @click="copyDistanceSum">Użyj</BaseTextButton>
        </div>
      </AlertInfo>
      <v-form class="d-flex flex-column" style="row-gap: 16px" ref="form">
        <!-- TODO: think to extract this input to NumberInput -->
        <TextField v-model.number="billForSubcontractor.distance" label="Liczba km" required type="number"></TextField>
        <TextField
          v-model.number="billForSubcontractor.waitingHours"
          label="Godziny oczekiwania"
          type="number"
        ></TextField>
        <TextField
          v-model.number="billForSubcontractor.highwayCost"
          label="Koszty za autostradę"
          type="number"
        ></TextField>
        <TextField v-model="billForSubcontractor.comment" label="Komentarz osoby rozliczającej"></TextField>
      </v-form>
    </section>
    <DialogSaveButton @click="saveBillForSubcontractor"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BaseTypography from '@/component/Base/base-typography';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import CloseIconButton from '@/component/Button/close-icon-button';
import BaseTextButton from '@/component/Base/base-text-button';
import EditIconButton from '@/component/Button/edit-icon-button';
import AlertInfo from '@/component/Alert/alert-info';
import TextField from '@/component/Field/Generic/text-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import { SubcontractorSettlementReport, DriverReport } from '@/component/NewCourse/Show/Details/report';
import ReportDialog from '@/component/NewCourse/Show/Details/report-dialog';

export default {
  name: 'SubcontractorSettlementModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    BaseTypography,
    CloseIconButton,
    DescriptionSection,
    DescriptionItem,
    BaseTextButton,
    EditIconButton,
    AlertInfo,
    TextField,
    DialogSaveButton,
    ReportDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      billFromDriver: new DriverReport(this.course),
      billForSubcontractor: new SubcontractorSettlementReport(this.course),
      reportDialogVisible: false,
    };
  },
  methods: {
    copyDriverReport() {
      this.billForSubcontractor = new SubcontractorSettlementReport({
        ...this.course,
        billForSubcontractor: this.billFromDriver.reportWithoutComments(),
      });

      this.$notify({
        type: 'success',
        text: 'Wartości zostały skopiowane',
      });
    },
    copyDistanceSum() {
      this.billForSubcontractor = new SubcontractorSettlementReport({
        ...this.course,
        billForSubcontractor: {
          ...this.billForSubcontractor,
          distance: this.course.distanceSum,
        },
      });
    },
    openDriverReportModal() {
      this.reportDialogVisible = true;
      this.billFromDriver = new DriverReport(this.course);
    },
    closeDriverReportModal() {
      this.reportDialogVisible = false;
      this.billFromDriver = new DriverReport(this.course);
    },
    updateReport(editedReport) {
      this.$store.dispatch('taxiCourse/updateValues', this.billFromDriver.payload(editedReport)).then(() => {
        this.closeDriverReportModal();
      });
    },
    saveBillForSubcontractor() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('taxiCourse/updateValues', this.billForSubcontractor.payload()).then(() => {
          this.$emit('close');
        });
      }
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.billForSubcontractor = new SubcontractorSettlementReport(this.course);
        this.billFromDriver = new DriverReport(this.course);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
