// eslint-disable-next-line import/prefer-default-export
export class AddContractorStakeStrategy {
  #store;

  constructor({ store, isContractorOfRailwayCompany = false, contractorId, contractorName }) {
    this.#store = store;
    this.isContractorOfRailwayCompany = isContractorOfRailwayCompany;
    this.contractorId = contractorId;
    this.contractorName = contractorName;
  }

  get company() {
    return this.isContractorOfRailwayCompany
      ? this.#store.getters['courseCreation/courseCompany']
      : this.#store.getters.loggedCompany;
  }

  save(contractorStake) {
    return this.#store.dispatch('taxiCourse/addContractorStake', {
      contractorStake,
      company: this.company,
      isContractorOfRailwayCompany: this.isContractorOfRailwayCompany,
      contractorId: this.contractorId,
      contractorName: this.contractorName,
    });
  }
}
