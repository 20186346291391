<template>
  <DialogFactory :value="value" v-on="$listeners">
    <slot name="header" v-bind:onClose="emitCloseEvent"></slot>
    <v-card-text class="flex-grow-1">
      <v-form ref="form" class="d-flex flex-column" style="row-gap: 20px">
        <FindPlaceField :placeEntityCopy.sync="placeEntityCopy"></FindPlaceField>
        <TextField
          v-if="isPlaceSelected"
          v-model="placeEntityCopy.address"
          label="Adres miejsca"
          hint="Ten adres będzie widoczny w wynikach wyboru miejsca"
          required
        ></TextField>
        <slot name="form-extension"></slot>
      </v-form>
    </v-card-text>
    <DialogSaveButton @click="savePlace"></DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import TextField from '@/component/Field/Generic/text-field';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import FindPlaceField from './place-field/find-place-field/find-place-field';

export default {
  name: 'AbstractPlaceModal',
  components: {
    DialogFactory,
    FindPlaceField,
    TextField,
    DialogSaveButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    placeEntity: {},
    strategy: {},
  },
  data() {
    return {
      placeEntityCopy: JSON.parse(JSON.stringify(this.placeEntity)),
    };
  },
  computed: {
    isPlaceSelected() {
      return this.placeEntityCopy.longitude !== null && this.placeEntityCopy.latitude;
    },
  },
  methods: {
    savePlace() {
      if (this.$refs.form.validate()) {
        this.strategy.save(this.placeEntityCopy).then((data) => {
          this.$emit('close', data);
          this.$refs.form.reset();
        });
      }
    },
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
