<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader>
      <template #prepend-item>
        <v-icon>mdi-taxi</v-icon>
      </template>
      <template #title>
        <div class="d-flex flex-column ml-2">
          <BaseTypography noMargin variant="h5">Przejazd {{ ride.number }}</BaseTypography>
          <BaseTypography regular variant="subtitle-2">{{ ride.company.name }}</BaseTypography>
        </div>
      </template>
      <template #close><CloseIconButton @click="$emit('close')"></CloseIconButton></template>
    </AbstractModalHeader>
    <section class="px-6 pb-4">
      <BaseTypography class="py-2" variant="subtitle">Informacje od kierowcy</BaseTypography>
      <DescriptionSection class="mb-4">
        <DescriptionItem title="Liczba km">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverDistance ? `${course.driverDistance} km` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Godziny oczekiwania">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverWaitingHours ? `${course.driverWaitingHours} h` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Koszty za autostrady">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverHighwayCost | currency }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Komentarz kierowcy">
          <div :class="course.driverComment ? 'text-right' : 'text-left'" style="min-width: 24px">
            {{ course.driverComment || '-' }}
          </div>
        </DescriptionItem>
      </DescriptionSection>
    </section>

    <ReportDialog
      :reportDialogVisible="reportDialogVisible"
      :reportModel="billFromDriver.report()"
      :isRequestPending="false"
      @close="closeDriverReportModal"
      @onReportEdit="updateReport"
    ></ReportDialog>

    <v-divider></v-divider>

    <section class="px-6 pb-4">
      <BaseTypography class="py-2" variant="subtitle">Rozliczenie przejazdu</BaseTypography>
      <AlertInfo v-if="course.rides.length === 1">
        Podane wartości zostaną przekazane do rozliczenia {{ isCourseWithSubcontractor ? 'podwykonawcy' : 'kierowcy' }}
      </AlertInfo>
      <AlertInfo icon="mdi-creation">
        <div class="d-flex justify-space-between align-center">
          <span>
            Automatycznie wyliczono
            <b>{{ ride.distanceSum }} km</b>
          </span>
          <BaseTextButton @click="copyDistanceSum">Użyj</BaseTextButton>
        </div>
      </AlertInfo>
      <v-form class="d-flex flex-column" style="row-gap: 16px" ref="form">
        <!-- TODO: think to extract this input to NumberInput -->
        <TextField v-model.number="billToSettled.distance" label="Liczba km" required type="number"></TextField>
        <TextField v-model.number="billToSettled.waitingHours" label="Godziny oczekiwania" type="number"></TextField>
        <TextField v-model.number="billToSettled.highwayCost" label="Koszty za autostradę" type="number"></TextField>
        <TextField v-model="billToSettled.comment" label="Komentarz osoby rozliczającej"></TextField>
      </v-form>
    </section>
    <DialogSaveButton style="padding-bottom: 8px !important" @click="saveBillToSettled">
      <template #extension>
        <BaseTextButton
          v-if="isRideCanBeAcceptedByContractor"
          class="mt-2"
          block
          @click="saveBillToSettledAndAcceptByContractor"
        >
          Zapisz i przekaż do rozliczenia
        </BaseTextButton>
      </template>
    </DialogSaveButton>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import BaseTypography from '@/component/Base/base-typography';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import AlertInfo from '@/component/Alert/alert-info';
import BaseTextButton from '@/component/Base/base-text-button';
import TextField from '@/component/Field/Generic/text-field';
import { RideSettledReport, DriverReport } from '@/component/NewCourse/Show/Details/report';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import EditIconButton from '@/component/Button/edit-icon-button';
import ReportDialog from '@/component/NewCourse/Show/Details/report-dialog';

export default {
  name: 'RideSettlementModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    BaseTypography,
    DescriptionSection,
    DescriptionItem,
    AlertInfo,
    BaseTextButton,
    DialogSaveButton,
    TextField,
    EditIconButton,
    ReportDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
    ride: {
      required: true,
    },
    isCourseWithSubcontractor: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      billFromDriver: new DriverReport(this.course),
      billToSettled: new RideSettledReport(this.ride),
      reportDialogVisible: false,
    };
  },
  computed: {
    isRideEndedByDriver() {
      return (
        this.ride.status === TAXI_COURSE_RIDE_STATUS.FINISHED ||
        this.ride.status === TAXI_COURSE_RIDE_STATUS.CANCELLED_DURING_REALIZATION
      );
    },
    isRideInInconsistentStatus() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.INCONSISTENCY;
    },
    isRideInAcceptedByTransportCompanyStatus() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY;
    },
    isRideCanBeAcceptedByContractor() {
      return (
        this.isRideEndedByDriver || this.isRideInInconsistentStatus || this.isRideInAcceptedByTransportCompanyStatus
      );
    },
    minimalDistanceForReport() {
      return Math.min(
        ...[this.billToSettled.distance, this.billFromDriver.report().distance, this.ride.distanceSum].filter(
          (v) => v !== null
        )
      );
    },
  },
  methods: {
    openDriverReportModal() {
      this.reportDialogVisible = true;
      this.billFromDriver = new DriverReport(this.course);
    },
    closeDriverReportModal() {
      this.reportDialogVisible = false;
      this.billFromDriver = new DriverReport(this.course);
    },
    updateReport(editedReport) {
      this.$store.dispatch('taxiCourse/updateValues', this.billFromDriver.payload(editedReport)).then(() => {
        this.closeDriverReportModal();
      });
    },
    copyDistanceSum() {
      this.billToSettled = new RideSettledReport({
        ...this.ride,
        distanceToSettled: this.ride.distanceSum,
      });
    },
    saveBillToSettled() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/updateValues', this.billToSettled.payload())
          .then(() => {
            if (this.course.rides.length === 1) {
              this.$store.dispatch('taxiCourse/updateValues', {
                '@id': this.course['@id'],
                payload: {
                  [this.isCourseWithSubcontractor ? 'billForSubcontractor' : 'billForDriver']: {
                    ...this.billToSettled.payload().payload.billToSettled,
                    distance: this.minimalDistanceForReport,
                  },
                },
              });
            }
          })
          .then(() => {
            this.$emit('close');
          });
      }
    },
    saveBillToSettledAndAcceptByContractor() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/updateValues', {
            ...this.billToSettled.payload(),
            payload: {
              ...this.billToSettled.payload().payload,
              status: TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR,
            },
          })
          .then(() => {
            if (this.course.rides.length === 1) {
              this.$store.dispatch('taxiCourse/updateValues', {
                '@id': this.course['@id'],
                payload: {
                  [this.isCourseWithSubcontractor ? 'billForSubcontractor' : 'billForDriver']: {
                    ...this.billToSettled.payload().payload.billToSettled,
                    distance: Math.min(
                      ...[
                        this.billToSettled.distance,
                        this.billFromDriver.report().distance,
                        this.ride.distanceSum,
                      ].filter((v) => v !== null)
                    ),
                  },
                },
              });
            }
          })
          .then(() => {
            this.$emit('close');
          });
      }
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.billToSettled = new RideSettledReport(this.ride);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ride-settlement__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0000008a;
}
</style>
