<template>
  <div>
    <section class="ride-summary__section">
      <h4 class="ride-summary__title">Ogólne</h4>
      <h5 class="ride-summary__subtitle">Suma automatycznie wyliczonych kilometrów</h5>
      <p class="ride-summary__text" data-testid="distance-sum">
        {{ course.distanceSum ? `${course.distanceSum} km` : '-' }}
      </p>
    </section>
    <v-divider></v-divider>
    <section class="ride-summary__section">
      <div class="ride-summary__row">
        <div class="ride-summary__column">
          <h5 class="ride-summary__subtitle">Koszty za autostrady do rozliczenia</h5>
          <span>{{ course.rides[0].highwayCostToSettled | currency }}</span>
        </div>
        <div class="ride-summary__column">
          <h5 class="ride-summary__subtitle">Kilometry do rozliczenia</h5>
          <span>{{ course.rides[0].distanceToSettled ? `${course.rides[0].distanceToSettled} km` : '-' }}</span>
        </div>
        <div class="ride-summary__column">
          <h5 class="ride-summary__subtitle">Komentarz do raportu do rozliczenia</h5>
          <base-comment>{{ course.rides[0].commentToSettled || '-' }}</base-comment>
        </div>
      </div>
      <div class="mt-6 d-inline-flex flex-column" style="row-gap: 16px">
        <template v-if="isCompany && isCourseFinished">
          <v-btn color="primary" :loading="isLoading" @click="updateStatus(courseStatus.TO_BE_SETTLED)">
            <v-icon left>mdi-check</v-icon>
            Zaakceptuj do rozliczenia
          </v-btn>
          <v-btn color="error" :loading="isLoading" @click="openCommentDialog">
            <v-icon left>mdi-close</v-icon>
            Niezgodność w rozliczeniu
          </v-btn>
        </template>
        <template v-if="isCompany && isCourseReadyToBeSettled">
          <v-btn color="warning" :loading="isLoading" @click="updateStatus(courseStatus.FINISHED)">
            <v-icon left>mdi-alert-outline</v-icon>
            Anuluj akceptację do rozliczenia
          </v-btn>
        </template>
        <template v-if="isAccountant && isCourseReadyToBeSettled">
          <v-btn color="primary" :loading="isLoading" @click="updateStatus(courseStatus.SETTLED)">
            <v-icon left>mdi-check</v-icon>
            Rozliczono
          </v-btn>
        </template>
        <template v-if="isAccountant && isCourseSettled">
          <v-btn color="warning" :loading="isLoading" @click="updateStatus(courseStatus.TO_BE_SETTLED)">
            <v-icon left>mdi-alert-outline</v-icon>
            Anuluj rozliczenie
          </v-btn>
        </template>
      </div>
    </section>
    <CommentDialog
      :value="commentDialog"
      :isRequestPending="isLoading"
      @close="closeDialog"
      @saveComment="markRideAsInconsistent"
    ></CommentDialog>
  </div>
</template>

<script>
import BaseComment from '@/component/Base/base-comment';
import CommentDialog from '@/component/Dialog/comment-dialog';
import { KILOMETER_COURSE_STATUS } from '@/core/dict/kilometer-course-dict';
import CommentService from '@/service/CommentService';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';
import { mapGetters } from 'vuex';

export default {
  name: 'KilometerCourseSummary',
  components: {
    BaseComment,
    CommentDialog,
  },
  data() {
    return {
      courseStatus: KILOMETER_COURSE_STATUS,
      commentDialog: false,
    };
  },
  props: {
    course: Object,
  },
  computed: {
    ...mapGetters(['isCompany', 'isAccountant']),
    ...mapGetters('taxiCourse', ['isLoading']),
    isCourseFinished() {
      return this.course.rides[0].status === KILOMETER_COURSE_STATUS.FINISHED;
    },
    isCourseReadyToBeSettled() {
      return this.course.rides[0].status === KILOMETER_COURSE_STATUS.TO_BE_SETTLED;
    },
    isCourseSettled() {
      return this.course.rides[0].status === KILOMETER_COURSE_STATUS.SETTLED;
    },
  },
  methods: {
    updateStatus(status) {
      return new UpdateRideStatusCommand({ ride: this.course.rides[0], status }).execute();
    },
    async markRideAsInconsistent(comment) {
      this.$store.commit('taxiCourse/SET_LOADING', true);
      try {
        await CommentService.create({ ...this.course.rides[0], '@type': 'Ride' }, comment, this.$store.getters.user.id);
        await this.updateStatus(KILOMETER_COURSE_STATUS.INCONSISTENCY);
        this.closeDialog();
        this.$store.commit('taxiCourse/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('taxiCourse/SET_LOADING', false);
      }
    },
    openCommentDialog() {
      this.commentDialog = true;
    },
    closeDialog() {
      this.commentDialog = false;
    },
  },
};
</script>

<!-- TODO: extract to separate components -->
<style scoped lang="scss">
.ride-summary {
  &__section {
    padding: 24px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
  }

  &__column {
    flex-basis: 50%;
  }

  &__title {
    font: 600 14px/18px 'Roboto';
    color: rgba(0, 0, 0, 0.54);

    margin-bottom: 32px;
  }

  &__subtitle {
    font: 400 14px/18px 'Roboto';
    color: rgba(0, 0, 0, 0.54);

    margin-bottom: 8px;
  }

  &__text {
    font: 400 14px/18px 'Roboto';
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }
}
</style>
