import { render, staticRenderFns } from "./page-container.vue?vue&type=template&id=7eab207e"
import script from "./page-container.vue?vue&type=script&lang=js"
export * from "./page-container.vue?vue&type=script&lang=js"
import style0 from "./page-container.vue?vue&type=style&index=0&id=7eab207e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports