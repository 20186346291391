import { store } from '@/core/store';

// eslint-disable-next-line import/prefer-default-export
export class UpdateRideStatusCommand {
  #store = store;

  #status;

  #ride;

  constructor({ ride, status }) {
    this.#ride = ride;
    this.#status = status;
  }

  execute() {
    return this.#store.dispatch('taxiCourse/updateValue', {
      '@id': this.#ride['@id'],
      model: 'status',
      value: this.#status,
    });
  }
}
