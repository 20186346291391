const StartingBaseList = () => import('@/controller/StartingBase/starting-base-list');
const StartingBaseCreate = () => import('@/controller/StartingBase/starting-base-create');
const StartingBaseShow = () => import('@/controller/StartingBase/starting-base-show');
const StartingBaseEdit = () => import('@/controller/StartingBase/starting-base-edit');

const name = 'starting_base';

export default [
  {
    path: '/starting_bases',
    name: `${name}_index`,
    component: StartingBaseList,
    meta: {
      title: 'Bazy startowe',
      create: `${name}_create`,
    },
  },
  {
    path: '/starting_bases/create',
    name: `${name}_create`,
    component: StartingBaseCreate,
    meta: {
      title: 'Bazy startowe',
    },
  },
  {
    path: '/starting_bases/:id',
    name: `${name}_show`,
    component: StartingBaseShow,
    meta: {
      title: 'Baza startowa',
    },
  },
  {
    path: '/starting_bases/:id/edit',
    name: `${name}_edit`,
    component: StartingBaseEdit,
    meta: {
      title: 'Baza startowa',
    },
  },
];
