<template>
  <!-- TODO: think how we can encapsulate what varies -->
  <!-- TODO: check what is going on with retain-focus -->
  <v-dialog
    :value="value"
    :retain-focus="false"
    width="518"
    @click:outside="emitCloseEvent"
    @keydown.esc="emitCloseEvent"
  >
    <v-card>
      <v-card-title class="text-h5 text-break pb-5 pt-6">Przenieś przejazd {{ transferSubjectNumber }}</v-card-title>
      <v-card-text>
        <TransferRideField v-model="selectedCourse" class="mb-6"></TransferRideField>
        <p class="transfer-modal__text">Informacja o zmianach zostanie wysłana do wykonawcy i/lub kierowcy.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="emitCloseEvent">anuluj</v-btn>
        <v-btn color="primary" :disabled="!selectedCourse" text @click="emitTransferRideEvent">przenieś</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import TransferRideField from './transfer-ride-field';

export default {
  name: 'TransferRideModal',
  components: {
    TransferRideField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedCourse: null,
    };
  },
  computed: {
    ...mapGetters('transferRide', ['transferSubjectNumber']),
  },
  methods: {
    clearInput() {
      this.selectedCourse = null;
    },
    emitCloseEvent() {
      this.clearInput();
      this.$emit('close');
    },
    emitTransferRideEvent() {
      this.$emit('transferRide', this.selectedCourse);
    },
  },
  watch: {
    value() {
      this.clearInput();
    },
  },
};
</script>

<style lang="scss" scoped>
.transfer-modal__text {
  color: rgba(0, 0, 0, 0.54);
  font: 400 1rem/1.5rem 'Roboto', sans-serif;
  letter-spacing: 0.15px;
  margin-bottom: 0;
}
</style>
