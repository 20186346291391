import { render, staticRenderFns } from "./chart-contribution-of-transport-companies-to-costs.vue?vue&type=template&id=c48dd8d6"
import script from "./chart-contribution-of-transport-companies-to-costs.vue?vue&type=script&lang=js"
export * from "./chart-contribution-of-transport-companies-to-costs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports