<template>
  <v-menu absolute top offset-y :close-on-content-click="false" backgound-color="white" content-class="white">
    <template #activator="data">
      <v-text-field
        v-on="data.on"
        :value="formatDate(datetime)"
        :autofocus="autofocus"
        :filled="filled"
        :clearable="clearable"
        :color="color"
        :dark="dark"
        :disabled="disabled"
        :error="error"
        :flat="flat"
        :height="height"
        :prepend-icon="icon"
        :label="label"
        :loading="loading"
        :outlined="outlined"
        :placeholder="placeholder"
        :solo="solo"
        :success="success"
        :type="type"
        :min="min"
        :max="max"
        readonly
        hide-details="auto"
        :rules="rules"
        @click:clear="clear"
      />
    </template>

    <v-row class="fluid">
      <v-col cols="12">
        <v-date-picker
          v-model="date"
          @input="setDate"
          locale="pl"
          first-day-of-week="1"
          class="elevation-0"
          data-cy="datepicker-input"
        />
      </v-col>
    </v-row>
  </v-menu>
</template>

<script>
import format from 'date-fns/format';
import { formatDate as formatter } from '@/core/filter/datetime-filter';

export default {
  name: 'OneFieldDate',
  props: {
    autofocus: Boolean,
    filled: Boolean,
    clearable: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    error: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    height: null,
    icon: String,
    label: String,
    value: null,
    loading: Boolean,
    outlined: Boolean,
    placeholder: String,
    readonly: Boolean,
    solo: Boolean,
    min: Number,
    max: Number,
    success: Boolean,
    type: String,
    rules: Array,
  },

  created() {
    if (this.value !== null) {
      this.date = format(this.value, this.format.date);
    } else {
      this.date = null;
    }
    this.$emit('input', this.datetime);
  },

  methods: {
    setDate(date) {
      this.date = date === null || date === undefined ? null : date;
      this.$emit('input', this.datetime);
    },

    clear() {
      this.setDate();
      this.$emit('input', null);
    },

    formatDate(date) {
      return formatter(date);
    },
  },

  data() {
    return {
      date: null,

      format: {
        date: 'YYYY-MM-DD',
      },
    };
  },

  computed: {
    datetime: {
      get() {
        if (this.date) {
          return this.date;
        }
        return null;
      },
    },
  },

  watch: {
    value() {
      if (this.value !== null) {
        this.date = format(this.value, this.format.date);
      } else {
        this.date = null;
      }
      this.$emit('input', this.datetime);
    },
  },
};
</script>

<style>
.v-picker {
  border-radius: 0 !important;
}

.v-picker__title {
  height: 100px !important;
}

.v-time-picker-title {
  height: 100px !important;
  padding: 0;
}
</style>
