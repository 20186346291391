<template>
  <v-app app class="grey lighten-3">
    <v-main>
      <v-container class="fill-height align justify" fluid>
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { LOGIN_ROUTE } from '@/core/router/route-names';
import Vue from 'vue';

export default {
  name: 'LogoutPage',
  created() {
    localStorage.removeItem(process.env.VUE_APP_JWT_STORAGE_NAME);
    localStorage.removeItem(process.env.VUE_APP_JWT_STORAGE_LOGIN_AS_NAME);
    localStorage.removeItem('x-switch-user');
    Vue.http.headers.common['x-switch-user'] = null;
    this.$store.commit('setUser', null);
    this.$store.dispatch('employeeRequest/reset');
    this.$router.push(LOGIN_ROUTE);
  },
};
</script>
