<template>
  <one-page style="width: 100%">
    <v-row>
      <v-col cols="12" md="3" class="mx-0 px-0">
        <one-profile-summary>
          <one-field-date label="Data od" v-model="model.startedAtFrom" icon="today" class="col-sm-12" clearable />
        </one-profile-summary>
      </v-col>

      <v-col cols="12" md="3" class="mx-0 px-0">
        <one-profile-summary>
          <one-field-date label="Data do" v-model="model.startedAtTo" icon="today" class="col-sm-12" clearable />
        </one-profile-summary>
      </v-col>

      <v-col cols="12" md="3">
        <v-list two-line class="pa-0" style="margin-top: 12px">
          <v-list-item>
            <v-list-item-avatar>
              <v-btn @click.prevent="addCompany" color="success" text small icon>
                <v-icon>add_circle</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <v-autocomplete
                  label="Firma"
                  hide-details
                  :items="data.companies"
                  clearable
                  v-model="tmp.company"
                  :loading="loadingCompanies"
                  item-text="name"
                  return-object
                ></v-autocomplete>
              </v-list-item-title>
              <v-list-item-subtitle>Firma</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <one-list :items="model.companies">
          <template #items="props">
            <v-list-item>
              <v-list-item-action>
                <v-btn @click.prevent="removeCompany(props.item)" icon color="error" text>
                  <v-icon>close</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ props.item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </one-list>
      </v-col>

      <v-col cols="12" md="3">
        <v-list two-line class="pa-0" style="margin-top: 12px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-autocomplete
                  label="Status akceptacji"
                  hide-details
                  :items="data.listOfApprovalStatuses"
                  clearable
                  v-model="model.companyApproved"
                  item-text="name"
                  item-value="value"
                ></v-autocomplete>
              </v-list-item-title>
              <v-list-item-subtitle>Akceptacja przez firmę kolejową (do rozliczenia)</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" class="text-right">
        <v-btn color="primary" @click="generateStatistics" large>Generuj statystyki</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="data.listOfStatisticsGroupedByCompany.length" class="wrap">
      <v-col md="3" v-for="companyStatistics in data.listOfStatisticsGroupedByCompany" :key="companyStatistics.id">
        <v-card :disabled="companyStatistics.isDisabled">
          <v-card-title class="text-h5">{{ companyStatistics.company.name }}</v-card-title>
          <v-card-text>
            <div>
              Suma kilometrów:
              <span>{{ companyStatistics.statistics.sumDistanceWithDefinedKilometers || '-' }}</span>
            </div>
            <div>
              Suma godzin oczekiwania:
              <span>{{ companyStatistics.statistics.sumDriverWaiting || '-' }}</span>
            </div>
            <div>
              Suma opłat za autostrady:
              <span>{{ companyStatistics.statistics.sumDriverCosts || '-' }}</span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="companyStatistics.isDisabled"
              @click="generateReport(companyStatistics.company)"
            >
              Raport
            </v-btn>
            <v-btn color="primary" disabled>Faktura</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </one-page>
</template>

<script>
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import download from 'downloadjs';
import OneFieldDate from '@/component/Field/Date/date-field';
import OnePage from '@/component/Page/page-index';
import OneProfileSummary from '@/component/Profile/profile-summary';
import OneList from '@/component/List/list-index';

export default {
  components: { OneList, OneProfileSummary, OnePage, OneFieldDate },
  data() {
    return {
      model: {
        startedAtFrom: null,
        startedAtTo: null,
        companyApproved: null,
        courseStatuses: [5],
        baseTransportCompanyIds: [this.$store.getters.user.company.id],
        companies: [],
      },
      data: {
        companies: [],
        listOfApprovalStatuses: [
          {
            name: 'Potwierdzone',
            value: true,
          },
          {
            name: 'Niepotwierdzone',
            value: false,
          },
        ],
        listOfStatisticsGroupedByCompany: [],
      },
      tmp: {
        company: null,
      },
      loadingCompanies: true,
      loadingStatistics: null,
    };
  },

  created() {
    this.fetchCompanies();
  },

  methods: {
    fetchCompanies() {
      this.$http
        .get('api/companies')
        .then(({ data }) => {
          this.loadingCompanies = false;
          this.data.companies = data['hydra:member'];
          this.model.companies = this.data.companies;
        })
        .catch(({ data, status }) => this.$notify(defaultApiResultManager.catch(data, status)));
    },

    fetchStatistics(postData) {
      return this.$http
        .post('financial_report/courses', postData)
        .then(({ data }) => data[0])
        .catch(({ data, status }) => this.$notify(defaultApiResultManager.catch(data, status)));
    },

    addCompany() {
      if (this.tmp.company) {
        this.model.companies.push(this.tmp.company);
        this.data.companies = this.data.companies.filter((company) => company.id !== this.tmp.company.id);
        this.tmp.company = null;
      }
    },

    removeCompany(companyToRemove) {
      this.model.companies = this.model.companies.filter((company) => company.id !== companyToRemove.id);
      this.data.companies.push(companyToRemove);
    },

    getBaseDataForStatisticsAndReport() {
      return {
        base_transport_company_ids: this.model.baseTransportCompanyIds,
        course_statuses: this.model.courseStatuses,
        company_approved: this.model.companyApproved,
        started_at_from: this.model.startedAtFrom ? `${this.model.startedAtFrom} 00:00:00` : this.model.startedAtFrom,
        started_at_to: this.model.startedAtTo ? `${this.model.startedAtTo} 23:59:59` : this.model.startedAtTo,
      };
    },

    generateStatistics() {
      this.loadingStatistics = true;
      const promises = this.model.companies.map((company) =>
        this.fetchStatistics({
          ...this.getBaseDataForStatisticsAndReport(),
          company_ids: [company.id],
        }).then((statistics) => ({
          company,
          statistics,
          isDisabled:
            !statistics.sumDistanceWithDefinedKilometers && !statistics.sumDriverWaiting && !statistics.sumDriverCosts,
        }))
      );

      Promise.all(promises).then((statistics) => {
        this.data.listOfStatisticsGroupedByCompany = statistics;
        this.loadingStatistics = false;
      });
    },

    generateReport(company) {
      this.$http
        .post('financial_report/courses/xls', {
          ...this.getBaseDataForStatisticsAndReport(),
          company_ids: [company.id],
        })
        .then((result) => {
          download(result.data, 'report.xls', 'application/vnd.ms-excel');
        });
    },
  },
};
</script>
