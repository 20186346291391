<template>
  <AbstractNotification
    icon="mdi-check-circle-outline"
    color="#4CAF50"
    v-bind="$attrs"
    v-on="$listeners"
  ></AbstractNotification>
</template>

<script>
import AbstractNotification from './abstract-notification';

export default {
  name: 'SuccessNotification',
  inheritAttrs: false,
  components: {
    AbstractNotification,
  },
};
</script>
