<template>
  <div>
    <v-autocomplete
      :class="{ 'mb-2': val }"
      v-model="val"
      :items="items"
      no-filter
      :search-input.sync="search"
      :loading="isLoading"
      item-text="description"
      item-value="place_id"
      hide-details="auto"
      outlined
      hint="Wpisz nazwę lub adres miejsca, przeszukamy bazę Goole Maps"
      persistent-hint
      hide-no-data
      :rules="[rules.required]"
      @input="onPlaceSelection"
      @update:search-input="onSearchUpdate"
    >
      <template #label>
        <span>Znajdź miejsce</span>
        <span class="red--text">*</span>
      </template>
    </v-autocomplete>
    <div v-show="val" class="map-preview" ref="map" data-testid="google-map"></div>
    <EditSelectedPlaceModal
      :value="editDialog"
      :coords="coords"
      @close="editDialog = false"
      @selectNewAddress="onSelectNewAddress"
    ></EditSelectedPlaceModal>
  </div>
</template>

<script>
import Vue from 'vue';
import vuetify from '@/core/config/vuetify';
import { INITIAL_MAP_CENTER, INITIAL_MAP_ZOOM, MAP_ZOOM_TYPES } from '@/core/dict/google-map-dict';
import formValidationRules from '@/core/utils/form-validation-rules';
import CustomPin from '@/component/GMap/custom-pin';
import { debounce } from 'lodash';
import EditSelectedPlaceButton from './edit-selected-place-button';
import EditSelectedPlaceModal from './edit-selected-place-modal';

export default {
  name: 'FindPlaceField',
  components: {
    EditSelectedPlaceModal,
  },
  props: {
    placeEntityCopy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      val: null,
      search: null,
      items: [],
      isLoading: false,
      rules: { ...formValidationRules },
      googleAutocompleteService: null,
      placesService: null,
      geocoderService: null,
      SessionTokenService: null,
      sessionToken: null,
      map: null,
      customPin: null,
      editDialog: false,
      pickBySearchTerm: false,
    };
  },
  created() {
    this.isLoading = true;
    this.initGoogleService().then(() => {
      this.debouncedInputFunction = debounce(this.fetchPlacesBySearchTerm, 700);
      this.initInput();
      this.isLoading = false;
    });
  },
  computed: {
    coords() {
      return {
        lat: this.placeEntityCopy?.latitude,
        lng: this.placeEntityCopy?.longitude,
        address: this.placeEntityCopy?.address,
      };
    },
    selectedPlaceDescription() {
      const place = this.items.find((item) => item.place_id === this.val);
      return place ? place.description : null;
    },
  },
  methods: {
    async initGoogleService() {
      /* eslint-disable-next-line no-undef */
      const { AutocompleteService, AutocompleteSessionToken, PlacesService } = await google.maps.importLibrary(
        'places'
      );
      // eslint-disable-next-line no-undef
      const { Geocoder } = await google.maps.importLibrary('geocoding');
      // eslint-disable-next-line no-undef
      const { Map } = await google.maps.importLibrary('maps');
      this.SessionTokenService = AutocompleteSessionToken;
      this.sessionToken = new this.SessionTokenService();
      // eslint-disable-next-line no-undef
      this.map = new Map(this.$refs.map, {
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        zoom: INITIAL_MAP_ZOOM,
        center: INITIAL_MAP_CENTER,
        gestureHandling: 'none',
        disableDefaultUI: true,
      });
      this.placesService = new PlacesService(this.map);
      const editBtn = this.createComponent(EditSelectedPlaceButton);
      editBtn.$on('click', () => {
        this.editDialog = true;
      });
      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(editBtn.$el);

      this.googleAutocompleteService = new AutocompleteService();
      this.geocoderService = new Geocoder();
      this.createCustomMarker();
    },
    fetchPlacesBySearchTerm(val) {
      if (val === '' || val === null || this.pickBySearchTerm) {
        if (this.pickBySearchTerm) {
          this.pickBySearchTerm = false;
        }

        if (val === null) {
          this.items = [];
          this.val = null;
        }
        return;
      }

      this.isLoading = true;

      this.googleAutocompleteService.getPlacePredictions(
        {
          input: val,
          componentRestrictions: {
            country: ['pl', 'de'],
          },
          sessionToken: this.sessionToken,
        },
        (data) => {
          this.items = data ?? [];
          this.isLoading = false;
        }
      );
    },
    onPlaceSelection(val) {
      if (val) {
        this.isLoading = true;
        this.placesService.getDetails(
          { placeId: val, fields: ['geometry'], sessionToken: this.sessionToken },
          ({ geometry }, status) => {
            this.sessionToken = new this.SessionTokenService();
            this.isLoading = false;
            // eslint-disable-next-line no-undef
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              this.$emit('update:placeEntityCopy', {
                ...this.placeEntityCopy,
                address: this.selectedPlaceDescription,
                latitude: geometry.location.lat(),
                longitude: geometry.location.lng(),
              });
              this.marker.position = {
                lat: geometry.location.lat(),
                lng: geometry.location.lng(),
              };
              this.map.panTo(this.marker.position);
              this.map.setZoom(MAP_ZOOM_TYPES.CITY);
            }
          }
        );
      }
    },
    onSearchUpdate(val) {
      // prevent from request when place selected
      if (this.selectedPlaceDescription && this.selectedPlaceDescription === val) {
        return;
      }

      this.debouncedInputFunction(val);
    },
    createComponent(component) {
      const ComponentClass = Vue.extend(component);
      const instance = new ComponentClass({
        vuetify,
      });
      instance.$mount();
      return instance;
    },
    async createCustomMarker() {
      /* eslint-disable-next-line no-undef */
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

      this.customPin = this.createComponent(CustomPin);

      this.marker = new AdvancedMarkerElement({
        map: this.map,
        content: this.customPin.$el,
      });
    },
    onSelectNewAddress({ placeId, ...val }) {
      this.$emit('update:placeEntityCopy', {
        ...this.placeEntityCopy,
        ...val,
      });
      this.marker.position = {
        lat: val.latitude,
        lng: val.longitude,
      };
      this.map.panTo(this.marker.position);
      this.items = [
        {
          description: val.address,
          place_id: placeId,
        },
      ];
      this.val = placeId;
      this.pickBySearchTerm = true;
    },
    initInput() {
      if (this.placeEntityCopy.address !== null) {
        this.geocoderService.geocode(
          {
            location: {
              lat: this.placeEntityCopy.latitude,
              lng: this.placeEntityCopy.longitude,
            },
          },
          ([place]) => {
            this.items = [{ ...place, description: this.placeEntityCopy.address }];
            this.val = place.place_id;
            this.pickBySearchTerm = true;
            this.marker.position = this.coords;
            this.map.panTo(this.coords);
          }
        );
      }
    },
  },
  watch: {
    placeEntityCopy() {
      this.initInput();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.map-preview {
  @include map-styles(144px);
}

::v-deep a[title='Pokaż ten obszar w Mapach Google (otwiera się w nowym oknie)'] {
  display: none !important;
}

::v-deep .gmnoprint a,
::v-deep .gmnoprint span,
::v-deep .gm-style-cc {
  display: none;
}
::v-deep .gmnoprint div {
  background: none !important;
}
</style>
