export default class EditPassengerStrategy {
  constructor(store) {
    this.store = store;
  }

  async save(data) {
    const payload = await this.store.dispatch('place/saveNewHomeplace', data);

    return this.store.dispatch('passenger/updatePassenger', payload);
  }
}
