<template>
  <div>
    <alert-info v-if="isRideReportAvailable" class="mb-2">
      <div class="alert__container">
        <p class="mb-0">Twoja ocena pomoże w ulepszeniu usług.</p>
        <base-text-button @click="openRideRating">Oceń przejazd</base-text-button>
      </div>
    </alert-info>

    <RideRatingForm :dialog="rideRatingDialog" :course="course" @close="closeRideRating"></RideRatingForm>
  </div>
</template>

<script>
import AlertInfo from '@/component/Alert/alert-info';
import BaseTextButton from '@/component/Base/base-text-button';
import RideRatingForm from '@/component/NewCourse/ride-rating-form';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { mapGetters } from 'vuex';

export default {
  name: 'TaxiCourseRating',
  components: {
    AlertInfo,
    BaseTextButton,
    RideRatingForm,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rideRatingDialog: false,
    };
  },
  computed: {
    ...mapGetters(['isPassenger', 'user']),
    isRideReportAvailable() {
      return this.isPassenger && this.isRideInWhitelist && this.isRideReportUncompleted;
    },
    isRideInWhitelist() {
      return [
        TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY,
        TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER,
        TAXI_COURSE_RIDE_STATUS.FINISHED,
        TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CUSTOMER,
        TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR,
      ].includes(this.course.rides[0].status);
    },
    isRideReportUncompleted() {
      return this.course.rides[0]?.rideRatings?.find((rating) => rating.createdBy.id === this.user.id) === undefined;
    },
  },
  methods: {
    openRideRating() {
      this.rideRatingDialog = true;
    },
    closeRideRating() {
      this.rideRatingDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
