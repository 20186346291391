<template>
  <div>
    <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
    <CompanyContractorStakesTable
      v-if="isStakesDefined"
      :companyContractorStakes="selectedEmployeeGroup.stakes"
    ></CompanyContractorStakesTable>
    <NoDataText v-else class="pt-10 pb-2">Brak zdefiniowanych warunków współpracy</NoDataText>
    <TextButton block prependIcon="mdi-plus" @click="openNewStakesModal">Dodaj nowe stawki</TextButton>
    <NewStakesModal :value="newStakesModal" :strategy="strategy" @close="closeNewStakesModal"></NewStakesModal>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import NoDataText from '@/component/Field/Generic/no-data-text';
import TextButton from '@/component/Base/base-text-button';
import NewStakesModal from '@/controller/Page/page-toolbar/new-stakes-modal.vue/new-stakes-modal';

import CompanyContractorStakesTable from '@/controller/Page/page-toolbar/new-stakes-modal.vue/company-contractor-stakes-table';
import { AddEmployeeGroupStakeStrategy } from './add-employee-group-stake-strategy';

export default {
  name: 'StakesTable',
  components: {
    BaseTypography,
    CompanyContractorStakesTable,
    NoDataText,
    TextButton,
    NewStakesModal,
  },
  props: {
    selectedEmployeeGroup: {
      default: null,
    },
  },
  data() {
    return {
      newStakesModal: false,
      strategy: new AddEmployeeGroupStakeStrategy(this.$store, this.selectedEmployeeGroup),
    };
  },
  computed: {
    isStakesDefined() {
      return this.selectedEmployeeGroup.stakes.length > 0;
    },
  },
  methods: {
    openNewStakesModal() {
      this.newStakesModal = true;
    },
    closeNewStakesModal() {
      this.newStakesModal = false;
    },
  },
};
</script>
