import PageContainer from '@/controller/Page/page-container';
import LoginPage from '@/controller/Auth';
import LogoutPage from '@/controller/Auth/Logout';
import * as routes from '@/core/router/route-names';
import Page from './page';
import SettingsChildren from './Page/settings';

const SettingsOverview = () => import('@/controller/Settings/SettingsOverview/settings-overview');
const TaxiCourseInfo = () => import('@/controller/NewCourse/course-info');
const UserPasswordResetForm = () => import('@/controller/Settings/user-password-reset-form');
const UserPasswordResetRequest = () => import('@/controller/Settings/user-password-reset-request');

export default [
  {
    path: '/',
    component: PageContainer,
    children: Page,
  },
  {
    path: '/login',
    name: routes.LOGIN_ROUTE,
    component: LoginPage,
  },
  {
    path: '/logout',
    name: routes.LOGOUT_ROUTE,
    component: LogoutPage,
  },
  {
    path: '/kurs/:id',
    name: routes.TAXI_COURSE_CREATE_INFO,
    component: TaxiCourseInfo,
  },
  {
    path: '/kurs/404',
    name: routes.TAXI_COURSE_NOT_FOUND,
    component: () => import('@/controller/NewCourse/course-not-found'),
  },
  {
    path: '/kilometer-course-create',
    name: routes.KILOMETER_COURSE_CREATE_ROUTE,
    component: () => import('@/controller/KilometerCourse/kilometer-course-form'),
  },
  {
    path: '/settings',
    component: SettingsOverview,
    children: SettingsChildren,
  },
  {
    path: '/reset-password/:token',
    name: routes.USER_PASSWORD_RESET_ROUTE,
    props: true,
    component: UserPasswordResetForm,
  },
  {
    path: '/unauthorized-reset-password-request',
    name: routes.UNAUTHORIZED_PASSWORD_RESET_REQUEST_ROUTE,
    props: {
      navigateTo: routes.LOGIN_ROUTE,
    },
    component: UserPasswordResetRequest,
  },
];
