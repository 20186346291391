<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader title="Samochód">
      <template #append-item>
        <RemoveIconButton @click="openConfirmModal"></RemoveIconButton>
      </template>
      <template #close>
        <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
      </template>
    </AbstractModalHeader>

    <ConfirmDialog
      :showDialog="isConfirmModalOpen"
      text="Czy chcesz usunąć samochód?"
      @confirm="onConfirm"
    ></ConfirmDialog>

    <v-card-text>
      <v-tabs v-model="selectedTab" grow>
        <v-tab>Szczegóły</v-tab>
      </v-tabs>

      <v-tabs-items class="mt-6" v-model="selectedTab">
        <v-tab-item>
          <DescriptionSection>
            <DescriptionItem title="Numer rejestracyjny">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.registrationNumber }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Marka">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.brand }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Model">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.model }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Numer VIN">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.vin }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Kierowca">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.driverName }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Stan licznika">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.initialMileage | mileageFormatter }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Pojemność silnika">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.engineCapacity | engineCapacityFormatter }}
              </BaseTypography>
            </DescriptionItem>
            <DescriptionItem title="Dodane przez">
              <BaseTypography variant="subtitle-3">
                {{ carEntity && carEntity.createdAt | datetimeHourFirst }}
                {{ carEntity && `przez ${carEntity.createdBy.name}` }}
              </BaseTypography>
            </DescriptionItem>
          </DescriptionSection>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import RemoveIconButton from '@/component/Button/remove-icon-button';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import BaseTypography from '@/component/Base/base-typography';
import ConfirmDialog from '@/component/Dialog/confirm-dialog';

export default {
  name: 'CarPreviewModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    RemoveIconButton,
    DescriptionSection,
    DescriptionItem,
    BaseTypography,
    ConfirmDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    carEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
      isConfirmModalOpen: false,
    };
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    onConfirm(confirmation) {
      if (confirmation) {
        this.$store.dispatch('car/deleteCar', this.carEntity).then(() => {
          this.$emit('carCreated', null);
          this.emitCloseEvent();
        });
      }
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
