<template>
  <v-radio class="mb-4" v-bind="$attrs" v-on="$listeners">
    <template #label>
      <p class="text-body-2 mb-0 grey--text text--darken-4">{{ label }}</p>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: 'RadioField',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
