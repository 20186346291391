<template>
  <v-dialog
    :value="dialog"
    transition="dialog-bottom-transition"
    @click:outside="emitCloseEvent"
    @keydown.esc="emitCloseEvent"
    fullscreen
  >
    <v-card>
      <v-card-title>
        <v-btn icon color="rgba(0, 0, 0, 0.87)" @click="emitCloseEvent"><v-icon>mdi-close</v-icon></v-btn>
        <span class="ride-rating__title">Oceń przejazd</span>
        <base-text-button :loading="requestPending" @click="postRideRating">wyślij</base-text-button>
      </v-card-title>
      <v-card-text class="ride-rating__form">
        <v-alert color="primary" text icon="mdi-shield-alert-outline">
          <p class="alert__message">Kierowca nie zobaczy Twojej oceny.</p>
        </v-alert>

        <section>
          <p class="ride-rating__header">Jak oceniasz przejazd z {{ courseExecutorName }}?</p>

          <v-item-group v-model="rideExperience">
            <div class="d-flex justify-space-between mb-7 mt-5">
              <v-item v-slot="{ active, toggle }" v-for="mark in ratingMarks" :key="mark.value">
                <div class="d-flex flex-column align-center">
                  <v-icon :color="active ? 'primary' : ''" size="53" @click="toggle">
                    {{ active ? mark.icon : `${mark.icon}-outline` }}
                  </v-icon>
                  <span>{{ mark.text }}</span>
                </div>
              </v-item>
            </div>
          </v-item-group>

          <v-text-field
            v-if="rideExperience === 0"
            v-model="rideExperienceMessage"
            placeholder="Co sprawiło, że jesteś niezadowolony?"
            outlined
          ></v-text-field>
        </section>

        <v-divider></v-divider>

        <section>
          <p class="ride-rating__header">Czas oczekiwania na kierowcę</p>
          <v-chip-group class="mt-3" v-model="selectedWaitingTime" active-class="custom--selected" column mandatory>
            <v-chip outlined color="primary" v-for="time in waitingTime" :key="time.text">{{ time.text }}</v-chip>
          </v-chip-group>
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseTextButton from '@/component/Base/base-text-button';
import { RIDE_RATING_MARK, RIDE_RATING_EXTREME_WAITING } from '@/core/dict/taxi-course-dict';

export default {
  name: 'RideRatingForm',
  components: { BaseTextButton },
  props: {
    course: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      waitingTime: [
        { text: '0 min', value: 0 },
        { text: '5 min', value: 300 },
        { text: '15 min', value: 900 },
        { text: '30 min', value: 1800 },
        { text: '1 h', value: 3600 },
        { text: 'Ponad 1 h', value: RIDE_RATING_EXTREME_WAITING.OVER_HOUR },
        { text: 'Kierowca nie przyjechał', value: RIDE_RATING_EXTREME_WAITING.DRIVER_NOT_ARRIVED },
      ],
      ratingMarks: [
        {
          text: 'Negatywnie',
          value: RIDE_RATING_MARK.BAD,
          icon: 'mdi-emoticon-sad',
        },
        {
          text: 'Neutralnie',
          value: RIDE_RATING_MARK.AVERAGE,
          icon: 'mdi-emoticon-neutral',
        },
        {
          text: 'Pozytywnie',
          value: RIDE_RATING_MARK.GOOD,
          icon: 'mdi-emoticon-happy',
        },
      ],
      selectedWaitingTime: null,
      rideExperience: null,
      rideExperienceMessage: null,
    };
  },
  computed: {
    courseExecutorName() {
      return this.course.driver?.name || this.course.subcontractor?.name || this.course.contractor?.name;
    },
    requestPending() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
    isExtraWaitingTimeSelected() {
      const selectedWaitingTimeVal = this.waitingTime[this.selectedWaitingTime].value;

      return (
        RIDE_RATING_EXTREME_WAITING.OVER_HOUR === selectedWaitingTimeVal ||
        RIDE_RATING_EXTREME_WAITING.DRIVER_NOT_ARRIVED === selectedWaitingTimeVal
      );
    },
    rideRating() {
      return {
        ride: this.course.rides[0]['@id'],
        mark: this.ratingMarks[this.rideExperience].value,
        comment: this.rideExperienceMessage,
        ...(this.isExtraWaitingTimeSelected ? this.extraWaitingTimeRating : this.normalWaitingTimeRating),
      };
    },
    extraWaitingTimeRating() {
      return { waitingTime: null, extremeWaiting: this.waitingTime[this.selectedWaitingTime].value };
    },
    normalWaitingTimeRating() {
      return { waitingTime: this.waitingTime[this.selectedWaitingTime].value, extremeWaiting: null };
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
    async postRideRating() {
      if (this.rideExperience === null) {
        this.$notify({
          group: 'global',
          type: 'error',
          text: 'Należy wybrać ocenę przejazdu',
        });
        return;
      }

      await this.$store.dispatch('taxiCourse/postRideRating', { payload: this.rideRating, courseId: this.course.id });

      this.emitCloseEvent();
    },
  },
};
</script>

<style lang="scss" scoped>
$text-color: rgba(0, 0, 0, 0.87);
$text-font: 'Roboto';

.ride-rating {
  &__title {
    font: 500 20px/32px $text-font;
    letter-spacing: 0.15px;
    color: $text-color;
    margin-left: 29px;
    flex-grow: 1;
  }

  &__header {
    font: 600 14px/20px $text-font;
    color: $text-color;
    margin-top: 29px;
    margin-bottom: 0;
  }

  &__form {
    margin-top: 14px;
  }
}

.alert {
  &__message {
    font: 400 14px/18px $text-font;
    letter-spacing: 0.25px;
    color: #0d3c61;
    margin-bottom: 0;
  }
}

::v-deep.v-chip.v-chip--active.v-chip.custom--selected {
  background-color: #007dff !important;
  color: white !important;
}
</style>
