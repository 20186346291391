import ServerSentEventService from '@/service/ServerSentEventService';

export default {
  namespaced: true,
  state: {
    eventSource: null,
  },
  mutations: {
    setEventSource: (state, val) => {
      state.eventSource = val;
    },
  },
  getters: {
    eventSource: (state) => state.eventSource,
  },
  actions: {
    openConnection({ commit, rootGetters }) {
      commit('setEventSource', ServerSentEventService.getEventSource(rootGetters.userTopics));
    },
  },
};
