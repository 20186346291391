<template>
  <div>
    <!-- MOBILE -->
    <template v-if="isMobile">
      <MobileCourseSegmentForm
        v-for="(segment, index) in segments"
        :key="segment.id"
        :segment="segment"
        :index="index"
        :companyId="companyId"
        :showDetails="isFirstOrIntermediateWaypoint(index)"
        :isLastWaypoint="index === segments.length - 1"
        :isSingleWaypointCourse="segments.length === 1"
        @addWaypoint="addWaypoint"
        @addWaypointAfter="addWaypointAfter"
        @deleteWaypoint="deleteWaypoint"
        @calculateRoute="calculateRouteDistance"
        @calculateTime="calculateRouteTime"
        @locomotiveSelected="locomotiveSelected"
        data-testid="segment-item"
      />
    </template>

    <v-timeline v-else dense>
      <div v-for="(segment, index) in segments" :key="segment.id" data-testid="segment-item">
        <add-waypoint-desktop-button class="mb-4" @click="addWaypoint(index)" data-testid="add-waypoint-before-btn" />
        <v-timeline-item fill-dot>
          <template #icon>
            <v-hover v-slot="{ hover }">
              <span v-if="!hover" class="white--text font-weight-bold">{{ generateWaypointName(index) }}</span>
              <v-btn v-else class="pa-5 rounded-circle error" color="white" icon @click="deleteWaypoint(index)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-hover>
          </template>
          <waypoint-form
            :segment="segment"
            :companyId="companyId"
            :showDetails="isFirstOrIntermediateWaypoint(index)"
            @calculateRoute="calculateRouteDistance(index, $event)"
            @calculateTime="calculateRouteTime(index)"
            @locomotiveSelected="locomotiveSelected($event, index)"
          />
        </v-timeline-item>
        <add-waypoint-desktop-button
          v-if="index === segments.length - 1"
          class="mt-4"
          @click="addWaypointAfter(index)"
          data-testid="add-waypoint-after-btn"
        />
      </div>
    </v-timeline>
  </div>
</template>

<script>
import MobileCourseSegmentForm from '@/component/NewCourse/Form/Ride/Segment//mobile-course-segment-form';
import AddWaypointDesktopButton from '@/component/NewCourse/Form/Ride/Segment/add-waypoint-desktop-button';
import WaypointForm from '@/component/NewCourse/Form/Ride/Segment/waypoint-form';
import waypointNameGenerator from '@/core/mixin/waypointNameGenerator';
import windowSize from '@/core/mixin/windowSize';

const DESKTOP_BREAKPOINT = 980;

export default {
  components: {
    WaypointForm,
    MobileCourseSegmentForm,
    AddWaypointDesktopButton,
  },
  mixins: [waypointNameGenerator, windowSize(DESKTOP_BREAKPOINT)],
  props: {
    segments: { type: Array },
    companyId: {},
  },
  methods: {
    async addWaypoint(index) {
      this.$store.dispatch('courseCreation/addWaypoint', index);
      this.showAddWaypointNotification(index);
    },
    async addWaypointAfter(index) {
      const indexAfter = index + 1;
      this.$store.dispatch('courseCreation/addWaypoint', indexAfter);
      this.showAddWaypointAfterNotification(index);
    },
    deleteWaypoint(indexToDelete) {
      this.$store.dispatch('courseCreation/deleteWaypoint', indexToDelete);
    },
    calculateRouteDistance(index, place) {
      this.$store.dispatch('courseCreation/setPlace', { index, place });
      this.$store.dispatch('courseCreation/calculateWaypointDistance', { index, companyId: this.companyId });
    },

    calculateRouteTime(index) {
      if (this.isTimeValid(index)) {
        this.$store.commit('courseCreation/SET_WAYPOINT_TIME', index);
      }
    },

    isTimeValid(index) {
      const [arrivingDate, arrivingTime] = this.segments[index].waypointStart.time.split('T');

      return arrivingDate.length && arrivingTime.length;
    },

    locomotiveSelected(locomotives, index) {
      this.$store.commit('courseCreation/SET_LOCOMOTIVE', { index, locomotives });
    },

    isFirstOrIntermediateWaypoint(index) {
      const FIRST_WAYPOINT_INDEX = 0;
      const LAST_WAYPOINT_INDEX = this.segments.length - 1;

      return index === FIRST_WAYPOINT_INDEX || index !== LAST_WAYPOINT_INDEX;
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-form__header {
  font: 700 16px/19px 'Roboto';
  letter-spacing: 0.15px;
}
// hide vuetify divider
.v-timeline::before {
  display: none;
}

::v-deep .v-timeline-item__divider::after,
::v-deep .v-timeline-item__divider::before {
  content: '';
  width: 2px;
  position: absolute;
  height: calc(50% - 38px);
  background-color: rgba(224, 224, 224, 1);
  border-radius: 2px;
}

::v-deep .v-timeline-item__divider::after {
  bottom: 0;
}
::v-deep .v-timeline-item__divider::before {
  top: 0;
}

::v-deep .v-timeline-item__dot {
  box-shadow: none;
}

// remove divider for last waypoint
.v-timeline > div:last-of-type ::v-deep.v-timeline-item:last-of-type .v-timeline-item__divider::after {
  display: none;
}
</style>
