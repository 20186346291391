<template>
  <v-navigation-drawer right v-model="$root.rightSidebar.active" temporary fixed width="300">
    <v-subheader><h4 class="text-center">Brakujące odcinki zdefiniowanych kilometrów</h4></v-subheader>
    <one-list :items="$root.rightSidebar.blankDefinedKilometers">
      <template #items="props">
        <v-container fluid class="pa-6">
          <v-row>
            <v-col md="5" class="pb-0 mb-0">
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ props.item[Object.keys(props.item)[0]].name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-row align="center">
              <v-col md="2">
                <v-icon color="green darken-2">arrow_forward</v-icon>
              </v-col>
            </v-row>
            <v-col md="5" class="pb-0 mb-0">
              <v-list two-line class="py-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ props.item[Object.keys(props.item)[1]].name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row class="ml-0 mr-0">
            <v-col md="12">
              <one-profile-summary class="col-12">
                <v-list two-line class="py-0">
                  <v-list-item>
                    <v-form ref="form" v-model="valid" lazy-validation class="col-12">
                      <v-list-item-content>
                        <v-text-field
                          v-model="props.item.kilometers"
                          class="col-12"
                          label="Liczba kilometrów"
                          data-cy="drawer-defined-kilometers"
                          type="number"
                          flat
                          prepend-icon="directions_car"
                          :rules="kilometerRules"
                        />

                        <v-text-field
                          v-model="props.item.time"
                          class="col-12"
                          label="Czas potrzebny na przejechanie odcinka (HH:MM)"
                          data-cy="drawer-defined-time"
                          type="text"
                          flat
                          prepend-icon="today"
                          :rules="timeRules"
                        />
                      </v-list-item-content>
                    </v-form>
                  </v-list-item>
                </v-list>
              </one-profile-summary>
              <one-form-action center>
                <v-btn
                  data-cy="drawer-add-defined-kilometers"
                  class="ma-2"
                  color="primary"
                  :loading="waiting"
                  :disabled="waiting"
                  @click="store(props.item)"
                >
                  Zapisz
                </v-btn>
              </one-form-action>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </one-list>
  </v-navigation-drawer>
</template>

<script>
import OneFormAction from '@/component/Form/Action/action-form';
import OneProfileSummary from '@/component/Profile/profile-summary';
import OneList from '@/component/List/list-index';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  components: { OneList, OneProfileSummary, OneFormAction },
  data() {
    return {
      valid: true,
      drawer: true,
      rightSidebar: this.$root.rightSidebar,
      waiting: false,
      kilometerRules: [(v) => !!v || 'Podaj kilometry'],
      timeRules: [
        (v) => !!v || 'Podaj czas (HH:MM)',
        (v) => /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Niepoprawny format czasu (HH:MM)!',
      ],
    };
  },

  methods: {
    store(definedKilometers) {
      this.waiting = true;
      // eslint-disable-next-line no-param-reassign
      definedKilometers.kilometers = parseInt(definedKilometers.kilometers, 10);
      this.$http
        .post('api/defined_kilometers', {
          ...definedKilometers,
          placeStart: definedKilometers.placeStart['@id'],
          placeEnd: definedKilometers.placeEnd['@id'],
          time: +`${definedKilometers.time}:00`.split(':').reduce((acc, time) => 60 * acc + +time),
        })
        .then(({ status }) => {
          this.waiting = false;
          if (status === 201) {
            this.rightSidebar.blankDefinedKilometers = this.rightSidebar.blankDefinedKilometers.filter(
              (el) => el !== definedKilometers
            );
            this.rightSidebar.active = this.rightSidebar.blankDefinedKilometers.length !== 0;

            this.$notify(defaultApiResultManager.then('zapisano'));
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-param-reassign
          error = error.body.violations.pop();
          this.$notify({
            group: 'global',
            type: 'error',
            title: 'Błąd zapisu',
            text: error ? error.message : 'Wystąpił błąd podczas zapisu',
          });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
  },
};
</script>
