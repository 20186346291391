<template>
  <section>
    <BaseTypography class="pt-4" variant="subtitle">Adres domowy</BaseTypography>
    <v-tooltip bottom :disabled="isEntityDefined" nudge-left="100" nudge-bottom="-10">
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <HomeBtnFactory
            :place="homePlace"
            :disabled="!isEntityDefined"
            :buttons="buttons"
            @openHomeAddressDialog="openHomeplaceModal"
            @selectHomeplace="openHomeplaceModal"
          ></HomeBtnFactory>
        </span>
      </template>
      <div>Adres domowy można zdefiniować poprzez edycję istniejącego już użytkownika</div>
    </v-tooltip>

    <HomeplaceModal :state="state" :strategy="strategy" @close="closeHomeplaceModal"></HomeplaceModal>
  </section>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import HomeBtnFactory from '@/controller/KilometerCourse/places/home-btn-factory';
import HomeplaceModal from '@/component/Field/Place/homeplace-modal';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';

export default {
  name: 'HomeplaceSection',
  components: {
    HomeBtnFactory,
    BaseTypography,
    HomeplaceModal,
  },
  props: {
    homePlace: {
      required: true,
    },
    entity: {
      required: true,
    },
    strategy: {
      required: true,
    },
    removeStrategy: {
      required: true,
    },
  },
  data() {
    return {
      state: MODAL_STATE.CLOSED,
    };
  },
  computed: {
    buttons() {
      return this.homePlace
        ? [
            {
              title: 'Usuń miejsce',
              command: this.removeStrategy,
            },
          ]
        : [];
    },
    isEntityDefined() {
      return this.entity.id != null && this.entity['@id'] != null;
    },
  },
  methods: {
    openHomeplaceModal() {
      this.state = this.homePlace
        ? { ...MODAL_STATE.EDIT_ENTITY_MODAL, selectedEntity: this.homePlace }
        : MODAL_STATE.CREATE_ENTITY_MODAL;
    },
    closeHomeplaceModal(val) {
      this.state = MODAL_STATE.CLOSED;
      this.emitSetHomeplace(val);
    },
    emitSetHomeplace(val) {
      this.$emit('setHomeplaceFromMap', val);
    },
  },
};
</script>
