import Vue from 'vue';
import router from '@/core/config/router/index';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  fetchCompany: (id) => Vue.http.get(`api/companies/${id}`),

  insertCompany(company) {
    return Vue.http
      .post(`api/companies`, company)
      .then(() => {
        Vue.notify(defaultApiResultManager.then('Nowa firma została dodana'));
        router.push({ name: 'company_index' });
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  createRailwayCompany(company) {
    return Vue.http
      .post(`api/companies`, company)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Nowa firma kolejowa została dodana'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        console.error(data);
      });
  },

  // TODO: probably is the duplication of the same method for creating the railway company
  createTransportCompany(company) {
    return Vue.http
      .post('api/companies', company)
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then('Nowa firma transportowa została dodana'));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
        console.error(data);
      });
  },

  updateCompany(company) {
    return Vue.http
      .put(company['@id'].substring(1), {
        ...company,
        user: undefined,
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());

        router.push({
          name: 'company_show',
          params: {
            id: data.id,
          },
        });
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },
};
