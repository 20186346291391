<template>
  <div>
    <DialogFactory :value="value" v-on="$listeners">
      <DialogTitleHeader :text="title" @click="emitCloseEvent">
        <RemoveIconButton
          v-if="isEditionModal && userHasRightsToRemovePlace"
          @click="openConfirmModal"
        ></RemoveIconButton>
      </DialogTitleHeader>
      <v-card-text class="pb-0 flex-grow-1">
        <v-form ref="form" class="d-flex flex-column" style="row-gap: 20px">
          <FindPlaceField :placeEntityCopy.sync="placeEntityCopy"></FindPlaceField>
          <TextField
            v-if="isPlaceSelected"
            v-model="placeEntityCopy.address"
            label="Adres miejsca"
            hint="Ten adres będzie widoczny w wynikach wyboru miejsca"
            required
          ></TextField>
          <slot name="form-extension" v-bind:rules="rules" v-bind:placeEntity="placeEntityCopy"></slot>
          <TextField
            v-if="isPlaceTypeSelected && !isHomePlaceTypeSelected"
            v-model="placeEntityCopy.name"
            label="Nazwa miejsca"
            :disabled="isHomePlaceTypeSelected"
            hint="Ta nazwa będzie widoczna w wynikach wyboru miejsca"
            required
          ></TextField>
          <template v-if="isHomePlaceTypeSelected && !isEditionModal">
            <NonEditablePassengerField v-model="placeEntityCopy.user" :icon="null" :rules="[rules.required]">
              <template #label>
                <span>Pasażer</span>
                <span class="red--text">*</span>
              </template>
            </NonEditablePassengerField>
            <AlertInfo class="mb-0">Nazwa miejsca będzie taka sama jak nazwa wybranego Pracownika.</AlertInfo>
          </template>
        </v-form>
      </v-card-text>
      <DialogSaveButton @click="savePlace"></DialogSaveButton>
    </DialogFactory>
    <ConfirmDialog
      :showDialog="isConfirmModalOpen"
      :text="`Czy na pewno usunąć ${placeEntity.name}`"
      @confirm="onConfirm"
    ></ConfirmDialog>
  </div>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import DialogSaveButton from '@/component/Dialog/dialog-save-button';
import ConfirmDialog from '@/component/Dialog/confirm-dialog';
import formValidationRules from '@/core/utils/form-validation-rules';
import RemoveIconButton from '@/component/Button/remove-icon-button';
import { ABSTRACT_PLACE_TYPES, BE_PLACE_TYPES } from '@/core/dict/place-type-dict';
import { mapGetters } from 'vuex';
import AlertInfo from '@/component/Alert/alert-info';
import NonEditablePassengerField from '@/component/Field/Passenger/passenger-field';
import TextField from '@/component/Field/Generic/text-field';
import FindPlaceField from './find-place-field/find-place-field';

export default {
  name: 'PlaceModal',
  components: {
    DialogFactory,
    DialogTitleHeader,
    DialogSaveButton,
    ConfirmDialog,
    FindPlaceField,
    AlertInfo,
    NonEditablePassengerField,
    RemoveIconButton,
    TextField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    placeEntity: {},
    strategy: {},
    isEditionModal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        ...formValidationRules,
      },
      placeEntityCopy: JSON.parse(JSON.stringify(this.placeEntity)),
      name: null,
      isConfirmModalOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isManager', 'isTransportCompany', 'isHeadOfCompany']),
    coords() {
      return {
        longitude: this.placeEntityCopy.longitude,
        latitude: this.placeEntityCopy.latitude,
      };
    },
    userHasRightsToRemovePlace() {
      return this.isManager || this.isTransportCompany || this.isHeadOfCompany;
    },
    isHomePlaceTypeSelected() {
      return (
        this.placeEntityCopy.type === ABSTRACT_PLACE_TYPES.HOME || this.placeEntityCopy['@type'] === BE_PLACE_TYPES.HOME
      );
    },
    isPlaceTypeSelected() {
      return this.isEditionModal ? this.placeEntityCopy['@type'] !== null : this.placeEntityCopy.type !== null;
    },
    isPlaceSelected() {
      return this.placeEntityCopy.longitude !== null && this.placeEntityCopy.latitude;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    savePlace() {
      if (this.$refs.form.validate()) {
        let company = null;

        if (this.isHomePlaceTypeSelected) {
          company = this.isEditionModal ? this.placeEntityCopy.company : this.placeEntityCopy.user.company;
        }

        this.strategy
          .save({
            ...this.placeEntityCopy,
            company,
            user:
              !this.isEditionModal && this.isHomePlaceTypeSelected
                ? this.placeEntityCopy.user.user
                : this.placeEntityCopy.user,
          })
          .then(() => {
            this.emitCloseEvent();
          });
      }
    },
    deletePlace() {
      this.$store.dispatch('place/softDeletePlace', this.placeEntity).then(() => {
        this.emitCloseEvent();
      });
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },
    onConfirm(val) {
      if (val) {
        this.deletePlace();
      }
      this.isConfirmModalOpen = false;
    },
  },
  watch: {
    value() {
      this.placeEntityCopy = JSON.parse(JSON.stringify(this.placeEntity));
    },
    placeEntityCopy: {
      handler(newVal) {
        if (newVal.user) {
          this.placeEntityCopy.name = newVal.user.name;
        }
      },
      deep: true,
    },
    isHomePlaceTypeSelected(newVal) {
      if (newVal === false) {
        this.placeEntityCopy.user = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button__container {
  position: sticky;
  bottom: 0;
  background: white;
}
</style>
