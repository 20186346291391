import { render, staticRenderFns } from "./action-form.vue?vue&type=template&id=65403eaa&scoped=true"
import script from "./action-form.vue?vue&type=script&lang=js"
export * from "./action-form.vue?vue&type=script&lang=js"
import style0 from "./action-form.vue?vue&type=style&index=0&id=65403eaa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65403eaa",
  null
  
)

export default component.exports