<template>
  <component
    :is="currentComponent"
    timeout="3000"
    min-width="min-content"
    v-model="notificationActive"
    v-bind="options"
    @input="toggleSnackbar"
  ></component>
</template>

<script>
import { NOTIFICATION_TYPES } from '@/core/dict/notification-dict';
import SuccessNotification from './success-notification';
import ErrorNotification from './error-notification';
import { events } from '../events';

export default {
  name: 'NotificationFactory',
  data() {
    return {
      notificationActive: false,
      type: null,
      options: null,
    };
  },
  mounted() {
    events.$on('add', this.showNotification);
  },
  computed: {
    currentComponent() {
      switch (this.type) {
        case NOTIFICATION_TYPES.SUCCESS:
          return SuccessNotification;
        case NOTIFICATION_TYPES.ERROR:
          return ErrorNotification;
        default:
          return null;
      }
    },
  },
  methods: {
    showNotification({ type, ...options }) {
      this.type = type;
      this.options = options;
      this.notificationActive = true;
    },
    toggleSnackbar(val) {
      this.notificationActive = val;
    },
  },
};
</script>
