import BaseRelationEditTemplate from './BaseRelation';

export default class DriverSegmentsEdit extends BaseRelationEditTemplate {
  // eslint-disable-next-line class-methods-use-this
  get relationField() {
    return 'driverSegments';
  }

  payload(args) {
    return { driverSegments: super.payload(args).driverSegments.map(({ id, ...segment }) => ({ ...segment })) };
  }
}
