import { EVENTS } from '@/core/dict/events-dict';

const PassengerList = () => import('@/controller/Passenger/passenger-list');

export default [
  {
    path: '/passengers',
    name: `passenger_index`,
    component: PassengerList,
    meta: {
      title: 'Pasażerowie',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
    },
  },
];
