<template>
  <v-app app>
    <online-indicator />
    <v-main>
      <div class="login__container">
        <v-card
          :elevation="$vuetify.breakpoint.xsOnly ? 0 : 4"
          :color="$vuetify.breakpoint.xsOnly ? 'transparent' : 'white'"
          width="480"
        >
          <AbstractModalHeader class="px-2" title="Zaloguj się"></AbstractModalHeader>

          <v-form class="d-flex flex-column pa-6" @submit.prevent="authenticate" style="row-gap: 16px">
            <TextField v-model="credentials.username" label="Nazwa użytkownika"></TextField>
            <PasswordField v-model="credentials.password" label="Hasło" />
            <v-btn type="submit" color="primary" block>Zaloguj</v-btn>
            <BaseLink
              class="text-center"
              :to="{ name: ROUTE_NAMES.UNAUTHORIZED_PASSWORD_RESET_REQUEST_ROUTE }"
              text="Nie pamiętasz hasła?"
            ></BaseLink>
          </v-form>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import LoginRedirectService from '@/service/LoginRedirectService';
import TextField from '@/component/Field/Generic/text-field';
import PasswordField from '@/component/Field/Generic/password-field';
import OnlineIndicator from '@/controller/Page/online-indicator';
import AuthService from '@/service/AuthService';
import { NOTIFICATION_TYPES } from '@/core/dict/notification-dict';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BaseLink from '@/component/Base/base-link';
import * as ROUTE_NAMES from '@/core/router/route-names';

export default {
  name: 'LoginPage',
  components: {
    AbstractModalHeader,
    PasswordField,
    TextField,
    OnlineIndicator,
    BaseLink,
  },
  data() {
    return {
      credentials: {
        username: null,
        password: null,
      },
    };
  },

  created() {
    delete Vue.http.headers.common.Authorization;
    this.ROUTE_NAMES = ROUTE_NAMES;
  },

  methods: {
    authenticate() {
      if (!!this.credentials.username && !!this.credentials.password) {
        this.$store.commit('setLoading', true);
        AuthService.login(this.credentials)
          .then((data) => {
            localStorage.setItem(process.env.VUE_APP_JWT_STORAGE_NAME, data.token);
            localStorage.setItem(process.env.VUE_APP_JWT_REFRESH_NAME, data.refresh_token);
            Vue.http.headers.common.Authorization = `Bearer ${data.token}`;
            this.$root.initUser().then(() => {
              this.updateUserDeviceToken();
              LoginRedirectService.redirect();
            });
          })
          .catch(() => {})
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      } else {
        this.$notify({
          type: NOTIFICATION_TYPES.ERROR,
          text: 'Uzupełnij wszystkie pola',
        });
      }
    },

    // TODO: remove to service or store -> Patryk help me.
    updateUserDeviceToken() {
      const deviceToken = localStorage.getItem(process.env.VUE_APP_FCM_DEVICE_TOKEN_NAME);

      if (!deviceToken) {
        return false;
      }

      return this.$http.put(`api/users/${this.$store.state.user.id}`, {
        deviceToken,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url('~@/assets/left_bg.jpeg') no-repeat bottom left fixed,
    url('~@/assets/right_bg.jpeg') no-repeat top right fixed;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    background: url('~@/assets/left_bg_mobile.jpeg') no-repeat bottom left fixed;
  }
}
</style>
