<template>
  <v-dialog
    class="list-settings__container"
    :value="modal"
    @click:outside="emitCloseEvent"
    @keydown.esc="emitCloseEvent"
    width="320"
    transition="dialog-bottom-transition"
    data-testid="columns-settings-modal"
  >
    <v-card>
      <v-card-title>Ustawienia widoku</v-card-title>
      <v-card-text>
        <template v-if="!isPassenger">
          <section class="list-settings__section">
            <p class="list-settings__title">Widoczne kolumny</p>
            <v-checkbox
              v-for="(val, key) of courseStatuses"
              :key="key"
              v-model="columnsSettings[key]"
              hide-details
              :label="val"
            ></v-checkbox>
          </section>
          <v-divider />
        </template>
        <section class="list-settings__section">
          <p class="list-settings__title">Kursy</p>
          <v-treeview
            v-model="rideCardSettings"
            selectable
            open-all
            :expand-icon="null"
            dense
            selected-color="primary"
            :items="rideCardOptions"
          ></v-treeview>
        </section>
      </v-card-text>
      <v-card-actions class="list-settings__actions">
        <v-btn color="primary" text @click="emitCloseEvent" data-testid="modal-cancel-btn">anuluj</v-btn>
        <v-btn color="primary" text @click="acceptColumnsSettings" data-testid="modal-accept-btn">zapisz zmiany</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { COURSE_STATUS_VERBAL, RIDE_CARD_SETTINGS } from '@/core/dict/taxi-course-dict';
import { mapGetters } from 'vuex';

export default {
  name: 'ColumnsSettingsModal',
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      columnsSettings: {},
      courseStatuses: COURSE_STATUS_VERBAL,
      rideCardOptions: [
        {
          id: RIDE_CARD_SETTINGS.RELATION,
          name: 'Relacja',
          children: [
            {
              id: RIDE_CARD_SETTINGS.INTERMEDIATE_WAYPOINTS,
              name: 'Przystanki pośrednie',
            },
            {
              id: RIDE_CARD_SETTINGS.PASSENGERS,
              name: 'Pasażerowie',
            },
            {
              id: RIDE_CARD_SETTINGS.DISTANCE_AND_TIME,
              name: 'Kilometry i czas',
            },
            {
              id: RIDE_CARD_SETTINGS.LOCOMOTIVES,
              name: 'Lokomotywy',
            },
            {
              id: RIDE_CARD_SETTINGS.FULL_WAYPOINT_ADDRESS,
              name: 'Pełne adresy przystanków',
            },
          ],
        },
      ],
      rideCardSettings: [],
      settingsUnwatch: null,
      columnSettingsUnwatch: null,
    };
  },
  created() {
    this.settingsUnwatch = this.$store.watch(
      (_, getters) => getters['courseSettings/rideCardSettings'],
      (newVal) => {
        this.rideCardSettings = newVal;
      }
    );
    this.rideCardSettings = this.$store.getters['courseSettings/rideCardSettings'];

    this.columnSettingsUnwatch = this.$store.watch(
      (_, getters) => getters['courseSettings/columnSettings'],
      (newVal) => {
        this.columnSettings = newVal;
      }
    );
    this.columnsSettings = this.$store.getters['courseSettings/columnSettings'];
  },
  destroyed() {
    this.settingsUnwatch();
    this.columnSettingsUnwatch();
  },
  methods: {
    acceptColumnsSettings() {
      this.saveSettings();
      this.emitCloseEvent();
    },
    saveSettings() {
      this.$store.dispatch('courseSettings/setColumnsSettings', this.columnsSettings);
      this.$store.dispatch('courseSettings/setRideCardSettings', this.rideCardSettings);
    },
    emitCloseEvent() {
      this.$emit('closeModal');
    },
  },
  computed: mapGetters(['isPassenger']),
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 0;
}
::v-deep .v-icon.v-treeview-node__toggle,
::v-deep .v-treeview-node__level:nth-of-type(odd) {
  display: none;
}

::v-deep .v-application--is-ltr .v-treeview-node__checkbox {
  margin-left: 0;
}

::v-deep .v-treeview-node__root {
  padding: 0;
}

::v-deep .v-label {
  font-size: 12.25px;
}

.list-settings {
  &__modal {
    position: relative;
  }

  &__section {
    padding: 24px;
  }

  &__title {
    font: 600 14px/18px 'Roboto';
    color: rgba(0, 0, 0, 0.54);
  }

  &__actions {
    position: sticky;
    bottom: 0;
    background-color: white;
    justify-content: flex-end;
  }
}
</style>
