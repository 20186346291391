import LocomotiveService from '@/service/LocomotiveService';
import * as types from '@/core/store/modules/types/locomotive-mutation-types';

export default {
  namespaced: true,
  state: {
    locomotiveList: [],
    locomotiveListLoading: false,
    isLoading: false,
  },
  getters: {
    isLoading: (state) => state.isLoading,
    locomotiveList: (state) => state.locomotiveList,
    locomotiveListLoading: (state) => state.locomotiveListLoading,
  },
  mutations: {
    [types.SET_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    [types.SET_LOCOMOTIVE_LIST]: (state, locomotiveList) => {
      state.locomotiveList = locomotiveList;
    },

    [types.SET_LOCOMOTIVE_LIST_LOADING]: (state, locomotiveListLoading) => {
      state.locomotiveListLoading = locomotiveListLoading;
    },
    [types.ADD_LOCOMOTIVE]: (state, locomotive) => {
      state.locomotiveList = [...state.locomotiveList, locomotive];
    },
    [types.SET_EDITED_LOCOMOTIVE]: (state, locomotive) => {
      const locomotiveIdx = state.locomotiveList.findIndex((loc) => loc.id === locomotive.id);
      state.locomotiveList.splice(locomotiveIdx, 1, locomotive);
    },
  },
  actions: {
    fetchLocomotiveList({ getters, commit }) {
      if (getters.locomotiveListLoading || getters.locomotiveList.length) {
        return;
      }

      commit(types.SET_LOCOMOTIVE_LIST_LOADING, true);
      LocomotiveService.fetchLocomotiveList()
        .then(({ data }) => commit(types.SET_LOCOMOTIVE_LIST, data['hydra:member']))
        .finally(() => commit(types.SET_LOCOMOTIVE_LIST_LOADING, false));
    },
    createLocomotive({ commit }, payload) {
      commit(types.SET_LOADING, true);
      return LocomotiveService.createLocomotive(payload)
        .then((data) => {
          commit(types.ADD_LOCOMOTIVE, data);
        })
        .finally(() => {
          commit(types.SET_LOADING, false);
        });
    },
    editLocomotive({ commit }, payload) {
      commit(types.SET_LOADING, true);
      return LocomotiveService.editLocomotive(payload)
        .then((data) => {
          commit(types.SET_EDITED_LOCOMOTIVE, data);
        })
        .finally(() => {
          commit(types.SET_LOADING, false);
        });
    },
  },
};
