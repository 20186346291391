<template>
  <ContextMenu left :buttons="$attrs.buttons">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon width="24" height="24" v-bind="attrs" v-on="on">
        <v-icon class="mr-0">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
  </ContextMenu>
</template>

<script>
import ContextMenu from '@/component/ContextMenu/context-menu';

export default {
  name: 'RecentlyUsedPlaceContextMenu',
  components: {
    ContextMenu,
  },
};
</script>
