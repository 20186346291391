<template>
  <v-text-field
    outlined
    hide-details="auto"
    :rules="[rules.contactCredentials(entity), rules.optionalEmail]"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
import formValidationRules from '@/core/utils/form-validation-rules';

export default {
  name: 'EmailField',
  inheritAttrs: false,
  props: {
    entity: {
      required: true,
    },
  },
  data() {
    return {
      rules: formValidationRules,
    };
  },
};
</script>
