<template>
  <section class="description__section">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'DescriptionSection',
};
</script>

<style lang="scss" scoped>
.description__section {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
</style>
