<template>
  <div class="online-indicator">
    <v-alert v-if="!online" class="ma-0 pa-1 d-flex align-center justify-center" dense text color="#fff2e6">
      <v-icon color="warning">mdi-circle-medium</v-icon>
      <span class="black--text text-body-2">Próba nawiązania połączenia z Internetem</span>
    </v-alert>
    <v-alert v-else-if="backOnline" class="ma-0 pa-1 d-flex align-center justify-center" dense text color="#dce6dc">
      <v-icon color="success">mdi-circle-medium</v-icon>
      <span class="black--text text-body-2">Przywrócono połączenie z Internetem</span>
    </v-alert>
  </div>
</template>
<script>
export default {
  name: 'OnlineIndicator',
  data() {
    return {
      online: navigator.onLine,
      backOnline: false,
    };
  },
  watch: {
    online(v) {
      if (v) {
        this.backOnline = true;
        setTimeout(() => {
          this.backOnline = false;
        }, 3000);
      }
    },
  },
  mounted() {
    window.addEventListener('online', () => {
      this.online = true;
    });
    window.addEventListener('offline', () => {
      this.online = false;
    });
  },
  beforeDestroy() {
    window.removeEventListener('online', () => {
      this.online = true;
    });
    window.removeEventListener('offline', () => {
      this.online = false;
    });
  },
};
</script>
<style lang="scss">
.online-indicator {
  .v-alert {
    position: absolute;
    z-index: 2;

    &--text:before {
      opacity: 0.8;
    }

    &__wrapper {
      z-index: 2;
    }
  }
}
</style>
