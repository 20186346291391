import { EVENTS } from '@/core/dict/events-dict';

const DriverList = () => import('@/controller/Driver/driver-list');

export default [
  {
    path: '/drivers',
    name: `driver_index`,
    component: DriverList,
    meta: {
      title: 'Kierowcy',
      createEvent: EVENTS.OPEN_CREATE_MODAL,
    },
  },
];
