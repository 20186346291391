export const ABSTRACT_PLACE_TYPES = {
  PLACE: 'place',
  HOTEL: 'hotel',
  HOME: 'home',
  WORK: 'workplace',
};

export const BE_PLACE_TYPES = {
  HOTEL: 'Hotel',
  HOME: 'Home',
  WORK: 'Workplace',
  PLACE: 'Place',
};

export const PLACE_TYPES_DICT = {
  [ABSTRACT_PLACE_TYPES.HOME]: 'Miejsce zamieszkania',
  [ABSTRACT_PLACE_TYPES.WORK]: 'Miejsce pracy',
  [ABSTRACT_PLACE_TYPES.HOTEL]: 'Hotel',
  [ABSTRACT_PLACE_TYPES.PLACE]: 'Inne',
};
