<template>
  <v-col :class="this.sizeableClasses">
    <v-text-field
      data-cy="search-input"
      :value="this.value"
      @input="this.input"
      :autofocus="this.autofocus"
      :clearable="this.clearable"
      :color="this.color"
      :disabled="this.disabled"
      :error="this.error"
      :flat="this.flat"
      :full-width="this.fullWidth"
      :height="this.height"
      :prepend-icon="this.icon"
      :label="this.label"
      :loading="this.loading"
      :outlined="this.outlined"
      :placeholder="this.placeholder"
      :readonly="this.readonly"
      :solo="this.inverted === false"
      :solo-inverted="this.inverted !== false"
      append-icon="search"
      :success="this.success"
      :type="this.type"
      hide-details
    />
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';
import filtersReminder from '@/core/shared/filtersReminder';

export default {
  name: 'OneFieldSearch',
  mixins: [sizeable],
  props: {
    autofocus: Boolean,
    filled: Boolean,
    clearable: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    error: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    height: null,
    icon: String,
    label: String,
    value: String,
    loading: Boolean,
    outlined: Boolean,
    placeholder: String,
    readonly: Boolean,
    solo: Boolean,
    success: Boolean,
    type: String,
    inverted: Boolean,
  },
  methods: {
    input(val) {
      filtersReminder.setSearchValue(val);
      this.$emit('input', val);
    },
  },
};
</script>
