<template>
  <v-simple-table>
    <thead>
      <tr>
        <th class="text-left">Obowiązuje od</th>
        <th class="text-left">Cena za 1 km</th>
        <th class="text-left">Cena za godzinę oczekiwania</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in companyContractorStakes" :key="item.id">
        <td>{{ item.validFrom | date }}</td>
        <td>{{ item.kilometerRate | companyContractorStake | currency }}</td>
        <td>{{ item.waitHourRate | companyContractorStake | currency }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'CompanyContractorStakesTable',
  props: {
    companyContractorStakes: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
