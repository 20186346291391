<template>
  <v-form @submit.prevent="$emit('submit')">
    <one-page-section>
      <slot></slot>
    </one-page-section>
  </v-form>
</template>

<script>
import OnePageSection from '@/component/Page/section-page';

export default {
  name: 'OneForm',
  components: { OnePageSection },
};
</script>
