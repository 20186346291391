<template>
  <autocomplete-field
    :icon="getIconType(this.selectedPlaceType)"
    :item-text="getPlaceText"
    :items="places"
    :loading="listLoading"
    :disabled="disabled || listLoading"
    :readonly="readonly"
    :value="value"
    :clearable="clearable"
    dense-outlined
    label="Miejsce"
    @input="emitChange"
    :menu-props="{
      closeOnContentClick: true,
    }"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import IconTypeMixin from '@/core/mixin/iconType';

export default {
  name: 'AbstractPlaceField',
  components: { AutocompleteField },
  mixins: [IconTypeMixin],
  data() {
    return {
      selectedPlaceType: null,
    };
  },
  props: {
    value: {
      required: false,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    placesKey: {
      type: String,
      default: 'abstractPlacesList',
    },
    clearable: {
      default: true,
    },
  },
  computed: {
    places() {
      return this.$store.getters[this.placesKey];
    },
    listLoading() {
      return this.$store.getters.abstractPlacesListLoading || this.$store.state.isUserPositionLoading;
    },
  },
  created() {
    this.$store.dispatch('fetchAbstractPlaces');
  },
  methods: {
    getPlaceText(place) {
      return `${place.name} (${place.address})`;
    },

    emitChange(place) {
      this.$emit('input', place);
      this.selectedPlaceType = place?.['@type'];
    },
  },
};
</script>
