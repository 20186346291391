<template>
  <div class="page-container">
    <template v-if="!isPassenger">
      <div class="course-grouped-list__tabs">
        <v-btn
          v-if="!isDriver"
          fab
          bottom
          right
          fixed
          color="primary"
          aria-label="utwórz taksówkę"
          @click="emitOpenCourseForm"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-tabs v-model="tab" grow flat background-color="primary" dark>
          <v-tab v-for="column in columns" :key="column">
            {{ statusesString[column] }}
          </v-tab>
        </v-tabs>
        <div class="overflow-x-auto">
          <course-list-navbar class="flex-nowrap elevation-0" />
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="column in columns" :key="column" class="course-grouped-list__inner">
            <course-list
              :courses="courses[column] || []"
              :is-loading="isLoading(column)"
              :totalCount="getAmountOfCoursesPerColumn(column)"
              @courseClick="showCourse"
              @rideClick="showCourse"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div class="page-container course-grouped-list__columns">
        <div
          v-if="isRequiresAttentionColumnPresent"
          class="requires_attention"
          :class="{ 'requires_attention--active': isRequiresAttentionColumnActive }"
        >
          <div class="d-flex justify-space-between align-center px-4">
            <CourseListHeader
              :counter="getAmountOfCoursesPerColumn('requires_attention')"
              :requiresAttention="isRequiresAttentionColumnActive"
            >
              <template #default>
                {{ statusesString['requires_attention'] }}
              </template>
            </CourseListHeader>
            <BaseTextButton
              :prependIcon="requiresAttentionToggle.icon"
              :disabled="!isRequiresAttentionColumnActive"
              @click="expandRequiresAttentionSection"
            >
              {{ requiresAttentionToggle.text }}
            </BaseTextButton>
          </div>
          <div class="grid-container" :class="{ 'grid-container--expanded': isRequiresAttentionExpanded }">
            <div class="accordion-content">
              <course-list
                class="mx-4"
                :courses="courses['requires_attention'] || []"
                :is-loading="isLoading('requires_attention')"
                :totalCount="getAmountOfCoursesPerColumn('requires_attention')"
                @courseClick="showCourse"
                @rideClick="showCourse"
              />
            </div>
          </div>
          <v-divider horizontal></v-divider>
        </div>
        <div class="flex-container">
          <v-divider v-if="filteredColumns.length === 2" vertical style="order: 1"></v-divider>
          <div
            v-for="(column, index) in filteredColumns"
            :key="column"
            class="d-flex flex-column px-4"
            :style="`order: ${index}`"
            :data-testid="`${column}-column`"
          >
            <CourseListHeader class="flex-shrink-0" :counter="getAmountOfCoursesPerColumn(column)">
              {{ statusesString[column] }}
              <template #append>
                <v-spacer></v-spacer>
                <ColumnContextMenuFactory
                  :column="column"
                  :counter="getAmountOfCoursesPerColumn(column)"
                ></ColumnContextMenuFactory>
              </template>
            </CourseListHeader>
            <course-list
              :courses="courses[column] || []"
              :is-loading="isLoading(column)"
              :totalCount="getAmountOfCoursesPerColumn(column)"
              @courseClick="showCourse"
              @rideClick="showCourse"
            />
          </div>
        </div>
      </div>
    </template>

    <div v-else class="course-grouped-list__inner mt-0" data-testid="passenger-course-list">
      <v-btn
        v-if="userHasPermissionForKilometerCourse"
        fab
        bottom
        right
        fixed
        color="primary"
        aria-label="utwórz kilometrówkę"
        @click="emitOpenCourseForm"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <course-list
        :courses="courses"
        :totalCount="coursesAmount"
        :is-loading="loading"
        @courseClick="showCourse"
        @rideClick="showCourse"
      />
    </div>
    <course-drawer-factory :course="getSelectedCourse" :ride="getSelectedRide" @close="selectedCourse = null" />
  </div>
</template>

<script>
import { COURSE_STATUS_VERBAL } from '@/core/dict/taxi-course-dict';
import CourseListHeader from '@/component/NewCourse/List/course-list-header';
import CourseList from '@/component/NewCourse/List/course-list';
import CourseDrawerFactory from '@/component/NewCourse/Show/course-drawer-factory';
import CourseListNavbar from '@/component/NewCourse/List/course-list-navbar';
import BaseTextButton from '@/component/Base/base-text-button';
import { mapGetters } from 'vuex';
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import { EVENTS, eventBus } from '@/core/dict/events-dict';
import ColumnContextMenuFactory from './column-context-menu-factory';

const IN_PROGRESS_TAB = 1;

export default {
  name: 'CourseGroupedList',
  components: {
    CourseDrawerFactory,
    CourseList,
    CourseListHeader,
    CourseListNavbar,
    BaseTextButton,
    ColumnContextMenuFactory,
  },
  props: {
    courses: {},
    columns: {
      type: Array,
    },
    coursesAmount: {
      required: true,
    },
  },
  data() {
    return {
      statusesString: COURSE_STATUS_VERBAL,
      selectedCourse: null,
      tab: IN_PROGRESS_TAB,
      isRequiresAttentionExpanded: false,
    };
  },
  created() {
    eventBus.$on(EVENTS.SHOW_COURSE_DRAWER, this.showCourse);
  },
  destroyed() {
    eventBus.$off(EVENTS.SHOW_COURSE_DRAWER);
  },
  computed: {
    ...mapGetters(['isDriver', 'isPassenger', 'userHasPermissionForKilometerCourse']),
    course() {
      return Object.values(this.courses)
        .flat()
        .find((haystack) => haystack.id === (this.selectedCourse.courseId || this.selectedCourse.id));
    },
    getSelectedCourse() {
      return this.selectedCourse ? this.course : null;
    },
    getSelectedRide() {
      return this.selectedCourse?.courseId
        ? this.course.rides.find((ride) => ride.id === this.selectedCourse.id)
        : null;
    },
    loading() {
      return this.$store.getters['taxiCourse/isAllCoursesLoading'];
    },
    requiresAttentionToggle() {
      return this.isRequiresAttentionExpanded
        ? { text: 'zwiń', icon: 'mdi-menu-up' }
        : { text: 'rozwiń', icon: 'mdi-menu-down' };
    },
    filteredColumns() {
      return this.columns.filter((column) => column !== 'requires_attention');
    },
    isRequiresAttentionColumnPresent() {
      return this.columns.includes('requires_attention');
    },
    isRequiresAttentionColumnActive() {
      return this.isRequiresAttentionColumnPresent && this.coursesAmount?.requires_attention > 0;
    },
  },
  methods: {
    isLoading(status) {
      return this.$store.getters['taxiCourse/isListLoading'][status];
    },
    getAmountOfCoursesPerColumn(status) {
      return this.coursesAmount[status];
    },
    showCourse(course) {
      if (
        !(
          this.isDriver ||
          (this.isPassenger &&
            [COURSE_TYPE_DICT.TAXI_COURSE, COURSE_TYPE_DICT.TAXI_COURSE_RIDE].includes(course['@type']))
        )
      ) {
        this.selectedCourse = course;
      }
    },
    emitOpenCourseForm() {
      eventBus.$emit(EVENTS.OPEN_COURSE_FORM);
    },
    expandRequiresAttentionSection() {
      this.isRequiresAttentionExpanded = !this.isRequiresAttentionExpanded;
    },
  },
  watch: {
    isRequiresAttentionColumnActive(val) {
      if (val === false) {
        this.isRequiresAttentionExpanded = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/core/style/variables' as *;

::v-deep .v-slide-group__prev {
  display: none !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.requires_attention--active {
  background: linear-gradient(180deg, rgba(178, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.grid-container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s;

  &--expanded {
    grid-template-rows: 1fr;
  }
}

.grid-container > .accordion-content {
  overflow: hidden;
}

.flex-container {
  display: flex;
  flex: 1;
  min-height: 400px;

  & > * {
    flex: 1;
    overflow-y: auto;
  }
}
.course-grouped-list {
  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__tabs {
    @media (min-width: $course-list-desktop-breakpoint) {
      display: none;
    }
  }

  &__columns {
    display: none;

    @media (min-width: $course-list-desktop-breakpoint) {
      display: inherit;
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100vh;
    padding-top: 16px;

    &--desktop {
      padding-top: 0;
      height: calc(100vh - 212px);
    }
  }
}
</style>
