<template>
  <div class="flex-grow-1">
    <AbstractContractorInput
      :items="items"
      :loading="loggedCompanyLoading"
      :rules="inputValidationRules"
      v-bind="$attrs"
      v-on="$listeners"
      @input="emitCourseContractor"
    >
      <template #append-item>
        <AddItemButton @click="openAddContractorModal">Dodaj podwykonawcę</AddItemButton>
      </template>
      <template #item="data">
        <p class="flex-grow-1 mb-0">{{ data.item.name }}</p>
        <v-btn color="primary" icon aria-label="edytuj" @click.stop="openEditContractorModal(data.item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </AbstractContractorInput>
    <AddContractorModalFactory
      :value="addContractorModal"
      :taxiCourse="taxiCourse"
      @close="closeAddContractorModal"
    ></AddContractorModalFactory>
    <EditContractorModalFactory
      :value="editContractorModal"
      :isContractorOfRailwayCompany="isContractorOfRailwayCompany"
      :companyContractor="companyContractorToEdit"
      @close="closeEditContractorModal"
    ></EditContractorModalFactory>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formValidationRules from '@/core/utils/form-validation-rules';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import AbstractContractorInput from './abstract-contractor-input';
import AddContractorModalFactory from '../add-contractor-modal/add-contractor-modal-factory';
import EditContractorModalFactory from '../edit-contractor-modal/edit-contractor-modal-factory';

export default {
  name: 'EditableContractorInput',
  inheritAttrs: false,
  components: {
    AbstractContractorInput,
    AddItemButton,
    AddContractorModalFactory,
    EditContractorModalFactory,
  },
  props: {
    taxiCourse: {
      required: true,
    },
  },
  data() {
    return {
      addContractorModal: false,
      editContractorModal: false,
      contractorId: null,
    };
  },
  computed: {
    ...mapGetters(['loggedCompany', 'loggedCompanyLoading', 'isCompany', 'user', 'isTransportCompany']),
    inputValidationRules() {
      return this.isCompany || !this.isSuperiorTransportCompany ? [formValidationRules.required] : [];
    },
    contractorsOfRailwayCompany() {
      return this.loggedCompany?.contractors?.map(({ contractor }) => contractor) ?? [];
    },
    selectedRailwayCompany() {
      return this.loggedCompany?.principals?.find(
        ({ principal }) => principal.id === this.taxiCourse.rides[0].company.id
      )?.principal;
    },
    isSuperiorTransportCompany() {
      return this.loggedCompany?.principals?.find(
        ({ principal }) => principal.id === this.taxiCourse.rides[0].company.id
      )?.superior;
    },
    items() {
      if (this.isCompany) {
        return this.contractorsOfRailwayCompany;
      }

      if (this.isSuperiorTransportCompany) {
        const railwayCompanyContractors = this.selectedRailwayCompany?.contractors
          ?.filter((contractor) => typeof contractor !== 'string')
          ?.map(({ contractor }) => contractor);

        return [
          this.loggedCompany,
          ...railwayCompanyContractors,
          ...this.loggedCompany.contractors.map(({ contractor }) => contractor),
        ];
      }

      return [this.loggedCompany, ...this.loggedCompany.contractors.map(({ contractor }) => contractor)];
    },
    isContractorOfRailwayCompany() {
      return !!this.selectedRailwayCompany?.contractors
        ?.filter((contractor) => typeof contractor !== 'string')
        .find((companyContractor) => companyContractor.contractor.id === this.contractorId);
    },
    companyContractorToEdit() {
      // TODO: can be solved by using program to an interface not an implementation
      if (this.isCompany) {
        return this.loggedCompany?.contractors.find(
          (companyContractor) => companyContractor.contractor.id === this.contractorId
        );
      }

      if (this.loggedCompany?.id === this.contractorId) {
        return this.loggedCompany.principals.find(
          (companyContractor) => companyContractor.principal.id === this.taxiCourse.rides[0].company.id
        );
      }

      if (this.isContractorOfRailwayCompany) {
        return this.selectedRailwayCompany?.contractors
          .filter((contractor) => typeof contractor !== 'string')
          .find((companyContractor) => companyContractor.contractor.id === this.contractorId);
      }

      return this.loggedCompany?.contractors.find(
        (companyContractor) => companyContractor.contractor.id === this.contractorId
      );
    },
  },
  methods: {
    openAddContractorModal() {
      this.addContractorModal = true;
    },
    closeAddContractorModal() {
      this.addContractorModal = false;
    },
    openEditContractorModal(val) {
      this.editContractorModal = true;
      this.contractorId = val.id;
    },
    closeEditContractorModal() {
      this.editContractorModal = false;
    },
    emitCourseContractor(val) {
      this.contractorId = val.id;
      if (this.isSuperiorTransportCompany && this.isContractorOfRailwayCompany) {
        this.taxiCourse.contractor = val;
        this.taxiCourse.subcontractor = null;
      } else if (this.isTransportCompany) {
        this.taxiCourse.contractor = this.loggedCompany;
        this.taxiCourse.subcontractor = this.loggedCompany.id !== val.id ? val : null;
      } else {
        this.taxiCourse.contractor = val;
        this.taxiCourse.subcontractor = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
