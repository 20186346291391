<template>
  <AbstractCourseCard class="taxi-course-card" v-bind="$attrs" v-on="$listeners">
    <template #course-title>
      <RideDate v-if="isPassenger || isDriver" v-bind="$attrs"></RideDate>
      <CourseExecutor v-else v-bind="$attrs"></CourseExecutor>
    </template>
    <template #course-badge>
      <TaxiCourseBadge></TaxiCourseBadge>
    </template>
    <template #course-subtitle>
      <TaxiCourseSubtitleFactory v-bind="$attrs"></TaxiCourseSubtitleFactory>
    </template>
    <template #course-report>
      <TaxiCourseRating v-bind="$attrs"></TaxiCourseRating>
    </template>
  </AbstractCourseCard>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractCourseCard from './abstract-course-card';
import RideDate from './CourseTitle/ride-date';
import CourseExecutor from './CourseTitle/course-executor';
import TaxiCourseBadge from './CourseBadge/taxi-course-badge';
import TaxiCourseRating from './CourseReport/taxi-course-rating';
import TaxiCourseSubtitleFactory from './CourseSubtitle/taxi-course-subtitle-factory';

export default {
  name: 'TaxiCourseCard',
  inheritAttrs: false,
  components: {
    AbstractCourseCard,
    RideDate,
    CourseExecutor,
    TaxiCourseBadge,
    TaxiCourseRating,
    TaxiCourseSubtitleFactory,
  },
  computed: {
    ...mapGetters(['isPassenger', 'isDriver']),
  },
};
</script>

<style lang="scss" scoped>
@use '@/core/style/global.scss' as *;

.taxi-course-card.theme--light.v-card {
  @include course-card(var(--taxi-course-card-background-color), var(--taxi-course-card-border-color));
}
</style>
