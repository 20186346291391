import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

/** X-HTTP-Method-Override */
Vue.http.options.emulateHTTP = true;
if (localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_NAME)) {
  Vue.http.headers.common.Authorization = `Bearer ${localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_NAME)}`;
  Vue.http.headers.common['x-switch-user'] = localStorage.getItem('x-switch-user');
}

export default {
  headers: {
    Accept: 'application/ld+json',
  },
};
