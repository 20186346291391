<template>
  <p class="no-data__paragraph"><slot /></p>
</template>

<script>
export default {
  name: 'NoDataText',
};
</script>

<style lang="scss" scoped>
.no-data__paragraph {
  padding: 24px 0 24px 0;
  font: 400 14px/18px 'Roboto', sans-serif;
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
  margin: 0;
}
</style>
