import * as routes from '@/core/router/route-names';

import SettingsDefault from '@/controller/Settings/settings-default';
import UserNameSettings from '@/controller/Settings/user-name-settings';
import UserEmailSettings from '@/controller/Settings/user-email-settings';
import GeolocationPermissionSettings from '@/controller/Settings/geolocation-permission-settings';
import NotificationPermissionSettings from '@/controller/Settings/notification-permission-settings';
import UserPhoneSettingsFactory from '@/controller/Settings/PhoneSettings/user-phone-settings-factory';
import UserHomeplaceSettings from '@/controller/Settings/user-homeplace-settings';
import UserPasswordChangeSettings from '@/controller/Settings/user-password-change-settings';
import UserPasswordResetRequest from '@/controller/Settings/user-password-reset-request';

export default [
  {
    path: '',
    name: routes.SETTINGS_ROUTE,
    component: SettingsDefault,
  },
  {
    path: 'user-name-settings',
    name: routes.USER_NAME_SETTINGS_ROUTE,
    component: UserNameSettings,
  },
  {
    path: 'user-email-settings',
    name: routes.USER_EMAIL_SETTINGS_ROUTE,
    component: UserEmailSettings,
  },
  {
    path: 'user-password-change-settings',
    name: routes.USER_PASSWORD_CHANGE_ROUTE,
    component: UserPasswordChangeSettings,
  },
  {
    path: 'user-password-reset-request',
    name: routes.USER_PASSWORD_RESET_REQUEST_ROUTE,
    component: UserPasswordResetRequest,
    props: {
      navigateTo: { name: routes.SETTINGS_ROUTE },
    },
  },
  {
    path: 'geolocation-settings',
    name: routes.GEOLOCATION_SETTINGS_ROUTE,
    component: GeolocationPermissionSettings,
  },
  {
    path: 'notification-settings',
    name: routes.NOTIFICATION_SETTINGS_ROUTE,
    component: NotificationPermissionSettings,
  },
  {
    path: 'user-phone-settings',
    name: routes.USER_PHONE_SETTINGS_ROUTE,
    component: UserPhoneSettingsFactory,
    props: (route) => ({
      state:
        route.query.type === 'create'
          ? { type: 'create' }
          : { type: 'edit', selectedPhoneIdx: +route.query.selectedPhone },
    }),
  },
  {
    path: 'user-homeplace-settings',
    name: routes.USER_HOMEPLACE_SETTINGS_ROUTE,
    component: UserHomeplaceSettings,
  },
];
