<template>
  <v-menu offset-y v-bind="$attrs">
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item v-for="(button, index) in buttons" :key="index" @click="onButtonClick(index)">
          <v-list-item-title>{{ button.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ContextMenu',
  inheritAttrs: false,
  props: {
    buttons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onButtonClick(idx) {
      this.buttons[idx].command.execute();
    },
  },
};
</script>
