<template>
  <alert icon="mdi-check-circle" color="#E4EEE4" icon-color="#4CAF50" text-color="#1E4620DE" v-bind="$attrs">
    <template #default>
      <slot></slot>
    </template>
  </alert>
</template>
<script>
import Alert from '@/component/Alert/alert';

export default {
  name: 'AlertSuccess',
  components: { Alert },
};
</script>

<style scoped lang="scss">
.v-alert {
  border: 1px solid #3b873e !important;
}
</style>
