const BROWSERS_DICT = {
  CHROME: 'Google Chrome',
  SAFARI: 'Safari',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  EDGE: 'Edge',
  SAMSUNG_INTERNET: 'Samsung Internet',
};

const GEOLOCATION_LINKS = {
  [BROWSERS_DICT.CHROME]: 'https://support.google.com/chrome/answer/142065',
  [BROWSERS_DICT.SAFARI]: 'https://support.apple.com/pl-pl/102647',
  [BROWSERS_DICT.FIREFOX]: 'https://support.mozilla.org/pl/kb/czy-firefox-udostepnia-moje-polozenie-stronom',
  [BROWSERS_DICT.OPERA]: 'https://help.opera.com/pl/latest/security-and-privacy/',
  [BROWSERS_DICT.EDGE]:
    'https://support.microsoft.com/pl-pl/microsoft-edge/lokalizacja-i-prywatno%C5%9B%C4%87-w-przegl%C4%85darce-microsoft-edge-31b5d154-0b1b-90ef-e389-7c7d4ffe7b04',
  [BROWSERS_DICT.SAMSUNG_INTERNET]:
    'https://www.samsung.com/pl/support/mobile-devices/co-zrobic-jezeli-lokalizacja-w-moim-smartfonie-samsung-jest-niedokladna/',
};

const NOTIFICATION_LINKS = {
  [BROWSERS_DICT.CHROME]: 'https://support.google.com/chrome/answer/3220216',
  [BROWSERS_DICT.SAFARI]: 'https://rootblog.pl/ios-16-4-powiadomienia-push/',
  [BROWSERS_DICT.FIREFOX]: 'https://support.mozilla.org/pl/kb/powiadomienia-web-push-firefox',
  [BROWSERS_DICT.OPERA]: 'https://help.opera.com/pl/latest/security-and-privacy/',
  [BROWSERS_DICT.EDGE]:
    'https://support.microsoft.com/pl-pl/microsoft-edge/zarz%C4%85dzanie-powiadomieniami-witryn-internetowych-w-przegl%C4%85darce-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b',
  [BROWSERS_DICT.SAMSUNG_INTERNET]:
    'https://eu.community.samsung.com/t5/aplikacje-i-us%C5%82ugi/powiadomienia-samsung-internet/td-p/4166244',
};

export default {
  getBrowserName() {
    const browserInfo = navigator.userAgent;
    let browser;

    if (browserInfo.includes('Opera') || browserInfo.includes('OPR')) {
      browser = BROWSERS_DICT.OPERA;
    } else if (browserInfo.includes('Edg')) {
      browser = BROWSERS_DICT.EDGE;
    } else if (browserInfo.includes('SamsungBrowser')) {
      browser = BROWSERS_DICT.SAMSUNG_INTERNET;
    } else if (browserInfo.includes('Chrome')) {
      browser = BROWSERS_DICT.CHROME;
    } else if (browserInfo.includes('Safari')) {
      browser = BROWSERS_DICT.SAFARI;
    } else if (browserInfo.includes('Firefox')) {
      browser = BROWSERS_DICT.FIREFOX;
    } else {
      throw new Error(`Unknown type of the browser detected: ${browserInfo}`);
    }
    return browser;
  },
  getGeolocationLink() {
    return GEOLOCATION_LINKS[this.getBrowserName()];
  },

  getNotificationLink() {
    return NOTIFICATION_LINKS[this.getBrowserName()];
  },
};
