<template>
  <div>
    <AbstractLocomotiveField v-on="$listeners" v-bind="$attrs" data-testid="editable-locomotive-input">
      <template #append-item>
        <div class="editable-locomotive__edit-btn">
          <v-divider></v-divider>
          <v-btn color="primary" text @click="openDialog">
            <v-icon left>mdi-plus</v-icon>
            Dodaj lokomotywę
          </v-btn>
        </div>
      </template>
      <template #item="data">
        <div class="d-flex flex-row align-center flex-grow-1">
          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
          <p class="flex-grow-1 mb-0" v-text="data.item.sideNumber"></p>
          <v-btn aria-label="edit-locomotive" icon @click.stop="openEditDialog(data.item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </AbstractLocomotiveField>
    <LocomotiveDialogFactory
      :isDialogOpen="dialog"
      :type="type"
      :locomotiveModel="locomotiveModel"
      @close="closeDialog"
    ></LocomotiveDialogFactory>
  </div>
</template>

<script>
import AbstractLocomotiveField from '@/component/Field/Locomotive/abstract-locomotive-field';
import LocomotiveDialogFactory from '@/component/Field/Locomotive/dialogs/locomotive-dialog-factory';
import { mapGetters } from 'vuex';

export default {
  name: 'EditableLocomotiveField',
  components: {
    AbstractLocomotiveField,
    LocomotiveDialogFactory,
  },
  data() {
    return {
      dialog: false,
      type: null,
      locomotiveModel: {
        sideNumber: null,
        company: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getCompanyById']),
    companyId() {
      return this.$attrs.companyId;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.type = 'create';
      this.locomotiveModel = {
        sideNumber: null,
        company: this.getCompanyById(this.companyId),
      };
    },
    openEditDialog(model) {
      this.dialog = true;
      this.type = 'edit';
      this.locomotiveModel = JSON.parse(JSON.stringify(model));
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.editable-locomotive__edit-btn {
  position: sticky;
  bottom: 0px;
  background-color: white;
  width: 100%;
}
</style>
