import TaxiCourseService from '@/service/TaxiCourseService';
import {
  UPDATE_COURSE_IN_COLUMN,
  SET_COURSE,
  SET_COURSES,
  SET_LIST_LOADING,
  SET_LOADING,
  SET_MERCURE_WATCH_INITIALIZED,
  SET_RIDE_NUMBER_LOADING,
  SET_RIDE_NUMBER,
  SET_COURSES_AMOUNT,
  ADD_LAZY_LOADED_COURSES_IN_COLUMN,
  ADD_LAZY_LOADED_COURSES_IN_LIST,
  SET_RIDE_RATING,
  SET_ALL_COURSES,
  SET_ALL_COURSES_COUNT,
  SET_ALL_COURSES_LOADING,
  UPDATE_COURSE_IN_LIST,
  RESET_TRANSPORT_TYPE,
  RESET_AFTER_DATE_FILTER,
  RESET_BEFORE_DATE_FILTER,
  REMOVE_FILTER,
  SET_SEARCH_TERM,
  RESET_LOCOMOTIVE_FILTER,
  EXCLUDE_CANCELLED_COURSES,
  RESET_CREATED_BY_FILTER,
  REMOVE_COURSE,
} from '@/core/store/modules/types/taxi-course-mutation-types';
import Vue from 'vue';
import { COURSE_STATUS, COURSE_TYPE_DICT, RIDE_STATUSES } from '@/core/dict/taxi-course-dict';
import { isEqual } from 'lodash';
import {
  DEFAULT_TAB,
  DEFAULT_FILTERS,
  DISCRIMINATOR_KEY,
  AFTER_DATE_START_KEY,
  BEFORE_DATE_START_KEY,
  LOCOMOTIVE_KEY,
  INCLUDE_CANCELLED_COURSES_KEY,
  CREATED_BY_KEY,
} from '@/core/dict/filters-dict';
import { formatDate } from '@/core/filter/datetime-filter';
import courseAdapter from '@/domain/CourseAdapter';
import { getStatusForCancelledRide } from '@/core/shared/status-picker';
import { AbstractCourseMapper } from '../../../shared/mappers/abstract-course/abstract-course.mapper.factory';

export default {
  namespaced: true,
  state: {
    course: {},
    courses: {
      [COURSE_STATUS.REQUIRES_ATTENTION]: [],
      [COURSE_STATUS.IN_PROGRESS]: [],
      [COURSE_STATUS.FINISHED]: [],
    },
    allCourses: [],
    totalCoursesCount: null,
    coursesAmount: {},
    isLoading: false,
    mercureWatchInitialized: false,
    isListLoading: {
      [COURSE_STATUS.REQUIRES_ATTENTION]: false,
      [COURSE_STATUS.IN_PROGRESS]: false,
      [COURSE_STATUS.FINISHED]: false,
    },
    isAllCoursesLoading: false,
    isRideNumberLoading: false,
    rideNumber: null,
    selectedFiltersTab: DEFAULT_TAB,
    filters: DEFAULT_FILTERS,
    searchTerm: '',
  },
  getters: {
    course: (state) => state.course,
    formattedFilters: ({ filters, selectedFiltersTab }) => {
      const filterKey = Object.keys(filters)[selectedFiltersTab];
      const CANCELLED_COURSE_LABEL = 'Anulowane';

      return {
        ...filters.abstractCourse,
        ...filters[filterKey],
        discriminator: COURSE_TYPE_DICT[filters[filterKey].discriminator] || null,
        rideStatus: RIDE_STATUSES[filters[filterKey].rideStatus],
        dateStartAfter: `Od: ${formatDate(filters.abstractCourse.dateStartAfter)}`,
        dateStartBefore: filters.abstractCourse.dateStartBefore
          ? `Do: ${formatDate(filters.abstractCourse.dateStartBefore)}`
          : null,
        includeCancelledCourses: filters.abstractCourse.includeCancelledCourses ? CANCELLED_COURSE_LABEL : null,
      };
    },
    courses: (state) => state.courses,
    coursesAmount: (state) => state.coursesAmount,
    isLoading: (state) => state.isLoading,
    mercureWatchInitialized: (state) => state.mercureWatchInitialized,
    isListLoading: (state) => state.isListLoading,
    isAllCoursesLoading: (state) => state.isAllCoursesLoading,
    isRideNumberLoading: (state) => state.isRideNumberLoading,
    rideNumber: (state) => state.rideNumber,
    allCourses: (state) => state.allCourses,
    totalCoursesCount: (state) => state.totalCoursesCount,
    selectedFiltersTab: (state) => state.selectedFiltersTab,
    filters: (state) => state.filters,
    filtersPayload: (state, _, _2, rootGetters) => {
      const filterKey = Object.keys(state.filters)[state.selectedFiltersTab];
      return {
        ...state.filters[filterKey],
        ...state.filters.abstractCourse,
        search: state.searchTerm,
        userId: rootGetters.user.id,
      };
    },
    searchTerm: (state) => state.searchTerm,
    courseMapper: (_, _2, _3, rootGetters) => new AbstractCourseMapper(rootGetters.isCompany),
  },
  mutations: {
    [SET_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },

    [SET_MERCURE_WATCH_INITIALIZED]: (state, mercureWatchInitialized) => {
      state.mercureWatchInitialized = mercureWatchInitialized;
    },

    [SET_LIST_LOADING]: (state, status) => {
      state.isListLoading[status] = !state.isListLoading[status];
    },

    [SET_COURSE]: (state, course) => {
      state.course = courseAdapter(course);
    },

    ADD_COURSE_IN_COLUMN: (state, course) => {
      const sortedColumn = [...state.courses[course.status], courseAdapter(course)];
      sortedColumn.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      state.coursesAmount[course.status] = sortedColumn.length;

      Vue.set(state.courses, course.status, sortedColumn);
    },

    [UPDATE_COURSE_IN_COLUMN]: (state, course) => {
      // TODO: refactor function into two separate actions:
      // - updating the existing course
      // - moving course to different column
      const index = state.courses[course.status].findIndex((haystackCourse) => haystackCourse.id === course.id);

      if (index >= 0) {
        if (course.rides.length === 0) {
          Vue.delete(state.courses[course.status], index);
        } else {
          Vue.set(state.courses[course.status], index, courseAdapter(course));
        }
        return;
      }

      Object.values(COURSE_STATUS).forEach((status) => {
        if (status !== course.status) {
          const indexToDelete = state.courses[status].findIndex((haystackCourse) => haystackCourse.id === course.id);
          if (indexToDelete >= 0) {
            Vue.delete(state.courses[status], indexToDelete);
            state.coursesAmount[status] = state.courses[status].length;

            if (course.rides.length === 0) {
              return;
            }

            const sortedColumn = [...state.courses[course.status], courseAdapter(course)];
            sortedColumn.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            state.coursesAmount[course.status] = sortedColumn.length;

            Vue.set(state.courses, course.status, sortedColumn);
          }
        }
      });
    },

    [SET_COURSES]: (state, payload) => {
      Vue.set(
        state.courses,
        payload.status,
        payload.courses.map((course) => courseAdapter(course))
      );
    },

    [SET_COURSES_AMOUNT]: (state, { status, totalAmount }) => {
      Vue.set(state.coursesAmount, status, totalAmount);
    },

    [ADD_LAZY_LOADED_COURSES_IN_COLUMN]: (state, { status, fetchedCourses }) => {
      const savedCourses = state.courses[status];
      Vue.set(state.courses, status, [...savedCourses, ...fetchedCourses.map((course) => courseAdapter(course))]);
    },

    [SET_RIDE_NUMBER_LOADING]: (state, isLoading) => {
      state.isRideNumberLoading = isLoading;
    },

    [SET_RIDE_NUMBER]: (state, rideNumber) => {
      const generateNewRideNumber = (number) => (Number(number) ? Number(number) + 1 : null);

      state.rideNumber = generateNewRideNumber(rideNumber);
    },
    [SET_RIDE_RATING]: (state, { rideRating, courseId }) => {
      const index = state.allCourses.findIndex((haystackCourse) => haystackCourse.id === courseId);

      Vue.set(state.allCourses, index, {
        ...state.allCourses[index],
        rides: [{ ...state.allCourses[index].rides[0], rideRatings: [rideRating] }],
      });
    },
    [SET_ALL_COURSES]: (state, payload) => {
      state.allCourses = payload.map((course) => courseAdapter(course));
    },
    [ADD_LAZY_LOADED_COURSES_IN_LIST]: (state, payload) => {
      state.allCourses = [...state.allCourses, ...payload.map((course) => courseAdapter(course))];
    },
    [SET_ALL_COURSES_COUNT]: (state, payload) => {
      state.totalCoursesCount = payload;
    },
    [SET_ALL_COURSES_LOADING]: (state, payload) => {
      state.isAllCoursesLoading = payload;
    },
    ADD_COURSE_IN_LIST: (state, newCourse) => {
      const sortedCourses = [...state.allCourses, courseAdapter(newCourse)];
      sortedCourses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      state.allCourses = sortedCourses;
    },
    [UPDATE_COURSE_IN_LIST]: (state, updatedCourse) => {
      const index = state.allCourses.findIndex((haystackCourse) => haystackCourse.id === updatedCourse.id);

      if (index !== -1) {
        Vue.set(state.allCourses, index, courseAdapter(updatedCourse));
      }
    },
    SET_FILTER_TAB(state, val) {
      state.selectedFiltersTab = val;
    },
    SAVE_FILTERS(state, val) {
      state.filters = {
        ...state.filters,
        ...val,
      };
    },
    RESET_FILTERS(state) {
      if (state.selectedFiltersTab !== DEFAULT_TAB || !isEqual(state.filters, DEFAULT_FILTERS)) {
        state.filters = DEFAULT_FILTERS;
        state.selectedFiltersTab = DEFAULT_TAB;
      }
    },
    [SET_SEARCH_TERM]: (state, val) => {
      state.searchTerm = val;
    },
    [REMOVE_FILTER]: (state, key) => {
      const filterKey = Object.keys(state.filters)[state.selectedFiltersTab];
      state.filters[filterKey][key] = null;
    },
    [RESET_AFTER_DATE_FILTER]: (state) => {
      if (!isEqual(state.filters.abstractCourse.dateStartAfter, DEFAULT_FILTERS.abstractCourse.dateStartAfter)) {
        state.filters.abstractCourse.dateStartAfter = DEFAULT_FILTERS.abstractCourse.dateStartAfter;
      }
    },
    [RESET_BEFORE_DATE_FILTER]: (state) => {
      if (!isEqual(state.filters.abstractCourse.dateStartBefore, DEFAULT_FILTERS.abstractCourse.dateStartBefore)) {
        state.filters.abstractCourse.dateStartBefore = DEFAULT_FILTERS.abstractCourse.dateStartBefore;
      }
    },
    [RESET_TRANSPORT_TYPE]: (state) => {
      if (state.selectedFiltersTab !== DEFAULT_TAB) {
        state.selectedFiltersTab = DEFAULT_TAB;
        state.filters.taxiCourse = DEFAULT_FILTERS.taxiCourse;
        state.filters.kilometerCourse = DEFAULT_FILTERS.kilometerCourse;
      }
    },
    [RESET_LOCOMOTIVE_FILTER]: (state) => {
      state.filters.abstractCourse.locomotive = null;
    },
    [EXCLUDE_CANCELLED_COURSES]: (state) => {
      state.filters.abstractCourse.includeCancelledCourses = false;
    },
    [RESET_CREATED_BY_FILTER]: (state) => {
      state.filters.abstractCourse.createdBy = null;
    },
    [REMOVE_COURSE]: (state, { courseId, isPassenger }) => {
      if (isPassenger) {
        const haystackCourseIdx = state.allCourses.findIndex((course) => course.id === courseId);
        state.allCourses.splice(haystackCourseIdx, 1);
      } else {
        let haystackCourseIdx;
        // eslint-disable-next-line no-restricted-syntax
        for (const status of Object.values(COURSE_STATUS)) {
          haystackCourseIdx = state.courses[status].findIndex((course) => course.id === courseId);

          if (haystackCourseIdx !== -1) {
            Vue.delete(state.courses[status], haystackCourseIdx);
            state.coursesAmount[status] = state.courses[status].length;
            return;
          }
        }
      }
    },
  },
  actions: {
    fetchCoursesByStatus({ commit, getters }) {
      Object.values(COURSE_STATUS).forEach((status) => {
        commit(SET_LIST_LOADING, status);
        TaxiCourseService.fetchCoursesByStatus(status, getters.filtersPayload)
          .then(({ data }) => {
            commit(SET_COURSES, {
              status,
              courses: data['hydra:member'].flatMap((c) => getters.courseMapper.toModel(c)),
            });
            commit(SET_COURSES_AMOUNT, { status, totalAmount: data['hydra:totalItems'] });
          })
          .finally(() => commit(SET_LIST_LOADING, status));
      });
    },

    fetchAllCourses({ commit, getters }) {
      commit(SET_ALL_COURSES_LOADING, true);

      TaxiCourseService.fetchAllCourses(getters.filtersPayload)
        .then(({ data }) => {
          commit(SET_ALL_COURSES, data['hydra:member']);
          commit(SET_ALL_COURSES_COUNT, data['hydra:totalItems']);
        })
        .finally(() => {
          commit(SET_ALL_COURSES_LOADING, false);
        });
    },

    fetchAllCoursesOnPage({ commit, getters }, page) {
      TaxiCourseService.fetchAllCoursesOnPage({ page, filters: getters.filtersPayload }).then(({ data }) => {
        commit(ADD_LAZY_LOADED_COURSES_IN_LIST, data['hydra:member']);
      });
    },

    fetchCoursesByStatusOnPage({ commit, getters }, { status, page }) {
      return TaxiCourseService.fetchCoursesByStatusOnPage({ status, page, filters: getters.filtersPayload }).then(
        ({ data }) => {
          commit(ADD_LAZY_LOADED_COURSES_IN_COLUMN, {
            status,
            fetchedCourses: data['hydra:member'].flatMap((c) => getters.courseMapper.toModel(c)),
          });
        }
      );
    },

    settleFinishedCourses({ commit, getters }) {
      commit('setLoading', true, { root: true });
      TaxiCourseService.settleFinishedCourses(getters.filtersPayload).finally(() => {
        commit('setLoading', false, { root: true });
      });
    },

    watchCourses({ getters, commit, rootGetters }) {
      if (getters.mercureWatchInitialized) {
        return;
      }

      commit(SET_MERCURE_WATCH_INITIALIZED, true);

      rootGetters['sse/eventSource'].addEventListener('course_delete', (e) => {
        const course = JSON.parse(e.data);

        console.log(course);

        [getters.courseMapper.toModel(course)]
          .flat()
          .forEach((c) => commit(REMOVE_COURSE, { courseId: c, isPassenger: rootGetters.isPassenger }));
      });

      rootGetters['sse/eventSource'].addEventListener('course_insert', (e) => {
        const course = JSON.parse(e.data);

        console.log(course);

        if (rootGetters.isPassenger) {
          commit('ADD_COURSE_IN_LIST', course);
        } else if (rootGetters.isDriver && getters.course.id === course.id) {
          commit(SET_COURSE, course);
        } else {
          [getters.courseMapper.toModel(course)].flat().forEach((c) => commit('ADD_COURSE_IN_COLUMN', c));
        }
      });

      rootGetters['sse/eventSource'].addEventListener('course_update', (e) => {
        const course = JSON.parse(e.data);

        console.log(course);

        if (rootGetters.isPassenger) {
          commit(UPDATE_COURSE_IN_LIST, course);
        } else if (rootGetters.isDriver && getters.course.id === course.id) {
          commit(SET_COURSE, course);
        } else {
          [getters.courseMapper.toModel(course)].flat().forEach((c) => commit(UPDATE_COURSE_IN_COLUMN, c));
        }
      });
    },

    downloadReport({ commit, getters }) {
      commit(SET_LOADING, true);
      TaxiCourseService.downloadReport(getters.filtersPayload).finally(() => commit(SET_LOADING, false));
    },

    fetchCourseByToken({ commit }, token) {
      commit(SET_LOADING, true);
      return TaxiCourseService.fetchCourseByToken(token)
        .then((data) => commit(SET_COURSE, data))
        .catch((error) => error)
        .finally(() => commit(SET_LOADING, false));
    },

    replyToRide({ commit }, payload) {
      commit(SET_LOADING, true);
      return TaxiCourseService.replyToRide(payload.driverAccessToken, payload.rideId, payload.reply)
        .then((data) => commit(SET_COURSE, data))
        .finally(() => commit(SET_LOADING, false));
    },

    segmentAchieved({ commit }, payload) {
      commit(SET_LOADING, true);
      return TaxiCourseService.segmentAchieved(payload.driverAccessToken, payload.waypointId)
        .then((data) => commit(SET_COURSE, data))
        .finally(() => commit(SET_LOADING, false));
    },

    startRide({ commit }, payload) {
      commit(SET_LOADING, true);
      return TaxiCourseService.startRide(payload.driverAccessToken)
        .then((data) => commit(SET_COURSE, data))
        .finally(() => commit(SET_LOADING, false));
    },

    endRide({ commit }, payload) {
      commit(SET_LOADING, true);
      TaxiCourseService.endRide(payload.driverAccessToken, payload.date)
        .then((data) => commit(SET_COURSE, data))
        .finally(() => commit(SET_LOADING, false));
    },

    sendCourseReport({ commit }, payload) {
      commit(SET_LOADING, true);
      return TaxiCourseService.sendCourseReport(payload)
        .then((data) => commit(SET_COURSE, data))
        .finally(() => commit(SET_LOADING, false));
    },

    create({ commit }, taxiCourse) {
      commit(SET_LOADING, true);
      TaxiCourseService.createTaxiCourse(taxiCourse)
        .then(() => commit('courseCreation/RESET_COURSE', null, { root: true }))
        .finally(() => commit(SET_LOADING, false));
    },

    updateValue({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.updateValue(payload).finally(() => {
        commit('setLoading', false, { root: true });
      });
    },

    updateValues({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.updateValues(payload)
        .then(({ data }) => {
          const TAXI_COURSE_ENTITY = 'TaxiCourse';

          if (data['@type'] === TAXI_COURSE_ENTITY) {
            commit(SET_COURSE, data);
          }

          return data;
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    fetchRideNumber({ commit }, { id }) {
      commit(SET_RIDE_NUMBER_LOADING, true);
      return TaxiCourseService.fetchRideNumber(id)
        .then((data) => commit(SET_RIDE_NUMBER, data['hydra:member'][0].number))
        .finally(() => commit(SET_RIDE_NUMBER_LOADING, false));
    },

    postRideRating({ commit }, { payload }) {
      commit(SET_LOADING, true);
      return TaxiCourseService.postRideReport(payload).finally(() => commit(SET_LOADING, false));
    },
    removeFilter({ commit }, filterKey) {
      if (filterKey === DISCRIMINATOR_KEY) {
        commit(RESET_TRANSPORT_TYPE);
      } else if (filterKey === AFTER_DATE_START_KEY) {
        commit(RESET_AFTER_DATE_FILTER);
      } else if (filterKey === BEFORE_DATE_START_KEY) {
        commit(RESET_BEFORE_DATE_FILTER);
      } else if (filterKey === LOCOMOTIVE_KEY) {
        commit(RESET_LOCOMOTIVE_FILTER);
      } else if (filterKey === INCLUDE_CANCELLED_COURSES_KEY) {
        commit(EXCLUDE_CANCELLED_COURSES);
      } else if (filterKey === CREATED_BY_KEY) {
        commit(RESET_CREATED_BY_FILTER);
      } else {
        commit(REMOVE_FILTER, filterKey);
      }
    },

    addPrincipal({ commit }, principal) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.addPrincipal(principal)
        .then((company) => {
          commit('setLoggedCompany', company, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    addContractor({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.addContractor(payload)
        .then((company) => {
          if (payload.contractor.contractorOf === 'railway_company') {
            commit('updateContractorsOfPrincipal', company, { root: true });
          } else {
            commit('setLoggedCompany', company, { root: true });
          }
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    addContractorStake({ commit }, { isContractorOfRailwayCompany, ...payload }) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.addContractorStake(payload)
        .then((company) => {
          if (isContractorOfRailwayCompany) {
            commit('updateContractorsOfPrincipal', company, { root: true });
          } else {
            commit('setLoggedCompany', company, { root: true });
          }
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    updateContractor({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return TaxiCourseService.updateContractor(payload)
        .then((updatedLoggedCompany) => {
          commit('setLoggedCompany', updatedLoggedCompany, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    revertCancelledRide({ dispatch }, { ride, course }) {
      return dispatch('updateValues', {
        '@id': ride['@id'],
        payload: {
          status: getStatusForCancelledRide(course),
        },
      });
    },
  },
};
