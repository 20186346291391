<template>
  <v-col :class="sizeableClasses">
    <v-card>
      <v-toolbar
        v-if="title !== undefined || back !== undefined || edit !== undefined"
        :color="color || 'transparent'"
        :dark="dark"
        :light="light"
        flat
      >
        <v-app-bar-nav-icon v-if="back" :to="back" exact data-cy="back-button">
          <v-icon>arrow_back</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>

        <v-spacer />

        <v-app-bar-nav-icon
          v-if="edit && (!edit.roles || hasRoles(edit.roles))"
          :to="edit"
          aria-label="edytuj"
          exact
          data-cy="edit-button"
        >
          <v-icon>edit</v-icon>
        </v-app-bar-nav-icon>

        <v-app-bar-nav-icon
          v-for="additionalElement in additionalElements"
          :href="additionalElement.href"
          :key="additionalElement.id"
          exact
        >
          <v-icon>{{ additionalElement.icon }}</v-icon>
        </v-app-bar-nav-icon>

        <v-app-bar-nav-icon v-if="remove && hasRoles(remove.roles)" exact>
          <v-btn icon small text color="error" @click="deleteEntity()" data-cy="remove-button">
            <v-icon>delete</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
      </v-toolbar>

      <slot></slot>
    </v-card>
  </v-col>
</template>

<script>
import sizeable from '@/core/mixin/sizeable';
import intersection from 'lodash/fp/intersection';

export default {
  name: 'OneProfileSummary',
  mixins: [sizeable],
  props: {
    title: String,
    back: Object,
    edit: Object,
    color: String,
    dark: Boolean,
    light: Boolean,
    remove: null,
    additionalElements: Array,
  },

  methods: {
    deleteEntity() {
      this.$dialog
        .confirm(
          {
            title: 'Czy jesteś pewien, że chcesz to zrobić? Operacja będzie nieodwracalna',
          },
          {
            okText: 'Tak, usuń',
            cancelText: 'Anuluj',
          }
        )
        .then(() => {
          this.$http
            .delete(`api/${this.$route.path.substring(1)}`)
            .then(() => {
              this.$notify({
                group: 'global',
                type: 'success',
                title: 'Sukces',
                text: 'Pomyślnie usunięto rekord',
              });
              this.$router.push(this.back);
            })
            .catch((error) => {
              this.$notify({
                group: 'global',
                type: 'error',
                title: 'Błąd usuwania',
                text: error.data.message ? error.data.message : 'Wystąpił błąd usuwania',
              });
            });
        });
    },

    hasRoles(roles) {
      return intersection(this.$store.state.user.roles, roles).length > 0;
    },
  },
};
</script>
