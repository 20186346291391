<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import EditablePassengerField from './editable-passenger-field';
import NonEditablePassengerField from './passenger-field';

export default {
  name: 'PassengerFieldFactory',
  inheritAttrs: false,
  computed: {
    ...mapGetters(['isManager', 'isCompany', 'isTransportCompany']),
    userWithEditableField() {
      return this.isManager || this.isCompany || this.isTransportCompany;
    },
    currentComponent() {
      return this.userWithEditableField ? EditablePassengerField : NonEditablePassengerField;
    },
  },
};
</script>
