<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import AddRailwayContractorModal from './add-railway-contractor-modal';
import AddSuperiorTransportCompanyContractorModal from './add-superior-transport-company-contractor-modal';
import AddBaseTransportCompanyContractorModal from './add-base-transport-company-contractor-modal';

export default {
  name: 'AddContractorModalFactory',
  inheritAttrs: false,
  computed: {
    ...mapGetters(['isCompany', 'isSuperiorTransportCompany']),
    currentComponent() {
      if (this.isCompany) {
        return AddRailwayContractorModal;
      }

      if (this.isSuperiorTransportCompany) {
        return AddSuperiorTransportCompanyContractorModal;
      }

      return AddBaseTransportCompanyContractorModal;
    },
  },
};
</script>
