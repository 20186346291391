<template>
  <v-chip class="rounded custom-chip custom-chip--disabled" x-small v-bind="$attrs">{{ courseBadge }}</v-chip>
</template>

<script>
export default {
  name: 'AbstractCourseBadge',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    courseBadge() {
      return this.text.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-chip {
  padding: 0 6px;
}
.custom-chip.custom-chip--disabled:hover:before {
  opacity: 0 !important;
}
</style>
