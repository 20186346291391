<template>
  <v-btn class="mr-1 mb-4" fab v-on="$listeners" aria-label="current-location-button">
    <v-icon v-if="gpsActive" color="primary">mdi-crosshairs-gps</v-icon>
    <v-icon v-else color="error">mdi-crosshairs-off</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CurrentLocationButton',
  inheritAttrs: false,
  props: {
    gpsActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
