import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { store } from '@/core/store/index';
import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';

export default {
  fetchPlace: (id) => Vue.http.get(`api/abstract_places/${id}`),

  fetchPlaces: () => Vue.http.get(`api/abstract_places?type=place,workPlace,home`),

  softDeletePlace: (id) =>
    Vue.http
      .put(`api/abstract_places/${id}`, { isActive: false })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then(`Miejsce ${data.name} zostało poprawnie usunięte`));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      }),

  insertPlace({ place, type, owner }) {
    const company = place.company ? place.company['@id'] : null;
    /**
     * @param {string} type - The type of selected place.
     * @returns {string}
     */
    const getType = () => {
      let placeType;
      switch (type) {
        case ABSTRACT_PLACE_TYPES.HOME:
          placeType = 'home';
          break;
        case ABSTRACT_PLACE_TYPES.WORK:
          placeType = 'workPlace';
          break;
        case ABSTRACT_PLACE_TYPES.PLACE:
          placeType = 'place';
          break;
        case ABSTRACT_PLACE_TYPES.HOTEL:
          placeType = 'hotel';
          break;
        default:
          throw Error(`Type of place "${type}" is not recognized.`);
      }

      return placeType;
    };

    return Vue.http
      .post(`api/abstract_places`, {
        ...place,
        type: getType(),
        company,
        owner,
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then(`Miejsce ${data.name} zostało poprawnie dodane`));
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  updatePlace({ place }) {
    const company = place.company ? place.company['@id'] : null;

    return Vue.http
      .put(`api/abstract_places/${place.id}`, {
        ...place,
        company: store.getters.isCompany ? undefined : company,
      })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return data;
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      });
  },

  getSuggestedPlace(employee, type, place) {
    if (type === ABSTRACT_PLACE_TYPES.HOTEL) {
      return Vue.http
        .get(`api/abstract_places?places_in_radius=${place.latitude},${place.longitude},50&type=hotel`)
        .then(({ data }) => data['hydra:member'][0]?.['@id']);
    }

    return Vue.http
      .get(`api/segments?employees.id=${employee.id}&order[startTime]=desc&type=${type}`)
      .then(({ data }) => {
        const hashmap = data['hydra:member']
          .reverse()
          .map((segment) => segment.waypointStart.place)
          .reduce((acc, val) => {
            acc[val] = (acc[val] || 0) + 1;
            return acc;
          }, {});

        if (!hashmap.length) {
          return [];
        }

        return Object.keys(hashmap).reduce((a, b) => (hashmap[a] > hashmap[b] ? a : b));
      });
  },

  addHomeplaceToUser({ user, home }) {
    return Vue.http.put(`api/users/${user.id}`, { id: user['@id'], home }).catch(({ data, status }) => {
      Vue.notify(defaultApiResultManager.catch(data, status));

      throw data;
    });
  },
};
