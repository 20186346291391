<template>
  <TimeField
    suffix="h"
    v-bind="$attrs"
    v-on="{ blur: $listeners.blur }"
    :value="formattedTime"
    @input="emitTimeInSeconds"
  >
    <template #append>
      <v-icon
        class="ml-1"
        :disabled="isDefaultValue"
        @click="intervalIconClicked(-incriminator)"
        data-cy="decrease-interval-btn"
      >
        mdi-minus-circle-outline
      </v-icon>
      <v-icon class="mr-1" @click="intervalIconClicked(incriminator)" data-cy="increase-interval-btn">
        mdi-plus-circle-outline
      </v-icon>
    </template>
  </TimeField>
</template>

<script>
import TimeField from './time-field';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MIN = 60;
const FIVE_MIN_IN_SEC = 300;

export default {
  name: 'FallbackDistanceTimeField',
  inheritAttrs: false,
  components: {
    TimeField,
  },
  computed: {
    isDefaultValue() {
      return this.$attrs.value === 0 || this.$attrs.value === null;
    },
    formattedTime() {
      const hours = Math.floor(this.$attrs.value / SECONDS_IN_HOUR);
      const minutes = Math.floor((this.$attrs.value % SECONDS_IN_HOUR) / SECONDS_IN_MIN);

      const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      return hours <= 0 && minutes <= 0 ? '00:00' : formattedTime;
    },
    incriminator() {
      return FIVE_MIN_IN_SEC;
    },
  },
  methods: {
    intervalIconClicked(timeToAdd) {
      this.$emit('input', this.$attrs.value + timeToAdd);
    },
    timeInSeconds(val) {
      const [hours, minutes] = val ? val.split(':') : [0, 0];
      return Number(hours) * SECONDS_IN_HOUR + Number(minutes) * SECONDS_IN_MIN;
    },
    emitTimeInSeconds(val) {
      this.$emit('input', this.timeInSeconds(val));
    },
  },
};
</script>
