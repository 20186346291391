<template>
  <v-text-field
    class="flex-grow-0"
    append-icon="mdi-magnify"
    dense
    outlined
    clearable
    background-color="#3397ff"
    hide-details
    single-line
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
export default {
  name: 'ToolbarSearchField',
};
</script>

<style lang="scss" scoped></style>
