import { format, subDays } from 'date-fns';

const PAST_FILTER_RANGE_IN_DAYS = 3;

export const DEFAULT_TAB = 0;
export const DISCRIMINATOR_KEY = 'discriminator';
export const AFTER_DATE_START_KEY = 'dateStartAfter';
export const BEFORE_DATE_START_KEY = 'dateStartBefore';
export const LOCOMOTIVE_KEY = 'locomotive';
export const INCLUDE_CANCELLED_COURSES_KEY = 'includeCancelledCourses';
export const CREATED_BY_KEY = 'createdBy';

export const DEFAULT_FILTERS = {
  abstractCourse: {
    dateStartAfter: format(subDays(new Date(), PAST_FILTER_RANGE_IN_DAYS), 'YYYY-MM-DD'),
    dateStartBefore: null,
    locomotive: null,
    includeCancelledCourses: false,
    createdBy: null,
  },
  taxiCourse: {
    discriminator: 'taxiCourse',
    rideStatus: null,
    taxiCourseDriver: null,
    transportCompany: null,
    customerCompany: null,
    fromStartingBase: null,
  },
  kilometerCourse: {
    discriminator: 'kilometerCourse',
    rideStatus: null,
    kilometerCourseDriver: null,
  },
};
