import Vue from 'vue';
import { TIME_AND_DISTANCE_SOURCE_DICT } from '@/core/dict/Course/time-and-distance-source-dict';

export default {
  sendDefinedKilometerRequest({ requestUrlParams, segmentModel, companyId }) {
    return Vue.http.get(`api/defined_kilometers?${requestUrlParams}`).then(({ data }) => {
      if (!(data['hydra:member'].length && data['hydra:member'][0].acceptedBy.includes(companyId))) {
        return this.sendEstimatedKilometerRequest(requestUrlParams, segmentModel);
      }

      const segment = data['hydra:member'][0];
      return {
        ...segmentModel,
        time: segment.time,
        distance: segment.kilometers,
        timeAndDistanceSource: TIME_AND_DISTANCE_SOURCE_DICT.DEFINED,
        unlockDefined: false,
      };
    });
  },

  sendEstimatedKilometerRequest(requestUrlParams, segmentModel) {
    return Vue.http
      .get(`api/segment_route_details?${requestUrlParams}`)
      .then(({ data }) => {
        const result = data['hydra:member'];
        // ? when does it called
        if (!result.length) {
          return {
            ...segmentModel,
            distance: null,
            time: null,
            timeAndDistanceSource: TIME_AND_DISTANCE_SOURCE_DICT.FALLBACK,
            unlockDefined: true,
          };
        }

        return {
          ...segmentModel,
          time: result[0].time,
          distance: result[0].kilometers,
          timeAndDistanceSource: TIME_AND_DISTANCE_SOURCE_DICT.ESTIMATED,
          unlockDefined: false,
        };
      })
      .catch(() => ({
        ...segmentModel,
        distance: null,
        time: null,
        timeAndDistanceSource: TIME_AND_DISTANCE_SOURCE_DICT.FALLBACK,
        unlockDefined: true,
      }));
  },
};
