import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import { PassengerMapper } from '@/shared/mappers/passenger.mapper';

export default {
  createPassenger(payload) {
    return Vue.http
      .post(`api/passengers`, PassengerMapper.toDTO(payload))
      .then(({ data }) => {
        Vue.notify(
          defaultApiResultManager.then(`Pasażer ${data.user.firstname} ${data.user.surname} został poprawnie dodany`)
        );
        // TODO: init user wasn't called
        return PassengerMapper.toModel(data);
      })
      .catch(({ status, data }) => {
        Vue.notify(
          defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd dodawania')
        );

        throw data;
      });
  },

  updatePassenger(payload) {
    return Vue.http
      .put(`api/passengers/${payload.entity.id}`, PassengerMapper.toDTO(payload))
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then());
        return PassengerMapper.toModel(data);
      })
      .catch(({ status, data }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd edycji'));

        throw data;
      });
  },

  deletePassenger(payload) {
    return Vue.http
      .delete(`api/passengers/${payload.id}`, { isActive: false })
      .then(({ data }) => {
        Vue.notify(defaultApiResultManager.then(`Pasażer ${payload.name} został usunięty`));
        return data;
      })
      .catch(({ status, data }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, data.message ? data.message : 'Wystąpił błąd usuwania'));

        throw data;
      });
  },

  fetchPassengerById(id) {
    return Vue.http
      .get(`api/passengers/${id}`)
      .then(({ data }) => PassengerMapper.toModel(data))
      .catch(({ status, data }) => {
        Vue.notify(defaultApiResultManager.catch(data, status, 'Wystąpił błąd podczas ładowania'));

        throw data;
      });
  },
};
