<template>
  <div class="d-inline">
    <v-list-item class="pb-6 mt-2">
      <taxi-course-ride-status-field
        :value="filters.rideStatus"
        label="Status taksówki"
        @input="$emit('input', { ...filters, rideStatus: $event })"
      />
    </v-list-item>
    <v-list-item class="pb-6">
      <driver-field
        :icon="null"
        :value="filters.taxiCourseDriver"
        @input="$emit('input', { ...filters, taxiCourseDriver: $event })"
      />
    </v-list-item>
    <v-list-item class="pb-6">
      <starting-base-field
        :icon="null"
        :value="filters.fromStartingBase"
        @input="$emit('input', { ...filters, fromStartingBase: $event })"
      />
    </v-list-item>
    <v-list-item class="pb-6">
      <company-field
        :icon="null"
        :value="filters.transportCompany"
        dense-outlined
        is-transport-company
        label="Firma realizująca zlecenie"
        @input="$emit('input', { ...filters, transportCompany: $event })"
      />
    </v-list-item>
    <v-list-item class="pb-6" v-if="!isCompanyUser">
      <company-field
        :icon="null"
        :value="filters.customerCompany"
        dense-outlined
        is-not-transport-company
        label="Obsługiwana firma"
        @input="$emit('input', { ...filters, customerCompany: $event })"
      />
    </v-list-item>
  </div>
</template>
<script>
import CompanyField from '@/component/Field/Company/company-field';
import DriverField from '@/component/Field/Driver/driver-field';
import StartingBaseField from '@/component/Field/Place/starting-base-field';
import TaxiCourseRideStatusField from '@/component/Field/TaxiCourse/taxi-course-ride-status-field';
import { mapGetters } from 'vuex';

export default {
  name: 'taxi-course-filters',
  components: {
    CompanyField,
    DriverField,
    TaxiCourseRideStatusField,
    StartingBaseField,
  },
  props: {
    filters: Object,
  },
  computed: mapGetters(['isTransportCompany', 'isCompanyUser']),
};
</script>
