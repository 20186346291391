import Vue from 'vue';
import { store } from '@/core/store/index';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';
import router from '@/core/config/router';
import download from 'downloadjs';

export default {
  fetchInvoices: () => Vue.http.get(`api/invoices`),

  fetchInvoice: (id) => Vue.http.get(`api/invoices/${id}`),

  fetchAcceptedCourses: (dateFrom, dateTo, companyParam) => {
    const requestUrlParams = new URLSearchParams();
    requestUrlParams.set('startedAt[after]', dateFrom);
    requestUrlParams.set('startedAt[before]', `${dateTo}T23:59:59`);
    requestUrlParams.set(companyParam, store.getters.user.company.id);

    return Vue.http
      .get(`api/courses?${requestUrlParams}`)
      .then(({ data }) => data)
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));
      });
  },

  saveInvoice: (invoice) =>
    Vue.http
      .post(`api/invoices`, {
        ...invoice,
        buyer: invoice.buyer['@id'] ? invoice.buyer['@id'] : `/api/companies/${invoice.buyer.id}`,
        seller: invoice.seller['@id'] ? invoice.seller['@id'] : `/api/companies/${invoice.seller.id}`,
        invoiceItems: invoice.invoiceItems.map((invoiceItem) => ({
          ...invoiceItem,
          price: invoiceItem.price * 100,
        })),
      })
      .then(() => {
        Vue.notify(defaultApiResultManager.then());
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      }),

  updateInvoice: (invoice) =>
    Vue.http
      .put(invoice['@id'].substring(1), {
        ...invoice,
        buyer: invoice.buyer['@id'] ? invoice.buyer['@id'] : `/api/companies/${invoice.buyer.id}`,
        seller: invoice.seller['@id'] ? invoice.seller['@id'] : `/api/companies/${invoice.seller.id}`,
        invoiceItems: invoice.invoiceItems.map((invoiceItem) => ({
          ...invoiceItem,
          id: invoiceItem['@id'],
        })),
      })
      .then(() => {
        Vue.notify(defaultApiResultManager.then());
        router.push({ name: 'invoices_index' });
      })
      .catch(({ data, status }) => {
        Vue.notify(defaultApiResultManager.catch(data, status));

        throw data;
      }),

  downloadInvoice: (invoice) =>
    Vue.http({
      method: 'GET',
      url: `api/invoices/${invoice.id}/pdf`,
      responseType: 'arraybuffer',
    }).then((response) => {
      download(response.data, `${invoice.number}.pdf`, 'application/pdf');
    }),

  downloadReport: (invoice) =>
    Vue.http({
      method: 'GET',
      url: `api/invoices/courses_export/${invoice.id}`,
      responseType: 'arraybuffer',
      headers: {
        accept: 'application/csv.polish.excel',
      },
    }).then((response) => {
      download(response.data, `${invoice.number}.csv`, 'text/csv');
    }),

  transform(payload, grouped, baseCompany = true) {
    const newInvoices = [];
    Object.keys(grouped).forEach((key) => {
      const oldInvoice = payload.invoices.find((invoice) => invoice.key === key);
      const waitStake = oldInvoice ? oldInvoice.waitStake : null;
      const kilometerStake = oldInvoice ? oldInvoice.kilometerStake : null;
      const companyApproved = oldInvoice ? oldInvoice.companyApproved : false;
      const courses = companyApproved ? grouped[key].filter((course) => course.companyApproved) : grouped[key];
      newInvoices.push({
        key,
        companyApproved,
        isCreated: oldInvoice ? oldInvoice.isCreated : false,
        number: oldInvoice ? oldInvoice.number : undefined,
        place: oldInvoice ? oldInvoice.place : null,
        issueDate: oldInvoice ? oldInvoice.issueDate : null,
        sellDate: oldInvoice ? oldInvoice.sellDate : null,
        paymentTo: oldInvoice ? oldInvoice.paymentTo : null,
        buyer: baseCompany ? grouped[key][0].passengers[0].passenger.company : store.getters.user.company,
        seller: baseCompany ? store.getters.user.company : grouped[key][0].baseTransportCompany,
        kilometerStake,
        waitStake,
        courses: courses.map((course) => course['@id']),
        invoiceItems: !courses.length
          ? []
          : courses.reduce(
              (result, current) => [
                {
                  name: 'Transport pasażerski',
                  quantityUnit: 'km',
                  tax: 8,
                  price: kilometerStake || 'Uzupełnij stawkę',
                  quantity: result[0].quantity + current.distanceWithDefinedKilometers,
                },
                {
                  name: 'Opłaty za oczekiwanie i autostrady',
                  quantityUnit: 'szt.',
                  tax: 23,
                  price: waitStake ? parseFloat(waitStake) + 1 : 'Uzupełnij stawkę',
                  quantity: result[1].quantity + current.driverWaiting + current.driverCosts,
                },
              ],
              [{ quantity: 0 }, { quantity: 0 }]
            ),
      });
    });

    return newInvoices;
  },
};
