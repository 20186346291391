export default {
  then(successMessage = 'Dane zapisano prawidłowo') {
    return {
      group: 'global',
      type: 'success',
      text: successMessage,
    };
  },

  catch(data, status, message = 'Coś poszło nie tak') {
    let errorMessage = message;
    if (status < 500) {
      errorMessage = data.violations ? data.violations.pop().message : data['hydra:description'] || message;
    }

    return {
      group: 'global',
      type: 'error',
      text: errorMessage,
    };
  },
};
