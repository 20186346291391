<template>
  <div class="divider">
    <div class="divider__count">{{ count }}</div>
  </div>
</template>

<script>
export default {
  name: 'CollapsedWaypointIndicator',
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.divider {
  position: relative;
  display: flex;
  align-items: center;
  width: min-content;
  height: 100%;
}

.divider::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: calc(50% - 1px);
  border: 1px dashed #e0e0e0;
  border-radius: 20px;
}

.divider__count {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cacaca;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  z-index: 1;
}
</style>
