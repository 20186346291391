<template>
  <AutocompleteField
    icon="mdi-account"
    label="Dodane przez"
    denseOutlined
    :loading="usersListLoading"
    :items="usersList"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import { mapGetters } from 'vuex';

export default {
  name: 'CreatedByField',
  inheritAttrs: false,
  components: {
    AutocompleteField,
  },
  created() {
    this.$store.dispatch('fetchUsers');
  },
  computed: {
    ...mapGetters(['usersList', 'usersListLoading']),
  },
};
</script>
