import CompanyService from '@/service/CompanyService';
import { SET_COMPANY, SET_LOADING } from '@/core/store/modules/types/company-mutation-types';

export default {
  namespaced: true,
  state: {
    company: {},
    isLoading: false,
  },
  getters: {
    company: (state) => state.company,
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    [SET_COMPANY]: (state, company) => {
      state.company = company;
    },

    [SET_LOADING]: (state, isLoading) => {
      state.isLoading = isLoading;
    },
  },
  actions: {
    fetchCompany({ commit }, id) {
      commit(SET_LOADING, true);
      CompanyService.fetchCompany(id)
        .then(({ data }) => {
          commit(SET_COMPANY, data);
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    },

    update({ commit }, company) {
      commit(SET_LOADING, true);
      CompanyService.updateCompany(company).finally(() => {
        commit(SET_LOADING, false);
      });
    },

    save({ commit }, company) {
      commit(SET_LOADING, true);
      CompanyService.insertCompany(company).finally(() => {
        commit(SET_LOADING, false);
      });
    },

    createRailwayCompany({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CompanyService.createRailwayCompany(payload)
        .then((company) => {
          commit('addRailwayCompany', company, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    createTransportCompany({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return CompanyService.createTransportCompany({ ...payload, isTransportCompany: true })
        .then((company) => {
          commit('addTransportCompany', company, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
