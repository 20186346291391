<template>
  <AbstractCarModal v-bind="$attrs" v-on="$listeners"></AbstractCarModal>
</template>

<script>
import AbstractCarModal from './abstract-car-modal';

export default {
  name: 'CreateCarForPassengerModal',
  components: {
    AbstractCarModal,
  },
};
</script>
