import { store } from '@/core/store';

// eslint-disable-next-line import/prefer-default-export
export class OpenTransferRideDialogCommand {
  #store = store;

  #transferSubject;

  constructor(transferSubject) {
    this.#transferSubject = transferSubject;
  }

  execute() {
    this.#store.dispatch('transferRide/openDialog', this.#transferSubject);
  }
}
