import PassengerService from '@/service/PassengerService';
import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';

export default {
  namespaced: true,
  actions: {
    createPassenger({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return PassengerService.createPassenger(payload)
        .then((data) => {
          commit('addPassenger', data, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    fetchPassengerById(_, payload) {
      return PassengerService.fetchPassengerById(payload);
    },

    updatePassenger({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return PassengerService.updatePassenger(payload)
        .then((data) => {
          commit('updatePassenger', data, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    deletePassenger({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return PassengerService.deletePassenger(payload)
        .then(() => {
          commit('removePassenger', payload, { root: true });
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },

    async createHomeplace({ dispatch }, { place, entity }) {
      if (entity.user.home) {
        await dispatch('place/softDeletePlace', entity.user.home, { root: true });
      }

      const placePayload = {
        ...place,
        user: entity.user,
        '@type': ABSTRACT_PLACE_TYPES.HOME,
        id: null,
        '@id': null,
        name: entity.user.name,
        company: entity.user.company,
      };

      const createdPlace = await dispatch(
        'place/save',
        {
          place: placePayload,
          type: ABSTRACT_PLACE_TYPES.HOME,
        },
        { root: true }
      );

      await dispatch('updatePassenger', {
        entity: {
          ...entity,
          user: {
            ...entity.user,
            home: createdPlace,
          },
        },
      });

      return createdPlace;
    },

    async removeHomeplace({ dispatch }, entity) {
      await dispatch('place/softDeletePlace', entity.user.home, { root: true });

      await dispatch('updatePassenger', {
        entity: {
          ...entity,
          user: {
            ...entity.user,
            home: null,
          },
        },
      });

      return null;
    },
  },
};
