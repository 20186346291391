<template>
  <div class="d-flex align-center">
    <v-btn class="elevation-0" fab small color="rgba(51, 151, 255, 0.26)" @click="emitClick">
      <v-icon small color="primary">mdi-plus</v-icon>
    </v-btn>
    <add-waypoint-button @click="emitClick" />
  </div>
</template>

<script>
import AddWaypointButton from '@/component/NewCourse/Form/Ride/Segment/add-waypoint-button';

export default {
  name: 'AddWaypointMobileButton',
  components: { AddWaypointButton },
  methods: {
    emitClick() {
      this.$emit('click');
    },
  },
};
</script>
