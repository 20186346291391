<template>
  <BrandModal :value="isModalOpen" :strategy="strategy" :entity="entity" v-on="$listeners">
    <template #header>
      <AbstractModalHeader v-if="isEditModal" title="Edytuj markę i modele">
        <template #close>
          <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
        </template>
      </AbstractModalHeader>
      <AbstractModalHeader v-else title="Dodaj markę i modele">
        <template #close>
          <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
        </template>
      </AbstractModalHeader>
    </template>
  </BrandModal>
</template>

<script>
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import { CarBrand } from '@/shared/models/car-brand.model';
import BrandModal from './brand-modal';
import { CreateCarBrandStrategy } from './create-car-brand.strategy';
import { EditCarBrandStrategy } from './edit-car-brand.strategy';

export default {
  name: 'BrandModalFactory',
  components: {
    BrandModal,
    AbstractModalHeader,
    CloseIconButton,
  },
  props: {
    state: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isModalOpen() {
      return this.state !== MODAL_STATE.CLOSED;
    },
    isEditModal() {
      return this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type;
    },
    strategy() {
      return this.isEditModal ? new EditCarBrandStrategy(this.$store) : new CreateCarBrandStrategy(this.$store);
    },
    entity() {
      return this.isEditModal ? new CarBrand(this.state.selectedEntity) : new CarBrand();
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
