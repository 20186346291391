import Vue from 'vue';
import defaultApiResultManager from '@/core/shared/defaultApiResultManager';

export default {
  fetchLocomotiveList: () => Vue.http.get(`api/locomotives`),
  createLocomotive: (payload) =>
    Vue.http
      .post(`api/locomotives`, payload)
      .then(({ body }) => {
        Vue.notify(defaultApiResultManager.then());

        return body;
      })
      .catch(({ data }) => {
        Vue.notify(defaultApiResultManager.catch());

        throw data;
      }),

  editLocomotive: ({ id, ...payload }) =>
    Vue.http
      .put(`api/locomotives/${id}`, payload)
      .then(({ body }) => {
        Vue.notify(defaultApiResultManager.then());

        return body;
      })
      .catch(({ data }) => {
        Vue.notify(defaultApiResultManager.catch());

        throw data;
      }),
};
