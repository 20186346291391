<template>
  <div class="custom-view-wrapper">
    <h2 v-if="options.html" class="dg-title" v-html="messageTitle"></h2>
    <h2 v-else class="dg-title">{{ messageTitle }}</h2>
    <div v-if="options.html" class="dg-content" v-html="messageBody"></div>
    <div v-else class="dg-content">{{ messageBody }}</div>
    <br />
    <button v-if="options.courseId" @click="handleProceed(true)" class="dg-btn dg-btn--ok dg-pull-right">
      <span class="dg-btn-content"><span>Przejdź do ostatniego kursu i przypisz mnie do zgłoszenia</span></span>
    </button>
    <button @click="handleProceed(false)" class="dg-btn dg-btn--ok dg-pull-right">
      <span class="dg-btn-content"><span>Przypisz mnie do zgłoszenia</span></span>
    </button>
    <button @click="handleDismiss()" class="dg-btn dg-btn--cancel"><span>Anuluj</span></button>
  </div>
</template>

<script>
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min';

export default {
  mixins: [DialogMixin],
  methods: {
    handleProceed(proceed) {
      this.proceed(proceed);
    },
    handleDismiss() {
      this.cancel();
    },
  },
};
</script>

<style scoped="">
button {
  width: 100%;
  margin-bottom: 10px;
  float: none;
}
</style>
