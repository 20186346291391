// eslint-disable-next-line import/prefer-default-export
export class CreateCarStrategy {
  #store;

  #user;

  constructor({ store, user }) {
    this.#store = store;
    this.#user = user;
  }

  execute(payload) {
    return this.#store.dispatch('car/createCar', { carModel: payload, userModel: this.#user });
  }
}
