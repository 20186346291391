import { store } from '@/core/store';

// eslint-disable-next-line import/prefer-default-export
export class RemoveMessageCommand {
  constructor(message) {
    this.message = message;
  }

  execute() {
    store.dispatch('comment/remove', this.message.id);
  }
}
