<template>
  <component :is="currentComponent"></component>
</template>

<script>
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';
import { CAR_FLEET_PAGE } from '@/core/router/Page/fleet';
import PageToolbar from './abstract-page-toolbar';
import CreatePageToolbar from './create-page-toolbar';
import CoursePageToolbar from './courses-page-toolbar';
import CarFleetToolbar from './car-fleet-toolbar';

export default {
  name: 'PageToolbarFactory',
  computed: {
    isCourseListPage() {
      return this.$route.name === TAXI_COURSE_LIST_ROUTE;
    },
    isCarFleetPage() {
      return this.$route.name === CAR_FLEET_PAGE;
    },
    currentComponent() {
      if (this.isCourseListPage) {
        return CoursePageToolbar;
      }

      if (this.isCarFleetPage) {
        return CarFleetToolbar;
      }

      if (this.$route.meta.create || this.$route.meta.createEvent) {
        return CreatePageToolbar;
      }

      return PageToolbar;
    },
  },
};
</script>
