import TaxiCourseList from '@/controller/NewCourse/course-list';
import { TAXI_COURSE_CREATE_ROUTE, TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';

const TaxiCourseCreate = () => import('@/controller/NewCourse/course-create');

export default [
  {
    path: '/course',
    name: TAXI_COURSE_LIST_ROUTE,
    component: TaxiCourseList,
    meta: {
      title: 'Kursy',
      create: 'taxi_course_create',
    },
  },
  {
    path: '/course/create',
    name: TAXI_COURSE_CREATE_ROUTE,
    component: TaxiCourseCreate,
    meta: {
      title: 'Utwórz kurs',
    },
  },
];
