<template>
  <div>
    <ContextMenu :buttons="buttons">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-on="on" v-bind="attrs" aria-label="otwórz kontekstowe menu">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
    </ContextMenu>
    <SettleCoursesConfirmDialog :showDialog="value" @confirm="onConfirmActionsClicked"></SettleCoursesConfirmDialog>
  </div>
</template>

<script>
import ContextMenu from '@/component/ContextMenu/context-menu';
import SettleCoursesConfirmDialog from './settle-courses-confirm-dialog';
import { SettleFinishedCoursesCommand } from './settle-finished-courses.command';
import { OpenConfirmDialogCommand } from './open-confirm-dialog.command';

export default {
  name: 'FinishedColumnContextMenu',
  components: {
    ContextMenu,
    SettleCoursesConfirmDialog,
  },
  data() {
    return {
      buttons: [{ title: 'Zaakceptuj wszystkie do rozliczenia', command: new OpenConfirmDialogCommand(this) }],
      value: false,
    };
  },
  methods: {
    async onConfirmActionsClicked(confirm) {
      if (confirm) {
        await new SettleFinishedCoursesCommand().execute();
      }

      this.closeConfirmDialog();
    },
    closeConfirmDialog() {
      this.value = false;
    },
  },
};
</script>
