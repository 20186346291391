import Vue from 'vue';
import Vuetify from 'vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import '@/core/style/variables.scss';
import pl from 'vuetify/lib/locale/pl';

Vue.use(Vuetify);

export const opts = {
  lang: {
    locales: { pl },
    current: 'pl',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#007DFF',
        error: '#F44336',
        warning: '#FF9800',
        success: '#4CAF50',
        info: '#2196F3',
      },
    },
  },
};

export default new Vuetify(opts);
