<template>
  <div>
    <AbstractCarBrandField v-bind="$attrs" v-on="$listeners">
      <template #append-item>
        <AddItemButton @click="openCreateBrandModal">Dodaj markę samochodu</AddItemButton>
      </template>
      <template #item="{ item }">
        <div class="d-flex flex-row align-center flex-grow-1" style="column-gap: 16px">
          <p class="flex-grow-1 mb-0">{{ item.name }}</p>
          <EditIconButton @click.stop="openEditBrandModel(item)"></EditIconButton>
        </div>
      </template>
    </AbstractCarBrandField>

    <BrandModalFactory :state="brandModalState" @close="closeBrandModal"></BrandModalFactory>
  </div>
</template>

<script>
import AddItemButton from '@/component/Field/Generic/add-item-button';
import EditIconButton from '@/component/Button/edit-icon-button';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import AbstractCarBrandField from './abstract-car-brand-field';
import BrandModalFactory from '../../car-brand-modal/brand-modal-factory';

export default {
  name: 'EditableModelField',
  inheritAttrs: false,
  components: {
    AbstractCarBrandField,
    AddItemButton,
    BrandModalFactory,
    EditIconButton,
  },
  data() {
    return {
      brandModalState: MODAL_STATE.CLOSED,
    };
  },
  methods: {
    openCreateBrandModal() {
      this.brandModalState = MODAL_STATE.CREATE_ENTITY_MODAL;
    },
    openEditBrandModel(item) {
      this.brandModalState = { ...MODAL_STATE.EDIT_ENTITY_MODAL, selectedEntity: item };
    },
    closeBrandModal() {
      this.brandModalState = MODAL_STATE.CLOSED;
    },
  },
};
</script>

<style lang="scss" scoped></style>
