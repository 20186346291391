import { ROLES } from '@/core/dict/role-dict';

const InvoiceIndex = () => import('@/controller/Invoice/invoice-index');
const InvoiceCreate = () => import('@/controller/Invoice/invoice-create');
const InvoiceShow = () => import('@/controller/Invoice/invoice-show');

export default [
  {
    path: '/invoices',
    name: 'invoices_index',
    component: InvoiceIndex,
    meta: {
      title: 'Fakturowanie',
      create: 'invoices_create',
      roles: [ROLES.ROLE_TRANSPORT_COMPANY],
    },
  },
  {
    path: '/invoices/create',
    name: 'invoices_create',
    component: InvoiceCreate,
    meta: {
      title: 'Utwórz fakturę',
    },
  },
  {
    path: '/invoices/show/:id',
    name: 'invoices_show',
    component: InvoiceShow,
    meta: {
      title: 'Podgląd faktury',
    },
  },
];
