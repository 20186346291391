<template>
  <v-alert :color="color" dense v-bind="$attrs">
    <template #prepend>
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </template>
    <v-row align="center" class="pl-3">
      <v-col class="grow text-body-1" :style="`color: ${textColor}`">
        <slot></slot>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  name: 'BaseAlert',
  props: {
    iconColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
  },
};
</script>
