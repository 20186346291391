<template>
  <div>
    <v-dialog
      :value="value"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
      max-width="480"
    >
      <v-card tile>
        <DialogTitleHeader :text="principalName" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text class="pb-8">
          <BillingModelSection :billingModel.sync="billingModel"></BillingModelSection>
          <section>
            <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
            <CompanyContractorStakesTable
              class="mb-3"
              :companyContractorStakes="principalStakes"
            ></CompanyContractorStakesTable>

            <TextButton prependIcon="mdi-plus" block @click="openStakesModal">Dodaj nowe stawki</TextButton>
          </section>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn block color="primary" @click="updateCompanyContractor">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NewStakesModal :value="stakesModal" :strategy="strategy" @close="closeStakesModal"></NewStakesModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import TextButton from '@/component/Base/base-text-button';
import BaseTypography from '@/component/Base/base-typography';
import CompanyContractorStakesTable from '@/controller/Page/page-toolbar/new-stakes-modal.vue/company-contractor-stakes-table';
import NewStakesModal from '../new-stakes-modal.vue/new-stakes-modal';
import { AddContractorStakeStrategy } from '../new-stakes-modal.vue/add-contractor-stake-strategy';
import BillingModelSection from './billing-model-section';

export default {
  name: 'EditPrincipalModal',
  components: {
    TextButton,
    NewStakesModal,
    DialogTitleHeader,
    CompanyContractorStakesTable,
    BillingModelSection,
    BaseTypography,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    companyContractor: {
      required: true,
    },
  },
  data() {
    return {
      stakesModal: false,
      billingModel: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
    principalName() {
      return this.companyContractor?.principal?.name;
    },
    principalStakes() {
      return this.companyContractor?.stakes;
    },
    principalBillingModel() {
      return this.companyContractor?.billingModel;
    },
    principalId() {
      return this.companyContractor ? this.companyContractor['@id'] : '';
    },
    strategy() {
      return new AddContractorStakeStrategy({
        store: this.$store,
        contractorName: this.principalName,
        contractorId: this.principalId,
      });
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.resetBillingModelToInitialState();
    },
    resetBillingModelToInitialState() {
      this.billingModel = this.companyContractor.billingModel;
    },
    openStakesModal() {
      this.stakesModal = true;
    },
    closeStakesModal() {
      this.stakesModal = false;
    },
    updateCompanyContractor() {
      this.$store.dispatch('taxiCourse/updateContractor', {
        payload: {
          billingModel: this.billingModel,
        },
        company: this.user.company,
        contractorId: this.principalId,
      });
    },
  },
  watch: {
    companyContractor(newVal) {
      this.billingModel = newVal.billingModel;
    },
  },
};
</script>
