<template>
  <v-app-bar app clipped-right flat color="primary" fixed dark height="64">
    <v-app-bar-nav-icon v-if="isMobile" aria-label="Otwórz menu" @click="setSidebar(!sidebar)">
      <employee-request-unread-counter :show="!sidebar">
        <v-icon>mdi-menu</v-icon>
      </employee-request-unread-counter>
    </v-app-bar-nav-icon>
    <slot name="header">
      <v-toolbar-title>{{ $route.meta.title || 'Panel' }}</v-toolbar-title>
    </slot>
    <slot name="extension"></slot>
  </v-app-bar>
</template>

<script>
import windowSize from '@/core/mixin/windowSize';
import EmployeeRequestUnreadCounter from '@/component/EmployeeRequest/employee-request-unread-counter';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AbstractPageToolbar',
  mixins: [windowSize()],
  components: { EmployeeRequestUnreadCounter },
  computed: {
    ...mapGetters(['sidebar']),
  },
  methods: {
    ...mapMutations(['setSidebar']),
  },
};
</script>
