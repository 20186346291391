<template>
  <div>
    <!-- TODO: this header is repeated across all instances -->
    <SettlementHeader @close="$emit('close')"></SettlementHeader>
    <!-- TODO: this section is repeated across all instances -->
    <section class="px-8 pt-4">
      <BaseTypography class="py-2" variant="subtitle">Dla klienta</BaseTypography>
      <SettlementTable>
        <thead>
          <tr>
            <th class="text-left">Klient</th>
            <th class="text-left">Liczba km</th>
            <th class="text-left">Godziny oczekiwania</th>
            <th class="text-left">Koszty za autostradę</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ride in course.rides" :key="ride.id">
            <td>
              <p class="settlement-table__link" @click="openRideSettlementModal(ride)">Przejazd {{ ride.number }}</p>
              <p class="mb-0">{{ ride.company.name }}</p>
            </td>
            <td>{{ ride.distanceToSettled ? `${ride.distanceToSettled} km` : '-' }}</td>
            <td>{{ ride.waitingHoursToSettled ? `${ride.waitingHoursToSettled} h` : '-' }}</td>
            <td>{{ ride.highwayCostToSettled | currency }}</td>
          </tr>
        </tbody>
        <tfoot v-if="course.rides.length >= 2">
          <tr>
            <td>Razem</td>
            <td>{{ totalDistance }} km</td>
            <td>{{ totalHours }} h</td>
            <td>{{ totalCost | currency }}</td>
          </tr>
        </tfoot>
      </SettlementTable>

      <RideSettlementModal
        :value="rideSettlementModal"
        :isCourseWithSubcontractor="false"
        :course="course"
        :ride="selectedRide"
        @close="closeRideSettlementModal"
      ></RideSettlementModal>

      <SettlementDivider></SettlementDivider>

      <BaseTypography class="py-2" variant="subtitle">Dla kierowcy</BaseTypography>
      <SettlementTable>
        <thead>
          <tr>
            <th class="text-left">Kierowca</th>
            <th class="text-left">Liczba km</th>
            <th class="text-left">Godziny oczekiwania</th>
            <th class="text-left">Koszty za autostradę</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="settlement-table__link" @click="openDriverSettlementModal">
              {{ course.driver ? course.driver.name : 'Brak kierowcy' }}
            </td>
            <td>{{ course.billForDriver ? `${course.billForDriver.distance} km` : '-' }}</td>
            <td>
              {{
                course.billForDriver && course.billForDriver.waitingHours
                  ? `${course.billForDriver.waitingHours} h`
                  : '-'
              }}
            </td>
            <td>
              {{ course.billForDriver && course.billForDriver.highwayCost | currency }}
            </td>
          </tr>
        </tbody>
      </SettlementTable>
    </section>
    <DriverSettlementModal
      :value="driverSettlementModal"
      :course="course"
      @close="closeDriverSettlementModal"
    ></DriverSettlementModal>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import SettlementHeader from '../header/settlement-header';
import DriverSettlementModal from './driver-settlement-modal';
import RideSettlementModal from './ride-settlement-modal';
import SettlementTable from './settlement-table/settlement-table';
import SettlementDivider from './settlement-table/settlement-divider';

export default {
  name: 'TransportCompanySettlementDrawer',
  components: {
    SettlementHeader,
    BaseTypography,
    DriverSettlementModal,
    RideSettlementModal,
    SettlementTable,
    SettlementDivider,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      driverSettlementModal: false,
      rideSettlementModal: false,
      selectedRide: this.course.rides[0],
    };
  },
  methods: {
    openDriverSettlementModal() {
      this.driverSettlementModal = true;
    },
    closeDriverSettlementModal() {
      this.driverSettlementModal = false;
    },
    openRideSettlementModal(ride) {
      this.rideSettlementModal = true;
      this.selectedRide = ride;
    },
    closeRideSettlementModal() {
      this.rideSettlementModal = false;
    },
  },
  computed: {
    totalDistance() {
      return this.course.rides.reduce((acc, item) => acc + item.distanceToSettled, 0);
    },
    totalHours() {
      return this.course.rides.reduce((acc, item) => acc + item.waitingHoursToSettled, 0);
    },
    totalCost() {
      return this.course.rides.reduce((acc, item) => acc + item.highwayCostToSettled, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
