import Vue from 'vue';
import router from '@/core/config/router';
import { EMPLOYEE_REQUEST_STATUS } from '@/core/dict/employee-request-dict';

export default {
  fetchEmployeeRequests: (status, query) =>
    Vue.http.get(`api/employee_requests?status=${status}&${query}`).then(({ data }) =>
      data['hydra:member'].map((employeeRequest) => ({
        ...employeeRequest,
        messages: employeeRequest.messages.reverse(),
      }))
    ),

  sendMessage: (message) =>
    Vue.http
      .post(message.new ? 'api/employee_requests' : 'api/employee_request_messages', message)
      .then((data) => data),

  viewEmployeeRequest: (id) => Vue.http.post(`api/employee_request/${id}/view`, {}).then((data) => data),

  finishEmployeeRequest: (id) =>
    Vue.http.put(`api/employee_requests/${id}`, { status: EMPLOYEE_REQUEST_STATUS.FINISHED }).then((data) => data),

  redirectToEmployeeRequest: (id) =>
    router.push({
      name: 'employee_request_index',
      params: { selectedEmployeeRequestId: id },
    }),
};
