<template>
  <v-dialog
    v-bind="$attrs"
    width="500"
    :value="reportDialogVisible"
    scrollable
    transition="dialog-bottom-transition"
    @click:outside="emitClose"
    @keydown.esc="emitClose"
  >
    <v-card>
      <div class="pl-3 pr-3">
        <div class="d-flex justify-space-between align-center mb-5">
          <div class="d-flex align-center">
            <v-btn icon @click="emitClose"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-title>Edytuj rozliczenie</v-card-title>
          </div>
          <text-button :loading="isRequestPending" @click="emitEditedReport">Wyślij</text-button>
        </div>
        <v-form ref="report-form">
          <report-dialog-field
            v-model.number="reportModelCopy.distance"
            label="Łączna liczba przejechanych km"
            required
            suffix="km"
            :rules="[
              rules.required,
              rules.minValue(0, 'Minimalna liczba przejechanych km to 0'),
              rules.integer('Liczba przejechanych km musi być liczbą całkowitą'),
            ]"
          />
          <report-dialog-field
            v-model.number="reportModelCopy.waitingHours"
            label="Czas oczekiwania do rozliczenia"
            suffix="godz."
          />
          <report-dialog-field
            v-model.number="reportModelCopy.highwayCost"
            label="Koszty za autostradę do rozliczenia"
            suffix="zł"
          />
          <text-field label="Komentarz" v-model="reportModelCopy.comment" />
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import TextButton from '@/component/Button/text-button';
import TextField from '@/component/Field/Generic/text-field';
import formValidationRules from '@/core/utils/form-validation-rules';
import ReportDialogField from './report-dialog-field';

export default {
  name: 'ReportModal',
  components: { TextButton, ReportDialogField, TextField },
  props: {
    reportModel: {
      type: Object,
      default: () => ({
        // TODO: why do I need this default specified
        distance: '',
        waitingHours: '',
        highwayCost: '',
        comment: '',
      }),
    },
    reportDialogVisible: {
      type: Boolean,
      required: true,
    },
    isRequestPending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportModelCopy: JSON.parse(JSON.stringify(this.reportModel)),
      rules: formValidationRules,
    };
  },
  methods: {
    emitEditedReport() {
      if (this.$refs['report-form'].validate()) {
        this.$emit('onReportEdit', this.reportModelCopy);
      }
    },
    emitClose() {
      this.$emit('close');
    },
  },
  watch: {
    reportDialogVisible(newVal) {
      if (newVal) {
        this.reportModelCopy = JSON.parse(JSON.stringify(this.reportModel));
        this.$refs['report-form']?.resetValidation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-input.v-text-field {
  margin-bottom: 24px;
}
</style>
