export const ADD_WAYPOINT = 'ADD_WAYPOINT';
export const SET_LOCOMOTIVE = 'SET_LOCOMOTIVE';
export const DELETE_WAYPOINT = 'DELETE_WAYPOINT';
export const SET_WAYPOINT_TIME = 'SET_WAYPOINT_TIME';
export const SET_PLACE = 'SET_PLACE';
export const RESET_COURSE = 'RESET_COURSE';
export const SET_COURSE = 'SET_COURSE';
export const UPDATE_WAYPOINTS_POSITION = 'UPDATE_WAYPOINTS_POSITION';
export const RESET_WAYPOINT_DISTANCE = 'RESET_WAYPOINT_DISTANCE';
export const UPDATE_WAYPOINT_ID = 'UPDATE_WAYPOINT_ID';
export const SET_COMPANY = 'SET_COMPANY';
export const RESET_RIDE_ACCEPTANCE_TIME = 'RESET_RIDE_ACCEPTANCE_TIME';
export const UPDATE_EMPLOYEES_IN_COURSE = 'UPDATE_EMPLOYEES_IN_COURSE';
