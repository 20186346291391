export default class CreatePlaceStrategy {
  constructor(store) {
    this.store = store;
  }

  save(form) {
    return this.store
      .dispatch('place/save', { place: form, type: form.type })
      .then((val) =>
        this.store.dispatch('place/addPlaceToUser', { data: { place: form, type: form.type }, place: val })
      );
  }
}
