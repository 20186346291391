<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </component>
</template>

<script>
import FullscreenDialog from '@/component/Dialog/fullscreen-dialog';
import BaseDialog from '@/component/Dialog/base-dialog';
import windowSize from '@/core/mixin/windowSize';

export default {
  name: 'DialogFactory',
  inheritAttrs: false,
  mixins: [windowSize()],
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentComponent() {
      return this.fullscreen || this.isMobile ? FullscreenDialog : BaseDialog;
    },
  },
};
</script>
