<template>
  <AbstractCarButton
    icon="mdi-pencil"
    iconColor="warning"
    btnColor="#FF98004D"
    v-bind="{ ...$attrs, ...editCarBtnConfig }"
    @click="$emit('openEditModal')"
  ></AbstractCarButton>
</template>

<script>
import AbstractCarButton from './abstract-car-button';

export default {
  name: 'EditCarButton',
  inheritAttrs: false,
  components: {
    AbstractCarButton,
  },
  computed: {
    editCarBtnConfig() {
      return {
        title: this.$attrs.car.brand,
        subtitle: this.$attrs.car.model,
      };
    },
  },
};
</script>
