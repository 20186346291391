export default {
  props: {
    xs12: Boolean,
    xs11: Boolean,
    xs10: Boolean,
    xs9: Boolean,
    xs8: Boolean,
    xs7: Boolean,
    xs6: Boolean,
    xs5: Boolean,
    xs4: Boolean,
    xs3: Boolean,
    xs2: Boolean,
    xs1: Boolean,
    sm12: Boolean,
    sm11: Boolean,
    sm10: Boolean,
    sm9: Boolean,
    sm8: Boolean,
    sm7: Boolean,
    sm6: Boolean,
    sm5: Boolean,
    sm4: Boolean,
    sm3: Boolean,
    sm2: Boolean,
    sm1: Boolean,
    md12: Boolean,
    md11: Boolean,
    md10: Boolean,
    md9: Boolean,
    md8: Boolean,
    md7: Boolean,
    md6: Boolean,
    md5: Boolean,
    md4: Boolean,
    md3: Boolean,
    md2: Boolean,
    md1: Boolean,
    lg12: Boolean,
    lg11: Boolean,
    lg10: Boolean,
    lg9: Boolean,
    lg8: Boolean,
    lg7: Boolean,
    lg6: Boolean,
    lg5: Boolean,
    lg4: Boolean,
    lg3: Boolean,
    lg2: Boolean,
    lg1: Boolean,
    xl12: Boolean,
    xl11: Boolean,
    xl10: Boolean,
    xl9: Boolean,
    xl8: Boolean,
    xl7: Boolean,
    xl6: Boolean,
    xl5: Boolean,
    xl4: Boolean,
    xl3: Boolean,
    xl2: Boolean,
    xl1: Boolean,
  },

  computed: {
    sizeableClasses: {
      get() {
        const regex = /^(xs|md|sm|lg|xl)([0-9]{1,2})$/gi;
        const allowed = Object.keys(this.$props).filter((name) => name.match(regex) !== null);
        const values = this.$props;
        const sizes = allowed.filter((name) => values[name] !== undefined);
        return sizes.join(' ');
      },
    },
  },
};
