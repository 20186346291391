<template>
  <ContextMenu left :buttons="buttons">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon width="24" height="24" v-bind="attrs" v-on="on">
        <v-icon class="mr-0">{{ menuActivatorIcon }}</v-icon>
      </v-btn>
    </template>
  </ContextMenu>
</template>

<script>
import ContextMenu from '@/component/ContextMenu/context-menu';

export default {
  name: 'RideContextMenu',
  components: {
    ContextMenu,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    menuActivatorIcon() {
      return this.vertical ? 'mdi-dots-vertical' : 'mdi-dots-horizontal';
    },
  },
};
</script>
