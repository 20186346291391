<template>
  <alert
    :class="{ 'alert--with-borders': bordered }"
    icon="mdi-alert-outline"
    color="#FF98001A"
    icon-color="#FF9800"
    text-color="#663D00"
    v-bind="$attrs"
  >
    <template #default>
      <slot></slot>
    </template>
  </alert>
</template>
<script>
import Alert from '@/component/Alert/alert';

export default {
  name: 'AlertWarning',
  components: { Alert },
  props: {
    bordered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.alert--with-borders {
  border: 1px solid #c77700 !important;
}
</style>
