import { render, staticRenderFns } from "./pulse-badge.vue?vue&type=template&id=8d13f878&scoped=true"
import script from "./pulse-badge.vue?vue&type=script&lang=js"
export * from "./pulse-badge.vue?vue&type=script&lang=js"
import style0 from "./pulse-badge.vue?vue&type=style&index=0&id=8d13f878&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d13f878",
  null
  
)

export default component.exports