<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import EditablePlaceField from './editable-place-field';
import NonEditablePlaceInput from './abstract-place-field';

export default {
  name: 'PlaceFieldFactory',
  inheritAttrs: false,
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentComponent() {
      return this.editable ? EditablePlaceField : NonEditablePlaceInput;
    },
  },
};
</script>
