import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export class CarModel {
  id;

  name;

  constructor({ id = uuidv4(), name = '' } = {}) {
    this.id = id;
    this.name = name;
  }
}
