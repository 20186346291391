<template>
  <AutocompleteField
    label="Obsługiwana firma"
    icon="mdi-train"
    :items="items"
    :loading="loading"
    :rules="[rules.required]"
    validate-on-blur
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-data>
      <NoDataText>Brak zdefiniowanych obsługiwanych firm</NoDataText>
    </template>

    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </AutocompleteField>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';
import NoDataText from '@/component/Field/Generic/no-data-text';

export default {
  name: 'AbstractRailwayCompanyInput',
  inheritAttrs: false,
  components: {
    AutocompleteField,
    NoDataText,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: (v) => !!v || 'Należy wybrać obsługiwaną firmę, aby przejść do tworzenia kursu',
      },
    };
  },
};
</script>
