// eslint-disable-next-line import/prefer-default-export
export class EditPhoneStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  #createPayload(editedPhone) {
    const userPhones = this.#store.getters.user.phones;
    const phonesPayload = userPhones.map(({ id, phone, main }) => {
      if (id === editedPhone.id) {
        return {
          id: `api/user_phones/${id}`,
          phone: editedPhone.phone,
          main: editedPhone.main,
        };
      }

      return {
        id: `api/user_phones/${id}`,
        phone,
        main: editedPhone.main ? false : main,
      };
    });

    return phonesPayload;
  }

  execute(editedPhone) {
    return this.#store.dispatch('editUserPhone', this.#createPayload(editedPhone));
  }
}
