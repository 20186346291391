<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column fill-height">
        <AbstractModalHeader class="flex-grow-0" title="Zmiana adresu e-mail">
          <template #prepend-item>
            <BackIconButton @click="navigateToSettings"></BackIconButton>
          </template>
        </AbstractModalHeader>
        <div class="flex-grow-1 pa-4 d-flex flex-column">
          <v-form ref="form" class="flex-grow-1 flex-md-grow-0 mb-4">
            <v-text-field
              v-model="userModel.email"
              label="Nowy adres e-mail"
              outlined
              hide-details="auto"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-form>
          <v-btn class="flex-grow-0 align-self-md-end" color="primary" @click="updateUserEmail">
            Zmień adres e-mail
          </v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BackIconButton from '@/component/Button/back-icon-button';
import formValidationRules from '@/core/utils/form-validation-rules';
import * as ROUTES from '@/core/router/route-names';

export default {
  name: 'UserEmailSettings',
  components: {
    AbstractModalHeader,
    BackIconButton,
  },
  data() {
    return {
      userModel: null,
      rules: formValidationRules,
    };
  },
  created() {
    this.userModel = JSON.parse(JSON.stringify(this.user));
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTES.SETTINGS_ROUTE });
    },
    updateUserEmail() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('updateUserEmail', this.userModel).then(() => {
          this.navigateToSettings();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
