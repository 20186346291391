// eslint-disable-next-line import/prefer-default-export
export const COURSE_TYPE_DICT = {
  TAXI_COURSE: 'TaxiCourse',
  KILOMETER_COURSE: 'KilometerCourse',
  TAXI_COURSE_RIDE: 'TaxiCourseRide',
  KILOMETER_COURSE_RIDE: 'KilometerCourseRide',
};

// TODO: need to be unified to one name, without first letter in lower case
export const API_COURSE_RIDE_DICT = {
  TAXI_COURSE_RIDE: 'taxiCourseRide',
  KILOMETER_COURSE_RIDE: 'kilometerCourseRide',
};
