import { LOGIN_ROUTE } from '@/core/router/route-names';

export default {
  methods: {
    async initUser() {
      if (this.isAuthenticated()) {
        const originalToken = localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_NAME);
        const user = this.extractJwt(
          localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_LOGIN_AS_NAME) || originalToken
        );

        if (user.company) {
          user.company['@id'] = `/api/companies/${user.company.id}`;
        }

        this.$store.commit('setUser', user);
        await this.$store.dispatch('sse/openConnection');
        await this.$store.dispatch('courseSettings/resetRideCardSettings', this.$store.getters.isPassenger);
        this.$store.getters['sse/eventSource'].addEventListener('passenger_change', (e) => {
          const passengerChange = JSON.parse(e.data);
          this.passengerChange(passengerChange);
        });
      }
    },

    loginAsOtherUser() {
      return this.$http
        .get('loginAs')
        .then(({ data }) => {
          const { token } = data;
          const userData = this.extractJwt(token);

          localStorage.setItem(process.env.VUE_APP_JWT_STORAGE_LOGIN_AS_NAME, token);
          this.$store.commit('setUser', userData);
        })
        .catch(() => {
          this.$router.push({ name: LOGIN_ROUTE });
        });
    },

    extractJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      );

      return JSON.parse(jsonPayload);
    },

    isAuthenticated() {
      const originalToken = localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_NAME);
      const timestampInSeconds = new Date().getTime() / 1000;

      return originalToken && this.extractJwt(originalToken).exp > timestampInSeconds;
    },
  },
};
