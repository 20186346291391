<template>
  <v-text-field
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    :rules="required ? [(v) => !!v || 'Pole wymagane', ...rules] : rules"
    outlined
    hide-details="auto"
    @click:append="showPassword = !showPassword"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label>
      <span>{{ label }}</span>
      <span v-if="required" class="red--text">*</span>
    </template>
  </v-text-field>
</template>

<script>
// TODO: this component wil replace the component under @/components/Field/Password/password-field.
// Now it's impossible, because the later is depend on col css classes
export default {
  name: 'PasswordField',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      default: () => [],
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
