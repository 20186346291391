const HotelList = () => import('@/controller/Hotel/hotel-list');
const HotelCreate = () => import('@/controller/Hotel/hotel-create');
const HotelEdit = () => import('@/controller/Hotel/hotel-edit');

export default [
  {
    path: '/hotels',
    name: 'hotel_index',
    component: HotelList,
    meta: {
      title: 'Hotele',
      create: 'hotel_create',
    },
  },
  {
    path: '/hotels/create',
    name: 'hotel_create',
    component: HotelCreate,
    meta: {
      title: 'Utwórz hotel',
    },
  },
  {
    path: '/hotels/:id',
    name: 'hotel_edit',
    component: HotelEdit,
    meta: {
      title: 'Edytuj hotel',
    },
  },
];
