<template>
  <v-col cols="12" class="pb-0 mb-0">
    <v-card flat class="pb-0 mb-0">
      <v-textarea
        label="Poniżej możesz zostawić komentarz dotyczący tego kursu, złożyć reklamację, etc."
        v-model="comment"
        background-color="transparent"
        filled
        hide-details
        :disabled="waiting"
      />
      <div class="px-4 pt-1 pb-0 mb-0">
        <v-btn color="success" @click="add" :disabled="waiting" :loading="waiting">Dodaj</v-btn>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import CommentService from '@/service/CommentService';

export default {
  name: 'CommentCreate',
  props: { entity: null },

  data() {
    return {
      comment: null,
      waiting: false,
    };
  },

  methods: {
    add() {
      this.waiting = true;
      CommentService.create(this.entity, this.comment, this.$store.getters.user.id)
        .then(({ data }) => {
          this.entity.comments.push(data);
          this.comment = null;
        })
        .catch()
        .finally(() => {
          this.waiting = false;
        });
    },
  },
};
</script>
