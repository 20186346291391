export default function (refName) {
  return {
    methods: {
      scrollToBottom() {
        this.$nextTick().then(() => {
          this.$refs[refName].scroll({
            top: this.$refs[refName].scrollHeight,
          });
        });
      },
    },
  };
}
