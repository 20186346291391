<template>
  <div>
    {{ courseRideDate | date }}
  </div>
</template>

<script>
export default {
  name: 'RideDate',
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    courseRideDate() {
      return this.course.rides[0].segments[0].waypointStart.time;
    },
  },
};
</script>
