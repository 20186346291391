import EmployeeGroupService from '@/service/EmployeeGroupService';
import * as types from './types/employee-groups-mutation-types';

export default {
  namespaced: true,
  state: {
    employeeGroups: [],
    isEmployeeGroupsLoading: false,
  },
  getters: {
    employeeGroups: (state) => state.employeeGroups,
    isEmployeeGroupsLoading: (state) => state.isEmployeeGroupsLoading,
  },
  mutations: {
    [types.SET_EMPLOYEE_GROUPS]: (state, val) => {
      state.employeeGroups = val;
    },
    [types.ADD_EMPLOYEE_GROUP]: (state, val) => {
      state.employeeGroups.push(val);
    },
    [types.UPDATE_EMPLOYEE_GROUP]: (state, val) => {
      const employeeGroupIdx = state.employeeGroups.findIndex((employeeGroup) => employeeGroup.id === val.id);
      state.employeeGroups.splice(employeeGroupIdx, 1, val);
    },
    [types.DELETE_EMPLOYEE_GROUP]: (state, id) => {
      state.employeeGroups = state.employeeGroups.filter((employeeGroup) => employeeGroup.id !== id);
    },
    [types.SET_EMPLOYEE_GROUPS_LOADING]: (state, val) => {
      state.isEmployeeGroupsLoading = val;
    },
  },
  actions: {
    fetchEmployeeGroups({ commit }) {
      commit(types.SET_EMPLOYEE_GROUPS_LOADING, true);
      return EmployeeGroupService.fetchEmployeeGroups().then((data) => {
        commit(types.SET_EMPLOYEE_GROUPS, data);
        commit(types.SET_EMPLOYEE_GROUPS_LOADING, false);
      });
    },
    createEmployeeGroup({ commit, rootGetters }, payload) {
      commit('setLoading', true, { root: true });
      return EmployeeGroupService.createEmployeeGroup({
        ...payload,
        company: rootGetters.user.company['@id'],
      })
        .then((employeeGroup) => {
          commit(types.ADD_EMPLOYEE_GROUP, employeeGroup);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    editEmployeeGroup({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return EmployeeGroupService.editEmployeeGroup(payload)
        .then((employeeGroup) => {
          commit(types.UPDATE_EMPLOYEE_GROUP, employeeGroup);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    addEmployeeGroupStake({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return EmployeeGroupService.addEmployeeGroupStake(payload)
        .then((employeeGroup) => {
          commit(types.UPDATE_EMPLOYEE_GROUP, employeeGroup);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    deleteEmployeeGroup({ commit }, id) {
      commit('setLoading', true, { root: true });
      return EmployeeGroupService.deleteEmployeeGroup(id)
        .then(() => {
          commit(types.DELETE_EMPLOYEE_GROUP, id);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
  },
};
