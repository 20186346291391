<template>
  <div class="course-list__wrapper">
    <course-list-navbar
      :class="[isPassenger ? 'course-list-navbar--passenger elevation-0' : 'course-list-navbar--global']"
    />
    <course-grouped-list
      :courses="courses"
      :columns="columnsToDisplay"
      :coursesAmount="coursesAmount"
      data-testid="grouped-list"
    />
  </div>
</template>

<script>
import CourseListNavbar from '@/component/NewCourse/List/course-list-navbar';
import CourseGroupedList from '@/component/NewCourse/List/course-grouped-list';
import { mapGetters } from 'vuex';

export default {
  name: 'TaxiCourseList',
  components: {
    CourseGroupedList,
    CourseListNavbar,
  },
  data() {
    return {
      unwatch: null,
    };
  },
  computed: {
    ...mapGetters(['isPassenger', 'isTransportCompany', 'user']),
    courses() {
      return this.isPassenger
        ? this.$store.getters['taxiCourse/allCourses']
        : this.$store.getters['taxiCourse/courses'];
    },
    coursesAmount() {
      return this.isPassenger
        ? this.$store.getters['taxiCourse/totalCoursesCount']
        : this.$store.getters['taxiCourse/coursesAmount'];
    },
    columnsToDisplay() {
      return this.$store.getters['courseSettings/columnsToDisplay'];
    },
  },
  mounted() {
    this.$store.dispatch('taxiCourse/watchCourses');

    if (this.isTransportCompany) {
      this.$store.dispatch('fetchCompanyById', this.user.company.id);
    }

    this.unwatch = this.$store.watch(
      (_, getters) => getters['taxiCourse/formattedFilters'],
      () => {
        // TODO: think if fetching is the responsibility of this component
        // TODO: refactor if/else statement to strategy pattern
        if (this.isPassenger) {
          this.$store.dispatch('taxiCourse/fetchAllCourses');
        } else {
          this.$store.dispatch('taxiCourse/fetchCoursesByStatus');
        }
      },
      { immediate: true, deep: true }
    );
  },
  destroyed() {
    this.unwatch();
  },
};
</script>

<style scoped lang="scss">
@use '@/core/style/variables' as *;

.course-list__wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.course-list-navbar--passenger {
  flex-wrap: nowrap;
  overflow-y: scroll;

  @media (min-width: $course-list-desktop-breakpoint) {
    flex-wrap: wrap;
    overflow-y: initial;
  }
}

.course-list-navbar--global {
  display: none;

  @media (min-width: $course-list-desktop-breakpoint) {
    display: flex;
  }
}
</style>
