export const ROLES = {
  ROLE_DEFAULT: 'ROLE_DEFAULT',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER',
  ROLE_COMPANY: 'ROLE_COMPANY',
  ROLE_USER_COMPANY: 'ROLE_USER_COMPANY',
  ROLE_DRIVER: 'ROLE_DRIVER',
  ROLE_PASSENGER: 'ROLE_PASSENGER',
  ROLE_TRANSPORT_COMPANY: 'ROLE_TRANSPORT_COMPANY',
  ROLE_COMPANY_STATISTICS: 'ROLE_COMPANY_STATISTICS',
  ROLE_COURSE_FINANCIAL_REPORT: 'ROLE_COURSE_FINANCIAL_REPORT',
  ROLE_HOTEL: 'ROLE_HOTEL',
  ROLE_DEFINED_KILOMETERS: 'ROLE_DEFINED_KILOMETERS',
  ROLE_KILOMETER_COURSE: 'ROLE_KILOMETER_COURSE',
  ROLE_ACCOUNTANT: 'ROLE_ACCOUNTANT',
  ROLE_INVOICE: 'ROLE_INVOICE',
  ROLE_EMPLOYEE_REQUEST: 'ROLE_EMPLOYEE_REQUEST',
  ROLE_COURSE_GENERATE_REPORT: 'ROLE_COURSE_GENERATE_REPORT',
  ROLE_PASSENGER_CHANGE: 'ROLE_PASSENGER_CHANGE',
  ROLE_IMPERSONATOR: 'IS_IMPERSONATOR',
  ROLE_FLEET: 'ROLE_FLEET',
};

export const ROLES_DICT = {
  [ROLES.ROLE_ADMIN]: 'Administrator',
  [ROLES.ROLE_USER]: 'Użytkownik',
  [ROLES.ROLE_COMPANY]: 'Firma',
  [ROLES.ROLE_USER_COMPANY]: 'Konto Firmowe',
  [ROLES.ROLE_DRIVER]: 'Kierowca',
  [ROLES.ROLE_PASSENGER]: 'Pasażer',
  [ROLES.ROLE_TRANSPORT_COMPANY]: 'Firma transportowa',
  [ROLES.ROLE_COMPANY_STATISTICS]: 'Statystki firmy',
  [ROLES.ROLE_COURSE_FINANCIAL_REPORT]: 'Raport finansowy',
  [ROLES.ROLE_HOTEL]: 'Hotele',
  [ROLES.ROLE_DEFINED_KILOMETERS]: 'Zdefiniowane kilometry',
  [ROLES.ROLE_KILOMETER_COURSE]: 'Kilometrówka',
  [ROLES.ROLE_COURSE_GENERATE_REPORT]: 'Kursy - generowanie raportów',
  [ROLES.ROLE_ACCOUNTANT]: 'Księgowy',
  [ROLES.ROLE_INVOICE]: 'Fakturowanie',
  [ROLES.ROLE_EMPLOYEE_REQUEST]: 'Zgłoszenia maszynistów',
  [ROLES.ROLE_PASSENGER_CHANGE]: 'Ewidencja zmian pasażerów',
  [ROLES.ROLE_FLEET]: 'Flota',
};
