<template>
  <v-btn icon color="red" aria-label="Usuń" v-bind="$attrs" v-on="$listeners"><v-icon>mdi-delete</v-icon></v-btn>
</template>

<script>
export default {
  name: 'RemoveIconButton',
  inheritAttrs: false,
};
</script>
