import { ColumnsSettingsStorage } from '@/core/shared/columnsSettingStorage';
import { RIDE_CARD_SETTINGS } from '@/core/dict/taxi-course-dict';
import * as TYPES from './types/course-settings-mutation-types';

export default {
  namespaced: true,
  state: {
    dialog: false,
    rideCardSettings: ColumnsSettingsStorage.getRideCardSettings(),
    columnSettings: ColumnsSettingsStorage.getColumnsSettings(),
  },
  getters: {
    dialog: (state) => state.dialog,
    rideCardSettings: (state) => state.rideCardSettings,
    columnSettings: (state) => JSON.parse(JSON.stringify(state.columnSettings)),
    columnsToDisplay: (state) => {
      const columns = state.columnSettings;

      return Object.keys(columns).filter((key) => columns[key]);
    },
    isRideSegmentsVisible: (state) => state.rideCardSettings.length > 0,
    isIntermediateWaypointsVisible: (state) =>
      state.rideCardSettings.includes(RIDE_CARD_SETTINGS.INTERMEDIATE_WAYPOINTS),
    isPassengersVisible: (state) => state.rideCardSettings.includes(RIDE_CARD_SETTINGS.PASSENGERS),
    isSegmentDistanceAndTimeVisible: (state) => state.rideCardSettings.includes(RIDE_CARD_SETTINGS.DISTANCE_AND_TIME),
    isSegmentLocomotivesVisible: (state) => state.rideCardSettings.includes(RIDE_CARD_SETTINGS.LOCOMOTIVES),
    isSegmentFullAddressVisible: (state) => state.rideCardSettings.includes(RIDE_CARD_SETTINGS.FULL_WAYPOINT_ADDRESS),
  },
  mutations: {
    [TYPES.ADD_RIDE_CARD_SETTINGS]: (state, val) => {
      state.rideCardSettings = val;
    },
    [TYPES.ADD_LIST_COLUMNS_SETTINGS]: (state, val) => {
      state.columnSettings = val;
    },
    [TYPES.SET_VISIBILITY_OF_SETTINGS_DIALOG]: (state, val) => {
      state.dialog = val;
    },
  },
  actions: {
    setRideCardSettings({ commit }, settings) {
      commit(TYPES.ADD_RIDE_CARD_SETTINGS, settings);
      ColumnsSettingsStorage.setRideCardSettings(settings);
    },
    setColumnsSettings({ commit }, settings) {
      commit(TYPES.ADD_LIST_COLUMNS_SETTINGS, JSON.parse(JSON.stringify(settings)));
      ColumnsSettingsStorage.setColumnsSettings(settings);
    },
    openSettingsDialog({ commit }) {
      commit(TYPES.SET_VISIBILITY_OF_SETTINGS_DIALOG, true);
    },
    closeSettingsDialog({ commit }) {
      commit(TYPES.SET_VISIBILITY_OF_SETTINGS_DIALOG, false);
    },
    resetRideCardSettings({ dispatch }, isPassenger) {
      dispatch('setRideCardSettings', ColumnsSettingsStorage.getRideCardSettings(isPassenger));
    },
  },
};
