<template>
  <component :is="currentComponent" :course="course" v-on="$listeners" v-bind="$attrs"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import RailwayCompanySettlementDrawer from './railway-company-settlement-drawer';
import TransportCompanySettlementDrawer from './transport-company-settlement-drawer';
import ContractorSettlementDrawer from './contractor-settlement-drawer';
import SubcontractorSettlementDrawer from './subcontractor-settlement-drawer';

export default {
  name: 'SettlementDrawerFactory',
  inheritAttrs: false,
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isCompany', 'isTransportCompany', 'user']),
    currentComponent() {
      if (this.isCompany) {
        return RailwayCompanySettlementDrawer;
      }

      if (this.isTransportCompany) {
        if (this.course.subcontractor === null) {
          return TransportCompanySettlementDrawer;
        }

        if (this.course.contractor.id === this.user.company.id) {
          return ContractorSettlementDrawer;
        }

        return SubcontractorSettlementDrawer;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
