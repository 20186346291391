<template>
  <div class="course-navbar elevation-4" v-resize="onResize">
    <v-badge inline color="success" :content="appliedFiltersCount" :value="isMobileViewport">
      <base-text-button class="px-2 py-1" prependIcon="mdi-filter-variant" @click="drawer = !drawer">
        Filtruj
      </base-text-button>
    </v-badge>
    <template v-for="(filter, type) in formattedFilters">
      <v-chip
        v-if="filter"
        :key="type"
        class="mx-1 text-body-2 flex-shrink-0 custom-chip custom-chip--disabled"
        close
        @click:close="removeSingleFilter(type)"
      >
        {{ getFilterValue(filter) }}
      </v-chip>
    </template>
    <v-divider v-if="isMobileViewport" vertical class="mx-3" style="height: 36px"></v-divider>
    <v-btn icon color="error" @click="resetFilters"><v-icon>mdi-delete</v-icon></v-btn>
    <v-divider v-if="!isMobileViewport" vertical class="mx-3" style="height: 36px"></v-divider>
    <v-btn
      class="hidden-on-mobile"
      icon
      color="primary"
      aria-label="ustawienia widoku"
      @click="openColumnsSettingDialog"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <base-text-button
      class="ml-auto"
      :class="{ 'v-btn--disabled': !isDownloadReportAvailable }"
      prependIcon="mdi-cloud-download"
      :loading="loading"
      @click="downloadReport"
    >
      Pobierz .xlsx
      <premium-feature-icon v-if="!isDownloadReportAvailable" class="ml-2" data-testid="premium-feature-icon" />
    </base-text-button>

    <columns-settings-modal
      :modal="columnsSettingDialog"
      @closeModal="closeColumnsSettingDialog"
      data-testid="columns-settings-modal"
    ></columns-settings-modal>
    <premium-feature-modal :dialog.sync="dialog" data-testid="premium-feature-modal" />
    <course-filters-drawer :show-drawer="drawer" @closeDrawer="drawer = false" />
    <transfer-ride-modal
      :value="isDialogOpen"
      :rideNumber="transferSubjectNumber"
      @close="closeTransferRideModal"
      @transferRide="onTransferRide"
    ></transfer-ride-modal>
  </div>
</template>
<script>
import PremiumFeatureModal from '@/component/PremiumFeature/premium-feature-modal';
import PremiumFeatureIcon from '@/component/PremiumFeature/premium-feature-icon';
import ColumnsSettingsModal from '@/component/NewCourse/List/course-list-settings-modal';
import { ROLES } from '@/core/dict/role-dict';
import BaseTextButton from '@/component/Base/base-text-button';
import CourseFiltersDrawer from '@/component/NewCourse/Filters/course-filters-drawer';
import TransferRideModal from '@/component/NewCourse/Modals/transfer-ride-modal';
import { mapGetters } from 'vuex';

export default {
  name: 'CourseListNavbar',
  components: {
    PremiumFeatureModal,
    PremiumFeatureIcon,
    ColumnsSettingsModal,
    BaseTextButton,
    CourseFiltersDrawer,
    TransferRideModal,
  },
  data() {
    return {
      dialog: false,
      drawer: null,
      isMobileViewport: false,
    };
  },
  methods: {
    downloadReport() {
      if (this.isDownloadReportAvailable) {
        this.$store.dispatch('taxiCourse/downloadReport');
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.dialog = true;
    },
    openColumnsSettingDialog() {
      this.$store.dispatch('courseSettings/openSettingsDialog');
    },
    closeColumnsSettingDialog() {
      this.$store.dispatch('courseSettings/closeSettingsDialog');
    },
    removeSingleFilter(val) {
      this.$store.dispatch('taxiCourse/removeFilter', val);
    },
    resetFilters() {
      this.$store.commit('taxiCourse/RESET_FILTERS');
    },
    getFilterValue(filter) {
      return filter.name || filter.sideNumber || filter;
    },
    // TODO: use scss variables in js
    onResize() {
      this.isMobileViewport = window.innerWidth <= 900;
    },
    closeTransferRideModal() {
      this.$store.dispatch('transferRide/closeDialog');
    },
    onTransferRide(targetRide) {
      this.$store.dispatch('transferRide/transferRide', targetRide).then(() => {
        this.closeTransferRideModal();
      });
    },
  },
  computed: {
    ...mapGetters('transferRide', ['isDialogOpen', 'transferSubjectNumber']),
    isDownloadReportAvailable() {
      return this.$store.getters.hasRole(ROLES.ROLE_COURSE_GENERATE_REPORT);
    },
    loading() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
    columnsSettingDialog() {
      return this.$store.getters['courseSettings/dialog'];
    },
    formattedFilters() {
      return this.$store.getters['taxiCourse/formattedFilters'];
    },
    appliedFiltersCount() {
      return Object.values(this.formattedFilters).filter((v) => !!v).length;
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/core/style/variables' as *;

.custom-chip {
  display: none;
  @media (min-width: $course-list-desktop-breakpoint) {
    display: flex;
  }
}

.custom-chip.custom-chip--disabled:hover:before {
  opacity: 0;
}

.v-btn--disabled {
  pointer-events: all;
}

.course-navbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  row-gap: 16px;

  @media (min-width: $course-list-desktop-breakpoint) {
    padding: 24px 24px 16px 24px;
  }
}

::v-deep .v-divider--vertical {
  max-height: 36px;
  min-height: 36px;
}

.hidden-on-mobile {
  display: none;

  @media (min-width: $course-list-desktop-breakpoint) {
    display: inline-flex;
    align-items: center;
  }
}
</style>
