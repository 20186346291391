const DriverAbsenceList = () => import('@/controller/DriverAbsence/driver-absence-list');
const DriverAbsenceCreate = () => import('@/controller/DriverAbsence/driver-absence-create');

const name = 'driver_absence';

export default [
  {
    path: '/driver_absences',
    name: `${name}_index`,
    component: DriverAbsenceList,
    meta: {
      title: 'Urlopy',
      create: `${name}_create`,
    },
  },
  {
    path: '/driver_absences/create',
    name: `${name}_create`,
    component: DriverAbsenceCreate,
    meta: {
      title: 'Urlopy',
    },
  },
];
