<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import EditableCarBrandField from './editable-car-brand-field';
import NonEditableCarBrandField from './abstract-car-brand-field';

export default {
  name: 'CarBrandFieldFactory',
  inheritAttrs: false,
  computed: {
    ...mapGetters(['isHeadOfCompany', 'userHasFleetRole']),
    currentComponent() {
      return this.isHeadOfCompany || this.userHasFleetRole ? EditableCarBrandField : NonEditableCarBrandField;
    },
  },
};
</script>
