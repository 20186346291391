<template>
  <v-autocomplete
    outlined
    :items="brands"
    :loading="isBrandsLoading"
    item-text="name"
    item-value="name"
    hide-details="auto"
    :rules="[rules.required]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #label>
      <span>Marka</span>
      <span class="red--text">*</span>
    </template>
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </v-autocomplete>
</template>

<script>
import formValidationRules from '@/core/utils/form-validation-rules';
import { mapGetters } from 'vuex';

export default {
  name: 'AbstractCarBrandField',
  inheritAttrs: false,
  data() {
    return {
      rules: formValidationRules,
    };
  },
  created() {
    this.$store.dispatch('car/fetchCarBrands');
  },
  computed: {
    ...mapGetters('car', ['brands', 'isBrandsLoading']),
  },
};
</script>

<style lang="scss" scoped></style>
