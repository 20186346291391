const AccommodationList = () => import('@/controller/Accommodation/accommodation-list');
const AccommodationForm = () => import('@/controller/Accommodation/accommodation-form');

export default [
  {
    path: '/accommodations',
    name: 'accommodation_index',
    component: AccommodationList,
    meta: {
      title: 'Lista noclegów',
      create: 'accommodation_create',
    },
  },
  {
    path: '/accommodations/create',
    name: 'accommodation_create',
    component: AccommodationForm,
    meta: {
      title: 'Utwórz Nocleg',
    },
  },
];
