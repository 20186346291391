<template>
  <one-page :loading="true"></one-page>
</template>

<script>
import LoginRedirectService from '@/service/LoginRedirectService';
import OnePage from '@/component/Page/page-index';

export default {
  name: 'IndexContainer',
  components: { OnePage },
  created() {
    if (this.$store.getters.isLoggedIn) {
      LoginRedirectService.redirect();
    }
  },
};
</script>
