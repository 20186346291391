<template>
  <div class="d-flex justify-center align-center white">
    <BaseTypography variant="body-1">Wybierz opcję z menu</BaseTypography>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'SettingsDefaul',
  components: {
    BaseTypography,
  },
};
</script>

<style lang="scss" scoped></style>
