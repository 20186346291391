<template>
  <component :is="currentComponent">
    <template #default>
      <v-list dense nav class="pt-0">
        <template v-for="(item, index) in menu">
          <v-list-group
            v-if="item.items && hasRoles(item.roles, item.expectedIntersection)"
            :key="index"
            :value="false || $route.path.startsWith(`/${item.link}`)"
            :prepend-icon="item.icon"
            class="menu-list-group"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title class="nav-item__title">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="child in item.items" :key="child.title" link :to="{ name: child.link }" class="pl-16">
              <v-list-item-content>
                <v-list-item-title class="nav-item__title" v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else-if="hasRoles(item.roles, item.expectedIntersection)"
            :key="`${index}-else`"
            link
            :to="{ name: item.link }"
          >
            <v-list-item-icon class="my-2">
              <employee-request-unread-counter v-if="item.link === 'employee_request_index'">
                <v-icon>{{ item.icon }}</v-icon>
              </employee-request-unread-counter>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="nav-item__title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="hasRoles([ROLES.ROLE_IMPERSONATOR])">
          <router-link :key="'999'" :to="'#'" tag="v-list-item">
            <v-list-item-action>
              <v-icon>keyboard_backspace</v-icon>
            </v-list-item-action>
            <v-list-item-content @click="exitLoginOtherUser()">
              <v-list-item-title class="nav-item__title">Opuść tryb podglądu</v-list-item-title>
            </v-list-item-content>
          </router-link>
        </template>
      </v-list>
    </template>
    <template #append v-if="!hasRoles([ROLES.ROLE_IMPERSONATOR])">
      <v-list dense nav>
        <v-list-item key="1" :to="{ path: `/${SETTINGS_ROUTE}` }" link>
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="nav-item__title">Ustawienia</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item key="2" :to="{ name: LOGOUT_ROUTE }" link>
          <v-list-item-action>
            <v-icon>mdi-power</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="nav-item__title">Wyloguj</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </component>
</template>

<script>
import Vue from 'vue';
import menu from '@/core/menu';
import windowSize from '@/core/mixin/windowSize';
import EmployeeRequestUnreadCounter from '@/component/EmployeeRequest/employee-request-unread-counter';
import intersection from 'lodash/fp/intersection';
import { LOGOUT_ROUTE, SETTINGS_ROUTE } from '@/core/router/route-names';
import { ROLES } from '@/core/dict/role-dict';
import DesktopPageDrawer from './desktop-page-drawer';
import MobilePageDrawer from './mobile-page-drawer';

export default {
  name: 'PageDrawerFactory',
  mixins: [windowSize()],
  components: {
    EmployeeRequestUnreadCounter,
  },
  data() {
    return {
      menu,
    };
  },
  created() {
    this.LOGOUT_ROUTE = LOGOUT_ROUTE;
    this.SETTINGS_ROUTE = SETTINGS_ROUTE;
    this.ROLES = ROLES;
  },
  computed: {
    currentComponent() {
      return this.isMobile ? MobilePageDrawer : DesktopPageDrawer;
    },
  },
  methods: {
    hasRoles(roles, expectedIntersection = 1) {
      return intersection(this.$store.state.user.roles, roles).length >= expectedIntersection;
    },

    exitLoginOtherUser() {
      localStorage.removeItem('x-switch-user');
      localStorage.removeItem(process.env.VUE_APP_JWT_STORAGE_LOGIN_AS_NAME);
      Vue.http.headers.common['x-switch-user'] = null;
      this.$store.dispatch('employeeRequest/reset');
      this.$root.initUser().then(() => {
        this.$store.dispatch('employeeRequest/fetchEmployeeRequests');
        this.$store.dispatch('employeeRequest/watchEmployeeRequests');
        return this.$router.push({ name: 'user_index' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item__title {
  font-size: 1rem !important;
}

::v-deep .menu-list-group.menu-list-group {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
