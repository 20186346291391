<template>
  <v-toolbar class="flex-grow-0" elevation="0" height="64" v-bind="$attrs">
    <slot name="prepend-item"></slot>
    <slot name="title">
      <BaseTypography variant="h5">{{ title }}</BaseTypography>
    </slot>
    <slot name="append-item"></slot>
    <v-spacer></v-spacer>
    <slot name="close"></slot>
  </v-toolbar>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';

export default {
  name: 'AbstractModalHeader',
  inheritAttrs: false,
  components: {
    BaseTypography,
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
