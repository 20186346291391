<template>
  <v-select
    label="Status"
    :items="statuses"
    :value="selectedStatus"
    item-text="1"
    item-value="0"
    hide-details
    outlined
    clearable
    @input="emitChange"
  />
</template>

<script>
import { KILOMETER_COURSE_DICT } from '@/core/dict/kilometer-course-dict';

export default {
  name: 'KilometerCourseStatusField',
  data() {
    return {
      statuses: Object.entries(KILOMETER_COURSE_DICT),
      selectedStatus: null,
    };
  },
  props: {
    status: {
      default: null,
    },
  },
  created() {
    [this.statuses[1], this.statuses[2]] = [this.statuses[2], this.statuses[1]];
    [this.statuses[3], this.statuses[4]] = [this.statuses[4], this.statuses[3]];
    [this.statuses[2], this.statuses[3]] = [this.statuses[3], this.statuses[2]];
    this.selectedStatus = !this.status
      ? this.selectedStatus
      : this.statuses.find((status) => status[0] === this.status.toString());
  },
  methods: {
    emitChange(company) {
      this.$emit('input', company);
    },
  },
};
</script>
