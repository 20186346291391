import router from '@/core/config/router/index';
import { TAXI_COURSE_LIST_ROUTE } from '@/core/router/route-names';

export default {
  redirect() {
    if (this.isRedirectDefined()) {
      router.push({ path: this.getRedirectPath() });
      return;
    }

    router.push({ name: TAXI_COURSE_LIST_ROUTE });
  },

  isRedirectDefined() {
    return !!this.getRedirectPath();
  },

  getRedirectPath() {
    return router.history.current.query?.redirectTo;
  },
};
