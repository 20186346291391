<template>
  <div>
    <AppNotification></AppNotification>
    <router-view />
    <LoadingScreen :isVisible="isLoading"></LoadingScreen>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingScreen from '@/component/GMap/loading-screen';

export default {
  name: 'AppComponent',
  components: {
    LoadingScreen,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
};
</script>

<style>
/* hides scrollbar on windows, and display it only when needed */
html {
  overflow-y: auto;
}

html,
body,
body > div,
.v-application,
.v-main {
  height: 100%;
}
</style>
