<template>
  <component
    :is="currentComponent"
    :value="isModalOpen"
    :selectedPassenger="state.selectedEntity"
    v-bind="$attrs"
    v-on="$listeners"
  ></component>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import EditPassengerModal from './edit-passenger-modal';
import CreatePassengerModal from './create-passenger-modal';

export default {
  name: 'PassengerModalFactory',
  inheritAttrs: false,
  props: {
    state: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isCompany', 'user']),
    isModalOpen() {
      return this.state !== MODAL_STATE.CLOSED;
    },
    isEditModal() {
      return this.state.type === MODAL_STATE.EDIT_ENTITY_MODAL.type;
    },
    currentComponent() {
      return this.isEditModal ? EditPassengerModal : CreatePassengerModal;
    },
  },
};
</script>

<style lang="scss" scoped></style>
