<template>
  <div>
    <!-- TODO: this header is repeated across all instances -->
    <SettlementHeader @close="$emit('close')"></SettlementHeader>
    <!-- TODO: this section is repeated across all instances -->
    <section class="px-8 pt-4">
      <BaseTypography class="py-2" variant="subtitle">Od zleceniodawcy</BaseTypography>
      <SettlementTable>
        <thead>
          <tr>
            <th class="text-left">Zleceniodawcy</th>
            <th class="text-left">Liczba km</th>
            <th class="text-left">Godziny oczekiwania</th>
            <th class="text-left">Koszty za autostradę</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p class="settlement-table__link" @click="openSubcontractorSettlementModal">
                {{ course.contractor.name }}
              </p>
            </td>
            <td>
              {{
                course.billForSubcontractor && course.billForSubcontractor.distance
                  ? `${course.billForSubcontractor.distance} km`
                  : '-'
              }}
            </td>
            <td>
              {{
                course.billForSubcontractor && course.billForSubcontractor.waitingHours
                  ? `${course.billForSubcontractor.waitingHours} h`
                  : '-'
              }}
            </td>
            <td>{{ course.billForSubcontractor && course.billForSubcontractor.highwayCost | currency }}</td>
          </tr>
        </tbody>
      </SettlementTable>

      <ReadonlySubcontractorSettlementModal
        :value="subcontractorSettlementModal"
        :course="course"
        @close="closeSubcontractorSettlementModal"
      ></ReadonlySubcontractorSettlementModal>

      <SettlementDivider></SettlementDivider>

      <BaseTypography class="py-2" variant="subtitle">Dla kierowcy</BaseTypography>
      <SettlementTable>
        <thead>
          <tr>
            <th class="text-left">Kierowca</th>
            <th class="text-left">Liczba km</th>
            <th class="text-left">Godziny oczekiwania</th>
            <th class="text-left">Koszty za autostradę</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="settlement-table__link" @click="openDriverSettlementModal">
              {{ course.driver ? course.driver.name : 'Brak kierowcy' }}
            </td>
            <td>{{ course.billForDriver ? `${course.billForDriver.distance} km` : '-' }}</td>
            <td>
              {{
                course.billForDriver && course.billForDriver.waitingHours
                  ? `${course.billForDriver.waitingHours} h`
                  : '-'
              }}
            </td>
            <td>
              {{ course.billForDriver && course.billForDriver.highwayCost | currency }}
            </td>
          </tr>
        </tbody>
      </SettlementTable>

      <DriverSettlementModal
        :value="driverSettlementModal"
        :course="course"
        @close="closeDriverSettlementModal"
      ></DriverSettlementModal>
    </section>
  </div>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import SettlementHeader from '../header/settlement-header';
import ReadonlySubcontractorSettlementModal from './readonly-subcontractor-settlement-modal';
import DriverSettlementModal from './driver-settlement-modal';
import SettlementTable from './settlement-table/settlement-table';
import SettlementDivider from './settlement-table/settlement-divider';

export default {
  name: 'SubcontractorSettlementDrawer',
  components: {
    SettlementHeader,
    BaseTypography,
    ReadonlySubcontractorSettlementModal,
    DriverSettlementModal,
    SettlementTable,
    SettlementDivider,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subcontractorSettlementModal: false,
      driverSettlementModal: false,
    };
  },
  methods: {
    openSubcontractorSettlementModal() {
      this.subcontractorSettlementModal = true;
    },
    closeSubcontractorSettlementModal() {
      this.subcontractorSettlementModal = false;
    },
    openDriverSettlementModal() {
      this.driverSettlementModal = true;
    },
    closeDriverSettlementModal() {
      this.driverSettlementModal = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
