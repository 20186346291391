<template>
  <autocomplete-field
    :icon="icon"
    label="Baza startowa"
    :items="startingBases"
    :item-text="getPlaceText"
    :value="value"
    @input="emitChange"
    :required="required"
    dense-outlined
    :loading="listLoading"
    :disabled="disabled"
    v-bind="$attrs"
  />
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';

export default {
  name: 'StartingBaseField',
  inheritAttrs: false,
  components: { AutocompleteField },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
    },
    icon: {
      type: String,
      default: 'mdi-map-marker-star',
    },
  },
  computed: {
    startingBases() {
      return this.$store.getters.startingBasesList;
    },
    listLoading() {
      return this.$store.getters.startingBasesListLoading;
    },
  },
  created() {
    if (!this.startingBases.length) {
      this.$store.dispatch('fetchStartingBases');
    }
  },
  methods: {
    getPlaceText(place) {
      return `${place.name} (${place.address})`;
    },
    emitChange(company) {
      this.$emit('input', company);
    },
  },
};
</script>
