import '@/component';
import sockets from '@/socket';
import './style/global.scss';
import { http, router } from './config';
import { store } from './store';
import auth from './mixin/auth';
import shared from './shared';
import './mixin';
import './filter';
import './sentry';
import './firebase';

export default {
  mixins: [auth, shared, sockets],
  router,
  store,
  http,
};
