<template>
  <div>
    <alert-warning v-if="isKilometerCourseReportUncompleted" bordered class="mb-2">
      <div class="alert__container">
        <p class="mb-0">Raport rozliczeniowy przejazdu czeka na wypełnienie.</p>
        <base-text-button color="#663D00" @click.stop="openKilometerCourseReport">Uzupełnij raport</base-text-button>
      </div>
    </alert-warning>

    <alert-success v-if="isKilometerCourseFinished" class="mb-2">
      <div class="alert__container">
        <p class="mb-0">Raport rozliczeniowy został uzupełniony.</p>
        <base-text-button color="#385C39" @click.stop="openKilometerCourseReport">edytuj raport</base-text-button>
      </div>
    </alert-success>

    <KilometerCourseReportDialog
      :reportDialogVisible="kilometerCourseReportVisible"
      :distanceSum="course.distanceSum"
      :reportModel="reportModel.report()"
      :isRequestPending="isLoading"
      fullscreen
      @onReportEdit="saveKilometerCourseReport"
      @close="closeKilometerCourseReport"
    ></KilometerCourseReportDialog>
  </div>
</template>

<script>
import AlertWarning from '@/component/Alert/alert-warning';
import AlertSuccess from '@/component/Alert/alert-success';
import BaseTextButton from '@/component/Base/base-text-button';
import KilometerCourseReportDialog from '@/controller/KilometerCourse/kilometer-course-report-dialog';
import { KILOMETER_COURSE_STATUS } from '@/core/dict/kilometer-course-dict';
import { Report, SettledReport } from '../../Show/Details/report';

export default {
  name: 'KilometerCourseReport',
  components: {
    AlertWarning,
    AlertSuccess,
    BaseTextButton,
    KilometerCourseReportDialog,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      kilometerCourseReportVisible: false,
      reportModel: new Report(),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
    isCourseCreatedByCurrentUser() {
      return this.course.createdBy.id === this.$store.getters.user.id;
    },
    isKilometerCourseReportUncompleted() {
      // TODO: change const name to represent kilometer course status
      return this.isCourseCreatedByCurrentUser && this.course.rides[0].status === KILOMETER_COURSE_STATUS.CREATED;
    },
    isKilometerCourseFinished() {
      // TODO: change const name to represent kilometer course status
      return (
        this.isCourseCreatedByCurrentUser &&
        [KILOMETER_COURSE_STATUS.FINISHED, KILOMETER_COURSE_STATUS.INCONSISTENCY].includes(this.course.rides[0].status)
      );
    },
  },
  methods: {
    openKilometerCourseReport() {
      this.kilometerCourseReportVisible = true;
      this.reportModel = new SettledReport({
        ...this.course.rides[0],
        distanceToSettled: this.course.rides[0].distanceToSettled ?? this.course.distanceSum,
      });
    },
    closeKilometerCourseReport() {
      this.kilometerCourseReportVisible = false;
    },
    saveKilometerCourseReport(report) {
      const payload = this.reportModel.payload(report);
      payload.payload.status = KILOMETER_COURSE_STATUS.FINISHED;
      this.$store.dispatch('taxiCourse/updateValues', payload).then(() => {
        this.closeKilometerCourseReport();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
