<template>
  <component
    :is="currentComponent"
    :strategy="dialogStrategy"
    :title="dialogTitle"
    v-bind="$attrs"
    v-on="$listeners"
  ></component>
</template>

<script>
import { mapGetters } from 'vuex';
import NonCompanyLocomotiveDialog from './non-company-locomotive-dialog';
import CompanyLocomotiveDialog from './company-locomotive-dialog';
import editLocomotive from './strategies/edit-locomotive-strategy';
import createLocomotive from './strategies/create-locomotive-strategy';

export default {
  name: 'LocomotiveDialogFactory',
  components: {
    NonCompanyLocomotiveDialog,
    CompanyLocomotiveDialog,
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['isCompany']),
    currentComponent() {
      return this.isCompany ? CompanyLocomotiveDialog : NonCompanyLocomotiveDialog;
    },
    isEditType() {
      return this.type === 'edit';
    },
    dialogTitle() {
      return this.isEditType ? 'Edytuj lokomotywę' : 'Dodaj lokomotywę';
    },
    dialogStrategy() {
      return this.isEditType ? editLocomotive : createLocomotive;
    },
  },
};
</script>
