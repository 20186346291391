export const SET_LOADING = 'SET_LOADING';
export const SET_MERCURE_WATCH_INITIALIZED = 'SET_MERCURE_WATCH_INITIALIZED';
export const SET_LIST_LOADING = 'SET_LIST_LOADING';
export const SET_COURSE = 'SET_TAXI_COURSE';
export const UPDATE_COURSE_IN_COLUMN = 'UPDATE_COURSE_IN_COLUMN';
export const SET_COURSES = 'SET_TAXI_COURSE_LIST';
export const ADD_LAZY_LOADED_COURSES_IN_COLUMN = 'ADD_LAZY_LOADED_COURSES_IN_COLUMN';
export const ADD_LAZY_LOADED_COURSES_IN_LIST = 'ADD_LAZY_LOADED_COURSES_IN_LIST';
export const SET_COURSES_AMOUNT = 'SET_TAXI_COURSES_AMOUNT';
export const SET_RIDE_NUMBER_LOADING = 'SET_RIDE_NUMBER_LOADING';
export const SET_RIDE_NUMBER = 'SET_RIDE_NUMBER';
export const SET_RIDE_RATING = 'SET_RIDE_RATING';
export const SET_ALL_COURSES = 'SET_ALL_COURSES';
export const SET_ALL_COURSES_COUNT = 'SET_ALL_COURSES_COUNT';
export const SET_ALL_COURSES_LOADING = 'SET_ALL_COURSES_LOADING';
export const UPDATE_COURSE_IN_LIST = 'UPDATE_COURSE_IN_LIST';
export const RESET_TRANSPORT_TYPE = 'RESET_TRANSPORT_TYPE';
export const RESET_DATE_FILTER = 'RESET_DATE_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const RESET_AFTER_DATE_FILTER = 'RESET_AFTER_DATE_FILTER';
export const RESET_BEFORE_DATE_FILTER = 'RESET_BEFORE_DATE_FILTER';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const RESET_LOCOMOTIVE_FILTER = 'RESET_LOCOMOTIVE_FILTER';
export const EXCLUDE_CANCELLED_COURSES = 'EXCLUDE_CANCELLED_COURSES';
export const RESET_CREATED_BY_FILTER = 'RESET_CREATED_BY_FILTER';
export const REMOVE_COURSE = 'REMOVE_COURSE';
