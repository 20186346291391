<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import EditContractorOfPrincipalModal from './edit-contractor-of-principal-modal';
import EditContactorOfTransportCompany from './edit-contractor-of-transport-company-modal';

export default {
  name: 'EditContractorModalFactory',
  inheritAttrs: false,
  computed: {
    ...mapGetters(['isCompany']),
    currentComponent() {
      return this.isCompany ? EditContractorOfPrincipalModal : EditContactorOfTransportCompany;
    },
  },
};
</script>
