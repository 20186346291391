<template>
  <abstract-locomotive-dialog v-bind="$attrs" v-on="$listeners">
    <template #default="{ model }">
      <company-field
        v-model="model.company"
        is-not-transport-company
        required
        dense-outlined
        item-value="@id"
        :rules="[(v) => !!v || 'Uzupełnij firmę']"
      ></company-field>
    </template>
  </abstract-locomotive-dialog>
</template>

<script>
import CompanyField from '@/component/Field/Company/company-field';
import AbstractLocomotiveDialog from './abstract-locomotive-dialog';

export default {
  name: 'NonCompanyLocomotiveDialog',
  components: {
    AbstractLocomotiveDialog,
    CompanyField,
  },
};
</script>
