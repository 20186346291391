<template>
  <div class="white fill-height flex-grow-1 d-flex flex-column">
    <KilometerCourseHeader @closeDrawer="$emit('close')"></KilometerCourseHeader>
    <KilometerCourseGeneralInfo :course="course" :ride="ride"></KilometerCourseGeneralInfo>
    <v-tabs v-model="selectedTab" fixed-tabs class="flex-grow-0">
      <v-tab>Podsumowanie</v-tab>
      <v-tab>Relacja</v-tab>
      <v-tab>
        <pulse-badge :isVisible="rideHasComments" offset-y="5px" offset-x="-5px">Komentarze</pulse-badge>
      </v-tab>
      <v-tab>Komunikacja</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="selectedTab" class="flex-grow-1">
      <v-tab-item class="tab__item--full-height">
        <kilometer-course-summary :course="course" />
      </v-tab-item>
      <v-tab-item class="tab__item--full-height">
        <segments-timeline :ride="course.rides[0]" editable extended-view />
      </v-tab-item>
      <v-tab-item class="tab__item--full-height">
        <comment-section class="flex-grow-1 overflow-y-auto" :entity="{ ...course.rides[0], '@type': 'Ride' }" />
      </v-tab-item>
      <v-tab-item class="tab__item--full-height">
        <ride-message-section class="flex-grow-1 overflow-y-auto" :ride="course.rides[0]" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import KilometerCourseHeader from '@/component/NewCourse/Show/kilometer-course-header';
import PulseBadge from '@/component/Base/pulse-badge';
import SegmentsTimeline from '@/component/NewCourse/Segments/segments-timeline-factory';
import CommentSection from '@/component/NewCourse/Show/Details/comment-section';
import RideMessageSection from '@/component/NewCourse/Show/Details/ride-message-section';
import KilometerCourseSummary from '@/component/NewCourse/Show/Details/kilometer-course-summary';
import KilometerCourseGeneralInfo from '@/component/NewCourse/Show/Details/kilometer-course-general-info';

const RELATION_TAB = 0;

export default {
  name: 'KilometerCourseDrawer',
  components: {
    KilometerCourseHeader,
    KilometerCourseGeneralInfo,
    KilometerCourseSummary,
    RideMessageSection,
    CommentSection,
    SegmentsTimeline,
    PulseBadge,
  },
  props: {
    ride: Object,
    course: Object,
  },
  data() {
    return {
      selectedTab: RELATION_TAB,
    };
  },
  computed: {
    rideHasComments() {
      return this.ride.comments.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab__item--full-height {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

::v-deep .v-window__container {
  height: 100%;
}
</style>
