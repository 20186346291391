<template>
  <v-dialog
    width="512"
    transition="dialog-bottom-transition"
    v-bind="$attrs"
    @click:outside="emitCloseEvent"
    @keydown.esc="emitCloseEvent"
  >
    <v-card tile class="d-flex flex-column">
      <v-card-title class="text-h5 text-break pb-6">Na czym polega niezgodność w rozliczeniu?</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="comment"
          label="Opis niezgodności w rozliczeniu"
          outlined
          :rules="[(v) => !!v || 'Należy podać powód niezgodności']"
          validate-on-blur
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="emitCloseEvent">anuluj</v-btn>
        <v-btn color="primary" :disabled="!comment" :loading="isRequestPending" text @click="emitSaveCommentEvent">
          zatwierdź
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommentDialog',
  props: {
    isRequestPending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comment: null,
    };
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
    },

    emitSaveCommentEvent() {
      this.$emit('saveComment', this.comment);
    },

    clearComment() {
      this.comment = null;
    },
  },
  watch: {
    '$attrs.value': function (val) {
      if (val === false) {
        this.clearComment();
      }
    },
  },
};
</script>
