// eslint-disable-next-line max-classes-per-file
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import { API_COURSE_RIDE_DICT } from '@/core/dict/course-dict';

// TODO: refactor to abstract class || interface
export class Report {
  // eslint-disable-next-line class-methods-use-this
  report() {
    return {
      driverDistance: '',
      driverDistanceComment: '',
      waitingHours: '',
      waitingHoursComment: '',
      highwayCost: '',
      highwayCostComment: '',
    };
  }

  // eslint-disable-next-line class-methods-use-this
  payload() {
    throw new Error('Method need to be implemented in derivative');
  }
}

export class DriverReport extends Report {
  #rides;

  constructor(course) {
    super();
    this.course = course;
    this.#rides = course.rides;
  }

  report() {
    return {
      distance: this.course.driverDistance,
      waitingHours: this.course.driverWaitingHours,
      highwayCost: this.course.driverHighwayCost,
      comment: this.course.driverComment,
    };
  }

  reportWithoutComments() {
    return {
      ...this.report(),
      comment: null,
    };
  }

  payload(editedReport) {
    return {
      '@id': this.course['@id'],
      payload: {
        billFromDriver: {
          distance: editedReport.distance,
          waitingHours: editedReport.waitingHours === '' ? null : editedReport.waitingHours,
          highwayCost: editedReport.highwayCost === '' ? null : editedReport.highwayCost,
          comment: editedReport.comment,
        },
        rides: this.#rides.map((r) => ({
          id: r['@id'],
          type: API_COURSE_RIDE_DICT.TAXI_COURSE_RIDE,
          status: TAXI_COURSE_RIDE_STATUS.FINISHED,
        })),
      },
    };
  }
}

export class DriverSettlementReport extends Report {
  #courseId;

  constructor(course) {
    super();
    this.#courseId = course['@id'];
    this.distance = course.billForDriver?.distance || null;
    this.waitingHours = course.billForDriver?.waitingHours || null;
    this.highwayCost = course.billForDriver?.highwayCost || null;
    this.comment = course.billForDriver?.comment || null;
  }

  payload() {
    return {
      '@id': this.#courseId,
      payload: {
        billForDriver: {
          distance: this.distance,
          waitingHours: this.waitingHours === '' ? null : this.waitingHours,
          highwayCost: this.highwayCost === '' ? null : this.highwayCost,
          comment: this.comment,
        },
      },
    };
  }
}

export class SettledReport extends Report {
  constructor(ride) {
    super();
    this.ride = ride;
  }

  report() {
    return {
      distance: this.ride.distanceToSettled,
      waitingHours: this.ride.waitingHoursToSettled,
      highwayCost: this.ride.highwayCostToSettled,
      comment: this.ride.commentToSettled,
    };
  }

  payload(editedReport) {
    return {
      '@id': this.ride['@id'],
      payload: {
        billToSettled: {
          distance: editedReport.distance,
          waitingHours: editedReport.waitingHours === '' ? null : editedReport.waitingHours,
          highwayCost: editedReport.highwayCost === '' ? null : editedReport.highwayCost,
          comment: editedReport.comment,
        },
      },
    };
  }
}

export class RideSettledReport extends Report {
  #rideId;

  constructor(ride) {
    super();
    this.#rideId = ride['@id'];
    this.distance = ride.distanceToSettled || null;
    this.waitingHours = ride.waitingHoursToSettled || null;
    this.highwayCost = ride.highwayCostToSettled || null;
    this.comment = ride.commentToSettled || null;
  }

  payload() {
    return {
      '@id': this.#rideId,
      payload: {
        billToSettled: {
          distance: this.distance,
          waitingHours: this.waitingHours === '' ? null : this.waitingHours,
          highwayCost: this.highwayCost === '' ? null : this.highwayCost,
          comment: this.comment,
        },
      },
    };
  }
}

export class SubcontractorSettlementReport extends Report {
  #courseId;

  constructor(course) {
    super();
    this.#courseId = course['@id'];
    this.distance = course.billForSubcontractor?.distance || null;
    this.waitingHours = course.billForSubcontractor?.waitingHours || null;
    this.highwayCost = course.billForSubcontractor?.highwayCost || null;
    this.comment = course.billForSubcontractor?.comment || null;
  }

  payload() {
    return {
      '@id': this.#courseId,
      payload: {
        billForSubcontractor: {
          distance: this.distance,
          waitingHours: this.waitingHours === '' ? null : this.waitingHours,
          highwayCost: this.highwayCost === '' ? null : this.highwayCost,
          comment: this.comment,
        },
      },
    };
  }
}
