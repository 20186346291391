<template>
  <component :is="currentComponent" :course="course" v-on="$listeners" v-bind="$attrs"></component>
</template>

<script>
import { COURSE_TYPE_DICT } from '@/core/dict/course-dict';
import windowSize from '@/core/mixin/windowSize';
import MobileCourseDrawer from './mobile-course-drawer';
import DesktopCourseDrawer from './desktop-course-drawer';

const MAX_WIDTH_OF_COURSE_DRAWER = 1300;

export default {
  name: 'CourseDrawerFactory',
  props: {
    course: Object,
  },
  mixins: [windowSize(MAX_WIDTH_OF_COURSE_DRAWER)],
  provide() {
    return {
      isMobile: this.reactiveIsMobileVal,
    };
  },
  computed: {
    isTaxiCourse() {
      return this.course && this.course['@type'] === COURSE_TYPE_DICT.TAXI_COURSE;
    },
    currentComponent() {
      if (this.isTaxiCourse && this.isMobile) {
        return MobileCourseDrawer;
      }

      return DesktopCourseDrawer;
    },
  },
};
</script>

<style lang="scss" scoped></style>
