<template>
  <div>{{ courseContractor }}</div>
</template>

<script>
export default {
  name: 'CourseExecutor',
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    courseContractor() {
      return this.course.subcontractor?.name || this.course.contractor?.name;
    },
  },
};
</script>
