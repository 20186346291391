<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader>
      <template #prepend-item>
        <v-icon>mdi-account</v-icon>
      </template>
      <template #title>
        <div class="d-flex flex-column ml-2">
          <BaseTypography noMargin variant="h5">
            {{ course.contractor.name }}
          </BaseTypography>
          <BaseTypography regular variant="subtitle-2">Zleceniodawca</BaseTypography>
        </div>
      </template>
      <template #close><CloseIconButton @click="$emit('close')"></CloseIconButton></template>
    </AbstractModalHeader>
    <section class="px-6 pb-4">
      <BaseTypography class="py-2" variant="subtitle">Informacje od kierowcy</BaseTypography>
      <DescriptionSection class="mb-4">
        <DescriptionItem title="Liczba km">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverDistance ? `${course.driverDistance} km` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Godziny oczekiwania">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverWaitingHours ? `${course.driverWaitingHours} h` : '-' }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Koszty za autostrady">
          <!-- TODO: extract this to separate component -->
          <v-chip color="rgba(0, 125, 255, 0.12)" small text-color="black" @click="openDriverReportModal">
            {{ course.driverHighwayCost | currency }}
            <EditIconButton class="ml-2" x-small></EditIconButton>
          </v-chip>
        </DescriptionItem>
        <DescriptionItem title="Komentarz kierowcy">
          <div :class="course.driverComment ? 'text-right' : 'text-left'" style="min-width: 24px">
            {{ course.driverComment || '-' }}
          </div>
        </DescriptionItem>
      </DescriptionSection>
    </section>

    <ReportDialog
      :reportDialogVisible="driverReportModal"
      :reportModel="billFromDriver.report()"
      :isRequestPending="false"
      @close="closeDriverReportModal"
      @onReportEdit="updateReport"
    ></ReportDialog>

    <v-divider></v-divider>

    <section class="px-6">
      <BaseTypography class="py-2" variant="subtitle">Rozliczenie podwykonawcy</BaseTypography>
      <DescriptionSection class="mb-4">
        <DescriptionItem title="Liczba km">
          <div>
            {{
              course.billForSubcontractor && course.billForSubcontractor.distance
                ? `${course.billForSubcontractor.distance} km`
                : '-'
            }}
          </div>
        </DescriptionItem>
        <DescriptionItem title="Godziny oczekiwania">
          <!-- TODO: extract this to separate component -->
          <div>
            {{
              course.billForSubcontractor && course.billForSubcontractor.waitingHours
                ? `${course.billForSubcontractor.waitingHours} h`
                : '-'
            }}
          </div>
        </DescriptionItem>
        <DescriptionItem title="Koszty za autostrady">
          <!-- TODO: extract this to separate component -->
          <div>
            {{ course.billForSubcontractor && course.billForSubcontractor.highwayCost | currency }}
          </div>
        </DescriptionItem>
        <DescriptionItem title="Komentarz osoby rozliczającej">
          <div>
            {{
              course.billForSubcontractor && course.billForSubcontractor.comment
                ? course.billForSubcontractor.comment
                : '-'
            }}
          </div>
        </DescriptionItem>
      </DescriptionSection>
    </section>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import BaseTypography from '@/component/Base/base-typography';
import DescriptionSection from '@/component/Description/description-section';
import DescriptionItem from '@/component/Description/description-item';
import EditIconButton from '@/component/Button/edit-icon-button';
import CloseIconButton from '@/component/Button/close-icon-button';
import ReportDialog from '@/component/NewCourse/Show/Details/report-dialog';
import { DriverReport } from '@/component/NewCourse/Show/Details/report';

export default {
  name: 'ReadonlySubcontractorSettlementModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    DescriptionSection,
    DescriptionItem,
    BaseTypography,
    EditIconButton,
    CloseIconButton,
    ReportDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      driverReportModal: false,
      billFromDriver: new DriverReport(this.course),
    };
  },
  methods: {
    openDriverReportModal() {
      this.driverReportModal = true;
      this.billFromDriver = new DriverReport(this.course);
    },
    closeDriverReportModal() {
      this.driverReportModal = false;
    },
    updateReport(editedReport) {
      this.$store.dispatch('taxiCourse/updateValues', this.billFromDriver.payload(editedReport)).then(() => {
        this.closeDriverReportModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
