<template>
  <div>
    <template v-if="isPassenger">
      <CourseStartIndicator :course="course"></CourseStartIndicator>
      <course-executor-label
        v-if="rideIsAcceptedByDriver || (rideIsAcceptedByTransportCompany && courseHasDriver)"
        :name="course.driver.name"
        :phone="course.driverPhoneNumber"
      />
      <course-executor-label v-else-if="courseContractor" :name="courseContractor" :phone="courseContractorPhone" />
    </template>
    <template v-else-if="isCompany && course.driver">
      <course-executor-label :name="course.driver.name" :phone="course.driverPhoneNumber" />
    </template>
    <template v-else-if="isDriver"></template>
    <div v-else-if="course.driver" class="pb-3">
      <CourseStartIndicator :course="course"></CourseStartIndicator>
      <course-executor-label :name="course.driver.name" :phone="course.driverPhoneNumber" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CourseExecutorLabel from '@/component/NewCourse/course-executor-label';
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import CourseStartIndicator from './course-start-indicator';

export default {
  name: 'TaxiCourseSubtitleFactory',
  components: {
    CourseExecutorLabel,
    CourseStartIndicator,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isPassenger', 'isDriver', 'isCompany']),
    courseContractor() {
      return this.course.subcontractor?.name || this.course.contractor?.name;
    },
    courseContractorPhone() {
      return this.course.subcontractor?.phone || this.course.contractor?.phone;
    },
    rideIsAcceptedByDriver() {
      return this.course.rides[0].status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_DRIVER;
    },
    rideIsAcceptedByTransportCompany() {
      return this.course.rides[0].status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_TRANSPORT_COMPANY;
    },
    courseHasDriver() {
      return this.course.driver !== null;
    },
  },
};
</script>
