<template>
  <alert :icon="icon" icon-color="#007DFF" text-color="#0D3C61" color="#007dff1a">
    <template #default>
      <slot></slot>
    </template>
  </alert>
</template>
<script>
import Alert from '@/component/Alert/alert';

export default {
  name: 'AlertInfo',
  components: {
    Alert,
  },
  props: {
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
  },
};
</script>
