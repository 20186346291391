<template>
  <v-btn icon aria-label="Powrót" v-bind="$attrs" v-on="$listeners"><v-icon>mdi-arrow-left</v-icon></v-btn>
</template>

<script>
export default {
  name: 'BackIconButton',
  inheritAttrs: false,
};
</script>
