<template>
  <component :is="currentComponent" v-bind="$attrs"></component>
</template>

<script>
import { COURSE_STATUS } from '@/core/dict/taxi-course-dict';
import FinishedColumnContextMenu from './finished-column-context-menu';

export default {
  name: 'ColumnContextMenuFactory',
  inheritAttrs: false,
  components: {
    FinishedColumnContextMenu,
  },
  props: {
    column: {
      type: String,
      required: true,
    },
    counter: {
      required: true,
    },
  },
  computed: {
    columnHasCourses() {
      const NO_COURSES = 0;
      return this.counter > NO_COURSES;
    },
    currentComponent() {
      return this.column === COURSE_STATUS.FINISHED && this.$store.getters.isHeadOfCompany && this.columnHasCourses
        ? FinishedColumnContextMenu
        : null;
    },
  },
};
</script>
