<template>
  <autocomplete-field
    :class="{ 'warning--text': warningColor }"
    item-text="name"
    :icon="icon"
    :label="label"
    :items="apiPassengers"
    :value="value"
    outlined
    return-object
    hide-details="auto"
    :loading="passengersLoading"
    @input="emitChange"
    data-cy="passenger-input"
    v-bind="$attrs"
  >
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </autocomplete-field>
</template>

<script>
import AutocompleteField from '@/component/Field/Generic/autocomplete-field';

export default {
  name: 'PassengerField',
  inheritAttrs: false,
  components: {
    AutocompleteField,
  },
  props: {
    label: {
      type: String,
      default: 'Pasażer',
    },
    icon: {
      type: String,
      default: 'mdi-human-greeting-variant',
    },
    companyId: {},
    value: {
      required: false,
    },
    warningColor: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch('fetchPassengers');
  },
  computed: {
    apiPassengers() {
      if (Array.isArray(this.companyId)) {
        const passengers = [];

        this.companyId.forEach((companyId) => {
          passengers.push(
            ...this.$store.getters.passengerList.filter((passenger) => passenger.company?.id === companyId)
          );
        });

        return passengers;
      }

      if (typeof this.companyId === 'number') {
        return this.$store.getters.passengerList.filter((passenger) => passenger.company?.id === this.companyId);
      }

      return this.$store.getters.passengerList;
    },
    passengersLoading() {
      return this.$store.getters.passengerListLoading;
    },
  },
  methods: {
    emitChange(passenger) {
      this.$emit('input', passenger);
    },
  },
};
</script>

<style lang="scss" scoped>
$warning-color: #ff9800 !important;

.v-input.warning--text.error--text {
  color: $warning-color;

  ::v-deep.v-icon,
  ::v-deep.v-label,
  ::v-deep.v-messages {
    color: $warning-color;
  }
}
</style>
