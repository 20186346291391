<template>
  <div>
    <AbstractPlaceField v-bind="$attrs" v-on="$listeners">
      <template #append-item><AddItemButton @click="openPlaceModal()">Dodaj miejsce</AddItemButton></template>
      <template #item="{ attrs, item }">
        <div class="d-flex flex-row align-center flex-grow-1">
          <v-checkbox :input-value="attrs.inputValue"></v-checkbox>
          <p class="flex-grow-1 mb-0">{{ item.name }} ({{ item.address }})</p>
          <v-btn aria-label="edytuj" icon @click.stop="openPlaceModal(item)"><v-icon>mdi-pencil</v-icon></v-btn>
        </div>
      </template>
    </AbstractPlaceField>
    <PlaceModalFactory :value="placeModal" :selectedPlace="selectedPlace" @close="closePlaceModal"></PlaceModalFactory>
  </div>
</template>

<script>
import AbstractPlaceField from './abstract-place-field';
import AddItemButton from '../../Generic/add-item-button';
import PlaceModalFactory from './place-modal-factory';

export default {
  name: 'EditablePlaceField',
  inheritAttrs: false,
  components: {
    AbstractPlaceField,
    AddItemButton,
    PlaceModalFactory,
  },
  data() {
    return {
      placeModal: false,
      selectedPlace: null,
    };
  },
  methods: {
    openPlaceModal(place = null) {
      this.placeModal = true;
      this.selectedPlace = place;
    },
    closePlaceModal() {
      this.placeModal = false;
      this.selectedPlace = null;
    },
  },
};
</script>
