<template>
  <v-dialog
    :value="value"
    max-width="480"
    transition="dialog-bottom-transition"
    @click:outside="emitCloseEvent"
    @keydown.esc="emitCloseEvent"
  >
    <v-card tile>
      <DialogTitleHeader text="Nowa firma" @click="emitCloseEvent"></DialogTitleHeader>
      <v-card-text class="pt-0 pb-6 px-6">
        <v-form class="d-flex flex-column" style="row-gap: 1.3rem" ref="form" data-testid="create-company-form">
          <v-text-field
            label="Numer NIP*"
            v-model="company.taxNumber"
            outlined
            clearable
            :rules="[rules.required, rules.nipValid, rules.nipLengthChecker]"
            validate-on-blur
            maxlength="10"
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            label="Nazwa firmy*"
            v-model="company.name"
            outlined
            clearable
            hide-details="auto"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="Ulica i numer domu*"
            v-model="company.headquartersStreet"
            outlined
            clearable
            hide-details="auto"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="Kod pocztowy*"
            v-model="company.headquartersZipCode"
            outlined
            clearable
            hide-details="auto"
            :rules="[rules.required, rules.zipCodeValid]"
            validate-on-blur
          ></v-text-field>
          <v-text-field
            label="Miejscowość*"
            v-model="company.headquartersCity"
            outlined
            clearable
            hide-details="auto"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="Adres e-mail"
            v-model="company.email"
            hint="Na ten adres będą wysyłane informacje o nowych oraz anulowanych kursach"
            outlined
            clearable
            :rules="[rules.emailValid]"
            hide-details="auto"
            persistent-hint
            validate-on-blur
          ></v-text-field>
          <v-text-field
            label="Numer telefonu"
            v-model="company.phone"
            hint="Numer kontaktowy na dyspozyturę, możliwość włączenia komunikatów SMS o nowych kursach"
            outlined
            hide-details="auto"
            :rules="[rules.phoneNumber]"
            persistent-hint
            validate-on-blur
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-6 pt-0 pb-6">
        <v-btn block color="primary" @click="createCompany">Dodaj firmę</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import formValidationRules from '@/core/utils/form-validation-rules';

export default {
  name: 'CreateCompanyModal',
  components: {
    DialogTitleHeader,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    strategy: {
      required: true,
    },
  },
  data() {
    return {
      rules: {
        nipValid: (v) => /\d/.test(+v) || 'NIP firmy powinien składać się tylko z cyfr',
        nipLengthChecker: (v) => v?.length === 10 || 'NIP firmy powinien zawierać dokładnie 10 cyfr',
        required: formValidationRules.required,
        zipCodeValid: (v) => /^\d{2}-\d{3}$/.test(v) || 'Kod pocztowy powinien zgadzać się z formatem dd-ddd',
        emailValid: (v) =>
          this.isFieldEmpty(v) || /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Adres email nie jest prawidłowy',
        phoneNumber: (v) => this.isFieldEmpty(v) || /^\d{9}$/.test(v) || 'Numer telefonu powinien składać się z 9 cyfr',
      },
      company: {
        taxNumber: null,
        name: null,
        headquartersCity: null,
        headquartersStreet: null,
        headquartersZipCode: null,
        email: null,
        phone: null,
      },
    };
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    isFieldEmpty(v) {
      return v === null || v === '';
    },
    createCompany() {
      if (this.$refs.form.validate()) {
        this.strategy.save(this.company).then(() => {
          this.emitCloseEvent();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__title {
    font: 500 20px/32px 'Roboto', sans-serif;
  }
}
</style>
