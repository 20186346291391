<template>
  <div>
    <!-- TODO: this header is repeated across all instances -->
    <SettlementHeader @close="$emit('close')"></SettlementHeader>
    <!-- TODO: this section is repeated across all instances -->
    <section class="px-8 pt-4">
      <BaseTypography class="py-2" variant="subtitle">Podsumowanie</BaseTypography>
      <SettlementTable>
        <thead>
          <tr>
            <th class="text-left">Klient</th>
            <th class="text-left">Liczba km</th>
            <th class="text-left">Godziny oczekiwania</th>
            <th class="text-left">Koszty za autostradę</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p class="settlement-table__link">Przejazd {{ ride.number }}</p>
              <p class="mb-0">{{ ride.company.name }}</p>
            </td>
            <td>{{ ride.distanceToSettled ? `${ride.distanceToSettled} km` : '-' }}</td>
            <td>{{ ride.waitingHoursToSettled ? `${ride.waitingHoursToSettled} h` : '-' }}</td>
            <td>{{ ride.highwayCostToSettled | currency }}</td>
          </tr>
        </tbody>
      </SettlementTable>

      <div class="mt-4 d-flex flex-column" style="row-gap: 16px">
        <v-btn v-if="isRideAcceptedByContractor" :loading="isLoading" color="success" @click="acceptRideByCustomer">
          <v-icon left>mdi-check</v-icon>
          zaakceptuj do rozliczenia
        </v-btn>

        <v-btn
          v-if="isRideAcceptedByContractor || isRideAcceptedByCustomer"
          :loading="isLoading"
          text
          color="error"
          @click="openCommentDialog"
        >
          <v-icon left>mdi-alert-circle</v-icon>
          zgłoś niezgodność
        </v-btn>
      </div>

      <CommentDialog
        :value="commentDialog"
        :isRequestPending="isLoading"
        @close="closeCommentDialog"
        @saveComment="markRideAsInconsistent"
      ></CommentDialog>
    </section>
  </div>
</template>

<script>
import { TAXI_COURSE_RIDE_STATUS } from '@/core/dict/taxi-course-dict';
import BaseTypography from '@/component/Base/base-typography';
import { UpdateRideStatusCommand } from '@/command/UpdateRideStatusCommand';
import CommentService from '@/service/CommentService';
import CommentDialog from '@/component/Dialog/comment-dialog';
import SettlementHeader from '../header/settlement-header';
import SettlementTable from './settlement-table/settlement-table';

export default {
  name: 'RailwayCompanySettlementDrawer',
  components: {
    SettlementHeader,
    BaseTypography,
    CommentDialog,
    SettlementTable,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      commentDialog: false,
    };
  },
  computed: {
    ride() {
      return this.course.rides[0];
    },
    isRideAcceptedByContractor() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CONTRACTOR;
    },
    isRideAcceptedByCustomer() {
      return this.ride.status === TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CUSTOMER;
    },
    isLoading() {
      return this.$store.getters['taxiCourse/isLoading'];
    },
  },
  methods: {
    updateStatus(status) {
      return new UpdateRideStatusCommand({ ride: this.ride, status }).execute();
    },
    acceptRideByCustomer() {
      this.updateStatus(TAXI_COURSE_RIDE_STATUS.ACCEPTED_BY_CUSTOMER);
    },
    async markRideAsInconsistent(message) {
      this.$store.commit('taxiCourse/SET_LOADING', true);
      try {
        await CommentService.create({ ...this.ride, '@type': 'Ride' }, message, this.$store.getters.user.id);
        await this.updateStatus(TAXI_COURSE_RIDE_STATUS.INCONSISTENCY);

        this.closeCommentDialog();
      } finally {
        this.$store.commit('taxiCourse/SET_LOADING', false);
      }
    },
    openCommentDialog() {
      this.commentDialog = true;
    },
    closeCommentDialog() {
      this.commentDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
