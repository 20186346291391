import DriverService from '@/service/DriverService';
import { ABSTRACT_PLACE_TYPES } from '@/core/dict/place-type-dict';
import * as types from './types/drivers-mutation-types';

export default {
  namespaced: true,
  state: {
    drivers: [],
    driversLoading: false,
  },
  getters: {
    drivers: (state) => state.drivers,
    driversLoading: (state) => state.driversLoading,
  },
  mutations: {
    [types.SET_DRIVERS]: (state, val) => {
      state.drivers = val;
    },
    [types.SET_DRIVERS_LOADING]: (state, val) => {
      state.driversLoading = val;
    },
    [types.ADD_DRIVER]: (state, val) => {
      state.drivers.push(val);
    },
    [types.UPDATE_DRIVER]: (state, val) => {
      const driverIdx = state.drivers.findIndex((el) => el.id === val.id);
      state.drivers.splice(driverIdx, 1, val);
    },
    [types.DELETE_DRIVER]: (state, driverToBeRemoved) => {
      state.drivers = state.drivers.filter((driver) => driver.id !== driverToBeRemoved.id);
    },
  },
  actions: {
    fetchDrivers({ getters, commit }) {
      if (!getters.driversLoading || !getters.drivers.length) {
        commit(types.SET_DRIVERS_LOADING, true);
        DriverService.fetchDrivers().then(({ data }) => {
          commit(types.SET_DRIVERS, data['hydra:member']);
          commit(types.SET_DRIVERS_LOADING, false);
        });
      }
    },
    createDriver({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return DriverService.createDriver(payload)
        .then((data) => {
          commit(types.ADD_DRIVER, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    updateDriver({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return DriverService.updateDriver(payload)
        .then((data) => {
          commit(types.UPDATE_DRIVER, data);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    deleteDriver({ commit }, payload) {
      commit('setLoading', true, { root: true });
      return DriverService.deleteDriver(payload)
        .then(() => {
          commit(types.DELETE_DRIVER, payload);
        })
        .finally(() => {
          commit('setLoading', false, { root: true });
        });
    },
    async createHomeplace({ dispatch }, { place, entity }) {
      if (entity.user.home) {
        await dispatch('place/softDeletePlace', entity.user.home, { root: true });
      }

      const placePayload = {
        ...place,
        user: entity.user,
        '@type': ABSTRACT_PLACE_TYPES.HOME,
        id: null,
        '@id': null,
        name: entity.user.name,
        company: entity.user.company,
      };

      const createdPlace = await dispatch(
        'place/save',
        {
          place: placePayload,
          type: ABSTRACT_PLACE_TYPES.HOME,
        },
        { root: true }
      );

      await dispatch('updateDriver', {
        entity: {
          ...entity,
          user: {
            ...entity.user,
            home: createdPlace,
          },
        },
      });

      return createdPlace;
    },

    async removeHomeplace({ dispatch }, entity) {
      await dispatch('place/softDeletePlace', entity.user.home, { root: true });

      await dispatch('updateDriver', {
        entity: {
          ...entity,
          user: {
            ...entity.user,
            home: null,
          },
        },
      });

      return null;
    },
  },
};
