const MOBILE_VIEWPORT = 500;

export default function (viewport = MOBILE_VIEWPORT) {
  return {
    data() {
      return {
        width: null,
        reactiveIsMobileVal: {
          value: this.isMobile,
        },
      };
    },
    created() {
      this.updateWidth();
      window.addEventListener('resize', this.updateWidth, { passive: true });
    },
    destroyed() {
      window.removeEventListener('resize', this.updateWidth, { passive: false });
    },
    computed: {
      isMobile() {
        return this.width < viewport;
      },
    },
    methods: {
      updateWidth() {
        this.width = window.innerWidth;
      },
    },
    watch: {
      isMobile: {
        handler(val) {
          this.reactiveIsMobileVal.value = val;
        },
        immediate: true,
      },
    },
  };
}
