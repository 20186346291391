// eslint-disable-next-line import/prefer-default-export
export class EditCarBrandStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  execute(payload) {
    return this.#store.dispatch('car/updateBrand', payload);
  }
}
