<template>
  <!-- TODO: remove wrapper div after migration to vue 3 -->
  <!-- because vue 2 doesn't support multiple root elements -->
  <div>
    <AbstractRailwayCompanyInput
      :items="principalsOfTransportCompany"
      :loading="loggedCompanyLoading"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #append-item>
        <AddItemButton @click="openPrincipalAdditionModal">Dodaj obsługiwaną firmę</AddItemButton>
      </template>
      <template #item="data">
        <p class="flex-grow-1 mb-0">{{ data.item.name }}</p>
        <v-btn color="primary" icon aria-label="edytuj" @click.stop="openEditPrincipalModal(data.item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </AbstractRailwayCompanyInput>
    <EditPrincipalModal
      :value="editPrincipalModal"
      :companyContractor="companyContractorToEdit"
      @close="closeEditPrincipalModal"
    ></EditPrincipalModal>
    <AddPrincipalModal
      :value="principalModal"
      @close="closePrincipalAdditionModal"
      data-testid="add-principal-modal"
    ></AddPrincipalModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import EditPrincipalModal from '../principal-modal/edit-principal-modal';
import AbstractRailwayCompanyInput from './abstract-railway-company-input';
import AddPrincipalModal from '../principal-modal/add-principal-modal';

export default {
  name: 'EditableRailwayCompanyInput',
  inheritAttrs: false,
  components: {
    AbstractRailwayCompanyInput,
    AddPrincipalModal,
    AddItemButton,
    EditPrincipalModal,
  },
  data() {
    return {
      principalModal: false,
      editPrincipalModal: false,
      principalId: null,
    };
  },
  computed: {
    ...mapGetters(['loggedCompany', 'loggedCompanyLoading']),
    principalsOfTransportCompany() {
      return (
        this.loggedCompany?.principals
          ?.filter(({ principal }) => !principal.isTransportCompany)
          .map(({ principal }) => principal) ?? []
      );
    },
    companyContractorToEdit() {
      return this.loggedCompany?.principals.find(
        (companyContractor) => companyContractor.principal.id === this.principalId
      );
    },
  },
  methods: {
    openPrincipalAdditionModal() {
      this.principalModal = true;
    },
    closePrincipalAdditionModal() {
      this.principalModal = false;
    },
    openEditPrincipalModal(val) {
      this.principalId = val.id;
      this.editPrincipalModal = true;
    },
    closeEditPrincipalModal() {
      this.editPrincipalModal = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
