import Vue from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function courseExecutor(course) {
  return course.subcontractor?.name || course.contractor?.name;
}

export function courseDriver(course) {
  return course.driver?.name;
}

export function courseRideNumber(course) {
  return course.rides.map((c) => c.number).join('+');
}

export function companyContractorStake(stake) {
  return stake / 100;
}

Vue.filter('courseExecutor', courseExecutor);
Vue.filter('courseDriver', courseDriver);
Vue.filter('courseRideNumber', courseRideNumber);
Vue.filter('companyContractorStake', companyContractorStake);
