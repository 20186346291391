<template>
  <one-page-section>
    <one-form-action>
      <v-spacer />
      <v-btn class="mt-3" color="success" @click="() => (generateReportDialog = true)" text>{{ reportName }}</v-btn>
    </one-form-action>

    <v-dialog v-model="generateReportDialog" fullscreen>
      <v-card>
        <v-toolbar color="transparent" flat>
          <v-toolbar-title>{{ reportName }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="() => (generateReportDialog = false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form v-model="valid" class="w-100">
          <one-form-section>
            <one-field-date label="Od" v-model="generateReportData.startedAt" icon="today" class="col-sm-12" />
            <one-field-date label="Do" v-model="generateReportData.finishedAt" icon="today" class="col-sm-12" />
            <v-autocomplete
              v-if="passengerFilterName"
              class="col-12"
              :label="passengerFieldName"
              prepend-icon="person"
              item-text="name"
              :items="passengers"
              :disabled="fetchingPassengers"
              :loading="fetchingPassengers"
              v-model="generateReportData.passenger"
              clearable
              return-object
              hide-details
              :rules="requireAll || requirePassenger ? [(v) => !!v || 'Pole wymagane'] : []"
            />
            <v-text-field
              v-if="kilometerField"
              label="Cena za kilomter"
              prepend-icon="attach_money"
              v-model="generateReportData.pricePerKm"
              min="0"
              step="0.1"
              type="number"
              class="col-12"
              :rules="requireAll ? [(v) => !!v || 'Pole wymagane'] : []"
            />
          </one-form-section>
          <div class="w100 d-flex justify-center mt-3">
            <v-btn color="primary" @click="generateReport" large :disabled="!valid || waiting">Generuj</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </one-page-section>
</template>

<script>
/* eslint-disable no-unused-expressions */
import format from 'date-fns/format';
import mime from 'mime';
import OneFieldDate from '@/component/Field/Date/date-field';
import OneFormAction from '@/component/Form/Action/action-form';
import OneFormSection from '@/component/Form/Section/section-form';
import OnePageSection from '@/component/Page/section-page';

export default {
  name: 'ReportGenerator',
  components: { OnePageSection, OneFormSection, OneFormAction, OneFieldDate },
  props: {
    endpoint: {
      required: true,
      type: String,
    },
    passengerFilterName: {
      default: null,
      type: String,
    },
    passengerFieldName: {
      default: 'Pasażer',
    },
    filenamePrefix: {
      type: String,
    },
    reportName: {
      default: 'Generuj raport',
    },
    startParam: {
      default: 'startedAt',
    },
    endParam: {
      default: 'finishedAt',
    },
    method: {
      default: 'GET',
    },
    type: {
      default: 'pdf',
    },
    requirePassenger: {
      default: false,
    },
    requireAll: {
      default: false,
    },
    kilometerField: {
      default: false,
    },
  },

  data() {
    return {
      generateReportDialog: false,
      generateReportData: {
        startedAt: new Date().setMonth(new Date().getMonth() - 1),
        finishedAt: new Date(),
        passenger: null,
        pricePerKm: 0.6,
      },
      fetchingPassengers: true,
      passengers: [],
      valid: true,
      waiting: false,
    };
  },

  created() {
    if (this.passengerFilterName) {
      this.fetchPassengers();
    }
  },

  methods: {
    fetchPassengers() {
      this.$http.get('api/passengers').then((response) => {
        this.passengers = response.data['hydra:member'];
        this.fetchingPassengers = false;
      });
    },

    generateReport() {
      this.waiting = true;
      this.$http(this.getRequestParams())
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers.map['content-type'][0],
          });
          const fileExtension = mime.getExtension(response.headers.map['content-type'][0]) || 'csv';
          const filename = response.headers.map['content-disposition']
            ? response.headers.map['content-disposition'][0].split('filename=')[1]
            : `${this.filenamePrefix}${format(new Date(), 'YYYY-MM-DD_HHmmss')}.${fileExtension}`;
          const result = document.createElement('a');
          result.href = window.URL.createObjectURL(blob);
          result.download = filename;
          result.click();
        })
        .finally(() => {
          this.waiting = false;
        });
    },

    getRequestParams() {
      if (this.method === 'GET') {
        const requestUrlParams = new URLSearchParams();
        this.generateReportData.startedAt
          ? requestUrlParams.set(this.startParam, this.generateReportData.startedAt)
          : '';
        this.generateReportData.finishedAt
          ? requestUrlParams.set(this.endParam, this.generateReportData.finishedAt)
          : '';
        this.generateReportData.passenger
          ? requestUrlParams.set(`${this.passengerFilterName}`, this.generateReportData.passenger.id)
          : '';

        return {
          method: 'GET',
          url: `${this.endpoint}?${requestUrlParams}`,
          responseType: 'arraybuffer',
          headers:
            this.type === 'csv'
              ? {
                  accept: 'application/csv.polish.excel',
                }
              : null,
        };
      }

      return {
        method: 'POST',
        url: this.endpoint,
        body: {
          [this.passengerFilterName]: this.generateReportData.passenger.id,
          [this.startParam]: this.generateReportData.startedAt,
          [this.endParam]: this.generateReportData.finishedAt,
          pricePerKm: +this.generateReportData.pricePerKm,
        },
        responseType: 'arraybuffer',
      };
    },
  },
};
</script>
