import IndexContainer from '@/controller/Index';
import AccommodationIndex from '@/controller/Accommodation/accommodation-index';
import ContainerIndex from '@/controller/container-index';
import Finances from '@/controller/Finances/finances-index';
import TaxiCourseRoutes from '@/core/router/Page/taxi-course-routes';

import EmployeeRequestRoutes from '@/core/router/Page/employee-request-routes';
import DriverRoutes from './Page/driver';
import DriverAbsenceRoutes from './Page/driver-absence';
import PassengerRoutes from './Page/passenger';
import PassengerChangeRoutes from './Page/passenger-change';
import companyRoutes from './Page/company';
import HotelRoutes from './Page/hotel-routes';
import InvoicesRoutes from './Page/invoices-routes';
import AccommodationRoutes from './Page/accommodation-routes';
import StartingBaseRoutes from './Page/starting-base';
import UserRoutes from './Page/user';
import PlaceRoutes from './Page/place';
import DefinedKilometerRoutes from './Page/defined-kilometer';
import FinancialSettlement from './Page/financial-settlement';
import Fleet from './Page/fleet';

export default [
  {
    path: '',
    name: 'index',
    component: IndexContainer,
  },
  {
    path: '/drivers',
    component: ContainerIndex,
    children: DriverRoutes,
  },
  {
    path: '/passengers',
    component: ContainerIndex,
    children: PassengerRoutes,
  },
  {
    path: '/passengers_change',
    component: ContainerIndex,
    children: PassengerChangeRoutes,
  },
  {
    path: '/starting_bases',
    component: ContainerIndex,
    children: StartingBaseRoutes,
  },
  {
    path: '/companies',
    component: ContainerIndex,
    children: companyRoutes,
  },
  {
    path: '/hotels',
    component: ContainerIndex,
    children: HotelRoutes,
  },
  {
    path: '/accommodations',
    component: AccommodationIndex,
    children: AccommodationRoutes,
  },
  {
    path: '/course',
    component: ContainerIndex,
    children: TaxiCourseRoutes,
  },
  {
    path: '/employee-request',
    component: ContainerIndex,
    children: EmployeeRequestRoutes,
  },
  {
    path: '/invoices',
    component: ContainerIndex,
    children: InvoicesRoutes,
  },
  {
    path: '/users',
    component: ContainerIndex,
    children: UserRoutes,
  },
  {
    path: '/driver_absences',
    component: ContainerIndex,
    children: DriverAbsenceRoutes,
  },
  {
    path: '/places',
    component: ContainerIndex,
    children: PlaceRoutes,
  },
  {
    path: '/defined-kilometers',
    component: ContainerIndex,
    children: DefinedKilometerRoutes,
  },
  {
    path: '/finances',
    component: Finances,
    name: 'finances',
    meta: {
      title: 'Finanse',
    },
  },
  {
    path: '/financial-settlement',
    component: ContainerIndex,
    children: FinancialSettlement,
  },
  {
    path: '/fleet',
    component: ContainerIndex,
    children: Fleet,
  },
];
