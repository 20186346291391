<template>
  <DialogFactory :value="value" v-on="$listeners">
    <AbstractModalHeader title="Dodaj nowe stawki">
      <template #close>
        <CloseIconButton @click="emitCloseEvent"></CloseIconButton>
      </template>
    </AbstractModalHeader>
    <v-card-text class="pb-8 flex-grow-1">
      <v-form class="d-flex flex-column" style="row-gap: 20px" ref="form">
        <v-text-field
          v-model.number="contractorStake.kilometerRate"
          label="Cena za 1 km"
          outlined
          type="number"
          hide-details="auto"
          :rules="[rules.isPositiveNumber, rules.required]"
        ></v-text-field>
        <v-text-field
          v-model.number="contractorStake.waitHourRate"
          label="Cena za 1 godz. oczekiwania"
          type="number"
          outlined
          hide-details="auto"
          :rules="[rules.isPositiveNumber, rules.required]"
        ></v-text-field>
        <date-field
          v-model="contractorStake.validFrom"
          label="Obowiązuje od"
          outlined
          :rules="[rules.required]"
          hide-details="auto"
        ></date-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0 px-6 pb-4">
      <v-btn color="primary" block @click="saveContractorStakes">Zapisz</v-btn>
    </v-card-actions>
  </DialogFactory>
</template>

<script>
import DialogFactory from '@/component/Dialog/dialog-factory';
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import DateField from '@/component/Field/Date/date-field';
import formatStake from '@/core/utils/stakeFormatter';
import formValidationRules from '@/core/utils/form-validation-rules';

export default {
  name: 'NewStakesModal',
  components: {
    DialogFactory,
    AbstractModalHeader,
    CloseIconButton,
    DateField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    strategy: {
      required: true,
    },
  },
  data() {
    return {
      contractorStake: {
        waitHourRate: null,
        kilometerRate: null,
        validFrom: null,
      },
      rules: {
        required: formValidationRules.required,
        isPositiveNumber: formValidationRules.isPositiveNumber(),
      },
    };
  },
  computed: {
    formattedContractorStakes() {
      return {
        waitHourRate: formatStake(this.contractorStake.waitHourRate),
        kilometerRate: formatStake(this.contractorStake.kilometerRate),
        validFrom: this.contractorStake.validFrom,
      };
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    saveContractorStakes() {
      if (this.$refs.form.validate()) {
        this.strategy.save(this.formattedContractorStakes).then(() => {
          this.emitCloseEvent();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
