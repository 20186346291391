<template>
  <div>
    <v-dialog
      :value="value"
      max-width="480"
      transition="dialog-bottom-transition"
      @click:outside="emitCloseEvent"
      @keydown.esc="emitCloseEvent"
    >
      <v-card tile>
        <DialogTitleHeader text="Dodaj podwykonawcę" @click="emitCloseEvent"></DialogTitleHeader>
        <v-card-text>
          <v-form class="d-flex flex-column" ref="form" style="row-gap: 16px">
            <v-autocomplete
              v-model="contractorEntity.contractor"
              label="Firma transportowa"
              :loading="companiesListLoading"
              :items="companiesToAddAsContractors"
              item-text="name"
              item-value="@id"
              hide-details="auto"
              prepend-inner-icon="mdi-domain"
              outlined
              :rules="[rules.required]"
              data-testid="add-contractor-input"
            >
              <template #append-item>
                <AddItemButton @click="openCreateCompanyModal">Dodaj firmę transportową</AddItemButton>
              </template>
            </v-autocomplete>
            <BaseTypography variant="subtitle">Warunki współpracy</BaseTypography>
            <SwitchField v-model="contractorEntity.superior">
              Nadrzędna firma transportowa dla firmy kolejowej
              <b>{{ railwayCompanyName }}</b>
            </SwitchField>
            <alert-info class="mb-0">
              <p class="mb-0">
                Nadrzędna firma transportowa ma dostęp do wszystkich kursów firmy kolejowej oraz może dysponować inne
                firmy transportowe, z którymi współpracuje firma kolejowa.
              </p>
            </alert-info>
            <BillingModelSection :billingModel.sync="contractorEntity.billingModel"></BillingModelSection>
            <v-text-field
              v-model.number="contractorEntity.waitHourRate"
              label="Cena za 1h oczekiwania"
              outlined
              hide-details="auto"
              type="number"
              :rules="[rules.isWaitHourRateValid, rules.isPositiveNumber]"
            ></v-text-field>
            <v-text-field
              v-model.number="contractorEntity.kilometerRate"
              label="Cena za 1km"
              outlined
              hide-details="auto"
              type="number"
              :rules="[rules.isKilometerRateValid, rules.isPositiveNumber]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pt-0 pb-6">
          <v-btn block color="primary" @click="save">Zapisz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreateCompanyModal
      :value="createCompanyModal"
      :strategy="createContractorStrategy"
      @close="closeCreateCompanyModal"
    ></CreateCompanyModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import DialogTitleHeader from '@/component/Dialog/dialog-title-header';
import AddItemButton from '@/component/Field/Generic/add-item-button';
import BaseTypography from '@/component/Base/base-typography';
import AlertInfo from '@/component/Alert/alert-info';
import CreateCompanyModal from '@/controller/Page/page-toolbar/create-company-modal/create-company-modal';
import formatStake from '@/core/utils/stakeFormatter';
import formValidationRules from '@/core/utils/form-validation-rules';
import SwitchField from '@/component/Field/Switch/switch-field';
import BillingModelSection from '../principal-modal/billing-model-section';
import CreateTransportCompanyStrategy from '../create-company-modal/create-transport-company.strategy';

export default {
  name: 'AddRailwayContractorModal',
  components: {
    DialogTitleHeader,
    AddItemButton,
    AlertInfo,
    SwitchField,
    CreateCompanyModal,
    BaseTypography,
    BillingModelSection,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      contractorEntity: {
        contractor: null,
        superior: false,
        billingModel: null,
        kilometerRate: null,
        waitHourRate: null,
      },
      createContractorStrategy: new CreateTransportCompanyStrategy(this.$store),
      rules: {
        required: formValidationRules.required,
        // TODO: is repeated in another modal
        isKilometerRateValid: () =>
          this.isWaitHourRateDefined ? this.isKilometerRateDefined || 'Pole jest wymagane' : true,
        isWaitHourRateValid: () =>
          this.isKilometerRateDefined ? this.isWaitHourRateDefined || 'Pole jest wymagane' : true,
        isPositiveNumber: formValidationRules.isPositiveNumber(),
      },
      createCompanyModal: false,
    };
  },
  created() {
    this.$store.dispatch('fetchCompanies', true);
  },
  computed: {
    ...mapGetters(['user', 'companiesList', 'companiesListLoading', 'loggedCompany']),
    // TODO: rename
    companiesToAddAsContractors() {
      const companyContractorsIds = this.loggedCompany?.contractors?.map(({ contractor }) => contractor.id) ?? [];
      return this.companiesList.filter((company) => !companyContractorsIds.includes(company.id));
    },
    railwayCompanyName() {
      return this.user.company.name;
    },
    // TODO: validation of stakes is duplicated
    isKilometerRateDefined() {
      return this.contractorEntity.kilometerRate !== null && this.contractorEntity.kilometerRate !== '';
    },
    isWaitHourRateDefined() {
      return this.contractorEntity.waitHourRate !== null && this.contractorEntity.waitHourRate !== '';
    },
    isStakesDefined() {
      return this.isKilometerRateDefined && this.isWaitHourRateDefined;
    },
    contractorPayload() {
      const { kilometerRate, waitHourRate, ...contractorPayload } = this.contractorEntity;

      if (this.isStakesDefined) {
        return {
          ...contractorPayload,
          stakes: [
            {
              kilometerRate: formatStake(kilometerRate),
              waitHourRate: formatStake(waitHourRate),
              validFrom: dayjs().format('YYYY-MM-DD'),
            },
          ],
        };
      }

      return contractorPayload;
    },
  },
  methods: {
    emitCloseEvent() {
      this.$emit('close');
      this.$refs.form.reset();
      this.contractorEntity.superior = false;
      this.contractorEntity.billingModel = null;
    },
    openCreateCompanyModal() {
      this.createCompanyModal = true;
    },
    closeCreateCompanyModal() {
      this.createCompanyModal = false;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('taxiCourse/addContractor', {
            contractor: this.contractorPayload,
            company: this.user.company,
          })
          .then(() => {
            this.emitCloseEvent();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// TODO: styles are repeated in a lot of places
.switch__label {
  color: #212b36;

  /* Body2 */
  // font: ;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
}
</style>
