import Vue from 'vue';
import router from './router';
import http from './resource';
import './notifications';
import './charts';
import './hotjar';

Vue.config.productionTip = false;

export { router, http };

export default {
  router,
  http,
};
