<template>
  <div class="add-button__container">
    <v-divider></v-divider>
    <v-btn class="mt-4 mb-2 ml-1" color="primary" text v-on="$listeners">
      <v-icon left>mdi-plus</v-icon>
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AutocompleteAddItemButton',
};
</script>

<style lang="scss" scoped>
.add-button__container {
  position: sticky;
  bottom: 0;
  background: white;
}
</style>
