<template>
  <component :is="currentComponent" v-bind="$attrs" v-on="$listeners"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import EditableRailwayCompanyInput from './editable-railway-company-input';
import NonEditableRailwayCompanyInput from './non-editable-railway-company-input';

export default {
  name: 'RailwayCompanyInputFactory',
  computed: {
    ...mapGetters(['isTransportCompany']),
    currentComponent() {
      return this.isTransportCompany ? EditableRailwayCompanyInput : NonEditableRailwayCompanyInput;
    },
  },
};
</script>
