<template>
  <AbstractModalHeader class="px-2" :title="`Przejazd ${rideNumber}`">
    <template #prepend-item v-if="isMobile.value"><BackIconButton @click="$emit('close')"></BackIconButton></template>
    <template #close>
      <RideContextMenuFactory :ride="rideFallback" :course="course" vertical></RideContextMenuFactory>
    </template>
  </AbstractModalHeader>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import RideContextMenuFactory from '@/component/NewCourse/RideContextMenu/ride-context-menu-factory';
import BackIconButton from '@/component/Button/back-icon-button';

const EMPTY_RIDE = {};

export default {
  name: 'TaxiRideHeader',
  components: {
    AbstractModalHeader,
    RideContextMenuFactory,
    BackIconButton,
  },
  inject: ['isMobile'],
  props: {
    rideNumber: {
      type: String,
      required: true,
    },
    ride: Object,
    course: Object,
  },
  computed: {
    rideFallback() {
      return this.ride || EMPTY_RIDE;
    },
  },
};
</script>

<style lang="scss" scoped></style>
