import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  getEventSource: (topics) => {
    const mercureHubUrl = new URL(process.env.VUE_APP_MERCURE_HUB_URL);

    topics.forEach((topic) => {
      mercureHubUrl.searchParams.append('topic', topic);
    });

    return new EventSourcePolyfill(mercureHubUrl, {
      lastEventIdQueryParameterName: 'lastEventID',
      headers: {
        // TODO: extract to a service in order to have full control over it
        Authorization: `Bearer ${localStorage.getItem(process.env.VUE_APP_JWT_STORAGE_NAME)}`,
      },
    });
  },
};
