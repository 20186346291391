// eslint-disable-next-line import/prefer-default-export
export class OpenConfirmDialogCommand {
  #componentData;

  constructor(componentData) {
    this.#componentData = componentData;
  }

  execute() {
    this.#componentData.$data.value = true;
  }
}
