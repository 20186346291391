<template>
  <section>
    <BaseTypography variant="subtitle">Samochód</BaseTypography>
    <CarButtonFactory
      :car="car"
      :isUserCreated="isUserCreated"
      @openCreateModal="openCreateModal"
      @openEditModal="openEditModal"
    ></CarButtonFactory>
    <CarModalFactory
      :state="state"
      :entity="entity"
      @carCreated="updatePassenger"
      @close="closeModal"
    ></CarModalFactory>
  </section>
</template>

<script>
import BaseTypography from '@/component/Base/base-typography';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';
import CarModalFactory from './car-modal/car-modal-factory';
import CarButtonFactory from './car-button/car-button-factory';

export default {
  name: 'CarSection',
  components: {
    BaseTypography,
    CarButtonFactory,
    CarModalFactory,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: MODAL_STATE.CLOSED,
    };
  },
  computed: {
    car() {
      return this.entity.user.car;
    },
    isUserCreated() {
      return this.entity.id != null && this.entity['@id'] != null;
    },
  },
  methods: {
    openCreateModal() {
      this.state = MODAL_STATE.CREATE_ENTITY_MODAL;
    },
    openEditModal() {
      this.state = { ...MODAL_STATE.EDIT_ENTITY_MODAL, selectedEntity: this.entity.user.car };
    },
    closeModal() {
      this.state = MODAL_STATE.CLOSED;
    },
    updatePassenger(car) {
      this.$emit('update:entity', {
        ...this.entity,
        user: {
          ...this.entity.user,
          car,
        },
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
