<template>
  <div class="premium-feature__container">
    <v-icon small id="premium-feature-icon">mdi-diamond-stone</v-icon>
  </div>
</template>

<script>
export default {
  name: 'PremiumFeatureButton',
};
</script>

<style scoped>
.premium-feature__container {
  height: 24px;
  width: 24px;
  background-color: #ff9800;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

#premium-feature-icon {
  color: white !important;
}
</style>
