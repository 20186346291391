<template>
  <v-tooltip v-if="course.driverStartAt && !course.driverEndAt" top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="success">mdi-circle-medium</v-icon>
    </template>
    <span>Start o {{ course.driverStartAt | datetimeHourFirst }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'CourseStartIndicator',
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
};
</script>
