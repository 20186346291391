<template>
  <v-app>
    <v-main>
      <HomeplaceModal :state="state" :strategy="strategy" @close="navigateToSettings"></HomeplaceModal>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeplaceModal from '@/component/Field/Place/homeplace-modal';
import * as ROUTES from '@/core/router/route-names';
import { MODAL_STATE } from '@/core/dict/modal-states-dict';

class CreateUserHomeplaceStrategy {
  #store;

  constructor(store) {
    this.#store = store;
  }

  save(val) {
    return this.#store.dispatch('addUserHomeplace', val);
  }
}

export default {
  name: 'UserHomeplaceSettings',
  components: {
    HomeplaceModal,
  },
  data() {
    return {
      state: MODAL_STATE.CREATE_ENTITY_MODAL,
    };
  },
  created() {
    this.state = this.user.home
      ? { ...MODAL_STATE.EDIT_ENTITY_MODAL, selectedEntity: this.user.home }
      : MODAL_STATE.CREATE_ENTITY_MODAL;
  },
  computed: {
    ...mapGetters(['user']),
    strategy() {
      return new CreateUserHomeplaceStrategy(this.$store);
    },
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: ROUTES.SETTINGS_ROUTE });
    },
  },
};
</script>

<style lang="scss" scoped></style>
