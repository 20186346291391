<template>
  <v-list-item
    class="d-flex justify-space-between settings__element"
    :class="{ 'settings__element--primary': variant === 'primary' }"
    active-class="selected-setting"
    exact
    v-bind="isLinkProvided ? { ...$attrs, ...linkProps } : $attrs"
    v-on="$listeners"
  >
    <v-list-item-content>
      <v-list-item-title class="settings__title">{{ title }}</v-list-item-title>
      <v-list-item-subtitle class="settings__subtitle">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <slot name="append-icon">
        <v-icon>mdi-chevron-right</v-icon>
      </slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'SettingsElement',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    variant: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'primary'].includes(value);
      },
    },
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    isLinkProvided() {
      return typeof this.href === 'string';
    },
    linkProps() {
      return {
        href: this.href,
        target: '_blank',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/core/style/variables';

.settings {
  &__element {
    background: white;
    padding: 0 8px;
    border-radius: 8px;

    &::before {
      border-radius: 8px;
    }

    &:hover {
      transition: background-color 0.3s;
      background-color: rgba(0, 125, 255, 0.06);
    }

    &--primary {
      background: #007dff1a;

      &:hover {
        background: #007dff25;
      }
    }

    & .v-list-item__content {
      padding: 8px 0;
    }
  }

  .v-list-item &__title {
    font: 600 15px/18px $base-font;
    color: $text-color--primary;
    margin-bottom: 4px;
  }

  .theme--light.v-list-item &__subtitle {
    font: 400 14px/18px $base-font;
    color: $text-color--primary;
    white-space: normal;
  }
}

.selected-setting.v-list-item {
  border: 2px solid #007dff;
  background: rgba(0, 125, 255, 0.08);

  &::before {
    border-radius: 6px !important;
  }
}
</style>
