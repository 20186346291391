<template>
  <AbstractCarModal v-bind="$attrs" v-on="$listeners">
    <template #form-extension="{ entity }">
      <DriverField v-model="entity.user" required :rules="[rules.required]" :icon="null"></DriverField>
    </template>
  </AbstractCarModal>
</template>

<script>
import DriverField from '@/component/Field/Driver/driver-field';
import formValidationRules from '@/core/utils/form-validation-rules';
import AbstractCarModal from './abstract-car-modal';

export default {
  name: 'CreateCarForDriverModal',
  components: {
    AbstractCarModal,
    DriverField,
  },
  data() {
    return {
      rules: formValidationRules,
    };
  },
};
</script>

<style lang="scss" scoped></style>
