<template>
  <AbstractCarButton
    icon="mdi-plus"
    iconColor="primary"
    btnColor="#3397FF42"
    v-bind="{ ...$attrs, ...createCarBtnConfig }"
    @click="$emit('openCreateModal')"
  ></AbstractCarButton>
</template>

<script>
import AbstractCarButton from './abstract-car-button';

export default {
  name: 'AddCarButton',
  inheritAttrs: false,
  components: {
    AbstractCarButton,
  },
  computed: {
    createCarBtnConfig() {
      return {
        title: 'Samochód',
        subtitle: 'Stwórz samochód',
      };
    },
  },
};
</script>
