<template>
  <AbstractModalHeader title="Kurs">
    <template #prepend-item>
      <CloseIconButton @click="$emit('close')"></CloseIconButton>
    </template>
    <template #append-item>
      <TaxiCourseBadge class="ml-2"></TaxiCourseBadge>
    </template>
    <template #close v-if="!isManager">
      <BaseTextButton @click="$emit('openSettlementColumn')">
        <img class="mr-2" src="../../../../../public/img/settlement-icon.svg" alt="ikona rozliczenia" />
        Rozliczenie
      </BaseTextButton>
    </template>
  </AbstractModalHeader>
</template>

<script>
import AbstractModalHeader from '@/component/Dialog/abstract-modal-header';
import CloseIconButton from '@/component/Button/close-icon-button';
import TaxiCourseBadge from '@/component/NewCourse/CourseCard/CourseBadge/taxi-course-badge';
import BaseTextButton from '@/component/Base/base-text-button';
import { mapGetters } from 'vuex';

export default {
  name: 'TaxiCourseHeader',
  components: {
    AbstractModalHeader,
    CloseIconButton,
    TaxiCourseBadge,
    BaseTextButton,
  },
  computed: {
    ...mapGetters(['isManager']),
  },
};
</script>

<style lang="scss" scoped></style>
