import { render, staticRenderFns } from "./employee-group-modal.vue?vue&type=template&id=325b2868&scoped=true"
import script from "./employee-group-modal.vue?vue&type=script&lang=js"
export * from "./employee-group-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325b2868",
  null
  
)

export default component.exports